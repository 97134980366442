import React, { FC, ChangeEvent, useState, useEffect, createRef } from 'react';

import {
  Tooltip,
  Divider,
  Box,
  Grid,
  Stack,
  FormControl,
  InputLabel,
  Card,
  Checkbox,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Select,
  MenuItem,
  Typography,
  useTheme,
  CardHeader,
  Button,
  TextField,
  LinearProgress,
  InputAdornment,
  Pagination,
  Link,
  Avatar,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { ApiError, ServiceResult } from '@core/index';
import { formatPhoneNumber, roleMapper } from '@core/utils/utils';
import BulkActions from '@components/BulkActions/BulkActions';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import { Admin, PaginatedList, Query, VideoCategory, VideoType } from '@core/constants/constants';
import { Retry } from '@components/Retry';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { AppInput, StyledSearch } from '@components/Styled';
import SearchIcon from '@mui/icons-material/Search';
import { useDeleteVideoCategoryMutation } from '@features/state/slices/api/video-slice';
import { toggleLoading } from '@features/state/slices/local/loading-slice';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { Navigate, useNavigate } from 'react-router';
import Filter from '@components/Filter';
import Iconify from '@components/iconify';
import { RefreshButton } from '@components/RefreshButton';
import { PackageCategory } from '@core/types';
import { AppDialog, IDialogRef } from '@components/AppDialog';

type Props = {
  loading: boolean;
  error: any | undefined;
  refetch: any;
  onChange: (query: Query) => void;
  data: ServiceResult<VideoCategory[], ApiError> | undefined;
};

const applyFilters = (videoCategories: VideoCategory[], filters: any): any[] => {
  return videoCategories.filter((videoCategory) => {
    let matches = true;

    if (filters.name && videoCategory.name !== videoCategory.name) {
      matches = false;
    }

    return matches;
  });
};

export function CategoriesTable({ data, loading, error, refetch, onChange }: Props) {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [filters, setFilters] = useState<any>({
    status: null,
  });
  const dialogRef = createRef<IDialogRef>();
  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(10);
  const [openFilter, setOpenFilter] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [deleteDialog, setDeleteDialog] = useState(false);

  const [videoCategoryId, setVideoCategoryId] = useState<number>(0);
  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);
  const [videoCategories, setvideoCategories] = useState<VideoCategory[]>([]);
  const [selectedvideoCategories, setSelectedvideoCategories] = useState<number[]>([]);

  const selectedBulkActions = selectedvideoCategories.length > 0;

  const [deleteVideoCategoryMutation] = useDeleteVideoCategoryMutation();

  const open = Boolean(anchorEl);

  const handleMenuOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const toggleDeleteDialog = () => {
    handleMenuClose();
    setDeleteDialog(!deleteDialog);
  };

  useEffect(() => {
    if (!loading && !error) {
      setvideoCategories(data?.data ?? []);
    }
  }, [loading, error, data]);

  const statusOptions = [
    {
      id: 'super_admin',
      name: 'Super Admin',
    },
    {
      id: 'admin',
      name: 'Admin',
    },
  ];

  const handleStatusChange = (e: any): void => {
    let value: any = null;

    if (e.target.value !== 'all') {
      value = e.target.value;
    }

    setFilters((prevFilters: any) => ({
      ...prevFilters,
      status: value,
    }));
  };

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  const handleSelectAllCryptoOrders = (event: ChangeEvent<HTMLInputElement>): void => {
    setSelectedvideoCategories(event.target.checked ? videoCategories.map((admin) => admin.id) : []);
  };

  const handleSelectOneCategory = (event: ChangeEvent<HTMLInputElement>, videoCategoryId: number): void => {
    if (!selectedvideoCategories.includes(videoCategoryId)) {
      setSelectedvideoCategories((prevSelected) => [...prevSelected, videoCategoryId]);
    } else {
      setSelectedvideoCategories((prevSelected) => prevSelected.filter((id) => id !== videoCategoryId));
    }
  };
  const handlePageChange = (event: any, page: number): void => {
    setPage(page);

    onChange({
      limit,
      page,
    });
  };

  const handleSearch = (event: any) => {
    setTimeout(
      () =>
        onChange({
          page,
          limit,
          searchTerm: event.target.value,
        }),
      1000,
    );
  };

  const handleVideoCategoryDelete = async (id: number) => {
    try {
      dispatch(toggleLoading());
      const { data, error, success } = await deleteVideoCategoryMutation({
        id,
      }).unwrap();

      if (!success && error) {
        toast.error(error.message!);
        dispatch(toggleLoading());
        return;
      }

      toast.success('Admin successfully deleted');

      refetch();
    } catch (e) {}
    toggleDeleteDialog();
    dispatch(toggleLoading());
  };

  const selectedSomevideoCategories = videoCategories.length > 0 && videoCategories.length < videoCategories.length;
  const selectedAllvideoCategories = selectedvideoCategories.length === videoCategories.length;

  return (
    <>
      {!error ? (
        <React.Fragment>
          <Card>
            {selectedBulkActions && (
              <Box flex={1} p={2}>
                <BulkActions />
              </Box>
            )}
            {!selectedBulkActions && (
              <CardHeader
                style={{ fontWeight: 'light' }}
                action={
                  <Box
                    width={500}
                    sx={{
                      float: 'right',
                      display: 'flex',
                      justifyContent: 'end',
                      alignItems: 'center',
                    }}
                  >
                    <Box></Box>
                    <Box>
                      <Filter
                        openFilter={openFilter}
                        onOpenFilter={handleOpenFilter}
                        selectedFilters={selectedFilters}
                        onCloseFilter={handleCloseFilter}
                        onFilterChange={setSelectedFilters}
                        filters={[
                          {
                            title: 'Categories',
                            filters: ['Product', 'Process'],
                          },
                        ]}
                      />
                    </Box>
                    <Box>
                      <RefreshButton onRefresh={refetch} />
                    </Box>
                  </Box>
                }
                title={
                  <StyledSearch
                    fullWidth
                    onChange={handleSearch}
                    placeholder="Search Video Categories"
                    startAdornment={
                      <InputAdornment position="start">
                        <Iconify
                          icon="eva:search-fill"
                          sx={{
                            color: 'text.disabled',
                            width: 20,
                            height: 20,
                          }}
                        />
                      </InputAdornment>
                    }
                    endAdornment={<InputAdornment position="start">{loading && <CircularProgress size={20} />}</InputAdornment>}
                  />
                }
              />
            )}
            <Divider />
            <TableContainer>
              <Table>
                {loading && <LinearProgress />}
                {!loading && (
                  <>
                    <TableHead>
                      <TableRow>
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={selectedAllvideoCategories}
                            indeterminate={selectedSomevideoCategories}
                            onChange={handleSelectAllCryptoOrders}
                          />
                        </TableCell>
                        <TableCell>ID</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell>Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {videoCategories.map((videoCategory: VideoCategory) => {
                        const isvideoCategorieselected = selectedvideoCategories.includes(videoCategory.id);
                        return (
                          <TableRow hover key={videoCategory.id} selected={isvideoCategorieselected}>
                            <TableCell padding="checkbox">
                              <Checkbox
                                color="primary"
                                checked={isvideoCategorieselected}
                                onChange={(event: ChangeEvent<HTMLInputElement>) => handleSelectOneCategory(event, videoCategory.id)}
                                value={isvideoCategorieselected}
                              />
                            </TableCell>
                            <TableCell>
                              <Typography variant="body1" fontWeight="bold" color="text.primary" gutterBottom noWrap>
                                {videoCategory.id}
                              </Typography>
                            </TableCell>
                            <TableCell
                              sx={{ cursor: 'pointer' }}
                              onClick={() => {
                                navigate(`edit/${videoCategory.id}`);
                              }}
                            >
                              <Tooltip title={videoCategory.name} arrow>
                                <Typography noWrap variant="body1" color="text.secondary">
                                  {videoCategory.name}
                                </Typography>
                              </Tooltip>
                            </TableCell>
                            <TableCell>
                              <Typography variant="body2" color="text.secondary" gutterBottom noWrap>
                                {videoCategory?.description ?? 'N/A'}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Tooltip title="Edit" arrow>
                                <IconButton
                                  size="small"
                                  color="inherit"
                                  sx={{
                                    '&:hover': {
                                      background: theme.colors.primary.lighter,
                                    },
                                    color: theme.palette.primary.main,
                                  }}
                                  onClick={() => {
                                    navigate(`edit/${videoCategory.id}`);
                                  }}
                                >
                                  <EditTwoToneIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Delete" arrow>
                                <IconButton
                                  size="small"
                                  color="inherit"
                                  sx={{
                                    '&:hover': {
                                      background: theme.colors.error.lighter,
                                    },
                                    color: theme.palette.error.main,
                                  }}
                                  onClick={() => {
                                    dialogRef.current?.openDialog();
                                    setVideoCategoryId(videoCategory.id);
                                  }}
                                >
                                  <DeleteTwoToneIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                    <AppDialog
                      ref={dialogRef}
                      title="Delete Video Category"
                      onConfirm={() => handleVideoCategoryDelete(videoCategoryId)}
                      subtitle="Are you sure you want to delete this video category?. This will render all videos under this category unsearchable in the app"
                    />
                  </>
                )}
              </Table>
            </TableContainer>
          </Card>
          {!loading && (
            <Box p={2} sx={{ float: 'right' }}>
              <Pagination color="primary" shape="rounded" variant="outlined" style={{ marginTop: 20 }} count={1} />
            </Box>
          )}
        </React.Fragment>
      ) : (
        <Retry refetch={refetch} />
      )}
    </>
  );
}
