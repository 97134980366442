import { HelperType } from '@core/enums/enums';
import Info from '../../assets/images/info.png';
import Error from '../../assets/images/error.png';
import { alpha, Box, Typography } from '@mui/material';

type Props = {
  title?: string;
  subtitle: string;
  type?: HelperType;
};

export const HelperText: React.FC<Props> = ({ type, title, subtitle }) => {
  const isInfo = type == HelperType.Info;
  return (
    <Box
      p={1}
      display="flex"
      alignItems="center"
      sx={{
        borderRadius: 0.5,
        border: `1px solid ${isInfo ? '#68C3E2' : 'red'}`,
        backgroundColor: alpha(isInfo ? '#23BDEF' : '#C62E0D', 0.1),
      }}
    >
      <img crossOrigin="anonymous" src={isInfo ? Info : Error} height={40} width={40} />
      <Box>
        <Typography ml={1} variant="h4">
          {title}
        </Typography>
        <Typography ml={1} variant="subtitle1" sx={{ fontSize: 12 }}>
          {subtitle}
        </Typography>
      </Box>
    </Box>
  );
};

HelperText.defaultProps = {
  type: HelperType.Info,
};
