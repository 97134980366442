import * as yup from 'yup';
import { BaseSubscription, StepProps } from '@core/types';
import { Navigator } from '../Navigator';
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { HelperType } from '@core/enums/enums';
import Center from '@components/Center/Center';
import { fadeInRight } from 'react-animations';
import { HelperText } from '@components/HelperText';
import { yupResolver } from '@hookform/resolvers/yup';
import TransitionWrapper from '@components/TransitionWrapper';
import { Subscription, subscriptionTabs } from '@core/constants/constants';
import { Box, Switch, Divider, Typography, FormControlLabel } from '@mui/material';
import { useGetSubscriptionsQuery } from '@features/state/slices/api/subscription-slice';
import { SubscriptionDisplay } from '@features/pages/Subscriptions/components/SubscriptionDisplay';

const schema = yup.object().shape({
  isCustom: yup.boolean().nullable(true),
  priceId: yup.string().required('Pricing Interval is required'),
  subscriptionId: yup.number().required('Subscrition Plan  is required'),
});

export const SubscriptionSelectionStep: React.FC<StepProps> = (props: StepProps) => {
  const { data, steps, activeStep, onCapture, handleBack, handleNext, handleSkip } = props;

  const {
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const {
    error,
    isError,
    isLoading,
    isSuccess,
    data: subscriptionData,
  } = useGetSubscriptionsQuery(
    {
      page: 1,
      limit: 10,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const [isCustom, setIsCustom] = useState(false);
  const [priceId, setPriceId] = useState<number | any>(null);
  const [pricePlan, setPricePlan] = useState<string | any>(null);
  const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);
  const [subscriptionId, setSubscriptionId] = useState<number | any>(null);
  const [selectedSubscription, setSelectedSubscription] = useState<Subscription | null>(null);

  useEffect(() => {
    if (!isLoading && !error) {
      setSubscriptions(subscriptionData?.data?.items ?? []);
    }
  }, [isLoading, isError, isSuccess]);

  useEffect(() => {
    if (data?.selectedSubscription) {
      setPricePlan(data?.selectedSubscription?.pricePlan);
      setSelectedSubscription(data?.selectedSubscription);
      setValue('subscriptionId', data?.selectedSubscription?.id);
      setValue('priceId', data?.selectedSubscription?.pricePlan as keyof Subscription);
    }

    setIsCustom(data?.subscriptionMetadata?.isCustom ?? false);
  }, [data]);

  const captureData = (data: any) => {
    handleNext();
    let capturedState = {
      subscriptionMetadata: { isCustom, ...data },
      selectedSubscription: {
        ...selectedSubscription,
        pricePlan,
      },
    };
    onCapture(capturedState);
  };

  const handleSelection = (subscription: any, pricingPlan: any) => {
    setPricePlan(pricingPlan);
    setSubscriptionId(subscription?.id);
    setPriceId(subscription[pricingPlan]);

    setSelectedSubscription(subscription);

    setValue('subscriptionId', subscription?.id);
    setValue('priceId', subscription[pricingPlan]);
  };

  return (
    <TransitionWrapper duration={0.2} animation={fadeInRight}>
      <Box mt={2} mb={5}>
        <Typography variant="subtitle1"></Typography>
        <HelperText title="Choose a subscription" subtitle="Select a pricing interval and choose a corresponsing subscription plan" />

        <SubscriptionDisplay
          onSelect={handleSelection}
          subscriptions={subscriptions}
          selectedSubscription={selectedSubscription}
          selectedTab={selectedSubscription?.pricePlan}
        />

        {/* {selectedSubscription && (
          <Center>
            <br></br>
            <br></br>
            <Divider textAlign="center">
              <Typography variant="button" align="center" style={{ fontSize: 11 }} gutterBottom>
                OR
              </Typography>
            </Divider>
            <Typography mt={4} variant="subtitle1" align="center" style={{ fontSize: 11 }} gutterBottom>
              Create a custom subscription using one of the predefined subscriptions as a basis
            </Typography>
            <Typography mt={2} mb={3} gutterBottom align="center" variant="subtitle1" style={{ fontSize: 11, fontWeight: 'bold' }}>
              Selected Subscription: {subscriptionTabs.find((i) => i?.value == pricePlan)?.label} for {selectedSubscription.name}{' '}
            </Typography>
            <FormControlLabel
              control={
                <Switch
                  sx={{ m: 1 }}
                  checked={isCustom}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setIsCustom(e.target.checked);
                    setValue('isCustom', e.target.checked);
                  }}
                />
              }
              label={<Typography variant="subtitle1">Do you want to create a custom subscription?</Typography>}
            />
          </Center>
        )} */}

        <br></br>
        {(errors?.subscriptionId?.message || (errors?.priceId?.message as string)) && (
          <HelperText
            type={HelperType.Error}
            title="Validation Error"
            subtitle={'Pricing Interval and Subscription Plan have not been selected'}
          />
        )}
      </Box>

      <Navigator
        steps={steps}
        handleSkip={handleSkip}
        handleBack={handleBack}
        activeStep={activeStep}
        handleNext={handleSubmit(captureData)}
      />
    </TransitionWrapper>
  );
};
