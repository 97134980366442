import * as React from 'react';
import toast from 'react-hot-toast';
import Box from '@mui/material/Box';
import jwt_decode from 'jwt-decode';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import { motion } from 'framer-motion';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { useForm } from 'react-hook-form';
import Logo from '@assets/images/logo.png';
import { useNavigate } from 'react-router';
import { AppInput } from '@components/Styled';
import Checkbox from '@mui/material/Checkbox';
import { browserName, getDeviceInfo } from '@core/utils/utils';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import RoofingVideo from '@assets/videos/login.mp4';
import CssBaseline from '@mui/material/CssBaseline';
import useResponsive from '@core/hooks/useResponsive';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { determineBorderColor } from '@core/utils/utils';
import { BrowserView, MobileView } from 'react-device-detect';
import { verticalAnimation } from '@core/constants/constants';
import FormControlLabel from '@mui/material/FormControlLabel';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { AuthenticationSchema } from '@core/constants/schemas';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { setAuth } from '@features/state/slices/local/auth.slice';
import { toggleLoading } from '@features/state/slices/local/loading-slice';
import { useAuthenticateAdminMutation } from '@features/state/slices/api/auth-slice';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormHelperText } from '@mui/material';
export default function Login() {
  const {
    watch,
    register,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(AuthenticationSchema),
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { animate, exit } = verticalAnimation;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showPassword, setShowPassword] = React.useState(false);
  const [experienceDialog, setExperienceDialog] = React.useState(false);
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
  const [authenticateAuthMutation, response] = useAuthenticateAdminMutation();

  React.useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });

  const togglePassword = () => setShowPassword(!showPassword);

  const toggleExperienceDialog = () => {
    setExperienceDialog(!experienceDialog);
  };

  const handleAuthentication = async (formData: any) => {
    try {
      const { email, password } = formData;
      dispatch(toggleLoading());

      const { error, success, data } = await authenticateAuthMutation({
        email,
        password,
        accessLogData: {
          adminEmail: email,
          device: getDeviceInfo(),
        },
      }).unwrap();

      if (data?.actionRequired && data.action == 'REQUIRES_PASSWORD_SET') {
        navigate('/auth/set-password');
        const token = data!;

        const { accessToken } = token;

        const user = jwt_decode<any>(accessToken);

        const auth = {
          token,
          user: user.user,
          role: user.role,
        };

        dispatch(setAuth(auth));
        dispatch(toggleLoading());
        return;
      }

      if (success === false && error) {
        const message = error?.message as string;

        toast.error(message);
        setResponseError(message);

        dispatch(toggleLoading());
        return;
      }

      const token = data!;

      const { accessToken } = token;

      const user = jwt_decode<any>(accessToken);

      const auth = {
        token,
        user: user.user,
        role: user.role,
      };

      dispatch(setAuth(auth));

      navigate(`/dashboard/home`);
    } catch (err) {
      toast.error('An error occured');
    }

    dispatch(toggleLoading());
  };

  const setResponseError = (message: string) => {
    setError('email', { type: 'focus' }, { shouldFocus: true });

    setError('password', { type: 'focus', message }, { shouldFocus: true });
  };

  const isDesktop = useResponsive('up', 'lg', 'sm');

  React.useEffect(() => {
    setTimeout(() => {
      setExperienceDialog(browserName !== 'Chrome');
    }, 1000);
  }, []);
  return (
    <>
      <Grid container component="main" className="login__container-height">
        <CssBaseline />
        {windowWidth > 600 && (
          <Grid item sm={6} md={8} lg={8} borderRadius={0}>
            <video className="login__video-grid" autoPlay muted loop>
              <source src={RoofingVideo} type="video/mp4" />
            </video>
          </Grid>
        )}

        <Grid item xs={12} sm={6} md={4} lg={4} component={Paper} elevation={6} square>
          <motion.div initial={{ y: 500 }} animate={animate} exit={exit}>
            <Box className="login__form-container">
              <img crossOrigin="anonymous" src={Logo} height={70} width={230} />
              <Typography variant="button" display="block" style={{ fontSize: 11 }} gutterBottom>
                ADMINISTRATOR
              </Typography>

              <Typography component="h1" variant="h5"></Typography>
              <Box noValidate className="login__form" component="form">
                <Box>
                  <FormControl variant="standard" style={{ width: '100%' }}>
                    <InputLabel shrink htmlFor="bootstrap-input">
                      Email Address
                    </InputLabel>
                    <AppInput
                      required
                      fullWidth
                      id="email"
                      placeholder="Email address"
                      style={determineBorderColor(errors?.email?.message as any)}
                      {...register('email', {
                        required: true,
                        maxLength: 80,
                      })}
                    />
                  </FormControl>

                  <FormHelperText style={{ color: 'red' }}>{errors?.email?.message as string}</FormHelperText>
                </Box>
                <Box mt={2}>
                  <FormControl variant="standard" style={{ width: '100%' }}>
                    <InputLabel shrink htmlFor="bootstrap-input">
                      Password
                    </InputLabel>

                    <AppInput
                      required
                      id="password"
                      fullWidth={true}
                      placeholder="Password"
                      {...register('password', {
                        required: true,
                        maxLength: 80,
                      })}
                      style={determineBorderColor(errors?.password?.message as any)}
                      type={showPassword ? 'text' : 'password'}
                    />

                    <div onClick={togglePassword} className="login__password-icon">
                      {showPassword ? (
                        <RemoveRedEyeIcon className="pointer" fontSize="small" />
                      ) : (
                        <VisibilityOffIcon className="pointer" fontSize="small" />
                      )}
                    </div>
                  </FormControl>
                  <FormHelperText style={{ color: 'red' }}>{errors?.password?.message as string}</FormHelperText>
                </Box>
                {/* <FormControlLabel control={<Checkbox value="remember" color="primary" />} label="Remember me" /> */}
                <Button fullWidth type="submit" variant="contained" className="login__button" onClick={handleSubmit(handleAuthentication)}>
                  Sign In
                </Button>
                <Grid container>
                  <Grid item xs>
                    <Link href="/auth/forgot-password" variant="body2" className="login__subtitle">
                      Forgot password?
                    </Link>
                  </Grid>
                </Grid>
                <Copyright className="login__copyright" />
              </Box>
            </Box>
          </motion.div>
        </Grid>
      </Grid>
      <Dialog open={experienceDialog} onClose={toggleExperienceDialog} aria-describedby="alert-dialog-slide-description">
        <DialogTitle>{'Please Note!'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {browserName !== 'other' && `We noticed you are using ${browserName}`}{' '}
            {browserName === 'other' && "We noticed you aren't using Chrome"} as your browser. For the best experience using this website,
            we recommend using{' '}
            <a
              target={'_blank'}
              href="https://www.google.com/chrome/?brand=YTUH&gclid=CjwKCAiA_6yfBhBNEiwAkmXy5x_jEwOha1Qkz-DtgdyXKlZgde4fWQtAt5D1jJVdakrT1U1bskFcrxoCoYgQAvD_BwE&gclsrc=aw.ds"
            >
              Chrome
            </a>{' '}
            as your browser.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleExperienceDialog}>OK</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit">Sales Assist</Link> {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}
