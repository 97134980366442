import React, { useState } from 'react';
import Filter from '@components/Filter';
import AppChip from '@components/AppChip';
import Iconify from '@components/iconify';
import { useAuth } from '@core/utils/utils';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router';
import { fadeInRight } from 'react-animations';
import { StyledSearch } from '@components/Styled';
import AppPageHeader from '@components/PageHeader';
import { baseQuery } from '@core/constants/constants';
import { RefreshButton } from '@components/RefreshButton';
import { PackageLisitng } from './components/PackageListing';
import TransitionWrapper from '@components/TransitionWrapper';
import AppTablePagination from '@components/AppTablePagination';
import { useGetPackagesQuery } from '@features/state/slices/api/package-slice';
import { Box, Button, CircularProgress, InputAdornment, Tooltip } from '@mui/material';
import { Retry } from '@components/Retry';
import Center from '@components/Center/Center';

type Props = {};

export default function ManagePackages({}: Props) {
  const { user } = useAuth();
  const navigate = useNavigate();

  const [openFilter, setOpenFilter] = useState(false);
  const { data, error, isError, isFetching, isLoading, isSuccess, refetch } = useGetPackagesQuery(baseQuery, {
    refetchOnMountOrArgChange: true,
  });

  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);

  const [query, setQuery] = useState(baseQuery);

  const handleSearch = (e: any) => {
    setTimeout(() => {
      setQuery({
        page: query.page,
        limit: query.limit,
        searchTerm: e.target.value,
      });
      refetch();
    }, 1000);
  };

  const handlePaginationChanged = (limit: number, page: number) => {
    setQuery({
      page,
      limit,
      searchTerm: query.searchTerm,
    });
    refetch();
  };

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  const navigateToCreatePackage = () => {
    navigate('create');
  };

  const contentAvailable = isSuccess && data?.data?.items !== null && data!.data!.items!.length > 0 && !isFetching;

  return (
    <TransitionWrapper duration={0.3} animation={fadeInRight}>
      <Helmet>
        <title>Manage Packages</title>
      </Helmet>

      <AppPageHeader
        title="Packages"
        subtitle={`${user?.name}, these are your recent packages`}
        trailing={
          <Button variant="contained" sx={{ mt: { xs: 2, md: 0 } }} onClick={navigateToCreatePackage}>
            Create a Package
          </Button>
        }
      />

      <Box display="flex" alignItems="center" justifyContent="space-between" mb={3}>
        <Box>
          <StyledSearch
            fullWidth
            sx={{ height: 45, borderRadius: 0.8 }}
            onChange={handleSearch}
            placeholder="Search Packages"
            startAdornment={
              <InputAdornment position="start">
                <Iconify
                  icon="eva:search-fill"
                  sx={{
                    color: 'text.disabled',
                    width: 20,
                    height: 20,
                  }}
                />
              </InputAdornment>
            }
          />
          {isFetching || (isLoading && <CircularProgress />)}
          {selectedFilters.map((filter) => (
            <Tooltip title={filter} placement="top">
              <AppChip sx={{ ml: 1 }}>{filter}</AppChip>
            </Tooltip>
          ))}
        </Box>

        <Box display="flex" alignItems="center" justifyContent="center">
          <Filter
            openFilter={openFilter}
            onOpenFilter={handleOpenFilter}
            selectedFilters={selectedFilters}
            onCloseFilter={handleCloseFilter}
            onFilterChange={setSelectedFilters}
            filters={[
              {
                title: 'Category',
                filters: ['Residential', 'Commercial'],
              },
            ]}
          />
          <RefreshButton onRefresh={refetch} />
        </Box>
      </Box>
      <br></br>

      <br></br>
      <br></br>
      {isFetching && (
        <Center>
          <Box mt={10}>
            <CircularProgress />
          </Box>
        </Center>
      )}

      {isError && !isSuccess && !isFetching && <Retry refetch={refetch} />}

      {contentAvailable && (
        <Box>
          <PackageLisitng packages={data?.data?.items ?? []} refetch={refetch} />
          <AppTablePagination onChange={handlePaginationChanged} totalPages={data?.data?.meta.totalPages ?? 1} />
        </Box>
      )}
    </TransitionWrapper>
  );
}
