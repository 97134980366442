import * as yup from 'yup';
import { BackButton } from '@components/BackButton';
import { HelperText } from '@components/HelperText';
import KeywordSelector from '@components/KeywordSelector';
import { AppInput } from '@components/Styled';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import TransitionWrapper from '@components/TransitionWrapper';
import { determineBorderColor } from '@core/utils/utils';
import {
  useEditVideoMutation,
  useGetVideoByIdQuery,
  useGetVideoCategoriesQuery,
  useGetVideoRecommendedTagsQuery,
  useGetVideoTypesQuery,
  useEditVideoMediaMutation,
} from '@features/state/slices/api/video-slice';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Autocomplete,
  Box,
  Card,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router';
import { VideoMode, VideoTypes, HelperType } from '@core/enums/enums';
import { Company, Query, VideoCategory, VideoFilter, VideoType, acceptedVideoFormats } from '@core/constants/constants';
import { PackageCategorySelect } from '../../VideoPackageManagement/Package/components/PackageCategorySelect';
import { TimeRangeInput } from './UploadVideo/components/TimeRangeInput';
import { SearchField } from '@components/SearchBar';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-hot-toast';
import { toggleLoading } from '@features/state/slices/local/loading-slice';
import { useGetCompaniesQuery } from '@features/state/slices/api/company-slice';
import { PackageCategory, Video } from '@core/types';
import { useGetPackageCategoriesQuery } from '@features/state/slices/api/package-slice';
import FileUpload from 'react-material-file-upload';

type Props = {};

const schema = yup.object().shape({
  videoName: yup.string().required('Video name is required'),
  keywords: yup.mixed().test('keywords', 'Keywords are required', (value) => {
    if (value === undefined) {
      return false;
    }
    if (value?.length === 0) return false;
    return true;
  }),
  price: yup.number().required('Price is required').min(1, 'Price must be greater than 1$').positive(),
  description: yup.string().required('Description is required'),
  videoCategory: yup.string().required('Video Category is required'),
  videoLengthType: yup.string().required('Video Type is required'),
  packageCategoryId: yup.string().required('Package Category is required'),
  video: yup.mixed().test('video', 'The file is too large', (value) => {
    if (value == null) {
      return true;
    }

    return value && value.size <= 2_000_000_000;
  }),
});

export const EditVideo: React.FC<Props> = ({}) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [type, setType] = useState(1);
  const [keywords, setKeywords] = useState<string[]>([]);
  const [types, setVideoTypes] = useState<VideoType[]>([]);

  const [editVideoMutation, response] = useEditVideoMutation();
  const [editVideoMediaMutation, mediaResponse] = useEditVideoMediaMutation();
  const [searchedVideo, setSearchedVideo] = useState<any>(null);
  const [category, setCategory] = useState<number | null>(null);
  const [packageCategories, setPackageCategories] = useState<PackageCategory[]>();
  const [parentVideoTimestamp, setTimestamp] = useState<any>(null);
  const [selectedCompany, setSelectedCompany] = useState<Company[]>();
  const [packageCategory, setPackageCategory] = useState<number | undefined>();
  const [categories, setVideoCategories] = useState<VideoCategory[]>([]);
  const [companies, setCompanies] = useState<Company[]>([]);
  const [filters, setFilters] = useState<VideoFilter[]>([]);
  const [selectedFilters, setSelectedFilters] = useState<VideoFilter[]>();
  const { state } = useLocation();
  const [files, setFiles] = useState<File[]>([]);
  const {
    watch,
    register,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { data: videoTypes, error: typesError, isLoading: typesLoading } = useGetVideoTypesQuery({});

  const { data: videoCategories, error: categoriesError, isLoading: categoriesLoading } = useGetVideoCategoriesQuery({});

  const {
    data,

    error: videoError,
    isLoading: videoLoading,
  } = useGetVideoByIdQuery({
    id,
  });
  const { data: videoTags, error: tagsError, isLoading: tagsLoading } = useGetVideoRecommendedTagsQuery({});

  const {
    data: packageCat,
    isError: ispackageCatError,
    isLoading: packageCatLoading,
    isFetching: packageCatFecth,
  } = useGetPackageCategoriesQuery({
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (!ispackageCatError && packageCat && !packageCatFecth && !packageCatLoading) {
      setPackageCategories(packageCat?.data);
    }
  }, [packageCatFecth]);

  const [query, setQuery] = useState<Query>({
    page: 1,
    limit: 10,
    searchTerm: '',
  });

  const {
    data: comapniesFetched,
    error: compaaniesError,
    isLoading: companiesLoading,
    refetch,
  } = useGetCompaniesQuery(
    {
      limit: query.limit,
      page: query.page,
      searchTerm: query.searchTerm,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const watchedType = watch<string>('videoLengthType');
  const watchedCategory = watch<string>('videoCategory');
  const watchedPackageCategory = watch<string>('packageCategoryId');

  useEffect(() => {
    setType(watchedType);
  }, [watchedType]);

  useEffect(() => {
    setCategory(watchedCategory);
  }, [watchedCategory]);

  useEffect(() => {
    setPackageCategory(watchedPackageCategory);
  }, [watchedPackageCategory]);

  useEffect(() => {
    if (videoTypes && !typesError && !typesLoading) {
      setVideoTypes(videoTypes.data!);
    }
  }, [typesError, typesLoading]);

  useEffect(() => {
    if (videoTags && !tagsError && !tagsLoading) {
      setFilters(videoTags.data!);
      console.log('TAGS ROOT ', videoTags.data!);
    }
  }, [tagsError, tagsLoading]);

  useEffect(() => {
    if (videoCategories && !categoriesError && !categoriesLoading) {
      setVideoCategories(videoCategories.data!);
    }
  }, [categoriesError, categoriesLoading]);

  useEffect(() => {
    if (data && !videoLoading && !videoError) {
      console.log('VIDEO ', data?.data);
      console.log(`BOOLS ${!videoLoading && !videoError}`);
      const keywords = data!.data!.keywords.split(',');

      setKeywords(keywords);
      setValue('keywords', keywords);
      setValue('price', data?.data?.price);
      setValue('videoName', data?.data?.videoName);
      setValue('description', data?.data?.description);
      setValue<string>('videoLengthType', data?.data?.videoLengthType);
      setValue<string>('videoCategory', data?.data?.videoCategory);
      setValue('packageCategoryId', data?.data?.packageCategoryId);

      setPackageCategory(data?.data?.packageCategoryId);
      setType(parseInt(data?.data?.videoLengthType as unknown as any));
      setCategory(parseInt(data?.data?.videoCategory as unknown as any));
      setSelectedCompany(data?.data?.selectedCompanies!);
      setSelectedFilters(data?.data?.tags!);
      console.log('TAGS SLECTED ', data?.data?.tags!);
    }
  }, [videoLoading, videoError]);

  useEffect(() => {
    if (comapniesFetched && !compaaniesError && !companiesLoading) {
      setCompanies(comapniesFetched.data!.items);
    }
  }, [compaaniesError, companiesLoading]);

  const editPackage = async (data: any) => {
    console.log('Edit package pressed');
    dispatch(toggleLoading());
    const payload = {
      ...data,
      ...{
        id,
        parentVideoTimestamp,
        keywords: data.keywords.toString(),
        parentVideoId: searchedVideo?.id ?? null,
        tags: JSON.stringify(selectedFilters!.map((e) => e.id)),
        selectedCompanies: JSON.stringify(selectedCompany!.map((e) => e.id)),
      },
    };
    console.log('ERROR Object ', data);
    try {
      const result = await editVideoMutation(payload).unwrap();

      if (!result.success && result?.error) {
        toast.error(result?.error?.message);
        dispatch(toggleLoading());
        return;
      }

      toast.success('Your video was successfully updated');
    } catch (err: any) {
      console.log('ERROR UPLOAD ', err);

      toast.error(err ?? 'An error occured');
      dispatch(toggleLoading());
    }

    if (getValues('video') != null) {
      const formData = new FormData();
      formData.append('video', getValues('video'));
      const mediaPayload = { id: id as string, video: formData };
      console.log('A Video was uploaded', formData.get('video'));
      try {
        const result = await editVideoMediaMutation(mediaPayload).unwrap();

        if (!result.success && result?.error) {
          toast.error(result?.error?.message);
          dispatch(toggleLoading());
          return;
        }

        toast.success('Your video media was successfully replaced ');
      } catch (err: any) {
        console.log('ERROR UPLOAD ', err);

        toast.error(err ?? 'An error occured');
        dispatch(toggleLoading());
      }
    }
    dispatch(toggleLoading());
  };

  const onChange = (files: File[]) => {
    setFiles(files);
    setValue('video', files[0]);
  };
  return (
    <TransitionWrapper>
      <Card sx={{ p: 3 }}>
        <Box sx={{ gap: 3, display: 'flex', flexDirection: 'column' }}>
          <Box display="flex" justifyContent="space-between">
            <Box>
              <Box display="flex">
                <BackButton />
                <Typography ml={1} variant="h2">
                  Edit Video
                </Typography>
              </Box>

              <Typography variant="subtitle1" mt={1}>
                Update your standard video details here
              </Typography>
            </Box>
          </Box>{' '}
        </Box>

        <Box mt={2} mb={5}>
          <FormControl variant="standard" style={{ width: '100%', marginTop: 15 }}>
            <InputLabel shrink htmlFor="bootstrap-input">
              Title
            </InputLabel>
            <AppInput
              required
              fullWidth
              id="name"
              {...register('videoName', {
                required: true,
                maxLength: 80,
              })}
              style={determineBorderColor(errors?.name?.message as any)}
              onChange={(event) => {
                setValue('videoName', event.target.value);
              }}
            />
            <FormHelperText style={{ color: 'red' }}>{errors?.name?.message as string}</FormHelperText>
          </FormControl>

          <FormControl variant="standard" style={{ width: '100%', marginTop: 15 }}>
            <InputLabel shrink htmlFor="bootstrap-input">
              Description
            </InputLabel>
            <AppInput
              required
              fullWidth
              id="description"
              {...register('description', {
                required: true,
                maxLength: 80,
              })}
              // multiline
              rows={4}
              maxRows={3}
              style={determineBorderColor(errors?.description?.message as any)}
              onChange={(event) => {
                setValue('description', event.target.value);
              }}
            />
            <FormHelperText style={{ color: 'red' }}>{errors?.description?.message as string}</FormHelperText>
          </FormControl>
          <FormControl variant="standard" style={{ width: '100%', marginTop: 15 }}>
            <InputLabel shrink htmlFor="bootstrap-input">
              Keywords
            </InputLabel>
            <KeywordSelector
              keywords={keywords}
              videoMode={VideoMode.Edit}
              subscriptionMode={null}
              onSelected={(keywords) => {
                setKeywords(keywords);
                setValue('keywords', keywords);
              }}
            />
            <FormHelperText style={{ color: 'red' }}>{errors?.keywords?.message as string}</FormHelperText>
          </FormControl>
        </Box>

        <FormControl variant="standard" style={{ width: '100%', marginTop: 5 }}>
          <InputLabel shrink htmlFor="bootstrap-input">
            Price
          </InputLabel>
          <AppInput
            required
            fullWidth
            id="price"
            type="number"
            {...register('price', {
              required: true,
              maxLength: 80,
            })}
            style={determineBorderColor(errors?.price?.message as any)}
            onChange={(event) => {
              setValue('price', event.target.value);
            }}
          />
          <div className="input-icon">
            <AttachMoneyIcon style={{ width: 20, color: 'grey' }} />
          </div>
        </FormControl>
        <FormHelperText style={{ color: 'red' }}>{errors?.price?.message as string}</FormHelperText>
        <Box mb={5}>
          <Box>
            <br></br>
            <InputLabel className="input-label">Type</InputLabel>
            <FormControl fullWidth variant="outlined">
              <Select
                label="Type"
                value={type}
                className="country-select__base"
                style={determineBorderColor(errors?.type?.message as any)}
                {...register<string>('videoLengthType', {
                  required: true,
                  maxLength: 80,
                })}
              >
                {types.map((x) => (
                  <MenuItem key={x.id} value={x.id}>
                    {x.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormHelperText style={{ color: 'red' }}>{errors?.type?.message as string}</FormHelperText>
          </Box>

          <Box mt={3}>
            <InputLabel className="input-label">Category</InputLabel>
            <FormControl fullWidth variant="outlined">
              <Select
                value={category}
                label="Category"
                className="country-select__base"
                style={determineBorderColor(errors?.category?.message as any)}
                {...register('videoCategory', {
                  required: true,
                  maxLength: 80,
                })}
              >
                {categories.map((x) => (
                  <MenuItem key={x.id} value={x.id}>
                    {x.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormHelperText style={{ color: 'red' }}>{errors?.category?.message as string}</FormHelperText>
          </Box>
          {packageCategory && packageCategories ? (
            <>
              <Box mt={3}>
                <InputLabel className="input-label">Package Category </InputLabel>
                <FormControl fullWidth variant="outlined">
                  <Select
                    value={packageCategory}
                    label="Package Category"
                    className="country-select__base"
                    style={determineBorderColor(errors?.category?.message as any)}
                    {...register('packageCategoryId', {
                      required: true,
                      maxLength: 80,
                    })}
                  >
                    {packageCategories.map((x) => (
                      <MenuItem key={x.id} value={x.id}>
                        {x.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormHelperText style={{ color: 'red' }}>{errors?.category?.message as string}</FormHelperText>
              </Box>
            </>
          ) : (
            <></>
          )}

          {/* <Box mt={3}>
            <InputLabel className="input-label">Package Category</InputLabel>

            <PackageCategorySelect
              label="Category"
              name="category"
              placeholder="Category"
              category={packageCategory}
              helperText={errors.packageTypeId?.message}
              error={errors.packageTypeId?.message !== undefined}
              onChange={(category: any) => {
                console.log('package changed ', category);
                setPackageCategory(category);
                setValue('packageCategoryId', category?.id, {
                  shouldValidate: true,
                });
              }}
            />
          </Box> */}
        </Box>

        {data && !videoLoading && !videoError && selectedCompany && type == VideoTypes.CLIP ? (
          <Box mt={3}>
            <InputLabel className="input-label" sx={{ marginBottom: 2 }}>
              Companies Assigned
            </InputLabel>

            <Autocomplete
              multiple
              limitTags={2}
              id="multiple-limit-tags"
              options={companies}
              getOptionLabel={(option) => option.name!}
              defaultValue={selectedCompany}
              onChange={(event, newCompany) => {
                setSelectedCompany(newCompany);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={selectedCompany.length == 0 ? 'All Companies' : 'Companies Assigned'}
                  placeholder="Select a Company"
                />
              )}
              isOptionEqualToValue={(a, b) => a.id === b.id}
            />
          </Box>
        ) : (
          <></>
        )}

        {data && !videoLoading && !videoError && selectedFilters && type == VideoTypes.CLIP && filters ? (
          <Box mt={3}>
            <InputLabel className="input-label" sx={{ marginBottom: 2 }}>
              Assigned Video Tags
            </InputLabel>

            <Autocomplete
              multiple
              limitTags={2}
              id="multiple-limit-tags"
              options={filters}
              getOptionLabel={(option) => option.name}
              defaultValue={selectedFilters}
              onChange={(event, newFilter) => {
                console.log('NEW FILTERS', newFilter);
                setSelectedFilters(newFilter);
              }}
              renderInput={(params) => (
                <TextField {...params} label={filters.length == 0 ? 'No Tags' : 'Tags Assigned'} placeholder="Select a Tag" />
              )}
              isOptionEqualToValue={(a, b) => a.id === b.id}
            />
          </Box>
        ) : (
          <></>
        )}

        {type === VideoTypes.CLIP ? (
          <Box mt={4} display="flex" width={500}>
            <Box>
              <InputLabel className="input-label">
                You have selected clip as your video type, you can search for the parent video in the field below
              </InputLabel>
              <SearchField onSelected={setSearchedVideo} width={500} />
            </Box>

            {searchedVideo && <TimeRangeInput onCompleted={setTimestamp} />}
          </Box>
        ) : (
          <div />
        )}

        <Box mt={4}>
          <HelperText title="Replace the Standard Video" subtitle="We recommend you upload .mp4 files. The maximum video size is 2GB" />

          <br></br>
          <FileUpload
            value={files}
            onChange={onChange}
            accept={acceptedVideoFormats}
            //sx={determineBorderColor(errors?.video?.message as string)}
          />
          <br></br>
          {errors?.video?.message && (
            <HelperText title="Validation Error" type={HelperType.Error} subtitle={errors?.video?.message as string} />
          )}
        </Box>

        <Box display="flex" justifyContent="end" mt={5}>
          <LoadingButton size="medium" type="submit" variant="contained" loading={response.isLoading} onClick={handleSubmit(editPackage)}>
            {response.isLoading ? <CircularProgress /> : 'Save Changes'}
          </LoadingButton>
        </Box>
      </Card>
    </TransitionWrapper>
  );
};
