import moment from 'moment';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { Video } from '@core/types';
import { motion } from 'framer-motion';
import Slide from '@mui/material/Slide';
import React, { createRef } from 'react';
import { useDispatch } from 'react-redux';
import AppChip from '@components/AppChip';
import Skeleton from '@mui/material/Skeleton';
import ErrorIcon from '@mui/icons-material/Error';
import ReplayIcon from '@mui/icons-material/Replay';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useNavigate, useLocation } from 'react-router';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { VideoProcessingStatus } from '@core/enums/enums';
import { AppDialog, IDialogRef } from '@components/AppDialog';
import ProgressiveImage from 'react-progressive-graceful-image';
import { toggleLoading } from '@features/state/slices/local/loading-slice';
import VideoVector from '../../../../../../assets/images/video-processing.webp';
import { useDeleteVideoMutation } from '@features/state/slices/api/video-slice';
import { Grid, Box, Menu, Button, Tooltip, MenuItem, Typography, LinearProgress } from '@mui/material';

const LINES_TO_SHOW = 2;

type Props = {
  video: Video;
  index: number;
  refetch: any;
};

const Transition = React.forwardRef(function Transition(props, ref) {
  const SlideComponent = Slide as any;
  return <SlideComponent direction="up" ref={ref} {...props} />;
});

export const VideoItem: React.FC<Props> = ({ video, index, refetch }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const handleShow = () => setShow(true);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const dialogRef = createRef<IDialogRef>();
  const [isHovering, setIsHovering] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [deleteVideoMutation] = useDeleteVideoMutation();
  const [deleteDialog, setDeleteDialog] = useState(false);

  const hasFailed = video.status == VideoProcessingStatus.FAILED;
  const hasCompleted = video.status == VideoProcessingStatus.COMPLETED;
  const isProcessing = video.status == VideoProcessingStatus.PROCESSING;

  const open = Boolean(anchorEl);

  const handleMenuOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const toggleDeleteDialog = () => {
    handleMenuClose();
    dialogRef.current?.openDialog();
  };

  const handleEdit = () => {
    console.log('VIDEO SENT', video.selectedCompanies);
    navigate(`edit/${video.id}`, { state: { video: video } });
  };
  const deleteVideo = async () => {
    toggleDeleteDialog();
    dispatch(toggleLoading());
    try {
      const result = await deleteVideoMutation({ id: video.id }).unwrap();

      if (result.success) {
        toast.success('Video deleted');
        refetch();
      } else {
        toast.error(result.error?.message!);
      }
    } catch (err) {
      console.error('Unable to delete your video');
    }
    dispatch(toggleLoading());
  };

  const determineThumbnail = () => {
    return video.videoThumbnail;
  };

  const determineVideoLengthType = (videoLengthType: string | number) => {
    switch (videoLengthType) {
      case 1:
        return 'Full';
      case 2:
        return 'Clip';
      default:
        return '';
    }
  };

  const determineClass = (classes: string[] = []): string => {
    switch (video.status) {
      case VideoProcessingStatus.PROCESSING:
        return [...classes, ...['video-processing']]?.join(' ');
      case VideoProcessingStatus.COMPLETED:
        return [...classes, ...[' video-completed']]?.join(' ');
      case VideoProcessingStatus.FAILED:
        return [...classes, ...[' video-failed']]?.join(' ');
      default:
        return classes.join(' ');
    }
  };

  const determineStyle = (): React.CSSProperties => {
    switch (video.status) {
      case VideoProcessingStatus.PROCESSING:
        return {
          cursor: 'not-allowed',
          borderBottomLeftRadius: 5,
          borderBottomRightRadius: 5,
        };
      case VideoProcessingStatus.FAILED:
        return { borderRadius: 5, cursor: 'not-allowed' };
      case VideoProcessingStatus.COMPLETED:
        return { borderRadius: 5, cursor: 'pointer' };

      default:
        return {};
    }
  };

  return (
    <>
      <Grid item xs={2} sm={4} md={4} mb={5} key={index}>
        <motion.div>
          <Box
            style={{ borderRadius: 0 }}
            sx={{ boxShadow: 10, borderRadius: 0 }}
            onClick={() => {
              if (hasCompleted && location.pathname.includes('stream')) {
                navigate(`/dashboard/videos/standard-videos/stream/${video.id}`, { replace: true, state: { from: location } });
              } else if (hasCompleted) {
                navigate(`stream/${video.id}`);
              }
            }}
          >
            <Box className="processing-container" sx={{ cursor: 'pointer' }}>
              {isProcessing && <LinearProgress style={{ borderTopRightRadius: 10, borderTopLeftRadius: 10 }} />}

              <div>
                <ProgressiveImage src={determineThumbnail() as string} placeholder={determineThumbnail() as string}>
                  {(src, loading) => {
                    if (loading) {
                      return (
                        <Skeleton
                          width={350}
                          height={200}
                          animation="wave"
                          variant="rectangular"
                          style={determineStyle()}
                          className={determineClass()}
                          sx={{ borderBottomLeftRadius: 10, borderBottomRightRadius: 10 }}
                        />
                      );
                    } else {
                      return <img src={src} width={350} height={200} style={determineStyle()} className={determineClass()} />;
                    }
                  }}
                </ProgressiveImage>
              </div>

              {hasFailed && (
                <Button size="small" className="processing-overlay processing-retry-button">
                  Retry <ReplayIcon fontSize="small" />
                </Button>
              )}

              {hasCompleted && (
                <Box className="duration">
                  <Typography fontSize={10} color="white">
                    {moment.utc(video.videoLength).format('mm:ss')}
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>

          <div
            style={{
              width: 350,
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Typography mt={1} variant="h4">
              {video.videoName}
            </Typography>

            <Box mt={1} display="flex" className="pointer" sx={{ color: 'grey' }}>
              <AppChip color="info">{determineVideoLengthType(video?.videoLengthType)}</AppChip>
              <Box>
                <MoreVertIcon onClick={handleMenuOpen} />
                <Menu
                  open={open}
                  id="basic-menu"
                  anchorEl={anchorEl}
                  onClose={handleMenuClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  {hasFailed && <MenuItem onClick={handleClose}>Retry</MenuItem>}
                  <MenuItem onClick={handleEdit}>Edit</MenuItem>
                  <MenuItem onClick={toggleDeleteDialog}>Delete</MenuItem>
                </Menu>
              </Box>
            </Box>
          </div>
          <Box width={350} display="flex" alignItems="center" justifyContent="space-between">
            <Box>
              {video.description.length > 80 ? (
                <Tooltip title={video.description} arrow enterDelay={300} leaveDelay={200}>
                  <Typography
                    color="grey"
                    variant="subtitle1"
                    width={200}
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      display: '-webkit-box',
                      WebkitLineClamp: LINES_TO_SHOW,
                      WebkitBoxOrient: 'vertical',
                    }}
                  >
                    {video?.description}
                  </Typography>
                </Tooltip>
              ) : (
                <Typography color="grey" variant="subtitle1" width={200}>
                  {video?.description}
                </Typography>
              )}
            </Box>
            <Box display="flex">
              <Typography color="grey" variant="subtitle1" align="right" fontSize={11} width={100} mr={1}>
                {video?.createdAt?.split('T')[0]}
              </Typography>
              {hasFailed && (
                <Tooltip title={video?.transcodingErrorMessage}>
                  <ErrorIcon sx={{ fontSize: 18, cursor: 'pointer', color: '#d9534f' }} />
                </Tooltip>
              )}
            </Box>
          </Box>
        </motion.div>
      </Grid>

      <AppDialog
        ref={dialogRef}
        onConfirm={deleteVideo}
        title={`Delete ${video.videoName}`}
        subtitle={`Are you sure you want to delete ${video.videoName}, any packages that contain this video will be affected`}
      />
    </>
  );
};
