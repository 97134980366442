import { ApiMethods, BaseEndpoints } from '@core/enums/enums';
import { baseQueryWithReauth } from './base-slice';
import { ApiError, ServiceResult } from '@core/index';
import { TagDescription, createApi } from '@reduxjs/toolkit/query/react';
import { PaginatedList, CustomVideoRequest } from '@core/constants/constants';
import { Country, Province } from '@core/types';

const descriptionTags = ['Service'] as readonly TagDescription<'Service'>[] & string[];

export const serviceSlice = createApi({
  tagTypes: descriptionTags,
  baseQuery: baseQueryWithReauth,
  reducerPath: BaseEndpoints.Service,

  endpoints: (builder) => ({
    generateThumbnail: builder.mutation<any, any>({
      query: (body: any) => ({
        body,
        method: ApiMethods.POST,
        url: `/${BaseEndpoints.Service}/transcoder/generate-thumbnail`,
      }),
    }),
    trimVideo: builder.mutation<any, any>({
      query: (body: any) => ({
        body,
        method: ApiMethods.POST,
        url: `/${BaseEndpoints.Service}/transcoder/trim-video`,
      }),
    }),
    getCountries: builder.query<ServiceResult<Country[], ApiError>, any>({
      query: (params) => ({
        method: ApiMethods.GET,
        url: `/${BaseEndpoints.Service}/get-countries`,
      }),
    }),
    getProvincesByCountry: builder.query<ServiceResult<Province[], ApiError>, any>({
      query: (params: any) => ({
        method: ApiMethods.GET,
        url: `/${BaseEndpoints.Service}/get-provinces/${params.iso}`,
      }),
    }),
  }),
});
export const { useGenerateThumbnailMutation, useTrimVideoMutation, useLazyGetCountriesQuery, useLazyGetProvincesByCountryQuery } =
  serviceSlice;
