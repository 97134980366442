import ComputerOutlinedIcon from '@mui/icons-material/ComputerOutlined';
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';
import TabletAndroidOutlinedIcon from '@mui/icons-material/TabletAndroidOutlined';

export const activeUsersChartData = [300, 590, 700, 800, 900, 850];

export const totalViewsChartData = [650, 554, 300, 500, 700, 450];

export const revenueChartData = [540, 590, 560, 600, 800, 900];

export const trafficeShareChartData = [
  { id: 1, label: 'Desktop', value: 60, color: '#272b3e', icon: ComputerOutlinedIcon },
  { id: 2, label: 'Mobile', value: 30, color: '#4fa2ca', icon: TabletAndroidOutlinedIcon },
  { id: 3, label: 'Tablet', value: 10, color: '#4b978b', icon: PhoneAndroidOutlinedIcon },
];

export const platformSubjectChartData = {
  labels: ['Subscriptions', 'Views', 'Users', 'Videos', 'Request'],
  metrics: [
    { name: 'Series 1', data: [80, 50, 30, 40, 100] },
    { name: 'Series 2', data: [20, 30, 40, 80, 20] },
    { name: 'Series 3', data: [44, 76, 78, 13, 43] },
  ],
};

export const appPerformanceChartData = {
  labels: [
    '01/01/2003',
    '02/01/2003',
    '03/01/2003',
    '04/01/2003',
    '05/01/2003',
    '06/01/2003',
    '07/01/2003',
    '08/01/2003',
    '09/01/2003',
    '10/01/2003',
    '11/01/2003',
  ],
  metrics: [
    {
      name: 'Traffic',
      type: 'column',
      fill: 'solid',
      data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30],
    },
    {
      name: 'Revenue',
      type: 'area',
      fill: 'gradient',
      data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43],
    },
    {
      name: 'Interactivity',
      type: 'line',
      fill: 'solid',
      data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39],
    },
  ],
};
