import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Roles } from '@core/enums/enums';
import { AppInput } from '@components/Styled';
import { BackButton } from '@components/BackButton';
import { yupResolver } from '@hookform/resolvers/yup';
import { determineBorderColor } from '@core/utils/utils';
import { ForgotPasswordSchema } from '@core/constants/schemas';
import { toggleLoading } from '@features/state/slices/local/loading-slice';
import { useRecoverPasswordMutation } from '@features/state/slices/api/admin-slice';
import { setForgotPasswordEmail } from '@features/state/slices/local/forgot.password.slice';
import { Box, Button, FormControl, FormHelperText, Grid, InputLabel, Typography } from '@mui/material';

type Props = {
  onNext: () => void;
};

const SendRecoveryEmail: React.FunctionComponent<Props> = ({ onNext }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(ForgotPasswordSchema),
  });

  const dispatch = useDispatch();

  const [recoverPasswordMutation] = useRecoverPasswordMutation();

  const recoverPassword = async (data: any) => {
    dispatch(toggleLoading());
    try {
      const { error, success } = await recoverPasswordMutation({
        email: data.email,
        userRole: Roles.Admin,
      }).unwrap();

      if (error && !success) {
        toast.error(error.message!);
        return;
      }

      dispatch(setForgotPasswordEmail(data.email!));

      onNext();

      toast.success('Email sent!');
    } catch (err: any) {
      toast.error(String(err));
    } finally {
      dispatch(toggleLoading());
    }
  };

  return (
    <>
      <Typography component="h1" variant="h2">
        Forgot Password?
      </Typography>
      <br></br>
      <Typography component="p" variant="subtitle1">
        No worries, we'll send you reset instructions.
      </Typography>
      <br></br>
      <Box noValidate className="login__form" component="form">
        <Box>
          <FormControl variant="standard" style={{ width: '100%' }}>
            <InputLabel shrink htmlFor="bootstrap-input">
              Email Address
            </InputLabel>
            <AppInput
              required
              fullWidth
              id="email"
              placeholder="Enter email address"
              style={determineBorderColor(errors?.email?.message as any)}
              {...register('email', {
                required: true,
                maxLength: 80,
              })}
            />
          </FormControl>

          <FormHelperText style={{ color: 'red' }}>{errors?.email?.message as string}</FormHelperText>
        </Box>

        <Box mt={1}>
          <Button fullWidth variant="contained" className="login__button" onClick={handleSubmit(recoverPassword)}>
            Reset Password
          </Button>
        </Box>
        <Grid container spacing={0} direction="row" alignItems="center" justifyContent="center">
          <BackButton />
          <Typography style={{ display: 'inline-block' }}>Back to log in</Typography>
        </Grid>
        {/* <Copyright className="login__copyright" /> */}
      </Box>
    </>
  );
};

export default SendRecoveryEmail;
