import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import authSlice from '../slices/local/auth.slice';
import localStorage from 'redux-persist/es/storage';
import { adminSlice } from '../slices/api/admin-slice';
import { videoSlice } from '../slices/api/video-slice';
import { PersistConfig } from 'redux-persist/es/types';
import layoutSlice from '../slices/local/layout.slice';
import loadingSlice from '../slices/local/loading-slice';
import { companySlice } from '../slices/api/company-slice';
import { packageSlice } from '../slices/api/package-slice';
import { requestSlice } from '../slices/api/request-slice';
import { serviceSlice } from '../slices/api/service-slice';
import { authenticationSlice } from '../slices/api/auth-slice';
import { notificationSlice } from '../slices/api/notification-slice';
import { subscriptionSlice } from '../slices/api/subscription-slice';
import { customVideoSlice } from '../slices/api/custom-video-slice';
import { videoCreditSlice } from '../slices/api/video-credit-slice';
import forgotPasswordSlice from '../slices/local/forgot.password.slice';
import { paymentMethodSlice } from '../slices/api/payment-methods.slice';
import { packageSubscriptionSlice } from '../slices/api/package-subscriptions-slice';

const persistanceConfiguartion: PersistConfig<any, any, any> = {
  key: 'persist',
  whitelist: ['auth'],
  blacklist: ['loading'],
  storage: localStorage,
};

const rootReducer = combineReducers({
  [authSlice.name]: authSlice.reducer,
  [layoutSlice.name]: layoutSlice.reducer,
  [loadingSlice.name]: loadingSlice.reducer,
  [videoSlice.reducerPath]: videoSlice.reducer,
  [adminSlice.reducerPath]: adminSlice.reducer,
  [requestSlice.reducerPath]: requestSlice.reducer,
  [companySlice.reducerPath]: companySlice.reducer,
  [packageSlice.reducerPath]: packageSlice.reducer,
  [serviceSlice.reducerPath]: serviceSlice.reducer,
  [forgotPasswordSlice.name]: forgotPasswordSlice.reducer,
  [videoCreditSlice.reducerPath]: videoCreditSlice.reducer,
  [customVideoSlice.reducerPath]: customVideoSlice.reducer,
  [notificationSlice.reducerPath]: notificationSlice.reducer,
  [subscriptionSlice.reducerPath]: subscriptionSlice.reducer,
  [paymentMethodSlice.reducerPath]: paymentMethodSlice.reducer,
  [authenticationSlice.reducerPath]: authenticationSlice.reducer,
  [packageSubscriptionSlice.reducerPath]: packageSubscriptionSlice.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export const persistedReducer = persistReducer<any, any>(persistanceConfiguartion, rootReducer);
