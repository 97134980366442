import moment from 'moment';
import { useEffect, useState } from 'react';
import { SearchFilter } from '@core/enums/enums';
import SearchIcon from '@mui/icons-material/Search';
import { useLazySearchQuery } from '@features/state/slices/api/video-slice';
import {
  Box,
  TextField,
  Autocomplete,
  CircularProgress,
  AutocompleteChangeReason,
  AutocompleteChangeDetails,
  AutocompleteRenderOptionState,
  Typography,
} from '@mui/material';

type Props = {
  width?: number;
  searchValues?: any | null;
  multiple?: boolean;
  onSelected: (value: any) => void;
};

export const SearchField: React.FC<Props> = ({ width, multiple, onSelected, searchValues }) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState<any>([]);
  const [values, setValues] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [trigger, result] = useLazySearchQuery({
    refetchOnFocus: true,
  });

  useEffect(() => {
    if (Array.isArray(searchValues)) {
      setValues(searchValues);
    } else {
      setValue(searchValues);
    }
  }, [searchValues]);

  useEffect(() => {
    if (!result.isFetching) {
      setLoading(false);
    }
  }, [result.isFetching]);

  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  const onChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: any,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<any> | undefined,
  ) => {
    onSelected(value);

    if (multiple) {
      setValues(value);
    } else {
      setValue(value);
    }
  };

  const onInputChange = (event: React.SyntheticEvent<Element, Event>, searchTerm: string): void => {
    setLoading(true);
    setTimeout(() => {
      trigger({
        searchTerm,
        filter: SearchFilter.VIDEOS,
      });
    }, 1500);
  };

  const getOptionLabel = (option: any) => {
    if (option?.videoName) {
      return option?.videoName;
    } else {
      return option?.packageName;
    }
  };

  const isOptionEqualToValue = (option: any, value: any) => {
    if (option.isVideo) {
      return option.id == value.id;
    } else {
      return option.id == value.id;
    }
  };

  const determineOptionRender = (props: React.HTMLAttributes<HTMLLIElement>, option: any, state: AutocompleteRenderOptionState) => {
    if (option.isVideo) {
      return (
        <>
          <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
            <img crossOrigin="anonymous" width="50" height="45" loading="lazy" style={{ borderRadius: 5 }} src={option.videoThumbnail} />
            <Box>
              <Typography variant="subtitle1"> {option.videoName}</Typography>
              <Typography variant="caption">{moment.utc(option.videoLength).format('mm:ss')}</Typography>
            </Box>
          </Box>
        </>
      );
    } else {
      return (
        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
          <img crossOrigin="anonymous" width="20" loading="lazy" src={option.packageThumbnail} />
          {option.packageName}
        </Box>
      );
    }
  };

  const renderInput = (params: any) => <TextField {...params} label="Videos" />;
  return (
    <Autocomplete
      freeSolo
      value={multiple ? values : value}
      open={open}
      loading={loading}
      onChange={onChange}
      onOpen={handleOpen}
      multiple={multiple}
      onClose={handleClose}
      renderInput={renderInput}
      onInputChange={onInputChange}
      className="autocomplete__base"
      getOptionLabel={getOptionLabel}
      sx={{ width: width ?? '100%' }}
      renderOption={determineOptionRender}
      isOptionEqualToValue={isOptionEqualToValue}
      options={(result?.data?.data?.items as any) ?? []}
    />
  );
};

SearchField.defaultProps = {
  multiple: false,
};
