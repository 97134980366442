import { FC, ReactNode } from 'react';
import { Box, Card, Container, styled } from '@mui/material';

const PageTitle = styled(Box)(
  ({ theme }) => `
        padding: ${theme.spacing(4)};
        border-radius: 20px;
`,
);

interface PageTitleWrapperProps {
  enable?: boolean;
  children?: ReactNode;
}

const PageTitleWrapper: FC<PageTitleWrapperProps> = ({ children, enable }) => {
  return <>{enable ? <Card sx={{ p: 3, mb: 3 }}>{children}</Card> : children}</>;
};

export default PageTitleWrapper;
