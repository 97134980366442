import { ActionResult } from '@core/types';
import { baseQueryWithReauth } from './base-slice';
import { ApiError, ServiceResult } from '@core/index';
import { ApiMethods, BaseEndpoints } from '@core/enums/enums';
import { Company, PaginatedList } from '@core/constants/constants';
import { TagDescription, createApi } from '@reduxjs/toolkit/query/react';

const descriptionTags = ['Company'] as readonly TagDescription<'Company'>[] & string[];

export const companySlice = createApi({
  tagTypes: descriptionTags,
  baseQuery: baseQueryWithReauth,
  reducerPath: BaseEndpoints.Company,
  endpoints: (builder) => ({
    getCompanies: builder.query<ServiceResult<PaginatedList<Company[]>, ApiError>, any>({
      query: (args) => ({
        params: { ...args },
        url: `/${BaseEndpoints.Company}`,
      }),
      providesTags: descriptionTags,
    }),
    createCompany: builder.mutation({
      query: (payload) => ({
        body: payload,
        method: ApiMethods.POST,
        url: `/${BaseEndpoints.Company}`,
      }),
      invalidatesTags: descriptionTags,
    }),
    deleteCompany: builder.mutation<ServiceResult<ActionResult, ApiError>, any>({
      query: (args) => ({
        method: ApiMethods.DELETE,
        url: `/${BaseEndpoints.Company}/${args.id}`,
      }),
      invalidatesTags: descriptionTags,
    }),
    editCompany: builder.mutation<ServiceResult<ActionResult, ApiError>, any>({
      query: (payload) => ({
        body: payload,
        method: ApiMethods.PUT,
        url: `/${BaseEndpoints.Company}`,
      }),
      invalidatesTags: descriptionTags,
    }),
    blockCompany: builder.mutation<ServiceResult<ActionResult, ApiError>, any>({
      query: (args) => ({
        method: ApiMethods.PUT,
        url: `/${BaseEndpoints.Company}/${args.id}/block`,
      }),
      invalidatesTags: descriptionTags,
    }),
    generateAnalyticsUrl: builder.query<ServiceResult<{ url: string }, ApiError>, any>({
      query: (args) => ({
        method: ApiMethods.GET,
        url: `/${BaseEndpoints.Company}/${args.id}/generate-analytics-url`,
      }),
    }),
    unblockCompany: builder.mutation<ServiceResult<ActionResult, ApiError>, any>({
      query: (args) => ({
        method: ApiMethods.PUT,
        url: `/${BaseEndpoints.Company}/${args.id}/unblock`,
      }),
      invalidatesTags: descriptionTags,
    }),
    searchCompany: builder.query<ServiceResult<Company[], ApiError>, any>({
      query: (args) => ({
        params: { ...args },
        url: `/${BaseEndpoints.Company}/search`,
      }),
      providesTags: descriptionTags,
    }),
    getCompanyById: builder.query<ServiceResult<Company, ApiError>, any>({
      query: (args) => ({
        url: `/${BaseEndpoints.Company}/${args.id}`,
      }),
      transformResponse: (response: any, meta: any, arg: any): ServiceResult<Company, ApiError> => {
        console.log(response);
        return response;
      },
    }),
  }),
});
export const {
  useGetCompaniesQuery,
  useGetCompanyByIdQuery,
  useEditCompanyMutation,
  useBlockCompanyMutation,
  useCreateCompanyMutation,
  useDeleteCompanyMutation,
  useLazySearchCompanyQuery,
  useLazyGetCompanyByIdQuery,
  useUnblockCompanyMutation,
  useGenerateAnalyticsUrlQuery,
} = companySlice;
