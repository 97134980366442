import React, { useEffect } from 'react';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';
import { useAuth } from '@core/utils/utils';
import DoneIcon from '@mui/icons-material/Done';
import { BackButton } from '@components/BackButton';
import YouTubeIcon from '@mui/icons-material/YouTube';
import VideoService from '@core/services/VideoService';
import { CreateVideoDTO } from '@core/constants/constants';
import { useUploadVideoMutation } from '@features/state/slices/api/video-slice';
import { Box, Step, Stepper, StepLabel, Typography, Card, CircularProgress } from '@mui/material';
import { EnterDetailsStep } from './components/Steps/EnterDetailsStep';
import { UploadThumbnailStep } from './components/Steps/UploadThumbailStep';
import { UploadVideoStep } from './components/Steps/UploadVideoStep';
import PromotionService from '@core/services/PromotionService';
import { toggleLoading } from '@features/state/slices/local/loading-slice';
import { useDispatch } from 'react-redux';
import { useGetCustomVideoBannerQuery } from '@features/state/slices/api/custom-video-slice';

type Props = {};

export const UpdateBannerPromotion: React.FC<Props> = ({}) => {
  const { user } = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const optionalSteps: number[] = [];
  const [data, setData] = useState<any>({});
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set<number>());

  const {
    data: queryData,
    isError,
    isFetching,
    refetch,
    isSuccess,
  } = useGetCustomVideoBannerQuery(
    {},
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const [steps, setSteps] = useState([
    {
      label: 'Enter Details',
      component: EnterDetailsStep,
    },
    {
      label: 'Upload Thumbnail',
      component: UploadThumbnailStep,
    },
    {
      label: 'Upload Video',
      component: UploadVideoStep,
    },
  ]);

  const isStepOptional = (step: number) => {
    return optionalSteps.includes(step);
  };
  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      updatePromotion();
      return;
    }

    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const updatePromotion = async () => {
    dispatch(toggleLoading());

    const formData = new FormData();
    try {
      formData.append('title', data.title);
      formData.append('videoFile', data?.videoFile);
      formData.append('description', data.description);
      formData.append('thumbnailFile', data?.thumbnailFile);

      const { success, error } = await PromotionService.updateCustomVideoPromotion(formData);

      if (!success && error) {
        toast.error(error.message!);
        dispatch(toggleLoading());
        return;
      }

      navigate('/dashboard/promotions');

      toast.success('Custom Video promotion updated');
    } catch (error) {
      toast.error(String(error));
    } finally {
      dispatch(toggleLoading());
    }
  };

  const onCapture = (capturedState: any) => {
    setData((prevState: any) => {
      return { ...prevState, ...capturedState };
    });
  };

  useEffect(() => {
    if (isSuccess && !isError && !isFetching) {
      setData(queryData.data);
    }
  }, [isSuccess, isError, isFetching]);

  const currentStep = steps[activeStep];

  const StepComponent = currentStep.component;

  return (
    <Card sx={{ padding: 3 }}>
      <Box sx={{ width: '100%' }}>
        <Box display="flex">
          <BackButton />
          <Typography ml={1} variant="h2">
            Update Custom Video Banner
          </Typography>
        </Box>
        <Typography variant="subtitle1" mt={1}>
          Here you can change your custom video banner
        </Typography>
        <br></br>

        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps: { completed?: boolean } = {};
            const labelProps: {
              optional?: React.ReactNode;
            } = {};
            if (isStepOptional(index)) {
              labelProps.optional = <Typography variant="caption">Optional</Typography>;
            }
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step key={label.label} {...stepProps}>
                <StepLabel {...labelProps}>{label.label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>

        <StepComponent
          data={data}
          steps={steps}
          onCapture={onCapture}
          handleBack={handleBack}
          handleNext={handleNext}
          activeStep={activeStep}
          handleSkip={handleSkip}
        />
      </Box>
    </Card>
  );
};
