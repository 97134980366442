import axios, { AxiosError } from "axios";
import { BaseService } from "./BaseService";
import { getDomain } from "@core/utils/utils";
import { ApiError, ServiceResult } from "..";
import { COUNTRY_API_ENDPOINT } from "@core/constants/constants";

const API_URL = getDomain();

const {
  REACT_APP_COUNTRY_STATE_API_KEY,
  REACT_APP_IP_API,
  REACT_APP_COUNTRY_API,
} = process.env;

class LocationService extends BaseService {
  constructor() {
    super({
      baseURL: `${API_URL}`,
    });
  }

  async getUserLocation(): Promise<ServiceResult<any, ApiError>> {
    let result: ServiceResult<any, ApiError> = {
      success: false,
    };

    try {
      const response = await axios.get(REACT_APP_IP_API!);

      result.success = true;
      result.data = response.data;

      return result;
    } catch (error) {
      const { response } = error as AxiosError<ApiError>;
      result.error = response?.data ?? {
        //error: 'An error occured',
        message: 'An error occured'
      };


      return result;
    }
  }

  async getCountries(): Promise<ServiceResult<any, ApiError>> {
    let result: ServiceResult<any, ApiError> = {
      success: false,
    };

    try {
      const response = await axios.get(`${REACT_APP_COUNTRY_API}/countries`, {
        headers: {
          "X-CSCAPI-KEY": REACT_APP_COUNTRY_STATE_API_KEY as string,
        },
      });

      result.success = true;
      result.data = response.data;

      return result;
    } catch (error) {
      const { response } = error as AxiosError<ApiError>;
      result.error = response?.data ?? {
        //error: 'An error occured',
        message: 'An error occured'
      };


      return result;
    }
  }

  async getProvinces(code: string): Promise<ServiceResult<any, ApiError>> {
    let result: ServiceResult<any, ApiError> = {
      success: false,
    };

    try {
      const response = await axios.get(
        `${REACT_APP_COUNTRY_API}/countries/${code}/states`,
        {
          headers: {
            "X-CSCAPI-KEY": REACT_APP_COUNTRY_STATE_API_KEY as string,
          },
        }
      );

      result.success = true;
      result.data = response.data;

      return result;
    } catch (error) {
      const { response } = error as AxiosError<ApiError>;
      result.error = response?.data ?? {
       // error: 'An error occured',
        message: 'An error occured'
      };


      return result;
    }
  }
}

const service = new LocationService();

export { service as default, service as LocationService };
