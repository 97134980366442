import numeral from 'numeral';
import { useSelector } from 'react-redux';
import Resizer from 'react-image-file-resizer';
import { Environments } from '@core/enums/enums';
import { Auth } from '@core/constants/constants';
import resolveConfig from 'tailwindcss/resolveConfig';
import config from '../../assets/css/tailwind.config';
import { RootState } from '@features/state/reducers/combinedReducers';
import moment from 'moment';

export const tailwindConfig = () => {
  return resolveConfig(config) as any;
};

export const hexToRGB = (h: string): string => {
  let r = 0;
  let g = 0;
  let b = 0;
  if (h.length === 4) {
    r = parseInt(`0x${h[1]}${h[1]}`);
    g = parseInt(`0x${h[2]}${h[2]}`);
    b = parseInt(`0x${h[3]}${h[3]}`);
  } else if (h.length === 7) {
    r = parseInt(`0x${h[1]}${h[2]}`);
    g = parseInt(`0x${h[3]}${h[4]}`);
    b = parseInt(`0x${h[5]}${h[6]}`);
  }
  return `${+r},${+g},${+b}`;
};
export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function determineBorderColor(property: string | null | undefined): React.CSSProperties {
  const IDLE_BORDER_STYLE = 'none';
  const ERROR_BORDER_STYLE = '1px solid red';
  if (property === undefined || property == null) {
    return { border: IDLE_BORDER_STYLE, borderRadius: 5 };
  }

  return { border: ERROR_BORDER_STYLE, borderWidth: 1, borderRadius: 5 };
}

export const toQueryString = (obj?: object) => {
  if (!obj) {
    return '';
  }

  const keyValuePairs = Object.entries(obj).map(([key, value]) => {
    if (value === null || value === undefined) {
      return null; // Skip adding the key-value pair to the query string
    }
    return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
  });

  // Filter out any null values (where value was null or undefined)
  const filteredPairs = keyValuePairs.filter((pair) => pair !== null);

  return filteredPairs.join('&');
};

export const getDeviceInfo = () => {
  const userAgent = navigator.userAgent;
  const platform = navigator.platform;
  const vendor = navigator.vendor;

  let deviceName = 'Unknown Device';

  // Check for common iOS devices based on user agent
  if (userAgent.match(/iPhone/i)) {
    deviceName = 'iPhone';
  } else if (userAgent.match(/iPad/i)) {
    deviceName = 'iPad';
  } else if (userAgent.match(/iPod/i)) {
    deviceName = 'iPod Touch';
  } else if (userAgent.match(/Mac/i)) {
    deviceName = 'Mac';
  } else if (userAgent.match(/Windows/i)) {
    deviceName = 'Windows PC';
  } else if (userAgent.match(/Android/i)) {
    deviceName = 'Android Device';
  } else if (userAgent.match(/Linux/i)) {
    deviceName = 'Linux PC';
  }

  let browserName = 'Unknown Browser';

  // Check for popular browsers based on user agent
  if (userAgent.indexOf('Chrome') !== -1) {
    browserName = 'Google Chrome';
  } else if (userAgent.indexOf('Firefox') !== -1) {
    browserName = 'Mozilla Firefox';
  } else if (userAgent.indexOf('Safari') !== -1) {
    browserName = 'Apple Safari';
  } else if (userAgent.indexOf('Edge') !== -1) {
    browserName = 'Microsoft Edge';
  } else if (userAgent.indexOf('Opera') !== -1 || userAgent.indexOf('OPR') !== -1) {
    browserName = 'Opera';
  } else if (userAgent.indexOf('Trident') !== -1) {
    browserName = 'Microsoft Internet Explorer';
  }

  return `${deviceName} (${browserName}) on ${platform} - Vendor: ${vendor}`;
};

export function getBase64(file: File) {
  return new Promise<string>((resolve) => {
    let fileInfo;
    let baseURL = '';
    // Make new FileReader
    let reader = new FileReader();

    // Convert the file to base64 text
    reader.readAsDataURL(file);

    // on reader load somthing...
    reader.onload = () => {
      // Make a fileInfo Object
      baseURL = reader.result as any;
      resolve(baseURL);
    };
  });
}

export function flattenStyleObjects(styles: Array<any>) {
  return styles.reduce((merged, style) => {
    return Object.assign(merged, style);
  }, styles[0])[0];
}

export function getDomain() {
  const { REACT_APP_SERVER_DEV_BASE_URL, REACT_APP_SERVER_PROD_BASE_URL, REACT_APP_SERVER_LOCAL_BASE_URL } = process.env;
  // console.log(`ENVIRONMENT ${process.env.NODE_ENV} ${process.env.REACT_APP_ENV}`)
  if (process.env.REACT_APP_ENV == Environments.PRODUCTION) {
    return REACT_APP_SERVER_PROD_BASE_URL;
  }
  if (process.env.NODE_ENV === Environments.LOCAL) {
    return REACT_APP_SERVER_LOCAL_BASE_URL;
  } else if (process.env.NODE_ENV === Environments.DEVELOPMENT) {
    return REACT_APP_SERVER_DEV_BASE_URL;
  } else if (process.env.NODE_ENV === Environments.PRODUCTION) {
    return REACT_APP_SERVER_PROD_BASE_URL;
  }
}

export function getStripe() {
  const { REACT_APP_DEV_STRIPE_KEY, REACT_APP_PROD_STRIPE_KEY } = process.env;
  if (process.env.REACT_APP_ENV == Environments.PRODUCTION) {
    return REACT_APP_PROD_STRIPE_KEY;
  }
  if (process.env.NODE_ENV === Environments.LOCAL || process.env.NODE_ENV === Environments.DEVELOPMENT) {
    return REACT_APP_DEV_STRIPE_KEY;
  } else if (process.env.NODE_ENV === Environments.PRODUCTION) {
    return REACT_APP_PROD_STRIPE_KEY;
  }
}

export function getClientDomain() {
  const { REACT_APP_LOCAL_COMPANY_PORTAL_DOMAIN, REACT_APP_DEV_COMPANY_PORTAL_DOMAIN, REACT_APP_PROD_COMPANY_PORTAL_DOMAIN } = process.env;
  if (process.env.REACT_APP_ENV == Environments.PRODUCTION) {
    return REACT_APP_PROD_COMPANY_PORTAL_DOMAIN;
  }
  if (process.env.NODE_ENV === Environments.LOCAL) {
    return REACT_APP_LOCAL_COMPANY_PORTAL_DOMAIN;
  } else if (process.env.NODE_ENV === Environments.DEVELOPMENT) {
    return REACT_APP_DEV_COMPANY_PORTAL_DOMAIN;
  } else if (process.env.NODE_ENV === Environments.PRODUCTION) {
    return REACT_APP_PROD_COMPANY_PORTAL_DOMAIN;
  }
}

export function useAuth(): Auth {
  return useSelector((state: RootState) => state.auth?.auth) as Auth;
}

export const formatPhoneNumber = (str: string) => {
  //Filter only numbers from the input
  let cleaned = ('' + str).replace(/\D/g, '');

  //Check if the input is of correct length
  let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return '(' + match[1] + ')' + match[2] + '-' + match[3];
  } else if (cleaned.length > 10) {
    match = cleaned.slice(-10).match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ')' + match[2] + '-' + match[3];
    }
  }

  return null;
};

export function roleMapper(role: string) {
  switch (role) {
    case 'super_admin':
      return 'Super Admin';
    case 'admin':
      return 'Admin';
    case 'sales_associate':
      return 'Sales Associate';
    case 'company':
      return 'Company';
    default:
      return '';
  }
}

export function fNumber(number: any) {
  return numeral(number).format();
}

export function fCurrency(number: any) {
  const format = number ? numeral(number).format('$0,0.00') : '';

  return result(format, '.00');
}

export function fPercent(number: any) {
  const format = number ? numeral(Number(number) / 100).format('0.0%') : '';

  return result(format, '.0');
}

export function fShortenNumber(number: any) {
  const format = number ? numeral(number).format('0.00a') : '';

  return result(format, '.00');
}

export function fData(number: any) {
  const format = number ? numeral(number).format('0.0 b') : '';

  return result(format, '.0');
}

function result(format: any, key = '.00') {
  const isInteger = format.includes(key);

  return isInteger ? format.replace(key, '') : format;
}

export function millisToMinutesAndSeconds(millis: number) {
  var minutes = Math.floor(millis / 60000);
  var seconds = parseInt(((millis % 60000) / 1000).toFixed(0));
  return seconds == 60 ? minutes + 1 + ':00' : minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
}

export function toCurrency(price: number) {
  const formattedPrice = price.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  return 'USD ' + formattedPrice;
}

export const browserName = (function (agent) {
  switch (true) {
    case agent.indexOf('edge') > -1:
      return 'MS Edge';
    case agent.indexOf('edg/') > -1:
      return 'Edge ( chromium based)';
    case agent.indexOf('opr') > -1:
      return 'Opera';
    case agent.indexOf('chrome') > -1:
      return 'Chrome';
    case agent.indexOf('trident') > -1:
      return 'MS IE';
    case agent.indexOf('firefox') > -1:
      return 'Mozilla Firefox';
    case agent.indexOf('safari') > -1:
      return 'Safari';
    default:
      return 'other';
  }
})(window.navigator.userAgent.toLowerCase());

export const toMegabytes = (size: number) => {
  return Number((size / 1024 ** 2).toFixed(2));
};

function startTimer(duration: number) {
  var start = Date.now(),
    diff,
    minutes,
    seconds;
  function timer() {
    // get the number of seconds that have elapsed since
    // startTimer() was called
    diff = duration - (((Date.now() - start) / 1000) | 0);

    // does the same job as parseInt truncates the float
    minutes = (diff / 60) | 0;
    seconds = diff % 60 | 0;

    minutes = minutes < 10 ? '0' + minutes : minutes;
    seconds = seconds < 10 ? '0' + seconds : seconds;

    if (diff <= 0) {
      // add one second so that the count down starts at the full duration
      // example 05:00 not 04:59
      start = Date.now() + 1000;
    }
    return minutes + ':' + seconds;
  }
  // we don't want to wait a full second before the timer starts
  timer();
  return setInterval(timer, 1000);
}

export const determineTo = (pathnames: string[], last: boolean, index: number) => {
  if (last) {
    return null;
  }
  let path: string = '';
  let labels: string[] = [];
  const usablePathnames = pathnames.slice(0, index + 1);
  usablePathnames.map((name, index) => {
    if (name.includes('-')) {
      path = [path + name].join('/');

      labels.push(capitalizeFirstLetter(name.split('-')[0]) + capitalizeFirstLetter(name.split('-')[1]));
    } else {
      path = [path + name].join('/');
      labels.push(capitalizeFirstLetter(name));
    }
  });
  // console.log({ path: path, labels: labels }, pathnames);
  return { path: path, labels: labels };
};

export function detectMob() {
  return window.innerWidth <= 820 && window.innerHeight <= 1180;
}

export async function toBase64(file: any) {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as any);
    reader.onerror = (error) => reject(error);
  });
}

export async function base64toFile(base64String: string, fileName: string) {
  const byteString = atob(base64String.split(',')[1]);

  // Convert the byte string to a Uint8Array
  const byteArray = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    byteArray[i] = byteString.charCodeAt(i);
  }

  // Create a Blob object from the byte array
  const blob = new Blob([byteArray], { type: 'application/octet-stream' });

  // Create a File object from the Blob object
  return new File([blob], fileName, { type: 'application/octet-stream' });
}

export const b64toBlob = (b64Data: any, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const resizeFile = (file: File) =>
  new Promise<any>((resolve) => {
    Resizer.imageFileResizer(
      file,
      600,
      300,
      'PNG',
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      'file',
    );
  });

export function sliderValueToVideoTime(duration: any, sliderValue: any) {
  return Math.round((duration * sliderValue) / 100);
}

export function secondsToTimeString(seconds: number) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds - hours * 3600) / 60);
  const remainingSeconds = seconds - hours * 3600 - minutes * 60;
  const durationString = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${remainingSeconds
    .toString()
    .padStart(2, '0')}`;

  return durationString;
}

export const toSentenceCase = (str: string | null) => {
  console.log(`String ${str}`);
  if (!str) return str;
  str = str.trim();

  // Regular expression to match sentence-ending punctuation marks
  const sentenceEndings = /([.!?])\s*/g;

  // Split the string into sentences
  const sentences = str.split(sentenceEndings);

  // Process each sentence
  for (let i = 0; i < sentences.length; i++) {
    if (sentences[i].length > 0) {
      sentences[i] = sentences[i].charAt(0).toUpperCase() + sentences[i].slice(1).toLowerCase();
    }
  }

  // Reassemble the string
  return sentences.join('');
};

export function convertDaysToYearsMonthsDays(days: number) {
  const startDate = moment().startOf('day'); // Today's date at the start of the day
  const endDate = moment(startDate).add(days, 'days'); // Add the number of days

  const years = endDate.diff(startDate, 'years');
  startDate.add(years, 'years');

  const months = endDate.diff(startDate, 'months');
  startDate.add(months, 'months');

  const remainingDays = endDate.diff(startDate, 'days');

  return { years, months, days: remainingDays };
}

export function formatDateDuration(duration: { years: number; months: number; days: number }): string {
  const parts: string[] = [];

  if (duration.years > 0) {
    parts.push(`${duration.years} ${duration.years === 1 ? 'year' : 'years'}`);
  }

  if (duration.months > 0) {
    parts.push(`${duration.months} ${duration.months === 1 ? 'month' : 'months'}`);
  }

  if (duration.days > 0) {
    parts.push(`${duration.days} ${duration.days === 1 ? 'day' : 'days'}`);
  }

  return parts.join(', ');
}
