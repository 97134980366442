import SelectUnstyled, {
  selectClasses as selectUnstyledClasses,
  SelectProps as SelectUnstyledProps,
  SelectRootSlotProps as SelectUnstyledRootSlotProps,
} from "@mui/base/Select";
import OptionUnstyled,{
  optionClasses as optionUnstyledClasses,
  Option
} from "@mui/base/Option";
import { styled } from "@mui/system";
import PopperUnstyled from "@mui/base/Popper";
import React from "react";
import { Popper, Select, SelectProps,} from "@mui/material";


const blue = {
  100: "#DAECFF",
  200: "#99CCF3",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const grey = {
  50: "#F3F6F9",
  100: "#E7EBF0",
  200: "#E0E3E7",
  300: "#CDD2D7",
  400: "#B2BAC2",
  500: "#A0AAB4",
  600: "#6F7E8C",
  700: "#3E5060",
  800: "#2D3843",
  900: "#1A2027",
};

export const StyledButton = styled("button")(
  ({ theme }) => `
 
  font-size: 0.875rem;
  box-sizing: border-box;
  min-height: calc(2.5em + 22px);
  min-width: 100%;
  background: ${theme.palette.background.default};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[300]};
  border-radius: 0.75em;
  margin: 0em;
  padding: 10px;
  text-align: left;
  line-height: 1.5;
  color: ${theme.palette.mode === "dark" ? "white" : grey[900]};

  &:hover {
    background: ${theme.palette.mode === "dark" ? "" : grey[100]};
    border-color: ${theme.palette.mode === "dark" ? blue[500] : grey[400]};
  }

  &.${selectUnstyledClasses.focusVisible} {
    outline: 3px solid ${theme.palette.mode === "dark" ? blue[600] : blue[100]};
  }

  &.${selectUnstyledClasses.expanded} {
    &::after {
      content: '▴';
    }
  }

  &::after {
    content: '▾';
    float: right;
  }
  `
);

const StyledListbox = styled("ul")(
  ({ theme }) => `
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  box-sizing: border-box;
  padding: 5px;
  z-index:100000000000000000000000000;
  margin: 10px 0;
  min-width: 300px;
  max-height: 450px;
  background: ${theme.palette.mode === "dark" ? "#000000" : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[300]};
  border-radius: 0.75em;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  overflow: auto;
  outline: 0px;
  `
);

export const StyledOption = styled(Option)(
  ({ theme }) => `
  list-style: none;
  padding: 8px;
  border-radius: 0.45em;
  cursor: pointer;
  z-index:100000000000000000000000000;
  &:last-of-type {
    border-bottom: none;
  }

  &.${optionUnstyledClasses.selected} {
    background-color: ${theme.palette.mode === "dark" ? blue[900] : blue[100]};
    color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
  }

  &.${optionUnstyledClasses.highlighted} {
    background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]};
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  }

  &.${optionUnstyledClasses.highlighted}.${optionUnstyledClasses.selected} {
    background-color: ${theme.palette.mode === "dark" ? blue[900] : blue[100]};
    color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
  }

  &.${optionUnstyledClasses.disabled} {
    color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
  }

  &:hover:not(.${optionUnstyledClasses.disabled}) {
    background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]};
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  }
  `
);

const StyledPopper = styled(Popper)`
  z-index: 10000000;
`;

export const Paragraph = styled("p")(
  ({ theme }) => `
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  margin: 10px 0;
  color: ${theme.palette.mode === "dark" ? grey[400] : grey[700]};
  `
);

export const Pre = styled("pre")(
  ({ theme }) => `
  font-family: monospace;
  font-size: 0.875rem;
  margin: 0px;
  padding: 5px 10px;
  border-radius: 10px;
  background-color: ${
    theme.palette.mode === "dark" ? "rgba(0, 30, 60, 0.5)" : grey[50]
  };
  color: ${theme.palette.mode === "dark" ? grey[400] : grey[700]};
  `
);

type Props = {
  value?: any;
  name?: string;
  error?: string;
  onChange?: any;
  children?: any;
  components?: any;
  IconComponent?: any;
};

const CustomSelect = React.forwardRef(function CustomSelect<TValue extends {}>(
  props: SelectProps<TValue>,
  ref: React.ForwardedRef<HTMLButtonElement>,
) {
  const slots = {
    root: StyledButton,
    listbox: StyledListbox,
    popper: StyledPopper,
    ...props.slots,
  };

  return <Select {...props} ref={ref} slots={slots} />;
});

export default CustomSelect;