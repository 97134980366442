import { useAuth } from './utils';
import { useEffect } from 'react';
import FadeInOut from '@components/FadeTransition';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

type Props = {
  handleBaseRedirect?: boolean;
  children?: React.ReactNode;
};

export const ProtectedRoute = ({ children, handleBaseRedirect }: Props): JSX.Element => {
  const auth = useAuth();
  const location = useLocation();

  if (auth && auth.token) {
    if (handleBaseRedirect) {
      if (!auth?.user?.hasInitialPassword) {
        return <Navigate to={'/auth/set-password'} replace state={{ from: location }} />;
      }
      return <Navigate to={'/dashboard/home'} replace state={{ from: location }} />;
    }

    if (!auth?.user?.hasInitialPassword) {
      return <Navigate to={'/auth/set-password'} replace state={{ from: location }} />;
    }
    return <FadeInOut> {children as any} </FadeInOut>;
  } else {
    return <Navigate to={'/auth/login'} replace state={{ from: location }} />;
  }
};

ProtectedRoute.defaultProps = {
  handleBaseRedirect: false,
};
