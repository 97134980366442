import { CustomVideo, Video } from '@core/types';
import Iconify from '@components/iconify';
import { Retry } from '@components/Retry';
import { Helmet } from 'react-helmet-async';
import { useAuth } from '@core/utils/utils';
import { VideoMode } from '@core/enums/enums';
import { fadeInRight } from 'react-animations';

import { Grid, Box, Pagination, Button, InputAdornment, Tooltip, CircularProgress, useTheme } from '@mui/material';
import PageTitleWrapper from '@components/PageTitleWrapper';
import TransitionWrapper from '@components/TransitionWrapper';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { CustomVideoListing } from './components/CustomVideoListing';
import { useGetCustomVideosQuery } from '@features/state/slices/api/custom-video-slice';
import AppTablePagination from '@components/AppTablePagination';
import { StyledSearch } from '@components/Styled';
import AppChip from '@components/AppChip';
import Filter from '@components/Filter';
import { RefreshButton } from '@components/RefreshButton';
import MovieFilterIcon from '@mui/icons-material/MovieFilter';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import AppPageHeader from '@components/PageHeader';
import { useNavigate } from 'react-router';
import Center from '@components/Center/Center';
import { EmptyTable } from '@components/EmptyTable';
import FourKIcon from '@mui/icons-material/FourK';
import { da } from 'date-fns/locale';

type props = {
  handleSetCustomVideo: (video: CustomVideo) => void;
  handleSetVideoMode: (mode: VideoMode) => void;
};
const CustomVideos = ({ handleSetCustomVideo, handleSetVideoMode }: props) => {
  const theme = useTheme();
  const { user } = useAuth();
  const navigate = useNavigate();
  const ref = useRef<any>(null);
  const [limit, setLimit] = useState<number>(10);
  const [openFilter, setOpenFilter] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);

  const [query, setQuery] = useState({
    page: 1,
    limit: limit,
    searchTerm: '',
  });

  useEffect(() => {
    ref?.current?.continuousStart();
  }, []);

  const { data, error, isError, isFetching, isSuccess, refetch } = useGetCustomVideosQuery(query, {
    refetchOnMountOrArgChange: true,
  });

  const handleSearch = (e: any) => {
    setTimeout(() => {
      setQuery({
        //FIXME Added page reset here to avoid pagination problem

        page: 1,
        //page: query.page,
        limit: query.limit,
        searchTerm: e.target.value,
      });
      refetch();
    }, 1000);
  };

  const handlePaginationChanged = (limit: number, page: number) => {
    setQuery({
      page,
      limit,
      searchTerm: query.searchTerm,
    });
    refetch();
  };

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  const navigateToUpload = () => {
    navigate('upload');
  };

  const contentAvailable = isSuccess && data?.data?.items !== null && data!.data!.items!.length > 0 && !isFetching;

  return (
    <TransitionWrapper duration={0.3} animation={fadeInRight}>
      <Helmet>
        <title>Sales Assist - Custom Videos</title>
      </Helmet>

      <AppPageHeader
        title="Custom Videos"
        subtitle={`${user?.name}, these are your recent uploads`}
        trailing={
          <Button
            variant="contained"
            style={{ borderRadius: 20 }}
            sx={{ mt: { xs: 2, md: 0 }, borderRadius: 20 }}
            onClick={navigateToUpload}
          >
            Upload Custom Video
          </Button>
        }
      />

      <Box display="flex" alignItems="center" justifyContent="space-between" mb={3}>
        <Box>
          <StyledSearch
            fullWidth
            sx={{ height: 45, borderRadius: 0.8 }}
            onChange={handleSearch}
            placeholder="Search Custom Videos"
            startAdornment={
              <InputAdornment position="start">
                <Iconify
                  icon="eva:search-fill"
                  sx={{
                    color: 'text.disabled',
                    width: 20,
                    height: 20,
                  }}
                />
              </InputAdornment>
            }
          />
          {selectedFilters.map((filter) => (
            <Tooltip title={filter} placement="top">
              <AppChip sx={{ ml: 1 }}>{filter}</AppChip>
            </Tooltip>
          ))}
        </Box>

        <Box display="flex" alignItems="center" justifyContent="center">
          <Filter
            openFilter={openFilter}
            onOpenFilter={handleOpenFilter}
            selectedFilters={selectedFilters}
            onCloseFilter={handleCloseFilter}
            onFilterChange={setSelectedFilters}
            filters={[
              {
                title: 'Status',
                filters: ['Processed', 'Completed', 'Failed'],
              },
            ]}
          />
          <RefreshButton onRefresh={refetch} />
        </Box>
      </Box>
      <Grid container direction={'row'} justifyContent="center" alignItems={'stretch'} spacing={3}>
        <Grid item xs={12} mt={4}>
          {isFetching && (
            <Center>
              <Box mt={10}>
                <CircularProgress />
              </Box>
            </Center>
          )}

          {data?.data?.items?.length != null && data?.data?.items?.length == 0 && (
            <EmptyTable
              subtitle="There are currently no custom videos on the system"
              icon={<MovieFilterIcon sx={{ color: theme.colors.primary.dark, fontSize: 130 }} />}
            />
          )}

          {isError && !isSuccess && !isFetching && <Retry refetch={refetch} />}

          {contentAvailable && (
            <Box>
              <CustomVideoListing
                videos={data?.data?.items ?? []}
                refetch={refetch}
                handleSetVideoMode={handleSetVideoMode}
                handleSetCustomVideo={handleSetCustomVideo}
              />
            </Box>
          )}
          <AppTablePagination
            onChange={handlePaginationChanged}
            totalPages={data?.data?.meta.totalPages ?? 1}
            contentAvailable={contentAvailable}
          />
        </Grid>
      </Grid>
    </TransitionWrapper>
  );
};

export default CustomVideos;
