import { StepProps } from '@core/types';
import { Navigator } from '../Navigator';
import { fadeInRight } from 'react-animations';
import Center from '@components/Center/Center';
import { Box, Button, Typography } from '@mui/material';
import TransitionWrapper from '@components/TransitionWrapper';
import { SIX_MONTH_CONTRACT_KEY } from '@core/constants/constants';
import Completion from '../../../../../../../../assets/images/subscription.jpg';
import { SubscriptionDisplayItem } from '@features/pages/Subscriptions/components/SubscriptionDisplay/SubscriptionItem';
import { CheckOutSubscriptionItem } from '../CheckOutSubscriptionItem';

export const CompletionStep: React.FC<StepProps> = (props: StepProps) => {
  const { data, steps, activeStep, handleBack, handleNext, handleSkip } = props;

  const selectedSubscription = data.subscriptionMetadata.isCustom ? data.customSubscription : data.selectedSubscription;
  const isTrial = data.subscriptionMetadata.isCustom
    ? data.customSubscription.isTrial
    : data.selectedSubscription.pricePlan == SIX_MONTH_CONTRACT_KEY;
  const hasUpFrontBillingCost = data.subscriptionMetadata.isCustom
    ? data.customSubscription.hasUpFrontBillingCost
    : data.selectedSubscription.pricePlan == SIX_MONTH_CONTRACT_KEY;

  return (
    <TransitionWrapper duration={0.2} animation={fadeInRight}>
      <Box
        mt={10}
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Box display="flex">
          <CheckOutSubscriptionItem
            isSelected={true}
            onSelect={(a, b) => null}
            config={{
              displayTrialPeriod: isTrial,
              displayUpFrontBilling: hasUpFrontBillingCost,
            }}
            subscription={selectedSubscription}
            pricePlan={selectedSubscription.pricePlan}
          />

          <Center>
            <Box>
              <img crossOrigin="anonymous" height={400} width={650} src={Completion} />
              <Center>
                <Typography variant="subtitle1" mt={1} mb={1}>
                  Click below to create this subscription
                </Typography>
                <Button onClick={handleNext} variant="contained">
                  Create Subscription
                </Button>{' '}
              </Center>
            </Box>
          </Center>
        </Box>
      </Box>

      <Navigator steps={steps} handleSkip={handleSkip} handleBack={handleBack} activeStep={activeStep} handleNext={handleNext} />
    </TransitionWrapper>
  );
};
