import _ from 'lodash';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { Avatar, Box, Button } from '@mui/material';
import React, { Fragment, useRef, useState } from 'react';
import { CloudUploadFill } from '@styled-icons/bootstrap/CloudUploadFill';
import { updateProfilePic } from '@features/state/slices/local/auth.slice';
import { toggleLoading } from '@features/state/slices/local/loading-slice';
import { useUpdateProfilePhotoMutation } from '@features/state/slices/api/admin-slice';
import { useAuth } from '@core/utils/utils';
import { ProfileBox, StyledBadge } from '@components/Styled';

type Props = {};

const ProfilePhoto: React.FC<Props> = () => {
  const { user } = useAuth();
  const ref = useRef<any>(null);
  const dispatch = useDispatch();
  const [url, setUrl] = useState('');
  const uploadInputRef = useRef<any>(null as any);
  const [isOpen, setOpen] = useState<boolean>(false);
  const [photo, setPhoto] = useState<File | null>(null as any);
  const [updateProfilePhotoMutation] = useUpdateProfilePhotoMutation();

  const handleUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      dispatch(toggleLoading());

      const photo = e.target?.files![0];

      const formData = new FormData();

      formData.append('photo', photo);
      formData.append('id', String(user?.id!));

      const { success, error, data } = await updateProfilePhotoMutation(formData).unwrap();

      if (!success && error) {
        toast.error(error?.message!);
        return;
      }

      dispatch(updateProfilePic(data?.url!));

      toast.success('Profile photo successfully updated');
    } catch (e) {
      toast.error('An error occured');
    }
    dispatch(toggleLoading());
  };

  const handleReferenceClick = async () => uploadInputRef.current && uploadInputRef.current.click();

  return (
    <ProfileBox>
      <StyledBadge overlap="circular" anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} variant="dot">
        <Avatar alt="photoURL" sx={{ height: 300, width: 300 }} src={user?.profilePic} />
      </StyledBadge>
      <div className="d-flex p-3">
        <Fragment>
          <input type="file" accept="image/*" ref={uploadInputRef} style={{ display: 'none' }} onChange={(e) => handleUpload(e)} />
          <Button onClick={() => handleReferenceClick()} variant="contained">
            Upload &nbsp;
            <CloudUploadFill size="20" />
          </Button>
        </Fragment>

        <Box ml={2} className="d-md-block text-start">
          <div className="fw-normal">Choose Image</div>
          <div style={{ marginBottom: 5 }} className="text-gray small mb-10">
            JPG, GIF or PNG. Max size of 800K
          </div>
        </Box>
      </div>
    </ProfileBox>
  );
};

export default ProfilePhoto;
