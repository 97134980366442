import Center from '@components/Center/Center';
import {
  Subscription,
  MONTHLY_CONTRACT_KEY,
  ONE_YEAR_CONTRACT_KEY,
  SIX_MONTH_CONTRACT_KEY,
  TWO_YEAR_CONTRACT_KEY,
} from '@core/constants/constants';
import FlipNumbers from 'react-flip-numbers';
import { Box, Tooltip, Typography } from '@mui/material';
import { convertDaysToYearsMonthsDays, formatDateDuration, toSentenceCase } from '@core/utils/utils';
import { lightBlue } from '@mui/material/colors';

type Props = {
  pricePlan: string;
  isSelected: boolean;
  config: DisplayConfig;
  subscription: Subscription & { intervalPrice?: any };
  onSelect: (subscription: Subscription, pricingPlan: string) => void;
  isAdminView?: boolean;
  onEdit?: (subscription: Subscription) => void;
};

type DisplayConfig = {
  displayTrialPeriod: boolean;
  displayUpFrontBilling: boolean;
};

export const SubscriptionDisplayItem = (props: Props) => {
  const { config, pricePlan, subscription, onSelect, isSelected, isAdminView = false, onEdit } = props;

  if (isAdminView && !onEdit) {
    throw new Error('onEdit must be provided when isAdminView is true');
  }

  const determinePrice = () => {
    if (subscription.hasOwnProperty('intervalPrice')) {
      return subscription.intervalPrice;
    }
    switch (pricePlan) {
      case SIX_MONTH_CONTRACT_KEY:
        return subscription.sixMonthContractCost;
      //DEPRECATED Two Year Contract
      // case TWO_YEAR_CONTRACT_KEY:
      //   return subscription.twoYearContractCost;
      case ONE_YEAR_CONTRACT_KEY:
        return subscription.oneYearContractCost;
      case MONTHLY_CONTRACT_KEY:
        return subscription.monthlyContractCost;

      default:
        return 0;
    }
  };
  const chooseSubscription = (subscription: Subscription) => {
    onSelect(subscription, pricePlan);
  };

  return (
    <div className="col-lg-4" style={{ height: 700 }}>
      <div className="card mb-5 mb-lg-0" style={{ height: 700 }}>
        <div className="card-body">
          <Box mt={2} mb={2}>
            <h5 className="card-title text-muted text-uppercase text-center">{subscription?.name}</h5>
          </Box>
          <Box mb={4} textAlign="center">
            {config?.displayUpFrontBilling && <span style={{ color: 'grey' }}>Pay ${subscription?.upFrontBillingCost} unfront and</span>}

            <h6
              className="card-price text-center mt-2"
              style={{
                marginTop: 5,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <FlipNumbers
                play
                width={30}
                height={40}
                color="black"
                background="white"
                numberStyle={{ textAlign: 'center' }}
                numbers={`$ ${determinePrice().toString()}`}
              />
              <span className="period"> &nbsp;/month</span>
              <br></br>
            </h6>
            {subscription.hasTrialPeriod && subscription.numberOfDaysInTrialPeriod && subscription.numberOfDaysInTrialPeriod != 0 && (
              <>
                <Box mt={1}>
                  <Typography variant="h3" fontSize={20} color={lightBlue}>
                    {`FREE TRIAL INCLUDED lasting ${formatDateDuration(
                      convertDaysToYearsMonthsDays(subscription.numberOfDaysInTrialPeriod ?? 100),
                    )}`}
                  </Typography>
                </Box>
              </>
            )}
          </Box>
          <ul className="fa-ul">
            <Box display="flex" mt={1}>
              <li>
                <span className="fa-li">
                  <i className="fas fa-check"></i>
                </span>
              </li>
              Users covered
              <Box ml={0.5} mt={0.5}>
                <FlipNumbers
                  play
                  width={8}
                  height={13.5}
                  color="black"
                  numberStyle={{ fontWeight: 'bold' }}
                  background="white"
                  numbers={`${subscription?.usersCovered}`}
                />
              </Box>
            </Box>
            {parseInt(subscription!.setupCost) != 0 && (
              <Box display="flex" mt={1}>
                <li>
                  <span className="fa-li">
                    <i className="fas fa-check"></i>
                  </span>
                </li>
                Setup Cost
                <Box ml={0.5}>
                  <FlipNumbers play width={8} height={13.5} color="black" background="white" numbers={`$ ${subscription?.setupCost}`} />
                </Box>
              </Box>
            )}

            {/* DEPRECATED Additional User Cost for subscription removed
            
            <Box display="flex" mt={1}>
              <li>
                <span className="fa-li">
                  <i className="fas fa-check"></i>
                </span>
              </li>{' '}
              Additional User Cost
              <Box ml={0.5}>
                <FlipNumbers
                  play
                  width={8}
                  height={13.5}
                  color="black"
                  background="white"
                  numbers={`$ ${subscription?.additionalUserCost}`}
                />
              </Box>
            </Box> */}

            {/* DEPRECATED Video Package Discounts no longer needed 
            
            <Box display="flex" mt={1}>
              <li>
                <span className="fa-li">
                  <i className="fas fa-check"></i>
                </span>
              </li>
              Discount for video packages{' '}
              <Box ml={0.5}>
                <FlipNumbers
                  play
                  width={8}
                  height={13.5}
                  color="black"
                  background="white"
                  numbers={`${subscription?.videoPackageDiscount}%`}
                />
              </Box>
            </Box> */}

            <Box mt={1}>
              <Typography variant="caption" fontSize={12}>
                FEATURES
              </Typography>
            </Box>

            <Box mt={1}>
              {subscription?.subscriptionItemsIncluded?.map((item) => (
                <Box mt={0.5}>
                  <li>
                    <span className="fa-li">
                      <i className="fas fa-check"></i>
                    </span>
                    {toSentenceCase(item)}
                  </li>{' '}
                </Box>
              ))}
            </Box>

            <Tooltip
              title={
                subscription.public
                  ? 'This means that companies which may be a specific company or all companies (depending on access given) can view \nand potentially purchase this subscription'
                  : 'This means that this subscription CANNOT be viewed or purchased by any company whether the subscription is publically available to all companies or a specific company'
              }
            >
              <Box mt={1} mb={3} sx={{ cursor: 'pointer' }}>
                <Typography variant="h3" fontSize={16} fontWeight={'bold'}>
                  Subscription is {subscription.public ? 'PUBLIC' : 'PRIVATE'}
                </Typography>
              </Box>
            </Tooltip>

            <Box mt={1} sx={{ cursor: 'pointer' }}>
              <Typography variant="h3" fontSize={16} fontWeight={'bold'}>
                Subscription is accessible to{' '}
                {subscription.companies.length == 0
                  ? 'All Companies'
                  : subscription.companies.length == 1
                  ? '1 Company'
                  : `${subscription.companies.length} Companies`}
              </Typography>

              <Box mt={1}>
                {' '}
                <Typography variant="caption" fontSize={13} fontWeight={'bold'}>
                  {subscription.public ? '' : ' HOWEVER they cannot view or purchase this subscription because it is PRIVATE'}
                </Typography>
              </Box>
            </Box>
          </ul>
          <div
            className="d-grid"
            style={{
              left: 10,
              right: 10,
              bottom: 30,
              position: 'absolute',
            }}
          >
            {isAdminView && onEdit ? (
              <>
                <a onClick={() => onEdit!(subscription)} className={`btn ${isSelected ? 'btn-primary' : 'btn-dark'} text-uppercase`}>
                  Edit Subscription
                </a>
              </>
            ) : (
              <>
                {isSelected ? (
                  <a className="btn btn-primary text-uppercase">Selected</a>
                ) : (
                  <a
                    onClick={() => chooseSubscription(subscription)}
                    className={`btn ${isSelected ? 'btn-primary' : 'btn-dark'} text-uppercase`}
                  >
                    Choose
                  </a>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
