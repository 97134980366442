import {
  Box,
  Stack,
  Button,
  Drawer,
  Divider,
  Checkbox,
  FormGroup,
  IconButton,
  Typography,
  FormControlLabel,
  Tooltip,
} from "@mui/material";
import { FilterType } from "@core/types";
import Scrollbar from "@components/Scrollbar";
import CloseIcon from "@mui/icons-material/Close";
import ClearAllIcon from "@mui/icons-material/ClearAll";
import FilterListIcon from "@mui/icons-material/FilterList";

type Props = {
  showText?: boolean;
  filters: FilterType[];
  openFilter: boolean;
  onOpenFilter: () => void;
  onCloseFilter: () => void;
  selectedFilters: string[];
  onFilterChange: (filters: string[]) => void;
};

AppFilter.defaultProps = {
  showText: false,
};

export default function AppFilter(props: Props) {
  const {
    filters,
    showText,
    openFilter,
    onOpenFilter,
    onCloseFilter,
    onFilterChange,
    selectedFilters,
  } = props;

  const handleFilterSelection = (e: any, item: any) => {
    let filters = [...selectedFilters];

    if (e.target.checked) {
      filters.push(item);
    } else {
      filters = filters.filter((f) => f !== item);
    }
    onFilterChange(filters);
  };
  return (
    <>
      <Tooltip title="Filter list" placement="top" arrow>
        {showText ? (
          <Button
            disableRipple
            color="inherit"
            onClick={onOpenFilter}
            endIcon={<FilterListIcon />}
          >
            Filters
          </Button>
        ) : (
          <IconButton onClick={onOpenFilter}>
            <FilterListIcon
              sx={{ width: 24, height: 24, color: "text.disabled" }}
            />
          </IconButton>
        )}
      </Tooltip>

      <Drawer
        anchor="right"
        open={openFilter}
        onClose={onCloseFilter}
        PaperProps={{
          sx: { width: 280, border: "none", overflow: "hidden" },
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ px: 1, py: 2 }}
        >
          <Typography variant="subtitle1" sx={{ ml: 1 }}>
            Filters
          </Typography>
          <IconButton onClick={onCloseFilter}>
            <CloseIcon />
          </IconButton>
        </Stack>

        <Divider />

        <Scrollbar>
          <Stack spacing={3} sx={{ p: 3 }}>
            {filters.map((item) => {
              return (
                <div>
                  <Typography variant="subtitle1">{item.title}</Typography>
                  <FormGroup>
                    {item.filters.map((item: any) => {
             
                      return (
                        <Box display="flex" alignItems="center"> 
                          <Checkbox
                            checked={selectedFilters.includes(item)}
                            onChange={(e) => handleFilterSelection(e, item)}
                          />
                          <Typography variant="subtitle1">
                         {item}
                          </Typography>
                        </Box>
                      );
                    })}
                  </FormGroup>
                </div>
              );
            })}
          </Stack>
        </Scrollbar>

        <Box sx={{ p: 3 }}>
          <Button
            fullWidth
            size="large"
            type="submit"
            color="inherit"
            variant="outlined"
            onClick={() => {
              onFilterChange([])
            }}
            startIcon={<ClearAllIcon />}
          >
            Clear All
          </Button>
        </Box>
      </Drawer>
    </>
  );
}
