import { ActionResult, Video } from '@core/types';
import { ApiError, ServiceResult } from '@core/index';
import { TagDescription, createApi } from '@reduxjs/toolkit/query/react';
import { VideoType, PaginatedList, VideoCategory, VideoFilter } from '@core/constants/constants';
import { SearchResponse } from '@core/types';
import { baseQueryWithReauth } from './base-slice';
import { ApiMethods, BaseEndpoints } from '@core/enums/enums';

const descriptionTags = ['Video'] as readonly TagDescription<'Video'>[] & string[];

export const videoSlice = createApi({
  tagTypes: descriptionTags,
  reducerPath: BaseEndpoints.Videos,
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getVideoById: builder.query<ServiceResult<Video, ApiError>, any>({
      query: (args: any) => ({
        url: `/${BaseEndpoints.Videos}/${args.id}`,
      }),
      providesTags: descriptionTags,
    }),
    getVideos: builder.query<ServiceResult<PaginatedList<Video[]>, ApiError>, any>({
      query: (args) => ({
        url: `/${BaseEndpoints.Videos}`,
        params: { ...args },
      }),
      providesTags: descriptionTags,
      //TODO Understand what is going on here
      // transformResponse: (response: any, meta: any, arg: any): ServiceResult<PaginatedList<Video[]>, ApiError> => {
      //   // Log the raw response
      //   console.log('Raw JSON response:', response);

      //   // Assuming response is already in the correct format, directly return it
      //   // Ensure that the response structure matches ServiceResult<PaginatedList<Video[]>, ApiError>
      //   return response;
      // },
    }),
    search: builder.query<ServiceResult<SearchResponse, ApiError>, any>({
      query: (args) => ({
        params: { ...args },
        url: `/${BaseEndpoints.Videos}/search`,
      }),
      providesTags: descriptionTags,
    }),
    editVideo: builder.mutation({
      query: (payload) => ({
        body: payload,
        method: ApiMethods.PUT,
        url: `/${BaseEndpoints.Videos}/${payload.id}`,
      }),
      invalidatesTags: descriptionTags,
    }),
    editVideoMedia: builder.mutation<any, { id: string; video: any }>({
      query: (payload) => ({
        body: payload.video,
        method: ApiMethods.POST,
        url: `/${BaseEndpoints.Videos}/${payload.id}/media`,
        // headers: { 'Content-Type': 'multipart/form-data' },
      }),
      invalidatesTags: descriptionTags,
    }),
    getVideoTypes: builder.query<ServiceResult<VideoType[], ApiError>, any>({
      query: () => ({
        url: `/${BaseEndpoints.Videos}/types`,
      }),
      providesTags: descriptionTags,
    }),
    getVideoRecommendation: builder.query<ServiceResult<Video[], ApiError>, any>({
      query: (args: any) => ({
        url: `/${BaseEndpoints.Videos}/recommended/${args.id}`,
      }),
      providesTags: descriptionTags,
    }),
    getVideoCategories: builder.query<ServiceResult<VideoCategory[], ApiError>, any>({
      query: () => ({
        url: `/${BaseEndpoints.Videos}/categories`,
      }),
      providesTags: descriptionTags,
    }),

    getVideoCategoryById: builder.query<ServiceResult<VideoCategory, ApiError>, any>({
      query: (args: any) => ({
        url: `/${BaseEndpoints.Videos}/categories/${args.id}`,
      }),
      providesTags: descriptionTags,
    }),
    getVideoRecommendedTags: builder.query<ServiceResult<VideoFilter[], ApiError>, any>({
      query: () => ({
        url: `/${BaseEndpoints.Videos}/preferences`,
      }),
      providesTags: descriptionTags,
    }),
    uploadVideo: builder.mutation({
      query: (payload) => ({
        body: payload,
        method: ApiMethods.POST,
        url: `/${BaseEndpoints.Videos}/upload`,
      }),
      invalidatesTags: descriptionTags,
    }),
    deleteVideo: builder.mutation({
      query: (args) => ({
        method: ApiMethods.DELETE,
        url: `/${BaseEndpoints.Videos}/${args.id}`,
      }),
      invalidatesTags: descriptionTags,
    }),
    deleteVideoCategory: builder.mutation({
      query: (args) => ({
        method: ApiMethods.DELETE,
        url: `/${BaseEndpoints.Videos}/categories/${args.id}`,
      }),
      invalidatesTags: descriptionTags,
    }),

    editVideoCategory: builder.mutation<ServiceResult<ActionResult, ApiError>, any>({
      query: (payload) => ({
        body: payload,
        method: ApiMethods.PUT,
        url: `/${BaseEndpoints.Videos}/categories`,
      }),
      invalidatesTags: descriptionTags,
    }),
    createVideoCategory: builder.mutation({
      query: (payload) => ({
        body: payload,
        method: ApiMethods.POST,
        url: `/${BaseEndpoints.Videos}/categories`,
      }),
      invalidatesTags: descriptionTags,
    }),
  }),
});
export const {
  useGetVideosQuery,
  useLazySearchQuery,
  useEditVideoMutation,
  useGetVideoByIdQuery,
  useGetVideoTypesQuery,
  useDeleteVideoMutation,
  useUploadVideoMutation,
  useGetVideoCategoriesQuery,
  useGetVideoCategoryByIdQuery,
  useEditVideoCategoryMutation,
  useGetVideoRecommendationQuery,
  useCreateVideoCategoryMutation,
  useDeleteVideoCategoryMutation,
  useGetVideoRecommendedTagsQuery,
  useEditVideoMediaMutation,
} = videoSlice;
