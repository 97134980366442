import { Grid } from "@mui/material";
import { NoVideos } from "./NoVideos";
import { CustomVideo } from "@core/types";
import { AnimatedList } from "react-animated-list";
import { CustomVideoItem } from "./CustomVideoItem";

type Props = {
  videos: CustomVideo[];
  refetch: any;
  handleSetCustomVideo: any;
  handleSetVideoMode: any;
};

export const CustomVideoListing: React.FC<Props> = ({
  videos,
  refetch,
  handleSetCustomVideo,
  handleSetVideoMode,
}) => {
  return (
    <div>
      {videos.length == 0 ? (
        <NoVideos />
      ) : (
        <Grid container spacing={3} ml={0.3}>
          <AnimatedList animation={"grow"}>
            {videos.map((video, index) => (
              <Grid item xs={3} mr={2}>
                <CustomVideoItem
                  video={video}
                  index={index}
                  refetch={refetch}
                  handleSetVideoMode={handleSetVideoMode}
                  handleSetCustomVideo={handleSetCustomVideo}
                />
              </Grid>
            ))}
          </AnimatedList>
        </Grid>
      )}
    </div>
  );
};
