import { useParams } from 'react-router';
import { useEffect, useRef, useState } from 'react';
import { BackButton } from '@components/BackButton';
import { Card, CircularProgress } from '@mui/material';
import TransitionWrapper from '@components/TransitionWrapper';
import { useGenerateAnalyticsUrlQuery } from '@features/state/slices/api/company-slice';

export default function ManageCompanyAnalytics() {
  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [source, setSource] = useState<string | undefined>(undefined);

  const { data, error, isFetching, isError, isSuccess } = useGenerateAnalyticsUrlQuery({
    id,
  });

  useEffect(() => {
    if (isSuccess && !isError && !isFetching) {
      setSource(data.data?.url);

      const iframe = iframeRef.current;
      if (iframe) {
        iframe.onload = handleIframeLoad;
        //iframe.style.padding = '20px';
      }

      return () => {
        if (iframe) {
          iframe.onload = null;
        }
      };
    }
  }, [isFetching, isError, isSuccess]);

  function handleIframeLoad() {
    setIsLoading(false);
  }

  return (
    <TransitionWrapper>
      <BackButton />
      <br></br>
      <div style={{ position: 'relative', width: '100%', height: '100%' }}>
        {isLoading && source && (
          <CircularProgress style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />
        )}
        <iframe
          src={source}
          ref={iframeRef}
          onLoad={handleIframeLoad}
          style={{ width: '100%', height: 1000, border: 'none', visibility: isLoading ? 'hidden' : 'visible' }}
        ></iframe>
      </div>
    </TransitionWrapper>
  );
}
