import { Navigator } from '../Navigator';
import { VideoMode } from '@core/enums/enums';
import { Box, Typography } from '@mui/material';
import { fadeInRight } from 'react-animations';
import TransitionWrapper from '@components/TransitionWrapper';
import Completion from '../../../../../../../assets/images/video-complete.webp';

type Props = {
  data: any;
  steps: any;
  activeStep: number;
  videoMode: VideoMode;
  handleBack: () => void;
  handleNext: () => void;
  handleSkip: () => void;
  onCapture: (data: any) => void;
};

export const CompletionStep: React.FC<Props> = (props: Props) => {
  const { steps, activeStep, videoMode, handleBack, handleNext, handleSkip } = props;

  return (
    <TransitionWrapper duration={0.2} animation={fadeInRight}>
      <Box display="flex" justifyContent="center" alignItems="center">
        <img crossOrigin="anonymous" height={400} width={650} src={Completion} />
        <Typography>You're all set now, click finish to upload your video</Typography>
        <br></br>
      </Box>{' '}
      <Navigator steps={steps} handleSkip={handleSkip} handleBack={handleBack} activeStep={activeStep} handleNext={handleNext} />
    </TransitionWrapper>
  );
};
