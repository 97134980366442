import { FLUSH, PAUSE, PURGE, PERSIST, REGISTER, REHYDRATE } from 'redux-persist';
import { Store } from 'redux';
import thunk from 'redux-thunk';
import { persistStore } from 'redux-persist';
import { configureStore } from '@reduxjs/toolkit';
import { adminSlice } from '../slices/api/admin-slice';
import { videoSlice } from '../slices/api/video-slice';
import { requestSlice } from '../slices/api/request-slice';
import { packageSlice } from '../slices/api/package-slice';
import { companySlice } from '../slices/api/company-slice';
import { authenticationSlice } from '../slices/api/auth-slice';
import { customVideoSlice } from '../slices/api/custom-video-slice';
import { notificationSlice } from '../slices/api/notification-slice';
import { subscriptionSlice } from '../slices/api/subscription-slice';
import { paymentMethodSlice } from '../slices/api/payment-methods.slice';
import { persistedReducer, RootState } from '../reducers/combinedReducers';
import { videoCreditSlice } from '../slices/api/video-credit-slice';
import { packageSubscriptionSlice } from '../slices/api/package-subscriptions-slice';
import { serviceSlice } from '../slices/api/service-slice';

const store: Store<RootState, any> & {
  dispatch: any;
} = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat([
      thunk,
      adminSlice.middleware,
      videoSlice.middleware,
      companySlice.middleware,
      packageSlice.middleware,
      requestSlice.middleware,
      serviceSlice.middleware,
      videoCreditSlice.middleware,
      customVideoSlice.middleware,
      notificationSlice.middleware,
      subscriptionSlice.middleware,
      paymentMethodSlice.middleware,
      authenticationSlice.middleware,
      packageSubscriptionSlice.middleware,
    ]),

  enhancers: [],
});

const persistor = persistStore(store);

export { store, persistor };
