import * as yup from 'yup';
import { Navigator } from '../Navigator';
import { Controller, useForm } from 'react-hook-form';
import { AppInput } from '@components/Styled';
import { yupResolver } from '@hookform/resolvers/yup';
import PercentIcon from '@mui/icons-material/Percent';
import { determineBorderColor } from '@core/utils/utils';
import {
  Box,
  InputLabel,
  Typography,
  FormControl,
  FormHelperText,
  Autocomplete,
  TextField,
  Checkbox,
  FormControlLabel,
  Switch,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { fadeInRight } from 'react-animations';
import TransitionWrapper from '@components/TransitionWrapper';
import KeywordSelector from '@components/KeywordSelector';
import { Company, CreateSubscriptionDTO, Query } from '@core/constants/constants';
import { SubscriptionMode } from '@core/enums/enums';
import Tooltip from '@mui/material/Tooltip';
import { useGetCompaniesQuery } from '@features/state/slices/api/company-slice';
//TODO Modify Create Subscription DTO to have public property
type Props = {
  data: CreateSubscriptionDTO;
  steps: any;
  activeStep: number;
  subscriptionMode: SubscriptionMode;
  handleBack: () => void;
  handleNext: () => void;
  handleSkip: () => void;
  onCapture: (data: any) => void;
};

const schema = yup.object().shape({
  name: yup.string().required('A Subscription name is required'),
  //DEPRECATED Credit
  //credits: yup.number().required('Credits is required').min(1, 'Credit must be greater than 1'),
  usersCovered: yup
    .number()
    .required('You must specify how many users are included in the plan')
    .min(1, 'At least one user must be covered by the subscription')
    .transform((value, originalValue) => {
      return originalValue === '' ? 0 : value;
    }),
  //DEPRECATED video package discount no longer available
  videoPackageDiscount: yup.number().default(0),
  // subscriptionItems: yup.mixed().test('Subscription Items', 'Subscription items are required', (items) => {
  //   if (items === undefined) {
  //     return false;
  //   }
  //   if (items?.length === 0) return false;
  //   return true;
  // }),
  subscriptionItemsIncluded: yup.mixed().default([]),
  access: yup.mixed().default([]),
  hasTrialPeriod: yup.boolean().default(false),
  numberOfDaysInTrialPeriod: yup
    .number()
    .default(0)
    .transform((value, originalValue) => {
      return originalValue === '' ? 0 : value;
    })
    .when('hasTrialPeriod', {
      is: true,
      then: yup.number().required('Please specify the number of days in the trial period').min(1, 'Trial period must be at least 1 day'),
      otherwise: yup.number().notRequired(),
    }),
  requiresAccessCode: yup.boolean().default(false),
  accessCodes: yup
    .array()
    .default([])
    .when('requiresAccessCode', {
      is: true,
      then: yup
        .array()
        .min(1, 'Please specify at least one access code for this subscription')
        .required('Please specify access codes to be used with this subscription'),
      otherwise: yup.array().notRequired(),
    }),
});

export const SubscriptionTitleStep: React.FC<Props> = (props: Props) => {
  const [subscriptionItems, setSubscriptionItems] = useState<string[]>([]);
  const { data, steps, activeStep, subscriptionMode, onCapture, handleBack, handleNext, handleSkip } = props;
  const [isSubscriptionPublic, setIsSubscriptionPublic] = useState(true);
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
    watch,
    control,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [companies, setCompanies] = useState<Company[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<Company[]>([]);

  const [query, setQuery] = useState<Query>({
    page: 1,
    limit: 10,
    searchTerm: '',
  });

  const {
    data: comapniesFetched,
    error,
    isLoading,
    refetch,
  } = useGetCompaniesQuery(
    {
      limit: query.limit,
      page: query.page,
      searchTerm: query.searchTerm,
      dump: true,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );
  useEffect(() => {
    if (comapniesFetched && !error && !isLoading) {
      setCompanies(comapniesFetched.data!.items);
    }
  }, [error, isLoading]);

  //TODO When in edit mode remmeber to populate to companies that have access
  useMemo(() => {
    if (subscriptionMode === SubscriptionMode.Edit) {
      setValue('name', data?.name);
      // setValue('credits', data?.credits);
      setValue('usersCovered', data?.usersCovered);
      setValue('discount', data?.videoPackageDiscount);
      setValue('subscriptionItems', data?.subscriptionItemsIncluded);
      setValue('hasTrialPeriod', data?.hasTrialPeriod);
      setValue('requiresAccessCode', data?.accessCodes.length != 0);

      setSubscriptionItems(data?.subscriptionItemsIncluded);
      setIsSubscriptionPublic(data?.subscriptionPublic);

      if (data?.hasTrialPeriod) {
        setValue('numberOfDaysInTrialPeriod', data?.numberOfDaysInTrialPeriod);
      }

      if (data.access.length !== 0) {
        console.log('access', data.access);

        const filteredCompanies = companies.filter((company) => data.access.includes(company.id));
        console.log('access', data.access, filteredCompanies);
        setSelectedCompany(filteredCompanies);
        setValue('access', data.access);
      }

      if (data.subscriptionPublic) {
        setIsSubscriptionPublic(data.subscriptionPublic);
      }

      if (data.accessCodes.length !== 0) {
        setValue('requiresAccessCode', true);
        setValue('access', data.accessCodes);
      }
    }
  }, [subscriptionMode, companies]);

  const captureData = (data: any) => {
    handleNext();
    onCapture({ ...data, subscriptionPublic: isSubscriptionPublic });
  };

  const hasTrialPeriod = watch('hasTrialPeriod', false);
  const requiresAccessCode = watch('requiresAccessCode', false);
  const handleToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsSubscriptionPublic(event.target.checked);
  };

  // console.log('Test ', data.access.length !== 0 && selectedCompany.length > 0, data.access.length === 0);
  return (
    <TransitionWrapper duration={0.2} animation={fadeInRight}>
      <Box mt={2} mb={5}>
        <Typography variant="subtitle1">Title your subscription</Typography>
        <FormControl variant="standard" style={{ width: '100%', marginTop: 15 }}>
          <InputLabel shrink htmlFor="bootstrap=input">
            Subscription Name
          </InputLabel>
          <AppInput
            required
            fullWidth
            id="name"
            {...register('name', {
              required: true,
              maxLength: 80,
            })}
            style={determineBorderColor(errors?.name?.message as any)}
          />
        </FormControl>
        <FormHelperText style={{ color: 'red' }}>{errors?.name?.message as string}</FormHelperText>
        {/* <FormControl variant="standard" style={{ width: '100%', marginTop: 15 }}>
          <InputLabel shrink htmlFor="bootstrap=input">
            Credits
          </InputLabel>
          <AppInput
            required
            fullWidth
            id="credits"
            type="number"
            {...register('credits', {
              required: true,
              maxLength: 80,
            })}
            style={determineBorderColor(errors?.credits?.message as any)}
          />
        </FormControl>
        <FormHelperText style={{ color: 'red' }}>{errors?.credits?.message as string}</FormHelperText> */}
        <FormControl variant="standard" style={{ width: '100%', marginTop: 15 }}>
          <Tooltip title="The amount of sales associates that a company admin can invite using this subscription">
            <InputLabel shrink htmlFor="bootstrap=input">
              Users Covered
            </InputLabel>
          </Tooltip>
          <AppInput
            required
            fullWidth
            id="usersCovered"
            type="number"
            {...register('usersCovered', {
              required: true,
              maxLength: 80,
            })}
            style={determineBorderColor(errors?.usersCovered?.message as any)}
          />
        </FormControl>
        <FormHelperText style={{ color: 'red' }}>{errors?.usersCovered?.message as string}</FormHelperText>
        {/* <FormControl variant="standard" style={{ width: '100%', marginTop: 15 }}>
          <InputLabel shrink htmlFor="bootstrap=input">
            Discount
          </InputLabel>
          <AppInput
            required
            fullWidth
            id="discount"
            type="number"
            {...register('discount', {
              required: true,
              maxLength: 80,
            })}
            style={determineBorderColor(errors?.discount?.message as any)}
          />
          <div className="input-icon">
            <PercentIcon style={{ width: 20, color: 'grey' }} />
          </div>
        </FormControl> */}
        {/* <FormHelperText style={{ color: 'red' }}>{errors?.discount?.message as string}</FormHelperText> */}
        <FormControl variant="standard" style={{ width: '100%', marginTop: 15 }}>
          <Tooltip title="You can include the features of the subscription or descriptive/promotional text about the subscription">
            <InputLabel shrink htmlFor="bootstrap-input">
              Included Features / Description
              {/* These are the features that come with the subscription. It can also be promotional text for the subscripton. Eg. `No shame about it!` for a basic subscription */}
            </InputLabel>
          </Tooltip>

          <KeywordSelector
            keywords={subscriptionItems}
            videoMode={null}
            subscriptionMode={subscriptionMode}
            onSelected={(subscriptionItems) => {
              // console.log(subscriptionItems);
              setSubscriptionItems(subscriptionItems);
              setValue('subscriptionItemsIncluded', subscriptionItems);
            }}
          />
          <FormHelperText style={{ color: 'red' }}>{errors?.subscriptionItems?.message as string}</FormHelperText>
        </FormControl>

        <FormControl variant="standard" style={{ width: '100%', marginTop: 5 }}>
          <Tooltip title="Choose which companies have access to the subscription plan">
            <InputLabel shrink htmlFor="bootstrap-input">
              Grant Subscription Access to Company
            </InputLabel>
          </Tooltip>
          {subscriptionMode === SubscriptionMode.Edit ? (
            <>
              {((data.access.length !== 0 && selectedCompany.length > 0) || data.access.length === 0) && (
                <Autocomplete
                  multiple
                  limitTags={2}
                  id="multiple-limit-tags"
                  options={companies}
                  defaultValue={selectedCompany}
                  getOptionLabel={(option) => option.name!}
                  onChange={(event, newCompany) => {
                    setSelectedCompany(newCompany);
                    setValue(
                      'access',
                      newCompany.map((company) => company.id),
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={selectedCompany.length == 0 ? 'All Companies' : 'Companies Assigned'}
                      placeholder="Select a Company"
                    />
                  )}
                  sx={{
                    marginTop: '25px',
                  }}
                />
              )}
            </>
          ) : (
            <Autocomplete
              multiple
              limitTags={2}
              id="multiple-limit-tags"
              options={companies}
              getOptionLabel={(option) => option.name!}
              onChange={(event, newCompany) => {
                setSelectedCompany(newCompany);
                setValue(
                  'access',
                  newCompany.map((company) => company.id),
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={selectedCompany.length == 0 ? 'All Companies' : 'Companies Assigned'}
                  placeholder="Select a Company"
                />
              )}
              sx={{
                marginTop: '25px',
              }}
            />
          )}
        </FormControl>

        <Box display={'flex'} flexDirection={'column'} alignItems="flex-start" marginTop={'20px'} marginBottom={'10px'}>
          <Tooltip arrow placement="top" title="This determines whether a company can see this subscription on their Company Portal">
            <InputLabel shrink htmlFor="watermark-toggle">
              Is Subscription Public
            </InputLabel>
          </Tooltip>

          <Box display="flex" alignItems="center" mt={2}>
            <Switch checked={isSubscriptionPublic} onChange={handleToggle} inputProps={{ 'aria-label': 'Watermark Access Toggle' }} />

            <Box ml={2} p={1} borderRadius={1} bgcolor={isSubscriptionPublic ? 'lightgreen' : 'lightcoral'} paddingX={'30px'}>
              <Typography variant="body2" fontWeight="bold" color={'white'}>
                {isSubscriptionPublic ? 'Subscription is Public' : 'Subscription is Private'}
              </Typography>
            </Box>
          </Box>
        </Box>
        <FormControlLabel
          control={
            <Controller name="hasTrialPeriod" control={control} render={({ field }) => <Checkbox {...field} checked={field.value} />} />
          }
          style={{ marginTop: 5 }}
          label="Does this subscription have a trial period?"
        />

        {hasTrialPeriod && (
          <FormControl variant="standard" style={{ width: '100%', marginTop: 15 }}>
            <Tooltip title="Please choose the number of days that this trial period should last.">
              <InputLabel shrink htmlFor="bootstrap-input">
                Number of Days in Trial Period
              </InputLabel>
            </Tooltip>
            <AppInput
              required
              fullWidth
              id="numberOfDaysInTrialPeriod"
              type="number"
              {...register('numberOfDaysInTrialPeriod')}
              style={determineBorderColor(errors?.numberOfDaysInTrialPeriod?.message as any)}
            />
            <FormHelperText style={{ color: 'red' }}>{errors?.numberOfDaysInTrialPeriod?.message as string}</FormHelperText>
          </FormControl>
        )}
        <br />
        <FormControlLabel
          control={
            <Controller name="requiresAccessCode" control={control} render={({ field }) => <Checkbox {...field} checked={field.value} />} />
          }
          style={{ marginTop: 5 }}
          label="Does this subscription require access codes to be purchased?"
        />

        {requiresAccessCode && (
          <FormControl variant="standard" style={{ width: '100%', marginTop: 15 }}>
            <Tooltip title="Give the access codes required to purchase this subscription">
              <InputLabel shrink htmlFor="bootstrap-input">
                Access Codes
              </InputLabel>
            </Tooltip>

            <KeywordSelector
              keywords={[]}
              videoMode={null}
              subscriptionMode={subscriptionMode}
              onSelected={(accessCodes) => {
                // console.log(subscriptionItems);
                // setSubscriptionItems(subscriptionItems);
                setValue('accessCodes', accessCodes);
              }}
            />
            <FormHelperText style={{ color: 'red' }}>{errors?.accessCodes?.message as string}</FormHelperText>
          </FormControl>
        )}

        <Navigator
          steps={steps}
          handleSkip={handleSkip}
          handleBack={handleBack}
          activeStep={activeStep}
          handleNext={handleSubmit(captureData)}
        />
      </Box>
    </TransitionWrapper>
  );
};
