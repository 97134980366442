import {
  Box,
  Card,
  Table,
  Avatar,
  Button,
  Dialog,
  Tooltip,
  Divider,
  Checkbox,
  TableRow,
  useTheme,
  TableBody,
  TableCell,
  TableHead,
  Typography,
  CardHeader,
  IconButton,
  DialogTitle,
  DialogActions,
  DialogContent,
  TableContainer,
  LinearProgress,
  InputAdornment,
  CircularProgress,
  DialogContentText,
} from '@mui/material';
import moment from 'moment';
import toast from 'react-hot-toast';
import Filter from '@components/Filter';
import { useDispatch } from 'react-redux';
import Iconify from '@components/iconify';
import AppChip from '@components/AppChip';
import { Retry } from '@components/Retry';
import { ServiceResult } from '@core/index';
import { useNavigate } from 'react-router';

import BlockIcon from '@mui/icons-material/Block';
import CheckIcon from '@mui/icons-material/Check';
import { StyledSearch } from '@components/Styled';
import { EmptyTable } from '@components/EmptyTable';
import { formatPhoneNumber } from '@core/utils/utils';
import { RefreshButton } from '@components/RefreshButton';
import { AppDialog, IDialogRef } from '@components/AppDialog';
import BulkActions from '@components/BulkActions/BulkActions';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import AppTablePagination from '@components/AppTablePagination';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import SearchOffOutlinedIcon from '@mui/icons-material/SearchOffOutlined';
import { Company, PaginatedList, Query } from '@core/constants/constants';
import { toggleLoading } from '@features/state/slices/local/loading-slice';
import React, { ChangeEvent, useState, useEffect, createRef } from 'react';
import { useBlockCompanyMutation, useDeleteCompanyMutation, useUnblockCompanyMutation } from '@features/state/slices/api/company-slice';

type Props = {
  refetch: any;
  loading: boolean;
  error: any | undefined;
  onChange: (query: Query) => void;
  data: ServiceResult<PaginatedList<Company[]>, any> | undefined;
};

export function CompanyTable({ data, error, loading, refetch, onChange }: Props) {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const dialogRef = createRef<IDialogRef>();
  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(10);
  const [adminId, setAdminId] = useState<number>(0);
  const [openFilter, setOpenFilter] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [companies, setAdmins] = useState<Company[]>([]);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [blockCompanyMutation] = useBlockCompanyMutation();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [deleteCompanyMutation] = useDeleteCompanyMutation();
  const [unBlockCompanyMutation] = useUnblockCompanyMutation();
  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);
  const [selectedCompanies, setSelectedCompanies] = useState<number[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<number | null>(null);

  const selectedBulkActions = selectedCompanies.length > 0;

  const open = Boolean(anchorEl);

  const handleMenuOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const toggleDeleteDialog = () => {
    handleMenuClose();
    setDeleteDialog(!deleteDialog);
  };

  useEffect(() => {
    if (!loading && !error) {
      setAdmins(data?.data?.items ?? []);
    }
  }, [loading, error, data]);

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  const handleCompanyEdit = (id: number) => {
    navigate(`update/${id}`);
  };

  const handleSelectAllCryptoOrders = (event: ChangeEvent<HTMLInputElement>): void => {
    setSelectedCompanies(event.target.checked ? companies.map((admin) => admin.id) : []);
  };

  const handleSearch = (event: any) => {
    setSearchTerm(event.target.value);
    setTimeout(
      () =>
        onChange({
          page,
          limit,
          searchTerm: event.target.value,
        }),
      1000,
    );
  };

  const handleSelectOneAdmin = (event: ChangeEvent<HTMLInputElement>, companyId: number): void => {
    if (!selectedCompanies.includes(companyId)) {
      setSelectedCompanies((prevSelected) => [...prevSelected, companyId]);
    } else {
      setSelectedCompanies((prevSelected) => prevSelected.filter((id) => id !== companyId));
    }
  };

  const handlePaginationChanged = (limit: number, page: number) => {
    setPage(page);
    setLimit(limit);

    onChange({
      page,
      limit,
      searchTerm,
    });
    refetch();
  };

  const handleCompanyBlock = async (id: number) => {
    try {
      dispatch(toggleLoading());
      const { data, error, success } = await blockCompanyMutation({
        id,
      }).unwrap();

      if (!success && error) {
        toast.error(error.message!);
        dispatch(toggleLoading());
        return;
      }

      toast.success('Company successfully blocked');

      refetch();
    } catch (e) {}
    dispatch(toggleLoading());
  };

  const handleCompanyUnblock = async (id: number) => {
    try {
      dispatch(toggleLoading());
      const { data, error, success } = await unBlockCompanyMutation({
        id,
      }).unwrap();

      if (!success && error) {
        toast.error(error.message!);
        dispatch(toggleLoading());
        return;
      }

      toast.success('Company successfully unblocked');

      refetch();
    } catch (e) {}
    dispatch(toggleLoading());
  };

  const handleCompanyDelete = async () => {
    //alert(selectedCompany);
    try {
      dispatch(toggleLoading());
      const { data, error, success } = await deleteCompanyMutation({
        id: selectedCompany,
      }).unwrap();

      if (!success && error) {
        toast.error(error.message!);
        return;
      }

      toast.success('Company successfully deleted');

      refetch();
    } catch (e) {
    } finally {
      setSelectedCompany(null);
      dispatch(toggleLoading());
    }
  };

  const selectedSomeAdmins = companies.length > 0 && companies.length < companies.length;
  const selectedAllAdmins = selectedCompanies.length === companies.length;

  return (
    <>
      {!error ? (
        <React.Fragment>
          <Card className="shadow-sm">
            {selectedBulkActions && (
              <Box flex={1} p={2}>
                <BulkActions />
              </Box>
            )}
            {!selectedBulkActions && (
              <CardHeader
                style={{ fontWeight: 'light' }}
                action={
                  <Box
                    sx={{
                      float: 'right',
                      display: 'flex',
                      justifyContent: 'end',
                      alignItems: 'center',
                    }}
                  >
                    <Box>
                      {selectedFilters.map((filter) => (
                        <Tooltip title={filter} placement="top">
                          <AppChip sx={{ ml: 1 }}>{filter}</AppChip>
                        </Tooltip>
                      ))}
                    </Box>
                    <Box display="flex" alignItems="center" justifyContent="center">
                      <Filter
                        openFilter={openFilter}
                        onOpenFilter={handleOpenFilter}
                        selectedFilters={selectedFilters}
                        onCloseFilter={handleCloseFilter}
                        onFilterChange={setSelectedFilters}
                        filters={[
                          {
                            title: 'Status',
                            filters: ['Active', 'Inactive'],
                          },
                        ]}
                      />
                    </Box>
                    <Box>
                      <RefreshButton onRefresh={refetch} />
                    </Box>
                  </Box>
                }
                title={
                  <StyledSearch
                    fullWidth
                    onChange={handleSearch}
                    placeholder="Search Companies"
                    startAdornment={
                      <InputAdornment position="start">
                        <Iconify
                          icon="eva:search-fill"
                          sx={{
                            color: 'text.disabled',
                            width: 20,
                            height: 20,
                          }}
                        />
                      </InputAdornment>
                    }
                    endAdornment={<InputAdornment position="start">{loading && <CircularProgress size={20} />}</InputAdornment>}
                  />
                }
              />
            )}
            <Divider />
            <TableContainer>
              <Table>
                {loading && <LinearProgress />}
                {!loading && (
                  <>
                    <TableHead>
                      <TableRow>
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={selectedAllAdmins}
                            indeterminate={selectedSomeAdmins}
                            onChange={handleSelectAllCryptoOrders}
                          />
                        </TableCell>
                        <TableCell>ID</TableCell>
                        <TableCell>Company</TableCell>
                        <TableCell>Company Admin Email</TableCell>
                        <TableCell>Phone Number</TableCell>
                        <TableCell align="left">Status</TableCell>
                        <TableCell align="left">Last Login</TableCell>
                        <TableCell align="left">Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {companies.map((company: Company) => {
                        const isCompanySelected = selectedCompanies.includes(company.id);
                        return (
                          <TableRow hover key={company.id} selected={isCompanySelected}>
                            <TableCell padding="checkbox">
                              <Checkbox
                                color="primary"
                                checked={isCompanySelected}
                                onChange={(event: ChangeEvent<HTMLInputElement>) => handleSelectOneAdmin(event, company.id)}
                                value={isCompanySelected}
                              />
                            </TableCell>
                            <TableCell>
                              {/* <Tooltip title={company.id} arrow> */}
                              <Typography variant="body1" fontWeight="bold" color="text.primary" gutterBottom noWrap>
                                {company.id}
                              </Typography>
                              {/* </Tooltip> */}
                            </TableCell>
                            <TableCell sx={{ cursor: 'pointer' }} onClick={() => handleCompanyEdit(company.id)}>
                              <Tooltip title={company.name} arrow>
                                <Box display="flex" alignItems="center">
                                  {/* <Avatar src={company?.profilePic} /> */}
                                  <Avatar src={company?.profilePic} />
                                  <Typography ml={1} noWrap variant="body2" color="text.secondary">
                                    {company.name}
                                  </Typography>
                                </Box>
                              </Tooltip>
                            </TableCell>
                            <TableCell>
                              {/* <Tooltip title={company.email} arrow> */}
                              <Typography variant="body1" color="text.primary" gutterBottom noWrap>
                                {company.email}
                              </Typography>
                              {/* </Tooltip> */}
                            </TableCell>
                            <TableCell>
                              {/* <Tooltip
                                title={
                                  formatPhoneNumber(company.phoneNumber) ??
                                  "N/A"
                                }
                                arrow
                              > */}
                              <Typography variant="body1" color="text.primary" gutterBottom noWrap>
                                {formatPhoneNumber(company.phoneNumber) ?? 'N/A'}
                              </Typography>
                              {/* </Tooltip> */}
                            </TableCell>
                            <TableCell align="left">
                              {company.blocked ? (
                                <AppChip color="error"> Blocked</AppChip>
                              ) : company.lastLogin == null ? (
                                <AppChip color="error">In Active</AppChip>
                              ) : (
                                <AppChip color="success">Active</AppChip>
                              )}
                            </TableCell>
                            <TableCell align="left">
                              {/* <Tooltip
                                title={
                                  moment(company.lastLogin).format(
                                    ""
                                  ) ?? "N/A"
                                }
                                arrow
                              > */}
                              <Typography variant="body1" color="text.primary" gutterBottom noWrap>
                                {company.lastLogin != null ? moment(company.lastLogin).format('L') : 'N/A'}
                              </Typography>
                              {/* </Tooltip> */}
                            </TableCell>
                            <TableCell align="left">
                              <Tooltip title="Edit Company" arrow>
                                <IconButton
                                  onClick={() => handleCompanyEdit(company.id)}
                                  sx={{
                                    '&:hover': {
                                      background: theme.colors.primary.lighter,
                                    },
                                    color: theme.palette.primary.main,
                                  }}
                                  color="inherit"
                                  size="small"
                                >
                                  <EditTwoToneIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Delete Company" arrow>
                                <IconButton
                                  sx={{
                                    '&:hover': {
                                      background: theme.colors.error.lighter,
                                    },
                                    color: theme.palette.error.main,
                                  }}
                                  color="inherit"
                                  size="small"
                                  onClick={() => {
                                    setSelectedCompany(company.id);
                                    dialogRef.current?.openDialog();
                                  }}
                                >
                                  <DeleteTwoToneIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>

                              <Tooltip title={company.blocked ? 'Unblock Company' : 'Block Company'} arrow>
                                <IconButton
                                  sx={{
                                    '&:hover': {
                                      background: theme.colors.error.lighter,
                                    },
                                    color: theme.palette.error.main,
                                  }}
                                  color="inherit"
                                  size="small"
                                  onClick={() => {
                                    if (company.blocked) {
                                      handleCompanyUnblock(company.id!);
                                    } else {
                                      handleCompanyBlock(company.id!);
                                    }
                                  }}
                                >
                                  {company.blocked ? <CheckIcon fontSize="small" /> : <BlockIcon fontSize="small" />}
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </>
                )}
              </Table>
            </TableContainer>
            <AppDialog
              ref={dialogRef}
              title="Delete Company"
              onConfirm={() => handleCompanyDelete()}
              subtitle="Are you sure you want to delete this company?. This will subsequently disable all sales associate accounts that are a part of this company"
            />
          </Card>
          {data?.data?.items?.length != null && data?.data?.items?.length == 0 && (
            <EmptyTable
              subtitle="There are currently no company accounts on the system"
              icon={<SearchOffOutlinedIcon sx={{ color: theme.colors.primary.dark, fontSize: 130 }} />}
            />
          )}
          {!loading && data?.data?.items?.length != null && data?.data?.items?.length > 0 && (
            <AppTablePagination onChange={handlePaginationChanged} totalPages={data?.data?.meta.totalPages ?? 1} />
          )}
        </React.Fragment>
      ) : (
        <Retry refetch={refetch} />
      )}
    </>
  );
}
