import { useEffect, useState } from 'react';
import { Box, styled } from '@mui/material';
import Status404 from '@components/Status404';
import ManageAdmins from '@features/pages/Admin';
import { Route, Routes } from 'react-router-dom';
import { CustomVideo, Video } from '@core/types';
import Dashboard from '@features/pages/Dashboard';
import { AppRoutes } from '@core/constants/routes';
import ManageCompany from '@features/pages/Company';
import ManageAccount from '@features/pages/Settings';
import Login from '@features/pages/Auth/Login/Login';
import ManageFeedback from '@features/pages/Feedback';
import SidebarLayout from '@core/layouts/AppLayout';
import { Subscription } from '@core/constants/constants';
import SetPassword from '@features/pages/Auth/SetPassword';
import ManagePromotions from '@features/pages/Promotions';
import { EditAdmin } from '@features/pages/Admin/EditAdmin';
import { SubscriptionMode, VideoMode } from '@core/enums/enums';
import ManageSubscriptions from '@features/pages/Subscriptions';
import { CreateAdmin } from '@features/pages/Admin/CreateAdmin';
import { ProtectedRoute } from '@core/utils/routeAuthentication';
import ForgetPassword from '@features/pages/Auth/ForgetPassword';
import ManageCustomVideoRequests from '@features/pages/Requests';
import { CreateCompany } from '@features/pages/Company/CreateCompany';
import ManageCompanyAnalytics from '@features/pages/Company/Analytics';
import CustomVideos from '@features/pages/VideoManagement/CustomVideos';
import ManageVideos from '@features/pages/VideoManagement/StandardVideos';
import ManagePackages from '@features/pages/VideoPackageManagement/Package';
import VideoCategories from '@features/pages/VideoManagement/VideoCategories';
import CreateSubscription from '@features/pages/Subscriptions/CreateSubscription';
import EditPackage from '@features/pages/VideoPackageManagement/Package/EditPackage';
import { EditVideo } from '@features/pages/VideoManagement/StandardVideos/EditVideo';
import PackageCategories from '@features/pages/VideoPackageManagement/PackageCategories';
import { UploadVideo } from '@features/pages/VideoManagement/StandardVideos/UploadVideo';
import CreatePackage from '@features/pages/VideoPackageManagement/Package/CreatePackage';
import { ManageCompanySubscriptionEditCompany } from '@features/pages/Company/EditCompany';
import EditVideoCategory from '@features/pages/VideoManagement/VideoCategories/EditVideoCategory';
import { UploadCustomVideo } from '@features/pages/VideoManagement/CustomVideos/UploadCustomVideo';
import CreateVideoCategory from '@features/pages/VideoManagement/VideoCategories/CreateVideoCategory';
import { VideoDetails } from '@features/pages/VideoManagement/StandardVideos/components/VideoDetails';
import { VideoPreview } from '@features/pages/VideoManagement/StandardVideos/components/VideoPreview';
import CustomVideoDetails from '@features/pages/VideoManagement/CustomVideos/components/CustomVideoDetails';
import EditPackageCategory from '@features/pages/VideoPackageManagement/PackageCategories/EditPackageCategory';
import CreatePackageCategory from '@features/pages/VideoPackageManagement/PackageCategories/CreatePackageCategory';
import CreateCompanyPackageSubscription from '@features/pages/Company/components/ManageCompanyPackagesCard/CreateCompanyPackageSubscription';
import ManageCompanySubscription from '@features/pages/Company/components/ManageCompanySubscriptionCard/ManageCompanySubscriptions/ManageCompanySubscription';
import { UpdateBannerPromotion } from '@features/pages/Promotions/UploadPromotion';

type Props = {};

const BaseContainer = styled('div')(({ theme }: any) => ({
  backgroundColor: theme.palette,
}));

export const Router: React.FC<Props> = (props: Props) => {
  const [video, setVideo] = useState<Video | null>(null);
  const [videoMode, setVideoMode] = useState<VideoMode>(VideoMode.Create);

  const [customVideo, setCustomVideo] = useState<CustomVideo | null>(null);

  const [subscription, setSubscription] = useState<Subscription | null>(null);
  const [subscriptionMode, setSubscriptionMode] = useState<SubscriptionMode>(SubscriptionMode.Create);

  const handleSetVideo = (video: Video) => {
    setVideo(video);
  };

  const handleSetCustomVideo = (video: CustomVideo) => {
    setCustomVideo(video);
  };

  const handleSetVideoMode = (mode: VideoMode) => {
    setVideoMode(mode);
  };

  const handleSetSubscription = (subscription: Subscription) => {
    setSubscription(subscription);
  };
  const handleSetSubscriptionMode = (mode: SubscriptionMode) => {
    setSubscriptionMode(mode);
  };

  return (
    <BaseContainer>
      <Routes>
        <Route
          path={`/${AppRoutes.AuthBase}/*`}
          element={
            <Routes>
              <Route path={`/${AppRoutes.Auth.Login}`} element={<Login />} />
              <Route path={`/${AppRoutes.Auth.SetPassword}`} element={<SetPassword />} />
              <Route path={`/${AppRoutes.Auth.ForgetPassword}`} element={<ForgetPassword />} />
            </Routes>
          }
        />

        <Route
          path={`/${AppRoutes.RouteBase}/*`}
          element={
            <ProtectedRoute>
              <SidebarLayout>
                <Routes>
                  <Route path={`/home`} element={<Dashboard />} />

                  <Route path={`/${AppRoutes.RequestBase}/*`} element={<ManageCustomVideoRequests />} />

                  <Route
                    path={`/${AppRoutes.CompanyBase}/*`}
                    element={
                      <Routes>
                        <Route path={`/`} element={<ManageCompany />} />
                        <Route path={`/${AppRoutes.Company.ManageAnalytics}`} element={<ManageCompanyAnalytics />} />
                        <Route
                          path={`/${AppRoutes.Company.Update}/*`}
                          element={
                            <Routes>
                              {' '}
                              <Route path={`/`} element={<ManageCompanySubscriptionEditCompany />} />
                              <Route path={`/${AppRoutes.Company.AddPackage}`} element={<CreateCompanyPackageSubscription />} />
                              <Route path={`/${AppRoutes.Company.ManageSubscription}`} element={<ManageCompanySubscription />} />
                            </Routes>
                          }
                        />

                        <Route path={`/${AppRoutes.Company.Create}`} element={<CreateCompany />} />
                      </Routes>
                    }
                  />

                  <Route
                    path={`/${AppRoutes.AdminBase}/*`}
                    element={
                      <Routes>
                        <Route path={`/`} element={<ManageAdmins />} />
                        <Route path={`/${AppRoutes.Admin.Edit}`} element={<EditAdmin />} />
                        <Route path={`/${AppRoutes.Admin.Create}`} element={<CreateAdmin />} />
                      </Routes>
                    }
                  />

                  <Route
                    path={`/${AppRoutes.ConfigBase}/*`}
                    element={
                      <Routes>
                        <Route path="/" element={<Status404 />} />
                        <Route
                          path={`/${AppRoutes.Configure.Videos}/*`}
                          element={
                            <Routes>
                              <Route path={`/`} element={<VideoCategories />} />
                              <Route path={`/${AppRoutes.VideoCategory.Edit}`} element={<EditVideoCategory />} />
                              <Route path={`/${AppRoutes.VideoCategory.Create}`} element={<CreateVideoCategory />} />
                            </Routes>
                          }
                        />
                        <Route
                          path={`/${AppRoutes.Configure.Packages}/*`}
                          element={
                            <Routes>
                              <Route path={`/`} element={<PackageCategories />} />
                              <Route path={`/${AppRoutes.PackageCategory.Edit}`} element={<EditPackageCategory />} />
                              <Route path={`/${AppRoutes.PackageCategory.Create}`} element={<CreatePackageCategory />} />
                            </Routes>
                          }
                        />
                      </Routes>
                    }
                  />

                  <Route
                    path={`/${AppRoutes.PromotionBase}/*`}
                    element={
                      <Routes>
                        <Route path="/" element={<ManagePromotions />} />
                        <Route path="/upload" element={<UpdateBannerPromotion />} />
                      </Routes>
                    }
                  />

                  <Route
                    path={`/${AppRoutes.VideosBase}/${AppRoutes.Videos.Standard}/*`}
                    element={
                      <Routes>
                        <Route path={`/`} element={<ManageVideos />} />
                        <Route path={`/${AppRoutes.StandardVideos.Upload}`} element={<UploadVideo video={video} videoMode={videoMode} />} />
                        <Route path={`/${AppRoutes.StandardVideos.Edit}`} element={<EditVideo />} />
                        <Route
                          path={`/${AppRoutes.StandardVideos.Stream}/*`}
                          element={
                            <Routes>
                              <Route path={`/`} element={<VideoDetails />} />
                              <Route path={`/${AppRoutes.StandardVideos.Preview}`} element={<VideoPreview />} />
                            </Routes>
                          }
                        />
                      </Routes>
                    }
                  />
                  <Route
                    path={`/${AppRoutes.VideosBase}/${AppRoutes.Videos.Custom}/*`}
                    element={
                      <Routes>
                        <Route
                          path={`/`}
                          element={<CustomVideos handleSetVideoMode={handleSetVideoMode} handleSetCustomVideo={handleSetCustomVideo} />}
                        />
                        <Route
                          path={`/${AppRoutes.CustomVideos.Upload}`}
                          element={<UploadCustomVideo customVideo={customVideo} videoMode={videoMode} />}
                        />
                        <Route
                          path={`/${AppRoutes.CustomVideos.Edit}`}
                          element={<UploadCustomVideo customVideo={customVideo} videoMode={videoMode} />}
                        />
                        <Route
                          path={`/${AppRoutes.CustomVideos.Stream}/*`}
                          element={
                            <Routes>
                              <Route path={`/`} element={<CustomVideoDetails />} />
                              <Route path={`/${AppRoutes.CustomVideos.Preview}`} element={''} />
                            </Routes>
                          }
                        />
                      </Routes>
                    }
                  />

                  <Route
                    path={`/${AppRoutes.PackageBase}/*`}
                    element={
                      <Routes>
                        <Route path={`/`} element={<ManagePackages />} />
                        <Route path={`/${AppRoutes.Packages.Create}/*`} element={<CreatePackage />} />
                        <Route path={`/${AppRoutes.Packages.Edit}/*`} element={<EditPackage />} />
                      </Routes>
                    }
                  />

                  <Route
                    path={`/${AppRoutes.SubscriptionBase}/*`}
                    element={
                      <Routes>
                        <Route
                          path={`/`}
                          element={
                            <ManageSubscriptions
                              handleSetSubscription={handleSetSubscription}
                              handleSetSubscriptionMode={handleSetSubscriptionMode}
                            />
                          }
                        />
                        <Route
                          path={`/${AppRoutes.Subscription.Create}`}
                          element={<CreateSubscription subscription={subscription} subscriptionMode={subscriptionMode} />}
                        />
                        <Route
                          path={`/${AppRoutes.Subscription.Edit}`}
                          element={<CreateSubscription subscription={subscription} subscriptionMode={subscriptionMode} />}
                        />
                      </Routes>
                    }
                  />
                  <Route path={`/${AppRoutes.FeedbackBase}/*`} element={<ManageFeedback />} />
                  <Route
                    path={`/${AppRoutes.SettingBase}/*`}
                    element={
                      <Routes>
                        <Route path={`/`} element={<ManageAccount />} />
                        <Route path={`/${AppRoutes.Settings.ResetPassword}`} element={<SetPassword />} />
                      </Routes>
                    }
                  />
                </Routes>
              </SidebarLayout>{' '}
            </ProtectedRoute>
          }
        />
        <Route path="/" element={<ProtectedRoute handleBaseRedirect={true} />} />
        <Route path="/*" element={<Status404 />} />
      </Routes>
    </BaseContainer>
  );
};
