import { useState } from 'react';
import jwt_decode from 'jwt-decode';
import toast from 'react-hot-toast';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { Helmet } from 'react-helmet-async';
import { AppInput } from '@components/Styled';
import Logo from '@assets/images/small-logo.png';
import { yupResolver } from '@hookform/resolvers/yup';
import { SetPasswordSchema } from '@core/constants/schemas';
import TransitionWrapper from '@components/TransitionWrapper';
import PasswordStrengthBar from 'react-password-strength-bar';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { setAuth } from '@features/state/slices/local/auth.slice';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { determineBorderColor, useAuth } from '@core/utils/utils';
import { toggleLoading } from '@features/state/slices/local/loading-slice';
import { useSetAdminPasswordMutation } from '@features/state/slices/api/auth-slice';
import { Box, Card, Grid, Button, InputLabel, Typography, FormControl, FormHelperText } from '@mui/material';

function SetPassword() {
  const {
    watch,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(SetPasswordSchema),
  });

  const auth = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [setAdminPasswordMutation] = useSetAdminPasswordMutation();
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const toggleNewPassword = () => setShowNewPassword(!showNewPassword);
  const toggleCurrentPassword = () => setShowCurrentPassword(!showCurrentPassword);
  const toggleConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);

  const setAdminPassword = async (data: any) => {
    dispatch(toggleLoading());
    try {
      const result = await setAdminPasswordMutation({
        ...data,
        ...{ id: auth!.user!.id },
      }).unwrap();

      if (!result.success && result.error) {
        const message = result.error?.message as string;

        toast.error(message);

        dispatch(toggleLoading());
        return;
      }

      const token = result.data!;

      const { accessToken } = token;

      const user = jwt_decode<any>(accessToken);

      const authDto = {
        token,
        user: user.user,
        role: user.role,
      };

      dispatch(setAuth(authDto));

      navigate(`/dashboard/home`);
    } catch (err: any) {
      toast.error(err.toString());
    }
    dispatch(toggleLoading());
  };

  return (
    <TransitionWrapper>
      <Helmet>
        <title>Admin - Set Password</title>
      </Helmet>
      <Box sx={{ p: 2 }}>
        <img crossOrigin="anonymous" src={Logo} height={100} width={140} style={{ objectFit: 'contain' }} />
      </Box>

      <Grid mt={4} container spacing={0} alignItems="center" justifyContent="center" style={{ maxHeight: '90vh' }}>
        <Grid item xs={12} lg={4} md={4} sm={4}>
          <Box>
            <Card sx={{ p: 4 }}>
              <Box mb={5}>
                <Typography variant="h2">Set Password</Typography>
                <Typography variant="subtitle1" mt={1}>
                  Choose a strong password
                </Typography>
              </Box>
              <Box mt={1}>
                <FormControl variant="standard" style={{ width: '100%' }}>
                  <InputLabel shrink htmlFor="bootstrap-input">
                    Current Password
                  </InputLabel>
                  <AppInput
                    required
                    id="currentPassword"
                    fullWidth={true}
                    placeholder="Enter your current password"
                    style={determineBorderColor(errors?.oldPassword?.message as any)}
                    {...register('oldPassword', {
                      required: true,
                      maxLength: 80,
                    })}
                    type={showCurrentPassword ? 'text' : 'password'}
                  />

                  <div onClick={toggleCurrentPassword} className="login__password-icon">
                    {showCurrentPassword ? (
                      <RemoveRedEyeIcon className="pointer" fontSize="small" />
                    ) : (
                      <VisibilityOffIcon className="pointer" fontSize="small" />
                    )}
                  </div>

                  <FormHelperText style={{ color: 'red' }}>{errors?.oldPassword?.message as string}</FormHelperText>
                </FormControl>
              </Box>
              <Box mt={1}>
                <FormControl variant="standard" style={{ width: '100%' }}>
                  <InputLabel shrink htmlFor="bootstrap-input">
                    New Password
                  </InputLabel>
                  <AppInput
                    required
                    id="newPassword"
                    fullWidth={true}
                    placeholder="Enter your new password"
                    style={determineBorderColor(errors?.newPassword?.message as any)}
                    {...register('newPassword', {
                      required: true,
                      maxLength: 80,
                    })}
                    type={showNewPassword ? 'text' : 'password'}
                  />

                  <div onClick={toggleNewPassword} className="login__password-icon">
                    {showNewPassword ? (
                      <RemoveRedEyeIcon className="pointer" fontSize="small" />
                    ) : (
                      <VisibilityOffIcon className="pointer" fontSize="small" />
                    )}
                  </div>

                  <PasswordStrengthBar password={watch<string>('newPassword')} />

                  <FormHelperText style={{ color: 'red' }}>{errors?.newPassword?.message as string}</FormHelperText>
                </FormControl>
              </Box>

              <Box mt={1}>
                <FormControl variant="standard" style={{ width: '100%' }}>
                  <InputLabel shrink htmlFor="bootstrap-input">
                    Confirm New Password
                  </InputLabel>

                  <AppInput
                    required
                    id="password"
                    fullWidth={true}
                    placeholder="Confirm your new password"
                    style={determineBorderColor(errors?.confirmPassword?.message as any)}
                    {...register('confirmPassword', {
                      required: true,
                      maxLength: 80,
                    })}
                    type={showConfirmPassword ? 'text' : 'password'}
                  />

                  <div onClick={toggleConfirmPassword} className="login__password-icon">
                    {showConfirmPassword ? (
                      <RemoveRedEyeIcon className="pointer" fontSize="small" />
                    ) : (
                      <VisibilityOffIcon className="pointer" fontSize="small" />
                    )}
                  </div>
                  <PasswordStrengthBar password={watch<string>('confirmPassword')} />
                  <FormHelperText style={{ color: 'red' }}>{errors?.confirmPassword?.message as string}</FormHelperText>
                </FormControl>
              </Box>
              <Box mt={1}>
                <Button
                  type="submit"
                  variant="contained"
                  className="login__button"
                  style={{ float: 'right' }}
                  onClick={handleSubmit(setAdminPassword)}
                >
                  Set Password
                </Button>
              </Box>
            </Card>
          </Box>
        </Grid>
      </Grid>
    </TransitionWrapper>
  );
}

export default SetPassword;
