import * as yup from 'yup';
import { Navigator } from '../Navigator';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { AppInput, IOSSwitch } from '@components/Styled';
import { determineBorderColor } from '@core/utils/utils';
import { Grid, Switch, InputLabel, Typography, FormControlLabel, Tab, styled, Tabs } from '@mui/material';
import {
  Subscription,
  subscriptionTabs,
  MONTHLY_CONTRACT_KEY,
  TWO_YEAR_CONTRACT_KEY,
  SIX_MONTH_CONTRACT_KEY,
  ONE_YEAR_CONTRACT_KEY,
} from '@core/constants/constants';
import { useEffect, useState } from 'react';
import { StepProps } from '@core/types';
import { fadeInRight } from 'react-animations';
import Center from '@components/Center/Center';
import TransitionWrapper from '@components/TransitionWrapper';
import { SubscriptionDisplayItem } from '@features/pages/Subscriptions/components/SubscriptionDisplay/SubscriptionItem';

const TabsWrapper = styled(Tabs)(
  () => `
    .MuiTabs-scrollableX {
      overflow-x: auto !important;
    }
`,
);
const customSchema = yup.object().shape({
  isTrial: yup.boolean().nullable(true),
  hasUpFrontBillingCost: yup.boolean().nullable(true),
  intervalPrice: yup.string().required('Pricing is required'),
  setupCost: yup.number().required('Setup Fee is required'),
  usersCovered: yup.number().required('Users covered is required'),
  billingMonthlyThreshold: yup.number().required('Trial period is required'),
  trialPeriod: yup.number().required('Trail is required').nullable(true),
  upFrontBillingCost: yup.number().required('UpFrontBilling Cost is required'),
  additionalUserCost: yup.number().required('Additional User Cost is required'),
  videoPackageDiscount: yup.number().required('Video Package Discount is required'),
});

export const CreateCustomSubscription: React.FC<StepProps> = (props: StepProps) => {
  const { data, steps, activeStep, onCapture, handleBack, handleNext, handleSkip } = props;

  const [selectedSubscription, setSelectedSubscription] = useState<Subscription & { intervalPrice?: string | number }>({} as any);

  const {
    watch,
    register,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors: errors },
  } = useForm({
    resolver: yupResolver(customSchema),
  });

  const isTrial = watch<string>('isTrial');
  const hasUpFrontBillingCost = watch<string>('hasUpFrontBillingCost');
  const [currentTab, setCurrentTab] = useState<string>('');

  useEffect(() => {
    let subscription;
    if (data?.customSubscription) {
      subscription = data?.customSubscription;
      setValue('intervalPrice', data?.customSubscription.intervalPrice);
    } else {
      subscription = data?.selectedSubscription;
      setValue('intervalPrice', determinePrice(subscription?.pricePlan));
    }
    let isTrial = subscription!.pricePlan == SIX_MONTH_CONTRACT_KEY;
    let hasUpFrontBillingCost = subscription!.pricePlan == SIX_MONTH_CONTRACT_KEY;

    setSelectedSubscription(subscription);
    setCurrentTab(data?.selectedSubscription?.pricePlan);

    setValue('isTrial', isTrial);
    setValue('setupCost', subscription?.setupCost);
    setValue('usersCovered', subscription?.usersCovered);
    setValue('hasUpFrontBillingCost', hasUpFrontBillingCost);
    setValue('trialPeriod', subscription?.billingMonthlyThreshold);
    setValue('upFrontBillingCost', subscription!.upFrontBillingCost);
    setValue('additionalUserCost', subscription?.additionalUserCost);

    setValue('videoPackageDiscount', subscription?.videoPackageDiscount);
    setValue('billingMonthlyThreshold', subscription?.billingMonthlyThreshold);
  }, [data]);

  const determinePrice = (pricingPlan: string) => {
    switch (pricingPlan) {
      case SIX_MONTH_CONTRACT_KEY:
        return data?.selectedSubscription?.sixMonthContractCost;
      //DEPRECATED Two Year Contract
      // case TWO_YEAR_CONTRACT_KEY:
      //   return data?.selectedSubscription?.twoYearContractCost;
      case ONE_YEAR_CONTRACT_KEY:
        return data?.selectedSubscription?.oneYearContractCost;
      case MONTHLY_CONTRACT_KEY:
        return data?.selectedSubscription?.monthlyContractCost;

      default:
        return 0;
    }
  };

  const captureData = (data: any) => {
    const customSubscription = { ...data, ...selectedSubscription };
    handleNext();
    onCapture({ customSubscription });
  };
  return (
    <TransitionWrapper duration={0.2} animation={fadeInRight}>
      <>
        <Typography mt={5} gutterBottom align="left" variant="subtitle1" style={{ fontSize: 20, fontWeight: 'bold' }}>
          {subscriptionTabs.find((i) => i?.value == selectedSubscription?.pricePlan)?.label} for {selectedSubscription?.name}{' '}
        </Typography>{' '}
        <Grid container spacing={2} mt={2}>
          <Grid item xs={6}>
            <Grid container spacing={2} mt={2}>
              <Grid item xs={12}>
                <InputLabel shrink htmlFor="bootstrap-input">
                  Subscription Interval Price
                </InputLabel>
                <AppInput
                  required
                  fullWidth
                  type="number"
                  value={getValues('intervalPrice')}
                  style={determineBorderColor(errors?.price?.message as any)}
                  onChange={(e) => {
                    const intervalPrice = e.target.value;
                    setValue('intervalPrice', intervalPrice);
                    setSelectedSubscription({
                      ...selectedSubscription,
                      intervalPrice,
                    });
                  }}
                />
              </Grid>
            </Grid>
            <br></br>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <InputLabel shrink htmlFor="bootstrap-input">
                  SetupFee
                </InputLabel>
                <AppInput
                  required
                  fullWidth
                  type="text"
                  value={getValues('setupCost')}
                  onChange={(e) => {
                    const setupCost = e.target.value;
                    setValue('setupCost', setupCost);
                    setSelectedSubscription({
                      ...selectedSubscription,
                      setupCost,
                    });
                  }}
                  style={determineBorderColor(errors?.setupCost?.message as any)}
                />
              </Grid>
              <Grid item xs={6}>
                <InputLabel shrink htmlFor="bootstrap-input">
                  Additonal User Cost
                </InputLabel>
                <AppInput
                  required
                  fullWidth
                  inputProps={{
                    maxLength: 13,
                    step: 0.5,
                  }}
                  value={getValues('additionalUserCost')}
                  onChange={(e) => {
                    const additionalUserCost = e.target.value;
                    setValue('additionalUserCost', additionalUserCost);
                    setSelectedSubscription({
                      ...selectedSubscription,
                      additionalUserCost,
                    });
                  }}
                  style={determineBorderColor(errors?.additionalUserCost?.message as any)}
                />
              </Grid>
            </Grid>
            <br></br>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <InputLabel shrink htmlFor="bootstrap-input">
                  Video Package Discount
                </InputLabel>
                <AppInput
                  required
                  fullWidth
                  value={getValues('videoPackageDiscount')}
                  onChange={(e) => {
                    const videoPackageDiscount = parseInt(e.target.value);
                    setValue('videoPackageDiscount', videoPackageDiscount);
                    setSelectedSubscription({
                      ...selectedSubscription,
                      videoPackageDiscount,
                    });
                  }}
                  style={determineBorderColor(errors?.videoPackageDiscount?.message as any)}
                />
              </Grid>
              <Grid item xs={6}>
                <InputLabel shrink htmlFor="bootstrap-input">
                  Users Covered
                </InputLabel>
                <AppInput
                  required
                  fullWidth
                  type="number"
                  value={getValues('usersCovered')}
                  onChange={(e) => {
                    const usersCovered = parseInt(e.target.value);
                    setValue('usersCovered', usersCovered);
                    setSelectedSubscription({
                      ...selectedSubscription,
                      usersCovered,
                    });
                  }}
                  style={determineBorderColor(errors?.usersCovered?.message as any)}
                />
              </Grid>
            </Grid>
            <br></br>

            <FormControlLabel
              control={
                <Switch
                  sx={{ m: 1 }}
                  checked={hasUpFrontBillingCost}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setValue('hasUpFrontBillingCost', e.target.checked)}
                />
              }
              label="Does subscription have an unfront billing cost?"
            />
            {hasUpFrontBillingCost && (
              <>
                <InputLabel shrink htmlFor="bootstrap-input">
                  Up Front Billing Cost
                </InputLabel>
                <AppInput
                  required
                  fullWidth
                  type="number"
                  value={getValues('upFrontBillingCost')}
                  style={determineBorderColor(errors?.upFrontBillingCost?.message as any)}
                  onChange={(e) => {
                    const upFrontBillingCost = e.target.value;
                    setValue('upFrontBillingCost', upFrontBillingCost);
                    setSelectedSubscription({
                      ...selectedSubscription,
                      upFrontBillingCost,
                    });
                  }}
                />
              </>
            )}

            <FormControlLabel
              control={
                <Switch
                  sx={{ m: 1 }}
                  checked={isTrial}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setValue('isTrial', e.target.checked)}
                />
              }
              label="Does subscription need a trial?"
            />
            {isTrial && (
              <>
                <InputLabel shrink htmlFor="bootstrap-input">
                  Trial Period (in months)
                </InputLabel>
                <AppInput
                  required
                  fullWidth
                  type="number"
                  value={getValues('billingMonthlyThreshold')}
                  style={determineBorderColor(errors?.trialPeriod?.message as any)}
                  onChange={(e) => {
                    const billingMonthlyThreshold = e.target.value;
                    setValue('billingMonthlyThreshold', billingMonthlyThreshold);
                    setSelectedSubscription({
                      ...selectedSubscription,
                      billingMonthlyThreshold,
                    });
                  }}
                />
              </>
            )}
          </Grid>

          <Grid item xs={6}>
            <>
              <TabsWrapper
                onChange={(e) => null}
                value={currentTab}
                variant="scrollable"
                scrollButtons="auto"
                textColor="primary"
                indicatorColor="primary"
              >
                {subscriptionTabs
                  .filter((s) => s.value == currentTab)
                  .map((tab) => (
                    <Tab key={tab.value} label={tab.label} value={tab.value} />
                  ))}
              </TabsWrapper>
            </>

            <br></br>

            <div className="subscription-pricing">
              <Center>
                {selectedSubscription && (
                  <SubscriptionDisplayItem
                    isSelected={true}
                    onSelect={(a, b) => null}
                    config={{
                      displayTrialPeriod: isTrial,
                      displayUpFrontBilling: hasUpFrontBillingCost,
                    }}
                    subscription={selectedSubscription}
                    pricePlan={selectedSubscription.pricePlan}
                  />
                )}
                <br></br>
              </Center>
            </div>
          </Grid>
        </Grid>
      </>
      <Navigator
        steps={steps}
        handleSkip={handleSkip}
        handleBack={handleBack}
        activeStep={activeStep}
        handleNext={handleSubmit(captureData)}
      />
    </TransitionWrapper>
  );
};
