import * as yup from 'yup';
import { Navigator } from '../Navigator';
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { fadeInRight } from 'react-animations';
import FileUpload from 'react-material-file-upload';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, FormControlLabel, FormHelperText, InputLabel, Switch, Typography } from '@mui/material';
import { CreateVideoDTO, acceptedImageFormats, MAX_IMAGE_FILE_SIZE } from '@core/constants/constants';
import TransitionWrapper from '@components/TransitionWrapper';
import { determineBorderColor, toMegabytes } from '@core/utils/utils';
import { HelperType, VideoMode } from '@core/enums/enums';
import { HelperText } from '@components/HelperText';
import { ThumbnailGenerator } from '@components/ThumbnailGenerator/ThumbnailGenerator';
import { EditingMode } from '@core/types';
import VideoEditor from '@components/VideoEditor/VideoEditor';

type Props = {
  steps: string[];
  activeStep: number;
  data: CreateVideoDTO;
  videoMode: VideoMode;
  handleBack: () => void;
  handleNext: () => void;
  handleSkip: () => void;
  onCapture: (data: any) => void;
};

const schema = yup.object().shape({
  isThumbnailGenerated: yup.boolean().default(false),
  thumbnail: yup.mixed().test('thumbnail', 'Thumbnail image is required', (value) => {
    if (value === undefined) {
      return false;
    }
    if (value?.length === 0) return false;
    return true;
  }),
});

export const UploadThumbnailStep: React.FC<Props> = (props: Props) => {
  const { data, steps, activeStep, onCapture, handleBack, handleNext, handleSkip } = props;

  const {
    watch,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    let fileList: File[] = [];

    if (data?.thumbnail) {
      fileList = [data?.thumbnail];
    }
    setFiles(fileList);
    setValue<string>('thumbnail', data?.thumbnail);
  }, []);

  const [files, setFiles] = useState<File[]>([]);
  const isThumbnailGenerated = watch<string>('isThumbnailGenerated');
  const [isGeneratingThumbnail, setIsGeneratingThumbnail] = useState(false);

  const captureDataThenNavigate = (data: any) => {
    onCapture(data);
    handleNext();
  };

  const captureData = (data: any) => {
    handleNext();
    onCapture(data);
  };

  const captureDataThenSkip = (data: any) => {
    onCapture(data);
    handleSkip();
  };

  const onChange = (files: File[]) => {
    setFiles(files);
    setValue<string>('thumbnail', files[0]);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setIsGeneratingThumbnail(checked);
    setValue<string>('isThumbnailGenerated', checked);
  };

  const onReset = () => {
    setValue<string>('thumbnail', null);
  };

  return (
    <TransitionWrapper duration={0.2} animation={fadeInRight}>
      <Box>
        <HelperText title="Upload a video thumbail" subtitle="We recommend you upload .png files. The maximum video size is 2MB" />
        <br></br>

        <Box alignSelf={'flex-end'} justifyContent="flex-end" display="flex">
          <FormControlLabel
            control={<Switch sx={{ m: 1 }} onChange={handleChange} checked={isThumbnailGenerated} />}
            label={<Typography variant="caption">Toggle Video Editor</Typography>}
          />
        </Box>
        <br></br>

        {!isGeneratingThumbnail && (
          <FileUpload
            value={files}
            onChange={onChange}
            accept={acceptedImageFormats}
            sx={{ borderColor: errors?.preview?.message && 'red' }}
          />
        )}
        {isGeneratingThumbnail && (
          <VideoEditor
            onReset={onReset}
            video={data.video}
            config={{
              mode: EditingMode.thumbnail,
            }}
            onEdited={(file) => {
              setValue<string>('thumbnail', file);
            }}
          />
        )}
        <br></br>
        {errors?.thumbnail?.message && (
          <HelperText title="Validation Error" type={HelperType.Error} subtitle={errors?.thumbnail?.message as string} />
        )}

        <Navigator
          steps={steps}
          handleBack={handleBack}
          activeStep={activeStep}
          handleSkip={handleSkip}
          handleNext={handleSubmit(captureData)}
        />
      </Box>
    </TransitionWrapper>
  );
};
