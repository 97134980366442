import { useState } from 'react';
import { Video } from '@core/types';
import { useForm } from 'react-hook-form';
import { AppInput } from '@components/Styled';
import FileUpload from 'react-material-file-upload';
import FormControl from '@mui/material/FormControl';
import { yupResolver } from '@hookform/resolvers/yup';
import { determineBorderColor } from '@core/utils/utils';
import FormHelperText from '@mui/material/FormHelperText';

import {
  Box,
  Grid,
  Card,
  List,
  Button,
  Typography,
  InputLabel,
  ListItemText,
  ListItemButton,
  CircularProgress,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormLabel,
  IconButton,
  Tooltip,
  useTheme,
} from '@mui/material';
import { toast } from 'react-hot-toast';
import { AnimatedList } from 'react-animated-list';
import { AppRoutes } from '@core/constants/routes';
import LoadingButton from '@mui/lab/LoadingButton';
import { BackButton } from '@components/BackButton';
import { SearchField } from '@components/SearchBar';
import { Navigate, useNavigate } from 'react-router';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import KeywordSelector from '@components/KeywordSelector';
import { CreatePackageSchema } from '@core/constants/schemas';
import TransitionWrapper from '@components/TransitionWrapper';
import { acceptedImageFormats } from '@core/constants/constants';
import { PackageTypeSelect } from './components/PackageTypeSelect';
import { PackageIntervalSelect } from './components/PackageIntervalSelect';
import { PackageCategorySelect } from './components/PackageCategorySelect';
import { useCreatePackageMutation } from '@features/state/slices/api/package-slice';
import { VideoMode } from '@core/enums/enums';

type Props = {};

const CreatePackage: React.FC<Props> = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [type, setType] = useState({} as any);
  const [files, setFiles] = useState<File[]>([]);
  const [interval, setInterval] = useState('month');
  const [category, setCategory] = useState({} as any);
  const [selectedIndex, setSelectedIndex] = useState(1);
  const [keywords, setKeywords] = useState<string[]>([]);
  const [preview, setVideoPreview] = useState<any>(null);
  const [selectedVideos, setSelectedVideos] = useState<Video[]>([]);
  const [createPackageMutation, response] = useCreatePackageMutation();
  const [radioValue, setRadioValue] = useState(false);

  const handleChange = (event: any) => {
    setRadioValue(event.target.value);
  };

  const handleListItemClick = (event: any, index: any, video: Video) => {
    setVideoPreview(video.highResolutionVideoPreview);
    setSelectedIndex(index);
  };

  const {
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(CreatePackageSchema),
  });

  const createPackage = async (data: any) => {
    try {
      const { price, videoIds, keywords, packageName, packageIteration, packageCategoryId, packageDescription, purchasableWithCredits } =
        data;

      const formData = new FormData();

      keywords.push(packageName);
      formData.append('price', price);
      formData.append('videoIds', videoIds);
      formData.append('interval', interval);
      formData.append('packageName', packageName);
      formData.append('keywords', keywords.toString());
      formData.append('packageIteration', packageIteration);
      formData.append('packageCategoryId', packageCategoryId);
      formData.append('packageDescription', packageDescription);
      formData.append('purchasableWithCredits', purchasableWithCredits);

      const { error, success } = await createPackageMutation(formData).unwrap();

      if (!success && error) {
        toast.error(error.message);
        return;
      }

      toast.success('Your package was sucessfully created');
      navigate(`/dashboard/packages`);
    } catch (e) {
      toast.success('An error occured');
    }
  };

  return (
    <TransitionWrapper>
      <Card sx={{ p: 3 }}>
        <Box display="flex">
          <BackButton />
          <Typography ml={1} variant="h2">
            Create Package
          </Typography>
        </Box>
        <Typography variant="subtitle1" mt={1}>
          Here you can bundles videos your videos and distribute them as packages to your customers
        </Typography>

        <Grid mt={3} container spacing={2}>
          <Grid item xs={6}>
            <FormControl variant="standard" style={{ width: '100%' }}>
              <InputLabel shrink htmlFor="bootstrap-input">
                Package Name
              </InputLabel>
              <AppInput
                fullWidth
                type="name"
                autoComplete="packageName"
                style={determineBorderColor(errors?.packageName?.message as any)}
                {...register('packageName', {
                  required: true,
                  maxLength: 80,
                })}
                onChange={(event) => null}
              />

              <FormHelperText style={{ color: 'red' }}>{errors?.packageName?.message as string}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl variant="standard" style={{ width: '100%' }}>
              <InputLabel shrink htmlFor="bootstrap-input">
                Package Iteration
              </InputLabel>
              <AppInput
                type="number"
                fullWidth
                style={determineBorderColor(errors?.packageIteration?.message as any)}
                {...register('packageIteration', {
                  required: true,
                  maxLength: 80,
                })}
              />
              <FormHelperText style={{ color: 'red' }}>{errors?.packageIteration?.message as string}</FormHelperText>
            </FormControl>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl variant="standard" style={{ width: '100%' }}>
              <InputLabel shrink htmlFor="bootstrap-input">
                Price
              </InputLabel>

              <AppInput
                fullWidth
                type="number"
                style={determineBorderColor(errors?.price?.message as any)}
                {...register('price', {
                  required: true,
                  maxLength: 80,
                })}
              />
              <FormHelperText style={{ color: 'red' }}>{errors?.price?.message as string}</FormHelperText>
            </FormControl>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <InputLabel className="input-label">Subscription Interval</InputLabel>

            <PackageIntervalSelect
              name="interval"
              label="Interval"
              interval={interval}
              placeholder="Interval"
              helperText={errors.packageInterval?.message}
              error={errors.packageInterval?.message !== undefined}
              onChange={(value) => {
                setInterval(value);
                setValue('packageInterval', value, {
                  shouldValidate: true,
                });
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <InputLabel className="input-label">Package Category</InputLabel>

            <PackageCategorySelect
              name="category"
              label="Category"
              category={category}
              placeholder="Category"
              helperText={errors.packageCategoryId?.message}
              error={errors.packageCategoryId?.message !== undefined}
              onChange={(category) => {
                setCategory(category);
                setValue('packageCategoryId', category, {
                  shouldValidate: true,
                });
              }}
            />
          </Grid>
        </Grid>
        <br />
        <FormControl>
          <FormLabel id="demo-controlled-radio-buttons-group">Is this package purchasable with credits?</FormLabel>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={radioValue}
            onChange={handleChange}
          >
            <FormControlLabel value={true} control={<Radio />} label="Yes" />
            <FormControlLabel value={false} control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl>

        <Box mt={2}>
          <InputLabel className="input-label">Search for videos to add to your package</InputLabel>
          <SearchField
            multiple={true}
            onSelected={(videos: Video[]) => {
              setSelectedVideos(videos);
              const videoIds = videos.map((x) => x.id);

              if (videoIds.length == 0) {
                setValue('videoIds', null);
              }

              setValue('videoIds', videoIds);
            }}
          />

          <FormHelperText style={{ color: 'red' }}>{errors?.videoIds?.message as string}</FormHelperText>
        </Box>

        <Box mt={5} mb={4}>
          <Grid container spacing={3} ml={0.3}>
            <AnimatedList animation={'grow'}>
              {selectedVideos.map((video) => (
                <Grid sx={{ cursor: 'pointer' }} item xs={3} mr={2}>
                  <Box height={150} width={220}>
                    <img
                      crossOrigin="anonymous"
                      style={{ objectFit: 'cover', borderRadius: 10 }}
                      height={150}
                      width={220}
                      src={video.highResolutionThumbnail}
                    />
                    <Box mt={0.5} m={0.5} display="flex" justifyContent="space-between">
                      <Box width={100}>
                        <Typography
                          color="grey"
                          variant="subtitle1"
                          width={150}
                          sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: '-webkit-box',
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: 'vertical',
                          }}
                        >
                          {video.videoName}
                        </Typography>
                      </Box>
                      {/* <Tooltip title="Delete" arrow>
                        <IconButton
                          sx={{
                            "&:hover": {
                              background: theme.colors.error.lighter,
                            },
                            color: theme.palette.error.main,
                          }}
                          color="inherit"
                          size="small"
                          onClick={() => removeVideo(video.id)}
                        >
                          <DeleteTwoToneIcon fontSize="small" />
                        </IconButton>
                      </Tooltip> */}
                    </Box>
                  </Box>
                </Grid>
              ))}
            </AnimatedList>
          </Grid>
        </Box>

        <Box>
          <FormControl variant="standard" style={{ width: '100%' }}>
            <InputLabel shrink htmlFor="bootstrap-input">
              Package Description
            </InputLabel>

            <AppInput
              fullWidth
              type="textarea"
              style={determineBorderColor(errors?.packageDescription?.message as any)}
              {...register('packageDescription', {
                required: true,
                maxLength: 80,
              })}
            />
          </FormControl>
          <FormHelperText style={{ color: 'red' }}>{errors?.packageDescription?.message as string}</FormHelperText>
        </Box>
        <Box>
          <FormControl variant="standard" style={{ width: '100%', marginTop: 15 }}>
            <InputLabel shrink htmlFor="bootstrap-input">
              Keywords
            </InputLabel>
            <KeywordSelector
              videoMode={VideoMode.Create}
              subscriptionMode={null}
              keywords={keywords}
              onSelected={(keywords) => {
                setKeywords(keywords);
                setValue('keywords', keywords);
              }}
            />
            <FormHelperText style={{ color: 'red' }}>{errors?.keywords?.message as string}</FormHelperText>
          </FormControl>
        </Box>
        <Box display="flex" justifyContent="end" mt={5}>
          <LoadingButton size="medium" type="submit" variant="contained" loading={response.isLoading} onClick={handleSubmit(createPackage)}>
            {response.isLoading ? <CircularProgress /> : 'Create Package'}
          </LoadingButton>
        </Box>
      </Card>
    </TransitionWrapper>
  );
};

export default CreatePackage;
