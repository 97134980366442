import { useAuth } from '@core/utils/utils';
import { Helmet } from 'react-helmet-async';

import AppPageHeader from '@components/PageHeader';
import PageTitleWrapper from '@components/PageTitleWrapper';
import TransitionWrapper from '@components/TransitionWrapper';
import { CustomVideoRequestTable } from './components/CustomVideoRequestTable';

function ManageCustomVideoRequests() {
  const { user } = useAuth();
  return (
    <TransitionWrapper>
      <Helmet>
        <title>Manage Custom Video Requests</title>
      </Helmet>

      <AppPageHeader title="Custom Video Requests" subtitle={`${user?.name}, these are your recent custom requests`} />

      <CustomVideoRequestTable />
    </TransitionWrapper>
  );
}

export default ManageCustomVideoRequests;
