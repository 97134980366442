import Filter from '@components/Filter';
import AppChip from '@components/AppChip';
import { Retry } from '@components/Retry';
import Iconify from '@components/iconify';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Helmet } from 'react-helmet-async';
import { fadeInRight } from 'react-animations';
import Center from '@components/Center/Center';
import { StyledSearch } from '@components/Styled';
import AppPageHeader from '@components/PageHeader';
import { EmptyTable } from '@components/EmptyTable';
import { useEffect, useRef, useState } from 'react';
import { RefreshButton } from '@components/RefreshButton';
import { VideoLisitng } from './components/VideoListing';
import NotStartedIcon from '@mui/icons-material/NotStarted';
import TransitionWrapper from '@components/TransitionWrapper';
import AppTablePagination from '@components/AppTablePagination';
import { RootState } from '@features/state/reducers/combinedReducers';
import { useGetVideosQuery } from '@features/state/slices/api/video-slice';
import { Box, Button, CircularProgress, Grid, InputAdornment, Tooltip, useTheme } from '@mui/material';

export default function ManageVideos() {
  const theme = useTheme();
  const ref = useRef<any>(null);
  const navigate = useNavigate();
  const [openFilter, setOpenFilter] = useState(false);
  const user = useSelector<RootState, any>((state) => state.auth!.auth!.user);
  const [query, setQuery] = useState({
    page: 1,
    limit: 10,
    searchTerm: '',
  });
  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);

  useEffect(() => {
    ref?.current?.continuousStart();
  }, []);

  const { data, error, isError, isFetching, isLoading, isSuccess, refetch } = useGetVideosQuery(query, {
    refetchOnMountOrArgChange: true,
  });

  const handleSearch = (e: any) => {
    setTimeout(() => {
      setQuery({
        //FIXME Added page reset here to avoid pagination problem
        //page: query.page,
        page: 1,
        limit: query.limit,
        searchTerm: e.target.value,
      });
      refetch();
    }, 1000);
  };

  const handlePaginationChanged = (limit: number, page: number) => {
    setQuery({
      page,
      limit,
      searchTerm: query.searchTerm,
    });
    refetch();
  };

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };
  const navigateToVideoUpload = () => {
    navigate('upload');
  };

  const contentAvailable = isSuccess && data?.data?.items !== null && data!.data!.items!.length > 0 && !isFetching;

  return (
    <TransitionWrapper duration={0.3} animation={fadeInRight}>
      <Helmet>
        <title>Sales Assist - Manage Videos</title>
      </Helmet>

      <AppPageHeader
        title="Standard Videos"
        subtitle={`${user?.name}, these are your recent uploads`}
        trailing={
          <Button variant="contained" sx={{ mt: { xs: 2, md: 0 } }} onClick={navigateToVideoUpload}>
            Upload Standard Video
          </Button>
        }
      />

      <Box display="flex" alignItems="center" justifyContent="space-between" mb={3}>
        <Box>
          <StyledSearch
            fullWidth
            sx={{ height: 45, borderRadius: 0.8 }}
            onChange={handleSearch}
            placeholder="Search Standard Videos"
            startAdornment={
              <InputAdornment position="start">
                <Iconify
                  icon="eva:search-fill"
                  sx={{
                    color: 'text.disabled',
                    width: 20,
                    height: 20,
                  }}
                />
              </InputAdornment>
            }
          />
          {isFetching || (isLoading && <CircularProgress />)}
          {selectedFilters.map((filter) => (
            <Tooltip title={filter} placement="top">
              <AppChip sx={{ ml: 1 }}>{filter}</AppChip>
            </Tooltip>
          ))}
        </Box>

        <Box display="flex" alignItems="center" justifyContent="center">
          <Filter
            openFilter={openFilter}
            onOpenFilter={handleOpenFilter}
            selectedFilters={selectedFilters}
            onCloseFilter={handleCloseFilter}
            onFilterChange={setSelectedFilters}
            filters={[
              {
                title: 'Status',
                filters: ['Processed', 'Completed', 'Failed'],
              },
            ]}
          />
          <RefreshButton onRefresh={refetch} />
        </Box>
      </Box>
      <Grid container direction={'row'} justifyContent="center" alignItems={'stretch'} spacing={3}>
        <Grid item xs={12} mt={4}>
          {isFetching && (
            <Center>
              <Box mt={10}>
                <CircularProgress />
              </Box>
            </Center>
          )}

          {data?.data?.items?.length != null && data?.data?.items?.length == 0 && (
            <EmptyTable
              subtitle="There are currently no standard videos on the system"
              icon={<NotStartedIcon sx={{ color: theme.colors.primary.dark, fontSize: 130 }} />}
            />
          )}

          {isError && !isSuccess && !isFetching && <Retry refetch={refetch} />}

          {contentAvailable && (
            <Box>
              <VideoLisitng refetch={refetch} loading={isFetching} videos={data?.data?.items ?? []} />
            </Box>
          )}
          <AppTablePagination
            onChange={handlePaginationChanged}
            totalPages={data?.data?.meta.totalPages ?? 1}
            contentAvailable={contentAvailable}
          />
        </Grid>
      </Grid>
    </TransitionWrapper>
  );
}
