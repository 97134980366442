import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { detectMob } from "@core/utils/utils";

export type Layout = {
  isOpen: boolean;
  layoutValue: any;
};

export const OPEN_SIDEBAR = {
  isOpen: true,
  layoutValue: 240,
};

export const CLOSE_SIDEBAR = {
  isOpen: false,
  layoutValue: 60,
};

const layoutSlice = createSlice({
  name: "layout",

  initialState: {
    isOpen: detectMob() ? CLOSE_SIDEBAR.isOpen : OPEN_SIDEBAR.isOpen,
    layoutValue: detectMob() ?  CLOSE_SIDEBAR.layoutValue : OPEN_SIDEBAR.layoutValue,
  },
  reducers: {
    toggleSidebar(state, action: PayloadAction<Layout>) {
      state.isOpen = action.payload.isOpen;
      state.layoutValue = action.payload.layoutValue;
    },
  },
});
export const { toggleSidebar } = layoutSlice.actions;
export default layoutSlice;
