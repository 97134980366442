export enum Roles {
  All = 'all',
  Admin = 'admin',
  Company = 'company',
  SuperAdmin = 'super_admin',
  SalesAssociate = 'sales_associate',
}
export enum TableAlignment {
  LEFT = 'left',
  RIGHT = 'right',
  CENTER = 'center',
}

export enum VideoMode {
  Edit = 'Edit',
  Create = 'Create',
}

export enum SubscriptionMode {
  Edit = 'Edit',
  Create = 'Create',
}

export enum SearchFilter {
  VIDEOS = 'Videos',
  PACKAGES = 'Packages',
}

export enum AppBreakpoints {
  Sm = 'sm',
  md = 'sm',
  lg = 'lg',
}

export enum CardTypes {
  Visa = 'visa',
  MasterCard = 'mastercard',
}

export enum PaymentResponseStatus {
  Success = 'success',
  Failed = 'failed',
}

export enum Environments {
  LOCAL = 'test',
  PRODUCTION = 'production',
  DEVELOPMENT = 'development',
}

export enum VideoTypes {
  FULL = 1,
  CLIP = 2,
  CUSTOM = 3,
}
export enum VideoProcessingStatus {
  PROCESSING = 'processing',
  COMPLETED = 'completed',
  FAILED = 'failed',
}
export enum SubscriptionStatus {
  ACTIVE = 1,
  EXPIRED = 2,
  INCOMPLETE = 3,
  FAILED = 4,
}

export enum HelperType {
  Info = 1,
  Error = 2,
}

export enum ApiMethods {
  GET = 'GET',
  PUT = 'PUT',
  POST = 'POST',
  DELETE = 'DELETE',
}

export enum BaseEndpoints {
  Auth = 'auth',
  Admin = 'admins',
  Videos = 'videos',
  Health = 'health',
  Request = 'requests',
  Service = 'service',
  Company = 'companies',
  Industry = 'industries',
  Analytics = 'analytics',
  VideoCredit = 'credits',
  SalesAssociate = 'users',
  VideoPackage = 'packages',
  Webhook = 'stripe-webhook',
  CustomVideo = 'custom-videos',
  PackageType = 'package-types',
  Promotions = 'promotions',
  Subscription = 'subscriptions',
  Notification = 'notifications',
  PackageAccess = 'package-access',
  PaymentMethod = 'payment-methods',
  PendingInvite = 'pending-invites',
  VideoCategory = 'video-categories',
  PackageCategory = 'package-categories',
}
