import { BaseEndpoints } from "@core/enums/enums";
import { baseQueryWithReauth } from "./base-slice";
import { ApiError, ServiceResult } from "@core/index";
import { TagDescription, createApi } from "@reduxjs/toolkit/query/react";
import { PaginatedList, CustomVideoRequest } from "@core/constants/constants";

const descriptionTags = ["Request"] as readonly TagDescription<"Request">[] &
  string[];

export const requestSlice = createApi({
  tagTypes: descriptionTags,
  baseQuery: baseQueryWithReauth,
  reducerPath: BaseEndpoints.Request,

  endpoints: (builder) => ({
    getCustomVideoRequests: builder.query<
      ServiceResult<PaginatedList<CustomVideoRequest[]>, ApiError>,
      any
    >({
      query: (args: any) => ({
        params: { ...args },
        url: `/${BaseEndpoints.Request}/custom-video`,
      }),
      providesTags: descriptionTags,
    }),
  }),
});
export const { useGetCustomVideoRequestsQuery } = requestSlice;
