import moment from 'moment';
import Blur from 'react-blur';
import { useState } from 'react';
import { motion } from 'framer-motion';
import Slide from '@mui/material/Slide';
import React, { createRef } from 'react';
import { Gallery } from 'react-grid-gallery';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {
  Grid,
  Box,
  Typography,
  MenuItem,
  Menu,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Card,
  Tooltip,
} from '@mui/material';
import Vector from '../../../../assets/images/404.png';
import { Video, VideoPackage } from '@core/types';
import { Navigate, useNavigate } from 'react-router';
import ProgressiveImage from 'react-progressive-graceful-image';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { useDeletePackageMutation } from '@features/state/slices/api/package-slice';

import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { toggleLoading } from '@features/state/slices/local/loading-slice';
import AppChip from '@components/AppChip';
import { AppDialog, IDialogRef } from '@components/AppDialog';

const LINES_TO_SHOW = 2;

type Props = {
  refetch: any;
  index: number;
  videoPackage: VideoPackage;
};

const Transition = React.forwardRef(function Transition(props, ref) {
  const SlideComponent = Slide as any;
  return <SlideComponent direction="up" ref={ref} {...props} />;
});

export const PackageItem: React.FC<Props> = ({ videoPackage, index, refetch }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const dialogRef = createRef<IDialogRef>();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [deletePackageMutation] = useDeletePackageMutation();
  const [currentVideoId, setVideoId] = useState(null as unknown as number);

  const open = Boolean(anchorEl);

  const deletePackage = async () => {
    dispatch(toggleLoading());
    try {
      const result = await deletePackageMutation({
        id: videoPackage.id,
      }).unwrap();
      // console.log(result);
      if (result.success) {
        dispatch(toggleLoading());
        toast.success('Package deleted');
        refetch();
      } else {
        dispatch(toggleLoading());
        toast.error(result.error?.message!);
      }
    } catch (err) {
      console.error('Unable to delete your package');
    }
    toggleDeleteDialog();
  };

  const handleMenuOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEditPackage = (id: number) => {
    navigate(`edit/${id}`);
  };
  const toggleDeleteDialog = () => {
    handleMenuClose();
    dialogRef.current?.openDialog();
  };

  return (
    <>
      <Grid sx={{}} item xs={2} sm={4} md={4} key={index} className="package">
        <motion.div>
          <div style={{ position: 'relative' }}>
            <Box
              style={{
                top: 10,
                left: 10,
                zIndex: 5,
                width: 250,
                height: 200,
                position: 'absolute',
              }}
            >
              <Grid container>
                {videoPackage.videos.slice(0, 5).map((video) => (
                  <Grid mt={1} item xs={4}>
                    <Tooltip title={moment.utc(video.videoLength).format('mm:ss')}>
                      <img
                        style={{
                          height: 70,
                          width: '85%',
                          borderRadius: 5,
                          objectFit: 'cover',
                        }}
                        crossOrigin="anonymous"
                        src={video.videoThumbnail!}
                      />
                    </Tooltip>
                  </Grid>
                ))}
              </Grid>
            </Box>
            <Card
              sx={{}}
              //className="test-rgba"
              style={{
                height: 200,
                width: 260,
                backgroundColor: 'white',
                borderRadius: 10,
              }}
            ></Card>
          </div>
          <Box width="100%">
            <div
              style={{
                width: 260,
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Box mt={1} display="flex" justifyContent="center" alignItems="center">
                <Typography variant="h5">{videoPackage.packageName}</Typography>
                <Box ml={1} display="flex" justifyContent="center" alignItems="center">
                  <AppChip> {moment.utc(videoPackage.packageLength).format('mm:ss')}</AppChip>
                </Box>{' '}
              </Box>
              <Box mt={1} className="pointer" sx={{ color: 'grey' }}>
                <MoreHorizIcon onClick={handleMenuOpen} />

                <Menu
                  open={open}
                  id="basic-menu"
                  anchorEl={anchorEl}
                  onClose={handleMenuClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      handleEditPackage(videoPackage.id);
                    }}
                  >
                    Edit
                  </MenuItem>
                  <MenuItem onClick={toggleDeleteDialog}>Delete</MenuItem>
                </Menu>
              </Box>
            </div>
            <Box width={260}>
              {' '}
              {videoPackage.packageDescription.length > 86 ? (
                <Tooltip title={videoPackage.packageDescription} arrow>
                  <Typography
                    variant="body2"
                    gutterBottom
                    sx={{
                      fontSize: 12,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      display: '-webkit-box',
                      WebkitLineClamp: LINES_TO_SHOW,
                      WebkitBoxOrient: 'vertical',
                    }}
                  >
                    {videoPackage.packageDescription}
                  </Typography>
                </Tooltip>
              ) : (
                <Typography sx={{ fontSize: 12 }} variant="body2" gutterBottom>
                  {videoPackage.packageDescription}
                </Typography>
              )}
            </Box>
          </Box>
        </motion.div>
      </Grid>

      <AppDialog
        ref={dialogRef}
        onConfirm={deletePackage}
        title={`Delete ${videoPackage.packageName}`}
        subtitle={`Are you sure you want to delete ${videoPackage.packageName}, any users subscribed to this package will be affected?`}
      />

      <Dialog
        open={deleteDialog}
        onClose={toggleDeleteDialog}
        TransitionComponent={Transition as any}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{'Delete Package'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to delete this Package? This will cancel subscriptions for all users that have subscribed to this package.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleDeleteDialog}>No</Button>
          <Button onClick={deletePackage}>Yes, Delete It</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
