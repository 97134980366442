import { PaginatedList } from '@core/constants/constants';
import { baseQuery } from './base-slice';
import { ApiMethods, BaseEndpoints } from '@core/enums/enums';
import { TagDescription, createApi } from '@reduxjs/toolkit/query/react';
import { ApiError, ServiceResult } from '@core/index';
import { AccessLog } from '@core/types';
import { toQueryString } from '@core/utils/utils';

const descriptionTags = ['Auth'] as readonly TagDescription<'Auth'>[] & string[];

export const authenticationSlice = createApi({
  reducerPath: 'authentication',
  baseQuery: baseQuery,
  tagTypes: descriptionTags,
  endpoints: (builder) => ({
    authenticateAdmin: builder.mutation({
      query: (payload) => ({
        body: payload,
        method: ApiMethods.POST,
        url: `/${BaseEndpoints.Auth}/admin`,
      }),
      invalidatesTags: descriptionTags,
    }),
    setAdminPassword: builder.mutation({
      query: (payload) => ({
        body: payload,
        method: ApiMethods.POST,
        url: `/${BaseEndpoints.Auth}/admin/set-password`,
      }),
      invalidatesTags: descriptionTags,
    }),
    getAccessLogs: builder.query<ServiceResult<PaginatedList<AccessLog[]>, ApiError>, any>({
      query: (args) => ({
        url: `/${BaseEndpoints.Auth}/admin/${args.adminId}/access-logs?${toQueryString(args.params)}`,
      }),
      providesTags: descriptionTags,
    }),
  }),
});
export const { useGetAccessLogsQuery, useAuthenticateAdminMutation, useSetAdminPasswordMutation } = authenticationSlice;
