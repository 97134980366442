import { Slider, Spin } from 'antd';
import { VideoPlayer } from './VideoPlayer';
import { useEffect, useState } from 'react';
import { createFFmpeg } from '@ffmpeg/ffmpeg';
import Center from '@components/Center/Center';
import { Box, Button, Typography } from '@mui/material';
import { secondsToTimeString, sliderValueToVideoTime } from '@core/utils/utils';
import VideoConversionButton from './VideoConversionButton';
import { EditingMode, VideoEditorConfiguration } from '@core/types';
import { LoadingOutlined } from '@ant-design/icons';
const ffmpeg = createFFmpeg({ log: false });

type Props = {
  value?: File;
  video: File;
  onReset: () => void;
  onEdited: (file: File) => void;
  config: VideoEditorConfiguration;
};

function VideoEditor(props: Props) {
  const { video, onEdited } = props;
  const [loading, setLoading] = useState(false);
  const [videoFile, setVideoFile] = useState<any>();
  const [processing, setProcessing] = useState(false);
  const [hasExecuted, setHasExecuted] = useState(false);
  const [videoPlayer, setVideoPlayer] = useState<any>();
  const [videoPlayerState, setVideoPlayerState] = useState<any>();
  const [trimmedVideo, setTrimmedVideo] = useState<File | null>(null);
  const [generatedThumbnail, setGeneratedThumbnail] = useState<File | null>(null);
  const [sliderValues, setSliderValues] = useState<[number, number]>([0, 100]);

  useEffect(() => {
    setVideoFile(video);

    const value = props.value ?? null;

    if (props.config.mode === EditingMode.thumbnail && value) {
      setHasExecuted(true);
    }

    props.config.mode === EditingMode.thumbnail ? setGeneratedThumbnail(value) : setTrimmedVideo(value);
  }, []);

  useEffect(() => {
    const min = sliderValues[0];
    // when the slider values are updated, updating the
    // video time
    if (min !== undefined && videoPlayer != null && videoPlayer != undefined && videoPlayerState != null && videoPlayerState != undefined) {
      videoPlayer?.seek(sliderValueToVideoTime(videoPlayerState.duration, min));
    }
  }, [sliderValues]);

  useEffect(() => {
    if (videoPlayer != null && videoPlayer != undefined && videoPlayerState != null && videoPlayerState != undefined) {
      // allowing users to watch only the portion of
      // the video selected by the slider
      const [min, max] = sliderValues;

      const minTime = sliderValueToVideoTime(videoPlayerState.duration, min);
      const maxTime = sliderValueToVideoTime(videoPlayerState.duration, max);

      if (videoPlayerState?.currentTime < minTime) {
        videoPlayer?.seek(minTime);
      }
      if (videoPlayerState?.currentTime > maxTime) {
        // looping logic
        videoPlayer?.seek(minTime);
      }
    }
  }, [videoPlayerState]);

  const resetEditor = () => {
    setTrimmedVideo(null);
    setHasExecuted(false);
    setSliderValues([0, 100]);
    setGeneratedThumbnail(null);
    setVideoPlayerState(undefined);
    setVideoPlayerState(undefined);
  };

  const onConversionStart = () => {
    setProcessing(true);
  };

  const onConversionEnd = (file: File) => {
    onEdited(file);
    setProcessing(false);

    if (props.config.mode == EditingMode.preview) {
      setTrimmedVideo(file);
    } else {
      setGeneratedThumbnail(file);
    }

    setHasExecuted(true);
  };

  const antIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;

  return (
    <Spin indicator={antIcon} spinning={loading}>
      <br></br>
      <div>
        {videoFile && !hasExecuted ? (
          <div className="video-container">
            <VideoPlayer
              src={URL.createObjectURL(videoFile)}
              onPlayerChange={(videoPlayer) => {
                setVideoPlayer(videoPlayer);
              }}
              onChange={(videoPlayerState) => {
                setVideoPlayerState(videoPlayerState);
              }}
            />
          </div>
        ) : (
          <h1></h1>
        )}
      </div>
      {!hasExecuted && (
        <>
          <div className={'slider-div'}>
            <br></br>

            <Slider
              range={true}
              value={sliderValues}
              disabled={!videoPlayerState || trimmedVideo != null}
              onChange={(values: any) => {
                setSliderValues(values);
              }}
              tooltip={{
                formatter: (value?: number) => {
                  const seconds = sliderValueToVideoTime(videoPlayerState?.duration ?? 0, value);
                  const timeSeeked = secondsToTimeString(seconds);
                  return <Typography>{timeSeeked}</Typography>;
                },

                open: true,
              }}
            />
          </div>
          <div className={'conversion-div'}>
            <VideoConversionButton
              config={props.config}
              videoFile={videoFile}
              onLoading={setLoading}
              sliderValues={sliderValues}
              onConversionEnd={onConversionEnd}
              videoPlayerState={videoPlayerState}
              onConversionStart={onConversionStart}
            />
          </div>
        </>
      )}
      {trimmedVideo && (
        <div>
          <div className="video-container">
            <VideoPlayer
              src={URL.createObjectURL(trimmedVideo)}
              onPlayerChange={(videoPlayer) => {
                setVideoPlayer(videoPlayer);
              }}
              onChange={(videoPlayerState) => {
                setVideoPlayerState(videoPlayerState);
              }}
            />
          </div>
          <Center>
            <br></br>
            <Button variant="outlined" onClick={() => resetEditor()}>
              Reset
            </Button>
          </Center>
        </div>
      )}

      {generatedThumbnail && (
        <Box>
          <div className="video-container">
            <img
              crossOrigin="anonymous"
              style={{ objectFit: 'contain', borderRadius: 10, width: window.innerWidth / 2 }}
              src={URL.createObjectURL(generatedThumbnail)}
            />
          </div>
          <Center>
            <br></br>
            <Button variant="outlined" onClick={() => resetEditor()}>
              Reset
            </Button>
          </Center>
        </Box>
      )}
    </Spin>
  );
}

export default VideoEditor;
