import { useState, useEffect, Fragment, useRef } from 'react';
import toast from 'react-hot-toast';
import { motion } from 'framer-motion';
import { LoadingButton } from '@mui/lab';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import FormControl from '@mui/material/FormControl';
import { AppInput, ProfileBox } from '@components/Styled';
import { yupResolver } from '@hookform/resolvers/yup';
import { determineBorderColor } from '@core/utils/utils';
import FormHelperText from '@mui/material/FormHelperText';
import PhoneInput from 'react-phone-input-2';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { RootState } from '@features/state/reducers/combinedReducers';
import { Box, Grid, Select, Button, MenuItem, InputLabel, Typography, CircularProgress, Card, Avatar } from '@mui/material';

import { useCreateAdminMutation } from '@features/state/slices/api/admin-slice';
import TransitionWrapper from '@components/TransitionWrapper';
import { BackButton } from '@components/BackButton';
import ProvinceSelect from '@components/ProvinceSelect/ProvinceSelect';
import CountrySelect from '@components/CountrySelect/CountrySelect';
import { CreateCompanySchema } from '@core/constants/schemas';
import { useCreateCompanyMutation } from '@features/state/slices/api/company-slice';
import { toggleLoading } from '@features/state/slices/local/loading-slice';
import { useNavigate } from 'react-router';
import { CloudUploadFill } from '@styled-icons/bootstrap/CloudUploadFill';

type Props = {
  show: boolean;
  onCreate: () => void;
  handleClose: () => void;
};

let easing = [0.6, -0.05, 0.01, 0.99];

const animate = {
  opacity: 1,
  y: 0,
  transition: {
    duration: 0.6,
    ease: easing,
    delay: 0.16,
  },
};

export function CreateCompany() {
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(CreateCompanySchema),
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [state, setState] = useState('CA');
  const [country, setCountry] = useState('US');
  const uploadInputRef = useRef<any>(null as any);
  const [displayUrl, setDisplayUrl] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [currentStates, setCurrentStates] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [permissions, setPermissions] = useState<string[]>([]);
  const [createCompanyMutation, response] = useCreateCompanyMutation();
  const [displayPhoto, setDisplayPhoto] = useState<File | Blob | null>(null);
  const user = useSelector((state: RootState) => state?.auth?.auth?.user) as any;

  const createCompany = async (form: any) => {
    try {
      const formData = new FormData();

      formData.append('city', form.city);
      formData.append('name', form.name);
      formData.append('email', form.email);
      formData.append('state', form.state);
      formData.append('country', form.country);
      formData.append('displayPhoto', displayPhoto!);
      formData.append('postalCode', form.postalCode);
      formData.append('phoneNumber', form.phoneNumber);
      formData.append('addressLine1', form.addressLine1);
      formData.append('addressLine2', form.addressLine2);

      dispatch(toggleLoading());
      const { data, error, success } = await createCompanyMutation(formData).unwrap();

      if (!success && error) {
        toast.error(error.message);
        dispatch(toggleLoading());
        return;
      }

      toast.success('Company account successfully created');

      navigate(-1);
    } catch (e) {}

    dispatch(toggleLoading());
  };

  const handleReferenceClick = async () => uploadInputRef.current && uploadInputRef.current.click();

  const handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e?.target?.files![0];
    const objectURL = URL.createObjectURL(file);

    setDisplayPhoto(file);
    setDisplayUrl(objectURL);
  };

  useEffect(() => {
    setValue('country', country);
    setValue('state', state);
  }, [country, state]);

  return (
    <TransitionWrapper>
      <Card sx={{ p: 3 }}>
        <Box sx={{ gap: 3, display: 'flex', flexDirection: 'column' }}>
          <Box display="flex" justifyContent="space-between">
            <Box>
              <Box display="flex">
                <BackButton />
                <Typography ml={1} variant="h2">
                  Create Company Admin
                </Typography>
              </Box>

              <Typography variant="subtitle1" mt={1}>
                Register a new company account into the system
              </Typography>
            </Box>

            <ProfileBox sx={{ p: 1, height: 200 }}>
              <Avatar alt="photoURL" src={displayUrl} sx={{ height: 180, width: 180 }} />

              {
                <div className="d-flex p-3">
                  <Fragment>
                    <input
                      type="file"
                      accept="image/*"
                      ref={uploadInputRef}
                      style={{ display: 'none' }}
                      onChange={(e) => handleUpload(e)}
                    />
                    <Button onClick={() => handleReferenceClick()} variant="contained" size="small">
                      Upload &nbsp;
                      <CloudUploadFill size="20" />
                    </Button>
                  </Fragment>

                  <Box ml={2} className="d-md-block text-start">
                    <div className="fw-normal">Choose Image</div>
                    <div style={{ marginBottom: 5 }} className="text-gray small mb-10">
                      JPG, GIF or PNG. Max size of 800K
                    </div>
                  </Box>
                </div>
              }
            </ProfileBox>
          </Box>
          <Box
            animate={{
              transition: {
                staggerChildren: 0.55,
              },
            }}
            component={motion.div}
          >
            <Box
              animate={animate}
              component={motion.div}
              initial={{ opacity: 0, y: 20 }}
              sx={{ gap: 3, display: 'flex', flexDirection: 'column' }}
            >
              <Typography variant="subtitle1" mt={1} sx={{ fontSize: 12 }}>
                Personal Information
              </Typography>

              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <FormControl variant="standard" style={{ width: '100%' }}>
                    <InputLabel shrink htmlFor="bootstrap-input">
                      Name
                    </InputLabel>
                    <AppInput
                      fullWidth
                      type="name"
                      autoComplete="name"
                      style={determineBorderColor(errors?.email?.message as any)}
                      {...register('name', {
                        required: true,
                        maxLength: 80,
                      })}
                    />

                    <FormHelperText style={{ color: 'red' }}>{errors?.name?.message as string}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl variant="standard" style={{ width: '100%' }}>
                    <InputLabel shrink htmlFor="bootstrap-input">
                      Email
                    </InputLabel>
                    <AppInput
                      fullWidth
                      type="text"
                      autoComplete="email"
                      style={determineBorderColor(errors?.email?.message as any)}
                      {...register('email', {
                        required: true,
                        maxLength: 80,
                      })}
                    />
                    <FormHelperText style={{ color: 'red' }}>{errors?.email?.message as string}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <InputLabel shrink htmlFor="bootstrap-input">
                    Phone Number
                  </InputLabel>
                  <FormControl variant="standard" style={{ width: '100%' }}>
                    <PhoneInput
                      country={'us'}
                      showDropdown={false}
                      value={phoneNumber}
                      containerStyle={{
                        width: '100%',
                        height: 45,
                        marginTop: 7,
                        zIndex: '1000000000',
                      }}
                      dropdownStyle={{ zIndex: 1000000000000000000000000 }}
                      inputStyle={{ width: '100%', height: 45 }}
                      onChange={(phone) => {
                        setValue('phoneNumber', phone);
                        setPhoneNumber(phone);
                      }}
                    />

                    <FormHelperText style={{ color: 'red' }}>{errors?.phoneNumber?.message as string}</FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>

              <Typography variant="subtitle1" mt={1} sx={{ fontSize: 12 }}>
                Address
              </Typography>
            </Box>
            <br></br>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControl variant="standard" style={{ width: '100%' }}>
                  <InputLabel shrink htmlFor="bootstrap-input">
                    Address Line 1
                  </InputLabel>

                  <AppInput
                    fullWidth
                    type="addressLine1"
                    autoComplete="addressLine1"
                    style={determineBorderColor(errors?.addressLine1?.message as any)}
                    {...register('addressLine1', {
                      required: true,
                      maxLength: 80,
                    })}
                  />
                  <FormHelperText style={{ color: 'red' }}>{errors?.addressLine1?.message as string}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl variant="standard" style={{ width: '100%' }}>
                  <InputLabel shrink htmlFor="bootstrap-input">
                    Address Line 2
                  </InputLabel>

                  <AppInput
                    fullWidth
                    type="addressLine2"
                    autoComplete="addressLine2"
                    style={determineBorderColor(errors?.addressLine2?.message as any)}
                    {...register('addressLine2', {
                      required: true,
                      maxLength: 80,
                    })}
                  />
                  <FormHelperText style={{ color: 'red' }}>{errors?.addressLine2?.message as string}</FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
            <br></br>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControl variant="standard" style={{ width: '100%' }}>
                  <InputLabel shrink htmlFor="bootstrap-input">
                    Country
                  </InputLabel>
                  <br></br>
                  <CountrySelect
                    name="country"
                    label="Country"
                    country={country}
                    //  countryISO={user?.countryISO}
                    helperText={errors.country?.message}
                    error={errors.country?.message !== undefined}
                    setCurrentStates={setCurrentStates}
                    onChange={(country, provinces) => {
                      setCountry(country);
                      setCurrentStates(provinces);
                      setValue('country', country, {
                        shouldValidate: true,
                      });
                    }}
                  />
                  <FormHelperText style={{ color: 'red' }}>{errors?.country?.message as string}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={6} mt={0.4}>
                <FormControl variant="standard" style={{ width: '100%' }}>
                  <InputLabel shrink htmlFor="bootstrap-input">
                    City
                  </InputLabel>

                  <AppInput
                    fullWidth
                    type="city"
                    autoComplete="city"
                    style={determineBorderColor(errors?.city?.message as any)}
                    {...register('city', {
                      required: true,
                      maxLength: 80,
                    })}
                  />
                  <FormHelperText style={{ color: 'red' }}>{errors?.city?.message as string}</FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
            <br></br>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControl variant="standard" style={{ width: '100%' }}>
                  <InputLabel shrink htmlFor="bootstrap-input">
                    Province
                  </InputLabel>
                  <br></br>
                  <ProvinceSelect
                    name="state"
                    label="State"
                    selectedProvince={state}
                    provinces={currentStates}
                    error={errors.state?.message !== undefined}
                    helperText={errors.state?.message}
                    onChange={(state) => {
                      if (!state) {
                        return;
                      }
                      setState(state);
                      setValue('state', state, {
                        shouldValidate: true,
                      });
                    }}
                  />
                  <FormHelperText style={{ color: 'red' }}>{errors?.state?.message as string}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={6} mt={0.4}>
                <FormControl variant="standard" style={{ width: '100%' }}>
                  <InputLabel shrink htmlFor="bootstrap-input">
                    Zip Code
                  </InputLabel>

                  <AppInput
                    fullWidth
                    type="text"
                    autoComplete="postalCode"
                    style={determineBorderColor(errors?.postalCode?.message as any)}
                    {...register('postalCode', {
                      required: true,
                      maxLength: 80,
                    })}
                  />
                  <FormHelperText style={{ color: 'red' }}>{errors?.postalCode?.message as string}</FormHelperText>
                </FormControl>
              </Grid>
            </Grid>

            <Box component={motion.div} initial={{ opacity: 0, y: 20 }} animate={animate}></Box>
          </Box>
          <Box display="flex" alignSelf="flex-end" mt={3}>
            <LoadingButton
              size="medium"
              type="submit"
              variant="contained"
              loading={response.isLoading}
              onClick={handleSubmit(createCompany)}
            >
              {response.isLoading ? <CircularProgress /> : 'Create Company'}
            </LoadingButton>
          </Box>
        </Box>
      </Card>
    </TransitionWrapper>
  );
}
