import React from "react";
import { Button } from "reactstrap";
import Iconify from "@components/iconify";
import RefreshIcon from "@mui/icons-material/Refresh";
import { IconButton } from "@mui/material";
type Props = {
  color?: string;
  onRefresh: () => void;
};
export const RefreshButton: React.FC<Props> = ({ color, onRefresh }) => {
  return (
    <IconButton onClick={onRefresh}>
      <RefreshIcon sx={{ width: 24, height: 24, color}} />
    </IconButton>
  );
};

RefreshButton.defaultProps = {
  color: "text.disabled"
}
