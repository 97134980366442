import React, { FC, ChangeEvent, useState, useEffect } from 'react';
import {
  Box,
  Card,
  Table,
  Avatar,
  Tooltip,
  Divider,
  useTheme,
  Checkbox,
  IconButton,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  CardHeader,
  TableContainer,
  LinearProgress,
  InputAdornment,
  CircularProgress,
} from '@mui/material';
import moment from 'moment';
import { Retry } from '@components/Retry';
import Iconify from '@components/iconify';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import AppFilter from '@components/Filter';
import { StyledSearch } from '@components/Styled';
import { EmptyTable } from '@components/EmptyTable';
import { formatPhoneNumber } from '@core/utils/utils';
import { RefreshButton } from '@components/RefreshButton';
import BulkActions from '@components/BulkActions/BulkActions';
import MovieFilterIcon from '@mui/icons-material/MovieFilter';
import AppTablePagination from '@components/AppTablePagination';
import PhoneForwardedIcon from '@mui/icons-material/PhoneForwarded';
import { CustomVideoRequest, Query } from '@core/constants/constants';
import { useGetCustomVideoRequestsQuery } from '@features/state/slices/api/request-slice';

type Props = {};

export function CustomVideoRequestTable({}: Props) {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [query, setQuery] = useState<Query>({
    page: 1,
    limit: 10,
    searchTerm: '',
  });

  const {
    data,
    error,
    refetch,
    isLoading: loading,
  } = useGetCustomVideoRequestsQuery(
    {
      page: query.page,
      limit: query.limit,
      searchTerm: query.searchTerm,
    },
    { refetchOnFocus: true, refetchOnMountOrArgChange: true },
  );

  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(10);
  const [openFilter, setOpenFilter] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);
  const [requests, setRequests] = useState<CustomVideoRequest[]>([]);
  const [selectedCustomVideoRequests, setSelectedCustomVideoRequests] = useState<number[]>([]);
  const selectedBulkActions = selectedCustomVideoRequests.length > 0;
  const [filters, setFilters] = useState<any>({
    status: null,
  });

  useEffect(() => {
    if (!loading && !error) {
      setRequests(data?.data?.items ?? []);
    }
  }, [loading, error, data]);

  const handleStatusChange = (e: any): void => {
    let value: any = null;

    if (e.target.value !== 'all') {
      value = e.target.value;
    }

    setFilters((prevFilters: any) => ({
      ...prevFilters,
      status: value,
    }));
  };

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  const handleSelectAllCryptoOrders = (event: ChangeEvent<HTMLInputElement>): void => {
    setSelectedCustomVideoRequests(event.target.checked ? requests.map((request) => request.id) : []);
  };

  const handleSelectOneRequest = (event: ChangeEvent<HTMLInputElement>, requestId: number): void => {
    if (!selectedCustomVideoRequests.includes(requestId)) {
      setSelectedCustomVideoRequests((prevSelected) => [...prevSelected, requestId]);
    } else {
      setSelectedCustomVideoRequests((prevSelected) => prevSelected.filter((id) => id !== requestId));
    }
  };
  const handlePageChange = (event: any, page: number): void => {
    setPage(page);

    setQuery({
      page,
      limit,
      searchTerm,
    });

    refetch();
  };

  const handleSearch = (event: any) => {
    setSearchTerm(event.target.value);

    setTimeout(() => 1000);

    // setQuery({
    //   limit: 10,
    //   page: 1,
    //   searchTerm: event.target.value
    // })

    // refetch()
  };

  const handlePaginationChanged = (limit: number, page: number) => {
    setPage(page);
    setLimit(limit);

    setQuery({
      page,
      limit,
      searchTerm,
    });
    refetch();
  };
  const selectedSomeRequests = requests.length > 0 && requests.length < requests.length;
  const selectedAllRequests = selectedCustomVideoRequests.length === requests.length;

  return (
    <>
      {!error ? (
        <React.Fragment>
          <Card>
            {selectedBulkActions && (
              <Box flex={1} p={2}>
                <BulkActions />
              </Box>
            )}
            {!selectedBulkActions && (
              <CardHeader
                style={{ fontWeight: 'light' }}
                action={
                  <Box
                    width={500}
                    sx={{
                      float: 'right',
                      display: 'flex',
                      justifyContent: 'end',
                      alignItems: 'center',
                    }}
                  >
                    <Box>
                      <StyledSearch
                        fullWidth
                        onChange={handleSearch}
                        placeholder="Search requests"
                        startAdornment={
                          <InputAdornment position="start">
                            <Iconify
                              icon="eva:search-fill"
                              sx={{
                                color: 'text.disabled',
                                width: 20,
                                height: 20,
                              }}
                            />
                          </InputAdornment>
                        }
                        endAdornment={<InputAdornment position="start">{loading && <CircularProgress size={20} />}</InputAdornment>}
                      />
                    </Box>
                    <Box>
                      <AppFilter
                        openFilter={openFilter}
                        onOpenFilter={handleOpenFilter}
                        selectedFilters={selectedFilters}
                        onCloseFilter={handleCloseFilter}
                        onFilterChange={setSelectedFilters}
                        filters={[
                          {
                            title: 'Status',
                            filters: ['request', 'Super request'],
                          },
                        ]}
                      />
                    </Box>
                    <Box>
                      <RefreshButton onRefresh={refetch} />
                    </Box>
                  </Box>
                }
                title={
                  <Typography gutterBottom variant="caption" component="h2">
                    Requests
                  </Typography>
                }
              />
            )}
            <Divider />
            <TableContainer>
              <Table>
                {loading && <LinearProgress />}
                {!loading && (
                  <>
                    <TableHead>
                      <TableRow>
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={selectedAllRequests}
                            indeterminate={selectedSomeRequests}
                            onChange={handleSelectAllCryptoOrders}
                          />
                        </TableCell>
                        <TableCell>ID</TableCell>
                        <TableCell>User</TableCell>
                        <TableCell>Company</TableCell>
                        <TableCell>Contact Number</TableCell>
                        <TableCell>Requested On</TableCell>
                        <TableCell>Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {requests.map((request: CustomVideoRequest) => {
                        const isRequestSelected = selectedCustomVideoRequests.includes(request.id);
                        return (
                          <TableRow hover key={request.id} selected={isRequestSelected}>
                            <TableCell padding="checkbox">
                              <Checkbox
                                color="primary"
                                checked={isRequestSelected}
                                onChange={(event: ChangeEvent<HTMLInputElement>) => handleSelectOneRequest(event, request.id)}
                                value={isRequestSelected}
                              />
                            </TableCell>
                            <TableCell>
                              <Typography variant="body1" fontWeight="bold" color="text.primary" gutterBottom noWrap>
                                {request.id}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Box display="flex" alignItems="center">
                                <Avatar alt={request.user.firstName} src={request.user?.profilePic} />
                                <Typography ml={1} noWrap variant="body1" color="text.secondary">
                                  {request.user.firstName} &nbsp;
                                  {request.user.lastName}
                                </Typography>
                              </Box>
                            </TableCell>
                            <TableCell>
                              <Box display="flex" alignItems="center">
                                <Avatar alt={request.company.name} src={request.company?.profilePic} />
                                <Typography ml={1} noWrap variant="body1" color="text.secondary">
                                  {request.company.name}
                                </Typography>
                              </Box>
                            </TableCell>

                            <TableCell>
                              <Typography variant="body2" color="text.secondary" noWrap>
                                {formatPhoneNumber(request.company.phoneNumber)}
                              </Typography>
                            </TableCell>

                            <TableCell>
                              <Typography variant="body2" color="text.secondary" gutterBottom noWrap>
                                {moment(request.createdAt).format('L')}
                              </Typography>
                            </TableCell>

                            <TableCell>
                              <Tooltip title={`Contact Company`} arrow>
                                <IconButton
                                  sx={{
                                    '&:hover': {
                                      background: theme.colors.primary.lighter,
                                    },
                                    color: theme.palette.primary.main,
                                  }}
                                  color="inherit"
                                  size="small"
                                >
                                  <PhoneForwardedIcon />
                                </IconButton>
                              </Tooltip>{' '}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </>
                )}
              </Table>
            </TableContainer>
          </Card>

          {data?.data?.items?.length != null && data?.data?.items?.length == 0 && (
            <EmptyTable
              subtitle="There are currently no custom video requests on the system"
              icon={<MovieFilterIcon sx={{ color: theme.colors.primary.dark, fontSize: 130 }} />}
            />
          )}
          {!loading && data?.data?.items?.length != null && data?.data?.items?.length > 0 && (
            <AppTablePagination onChange={handlePaginationChanged} totalPages={data?.data?.meta.totalPages ?? 1} />
          )}
        </React.Fragment>
      ) : (
        <Retry refetch={refetch} />
      )}
    </>
  );
}
