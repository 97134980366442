import moment from 'moment';
import { useEffect, useState } from 'react';
import { SearchFilter } from '@core/enums/enums';
import SearchIcon from '@mui/icons-material/Search';
import { useLazySearchQuery } from '@features/state/slices/api/video-slice';
import {
  Box,
  TextField,
  Autocomplete,
  CircularProgress,
  AutocompleteChangeReason,
  AutocompleteChangeDetails,
  AutocompleteRenderOptionState,
  Typography,
} from '@mui/material';
import { useLazySearchCompanyQuery } from '@features/state/slices/api/company-slice';

type Props = {
  width?: number;
  multiple?: boolean;
  onSelected: (value: any) => void;
};

export const CompanySearch: React.FC<Props> = ({ width, multiple, onSelected }) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState<any>({});
  const [values, setValues] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [trigger, result] = useLazySearchCompanyQuery({
    refetchOnFocus: true,
  });

  useEffect(() => {
    if (!result.isFetching) {
      setLoading(false);
    }
  }, [result.isFetching]);

  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  const onChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: any,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<any> | undefined,
  ) => {
    onSelected(value);

    if (multiple) {
      setValues(value);
    } else {
      setValue(value);
    }
  };

  const onInputChange = (event: React.SyntheticEvent<Element, Event>, searchTerm: string): void => {
    setLoading(true);
    setTimeout(() => {
      trigger({
        searchTerm,
      });
    }, 1500);
  };

  const getOptionLabel = (option: any) => {
    return option?.name;
  };

  const isOptionEqualToValue = (option: any, value: any) => {
    return option.id == value.id;
  };

  const determineOptionRender = (props: React.HTMLAttributes<HTMLLIElement>, option: any, state: AutocompleteRenderOptionState) => {
    return (
      <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
        <img crossOrigin="anonymous" width="20" loading="lazy" src={option.profilePic} />
        {option.name}
      </Box>
    );
  };

  const renderInput = (params: any) => <TextField {...params} label="Search Company" />;

  return (
    <Autocomplete
      freeSolo
      open={open}
      loading={loading}
      onChange={onChange}
      onOpen={handleOpen}
      multiple={multiple}
      onClose={handleClose}
      renderInput={renderInput}
      onInputChange={onInputChange}
      className="autocomplete__base"
      getOptionLabel={getOptionLabel}
      sx={{ width: width ?? '100%' }}
      renderOption={determineOptionRender}
      isOptionEqualToValue={isOptionEqualToValue}
      options={result.isFetching ? [] : (result?.data?.data as any) ?? []}
    />
  );
};

CompanySearch.defaultProps = {
  multiple: false,
};
