import * as yup from 'yup';
import { Navigator } from '../Navigator';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { InputLabel, Typography, FormHelperText, Box, Switch, FormControlLabel } from '@mui/material';
import { fadeInRight } from 'react-animations';
import { VideoMode } from '@core/enums/enums';
import { useLocation } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import { CompanySearch } from '@components/CompanySearch';
import TransitionWrapper from '@components/TransitionWrapper';
import { Company, CreateVideoDTO } from '@core/constants/constants';
import { companySlice, useLazyGetCompanyByIdQuery } from '@features/state/slices/api/company-slice';
import { HelperText } from '@components/HelperText';

type Props = {
  steps: any;
  activeStep: number;
  videoMode: VideoMode;
  data: CreateVideoDTO;
  handleBack: () => void;
  handleNext: () => void;
  handleSkip: () => void;
  onCapture: (data: any) => void;
};

const schema = yup.object().shape({
  includeLogo: yup.boolean().default(false),
  companyId: yup.number().required('A company must be selected'),
});

export const CompanySelectionStep: React.FC<Props> = (props: Props) => {
  const { data, steps, activeStep, onCapture, handleBack, handleNext, handleSkip, videoMode } = props;

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const [getCompany, response] = useLazyGetCompanyByIdQuery();
  const [selectedCompany, setSelectedCompany] = useState<Company | null>(null);

  useEffect(() => {
    handleCompanyFetch();
  }, []);

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleCompanyFetch = async () => {
    let companyId = null;
    const selectedCompanyId = query.get('selectedCompany');

    let hasId = data.companyId !== null || query.get('selectedCompany') !== null;

    if (hasId) {
      if (data.companyId) {
        companyId = data.companyId;
      } else if (selectedCompanyId) {
        companyId = selectedCompanyId;
      }

      const result = await getCompany({ id: companyId }).unwrap();

      if (!result.success) {
        return;
      }

      setSelectedCompany(result.data!);
      setValue('companyId', result.data!.id);
    }
  };
  const captureData = (data: any) => {
    handleNext();
    onCapture(data);
  };

  return (
    <TransitionWrapper duration={0.2} animation={fadeInRight}>
      <Box mt={2} mb={5}>
        <Box mt={2}>
          <HelperText
            title="Search for a company"
            subtitle="You can query the system to find a company to assign a custom video to. If you navigated from the company edit page, that company account will be used be default"
          />
          <br></br>

          <CompanySearch
            multiple={false}
            onSelected={(company: Company) => {
              setSelectedCompany(company);
              setValue('companyId', company?.id);
            }}
          />
        </Box>

        {selectedCompany && (
          <Box mt={2}>
            <Typography variant="h4">{selectedCompany?.name}</Typography>

            <Typography variant="subtitle1">We will apply this company's logo to the processed video</Typography>
            <img
              crossOrigin="anonymous"
              width={500}
              height={400}
              src={selectedCompany.profilePic}
              style={{ objectFit: 'contain', borderRadius: 10 }}
            ></img>

            {selectedCompany?.profilePic?.includes('null') && (
              <Typography mt={2} variant="subtitle1">
                This company does not have a logo uploaded. We will use the default sales assist logo instead
              </Typography>
            )}
            <br></br>
            <br></br>
            <FormControlLabel
              control={
                <Switch
                  sx={{ m: 1 }}
                  {...register('includeLogo', {
                    required: true,
                    maxLength: 80,
                  })}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setValue('includeLogo', e.target.checked);
                  }}
                />
              }
              label={<Typography variant="subtitle1">Include this company's logo on your custom video?</Typography>}
            />
          </Box>
        )}

        <FormHelperText style={{ color: 'red' }}>{errors?.companyId?.message as string}</FormHelperText>
        <Navigator
          steps={steps}
          handleSkip={handleSkip}
          handleBack={handleBack}
          activeStep={activeStep}
          handleNext={handleSubmit(captureData)}
        />
      </Box>
    </TransitionWrapper>
  );
};
