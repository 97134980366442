import { useChart } from '@components/AppChart';
import ReactApexChart from 'react-apexcharts';
import { Card, CardHeader, Box } from '@mui/material';

type Props = {
  title: string;
  chartData: any[];
  subheader: string;
  chartLabels: string[];
};

export default function DashboardPerformanceChart({ title, subheader, chartLabels, chartData, ...other }: Props) {
  const chartOptions = useChart({
    plotOptions: { bar: { columnWidth: '16%' } },
    fill: { type: chartData.map((i: any) => i.fill) },
    labels: chartLabels,
    xaxis: { type: 'datetime' },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (y: any) => {
          if (typeof y !== 'undefined') {
            return `${y.toFixed(0)}`;
          }
          return y;
        },
      },
    },
  });

  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} />

      <Box sx={{ p: 4, pb: 1 }} dir="ltr">
        <ReactApexChart type="line" height={364} series={chartData} options={chartOptions} />
      </Box>
    </Card>
  );
}
