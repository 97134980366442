import * as yup from "yup";
import { Navigator } from "../Navigator";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { fadeInRight } from "react-animations";
import FileUpload from "react-material-file-upload";
import { yupResolver } from "@hookform/resolvers/yup";
import { determineBorderColor } from "@core/utils/utils";
import { Box, FormHelperText, InputLabel } from "@mui/material";
import {
  CreateVideoDTO,
  acceptedVideoFormats,
} from "@core/constants/constants";
import TransitionWrapper from "@components/TransitionWrapper";
import { HelperType, VideoMode } from "@core/enums/enums";
import { HelperText } from "@components/HelperText";
import { CustomVideoStepProps } from "@core/types";

const schema = yup.object().shape({
  video: yup
    .mixed()
    .test("video", "Video is required", (value) => {
      if (value === undefined) {
        return false;
      }
      if (value?.length === 0) return false;
      return true;
    })
    .test("video", "The file is too large", (value) => {
      return value && value.size <= 2_000_000_000;
    }),
});

export const UploadVideoStep: React.FC<CustomVideoStepProps> = (props: CustomVideoStepProps) => {
  const {
    data,
    steps,
    activeStep,
    videoMode,
    onCapture,
    handleBack,
    handleNext,
    handleSkip,
  } = props;

  const [files, setFiles] = useState<File[]>([]);

  useEffect(() => {
    let fileList: File[] = [];

    if (data?.video) {
      fileList = [data?.video];
    }
    setFiles(fileList);
    setValue("video", data?.video);
  }, []);

  const {
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const captureData = (data: any) => {
    handleNext();
    onCapture(data);
  };

  const onChange = (files: File[]) => {
    setFiles(files);
    setValue("video", files[0]);
  };

  return (
    <TransitionWrapper duration={0.2} animation={fadeInRight}>
      <Box>
        <HelperText
          title="Upload a video file"
          subtitle="We recommend you upload .mp4 files. The maximum video size is 2GB"
        />
        <br></br>
        <FileUpload
          value={files}
          onChange={onChange}
          accept={acceptedVideoFormats}
          //sx={determineBorderColor(errors?.video?.message as string)}
        />
        <br></br>
        {errors?.video?.message && (
          <HelperText
            title="Validation Error"
            type={HelperType.Error}
            subtitle={errors?.video?.message as string}
          />
        )}
        <Navigator
          steps={steps}
          handleSkip={handleSkip}
          handleBack={handleBack}
          activeStep={activeStep}
          handleNext={handleSubmit(captureData)}
        />
      </Box>
    </TransitionWrapper>
  );
};
