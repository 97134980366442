import { VideoPackage } from "@core/types";
import { Box, Grid, Typography } from "@mui/material";
import { AnimatedList } from "react-animated-list";
import { NoPackages } from "./NoPackages";
import { PackageItem } from "./PackageItem";

type Props = { packages: VideoPackage[]; refetch: any };

export const PackageLisitng: React.FC<Props> = ({ packages, refetch }) => {
  return (
    <div>
      {packages.length == 0 ? (
     <NoPackages/>
      ) : (
        <Grid container spacing={3} ml={0.3}>
          <AnimatedList animation={"grow"}>
            {packages.map((videoPackage, index) => (
              <Grid item xs={3} mr={4}>
                <PackageItem
                  videoPackage={videoPackage}
                  index={index}
                  refetch={refetch}
                />
              </Grid>
            ))}
          </AnimatedList>
        </Grid>
      )}
    </div>
  );
};
