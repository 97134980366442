import * as React from 'react';
import Chip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';
import { AppInput } from '@components/Styled';
import { Box, Button, InputAdornment } from '@mui/material';
import { SubscriptionMode, VideoMode } from '@core/enums/enums';

type Props = {
  keywords: string[];
  videoMode?: VideoMode | null;
  subscriptionMode: SubscriptionMode | null;
  onSelected: (keywords: string[]) => void;
};

const ListItem = styled('li')(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

export default function KeywordSelector(props: Props) {
  const [value, setValue] = React.useState('');
  const [chipData, setChipData] = React.useState<string[]>([]);

  React.useEffect(() => {
    if (props.videoMode === VideoMode.Edit) {
      setChipData(props.keywords);
    }
    if (props.subscriptionMode === SubscriptionMode.Edit) {
      setChipData(props.keywords);
    }
  }, [props.videoMode, props.keywords]);

  const handleDelete = (chipToDelete: string) => () => {
    const filteredChipData = chipData.filter((chip) => chip !== chipToDelete);
    setChipData(filteredChipData);
    props.onSelected(filteredChipData);
  };

  const handleAddKeyword = () => {
    setChipData((prevState: string[]) => (prevState ? [...prevState, value] : [value]));
    setValue('');
    props.onSelected([...chipData, value]);
  };

  return (
    <React.Fragment>
      <AppInput
        value={value}
        endAdornment={
          <InputAdornment position="start">
            <Box ml={2}>
              <Button variant="contained" onClick={handleAddKeyword} disabled={value.length == 0}>
                Add
              </Button>
            </Box>
          </InputAdornment>
        }
        onChange={(e) => setValue(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && value.length > 0) {
            e.preventDefault(); // Prevent the default action to avoid submitting a form if it exists
            handleAddKeyword();
          }
        }}
      />
      <Box
        sx={{
          m: 0,
          p: 0.5,
          display: 'flex',
          flexWrap: 'wrap',
          listStyle: 'none',
          justifyContent: 'center',
        }}
        component="ul"
      >
        {chipData?.map((data, index) => (
          <ListItem key={index}>
            <Chip label={data} onDelete={handleDelete(data)} />
          </ListItem>
        ))}
      </Box>
    </React.Fragment>
  );
}
