import { useMemo, useState } from 'react';
import { useAuth } from '@core/utils/utils';
import { Box, Step, Stepper, StepLabel, Card, CircularProgress, Typography } from '@mui/material';
import React from 'react';
import { useSnackbar } from 'notistack';
import { CreateSubscriptionDTO, EditSubscriptionDTO } from '@core/constants/constants';
import { useNavigate } from 'react-router';
import { BackButton } from '@components/BackButton';
import { Subscription } from '@core/constants/constants';
import { SubscriptionMode } from '@core/enums/enums';
import { SubscriptionTitleStep } from './components/Steps/SubscriptionTitleStep';
import { ContractCostsForm } from './components/Steps/ContractCostsForm';
import { AdditionalCostsForm } from './components/Steps/AdditionalCostsForm';
import { CompletionStep } from './components/Steps/CompletionStep';
import { useDispatch } from 'react-redux';
import { toggleLoading } from '@features/state/slices/local/loading-slice';
import { useCreateBaseSubscriptionMutation, useEditSubscriptionsMutation } from '@features/state/slices/api/subscription-slice';
import { toast } from 'react-hot-toast';
import { findSourceMap } from 'module';

//NOTE Focus here Creating a subscription
//DEPRECATED Additional Costs are no longer applied
// const steps = ['Enter subscription name', 'Enter contract costs', 'Enter additional costs', 'Finish'];
const steps = ['Enter subscription name', 'Enter contract costs', 'Finish'];

type Props = {
  subscription: Subscription | null;
  subscriptionMode: SubscriptionMode;
};

const CreateSubscription = ({ subscription, subscriptionMode }: Props) => {
  const { user } = useAuth();
  const optionalSteps: number[] = [];
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set<number>());
  const [data, setData] = useState<CreateSubscriptionDTO>({} as CreateSubscriptionDTO);
  // const [data, setData] = useState<CreateSubscriptionDTO>({} as CreateSubscriptionDTO);
  const dispatch = useDispatch();
  const [createBaseSubscription, response] = useCreateBaseSubscriptionMutation();
  const [editBaseSubscription, editResponse] = useEditSubscriptionsMutation();
  const isStepOptional = (step: number) => {
    return optionalSteps.includes(step);
  };
  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      if (subscriptionMode == SubscriptionMode.Edit) {
        editSubscription();
      } else {
        createSubscription();
      }

      return;
    }

    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const editSubscription = async () => {
    //TODO Write code to edit subscription

    dispatch(toggleLoading());
    const formData = new FormData();

    const {
      upFrontBillingCost,
      billingMonthlyThreshold,
      additionalUserCost,
      access,
      monthlyContractCost,
      name,
      oneYearContractCost,
      setupCost,
      sixMonthContractCost,
      subscriptionItemsIncluded,
      twoYearContractCost,
      usersCovered,
      videoPackageDiscount,
      hasTrialPeriod,
      numberOfDaysInTrialPeriod,
      subscriptionPublic,
      accessCodes,
      id,
    } = data;
    // formData.append('upFrontBillingCost', upFrontBillingCost ?? '0');
    // formData.append('billingMonthlyThreshold', billingMonthlyThreshold ?? '0');
    // formData.append('additionalUserCost', additionalUserCost ?? '0');
    formData.append('id', id.toString());
    formData.append('access', JSON.stringify(access));
    // formData.append('monthlyContractCost', monthlyContractCost);
    formData.append('name', name);
    // formData.append('oneYearContractCost', oneYearContractCost);
    // formData.append('setupCost', String(setupCost));
    // formData.append('sixMonthContractCost', sixMonthContractCost);
    formData.append('subscriptionItemsIncluded', subscriptionItemsIncluded.toString());
    // formData.append('twoYearContractCost', twoYearContractCost);
    formData.append('usersCovered', String(usersCovered));
    // formData.append('videoPackageDiscount', String(videoPackageDiscount));
    formData.append('hasTrialPeriod', String(hasTrialPeriod));
    formData.append('numberOfDaysInTrialPeriod', numberOfDaysInTrialPeriod.toString());
    formData.append('public', subscriptionPublic ? 'true' : 'false');
    formData.append('accessCodes', JSON.stringify(accessCodes));

    try {
      const jsonObject: any = {};
      formData.forEach((value, key) => {
        jsonObject[key] = value;
      });

      // If you need to send it as JSON, convert it to a JSON string
      const jsonString = JSON.stringify(jsonObject);
      console.log('JSON subscription', jsonObject);
      const { success, error, data } = await editBaseSubscription(jsonObject).unwrap();
      console.log('States Error Occured', success, error, data);
      if (success) {
        toast.success('Your subscription plan was edited successfully');
        navigate(-1);
      } else if (error) {
        console.log('States Error Occured ');
        toast.error('Your subscription plan  could not be edited');
      }
    } catch (e: any) {
      console.log('States Error Occured catch block ');

      toast.error('Your subscription plan  could not be edited');
    } finally {
      dispatch(toggleLoading());
    }
  };
  //Seems to be creating a system  subscription plan

  const createSubscription = async () => {
    dispatch(toggleLoading());

    // let key;
    const formData = new FormData();

    const {
      upFrontBillingCost,
      billingMonthlyThreshold,
      additionalUserCost,
      access,
      monthlyContractCost,
      name,
      oneYearContractCost,
      setupCost,
      sixMonthContractCost,
      subscriptionItemsIncluded,
      twoYearContractCost,
      usersCovered,
      videoPackageDiscount,
      hasTrialPeriod,
      numberOfDaysInTrialPeriod,
      subscriptionPublic,
      accessCodes,
    } = data;

    formData.append('upFrontBillingCost', upFrontBillingCost ?? '0');
    formData.append('billingMonthlyThreshold', billingMonthlyThreshold ?? '0');
    formData.append('additionalUserCost', additionalUserCost ?? '0');
    formData.append('access', JSON.stringify(access));
    formData.append('monthlyContractCost', monthlyContractCost);
    formData.append('name', name);
    formData.append('oneYearContractCost', oneYearContractCost);
    formData.append('setupCost', String(setupCost));
    formData.append('sixMonthContractCost', sixMonthContractCost);
    formData.append('subscriptionItemsIncluded', subscriptionItemsIncluded.toString());
    formData.append('twoYearContractCost', twoYearContractCost);
    formData.append('usersCovered', String(usersCovered));
    formData.append('videoPackageDiscount', String(videoPackageDiscount));
    formData.append('hasTrialPeriod', String(hasTrialPeriod));
    formData.append('numberOfDaysInTrialPeriod', numberOfDaysInTrialPeriod.toString());
    formData.append('public', subscriptionPublic ? 'true' : 'false');
    formData.append('accessCodes', JSON.stringify(accessCodes));

    try {
      const jsonObject: any = {};
      formData.forEach((value, key) => {
        jsonObject[key] = value;
      });

      // If you need to send it as JSON, convert it to a JSON string
      const jsonString = JSON.stringify(jsonObject);
      console.log('JSON subscription', jsonObject);
      const { success, error, data } = await createBaseSubscription(jsonObject).unwrap();
      console.log('States Error Occured', success, error, data);
      if (success) {
        toast.success('Your subscription plan was created successfully');
        navigate(-1);
      } else if (error) {
        console.log('States Error Occured ');
        toast.error('Your subscription plan  could not be created');
      }
    } catch (e: any) {
      console.log('States Error Occured catch block ');

      toast.error('Your subscription plan  could not be created');
    } finally {
      dispatch(toggleLoading());
    }
    // key = enqueueSnackbar(
    //   <Box display={'flex'} alignItems="center" justifyContent={'center'}>
    //     <Typography>Submitting</Typography>
    //     <CircularProgress size={20} sx={{ ml: 2 }} />
    //   </Box>,
    //   {
    //     persist: true,
    //     draggable: true,
    //     onClose: () => null,
    //   },
    // );

    // navigate('/dashboard/subscriptions');

    // enqueueSnackbar(
    //   <Box display={'flex'} alignItems="center" justifyContent={'center'}>
    //     <Typography>Submitted</Typography>
    //     <CircularProgress size={20} sx={{ ml: 2 }} />
    //   </Box>,
    //   {
    //     draggable: true,
    //     onClose: () => null,
    //   },
    // );

    // closeSnackbar(key);
    console.log('DONE ', data);
  };

  const determineStep = () => {
    switch (activeStep) {
      case 0:
        return (
          <SubscriptionTitleStep
            data={data}
            steps={steps}
            handleBack={handleBack}
            handleNext={handleNext}
            activeStep={activeStep}
            handleSkip={handleSkip}
            subscriptionMode={subscriptionMode}
            onCapture={(c) =>
              setData((prevState) => {
                console.log('Captured Data From Tite ', c);
                return { ...prevState, ...c };
              })
            }
          />
        );
      case 1:
        return (
          <ContractCostsForm
            data={data}
            steps={steps}
            handleBack={handleBack}
            handleNext={handleNext}
            activeStep={activeStep}
            handleSkip={handleSkip}
            subscriptionMode={subscriptionMode}
            onCapture={(c) =>
              setData((prevState) => {
                console.log('Captured Data From Contract ', c);
                console.log('Captured Data From Contract ', prevState);
                return { ...prevState, ...c };
              })
            }
          />
        );
      // case 2:
      //   return (
      //     <AdditionalCostsForm
      //       data={data}
      //       steps={steps}
      //       handleBack={handleBack}
      //       handleNext={handleNext}
      //       activeStep={activeStep}
      //       handleSkip={handleSkip}
      //       subscriptionMode={subscriptionMode}
      //       onCapture={(c) =>
      //         setData((prevState) => {
      //           return { ...prevState, ...c };
      //         })
      //       }
      //     />
      //   );
      case 2:
        return (
          <CompletionStep
            data={data}
            steps={steps}
            handleBack={handleBack}
            handleNext={handleNext}
            activeStep={activeStep}
            handleSkip={handleSkip}
            subscriptionMode={subscriptionMode}
            onCapture={(c) =>
              setData((prevState) => {
                return { ...prevState, ...c };
              })
            }
          />
        );
      default:
        return;
    }
  };

  useMemo(() => {
    if (subscriptionMode === SubscriptionMode.Edit && subscription) {
      console.log(`subscription ${JSON.stringify(subscription.public)}`);
      const data: CreateSubscriptionDTO = {
        upFrontBillingCost: subscription.upFrontBillingCost.toString(),
        billingMonthlyThreshold: subscription.billingMonthlyThreshold.toString(),
        additionalUserCost: subscription.additionalUserCost.toString(),
        credits: subscription.credits,
        id: subscription.id,
        monthlyContractCost: subscription.monthlyContractCost.toString(),
        name: subscription.name,
        oneYearContractCost: subscription.oneYearContractCost.toString(),
        setupCost: subscription.setupCost,
        sixMonthContractCost: subscription.sixMonthContractCost.toString(),
        subscriptionItemsIncluded: subscription.subscriptionItemsIncluded,
        twoYearContractCost: subscription.twoYearContractCost?.toString() ?? '0',
        usersCovered: subscription.usersCovered,
        videoPackageDiscount: subscription.videoPackageDiscount ?? 0,
        access: subscription.companies.map((c) => c.id),
        hasTrialPeriod: subscription.hasTrialPeriod ?? false,
        numberOfDaysInTrialPeriod: subscription.numberOfDaysInTrialPeriod ?? 0,
        subscriptionPublic: subscription.public,
        accessCodes: subscription.accessCodes ?? [],
      };
      setData(data);
    }
  }, [subscriptionMode, subscription]);

  return (
    <Card sx={{ padding: 3 }}>
      <Box sx={{ width: '100% ' }}>
        <Box display={'flex'}>
          <BackButton />
          <Typography ml={1} variant="h2">
            {subscriptionMode === SubscriptionMode.Create ? 'Create Subscription' : 'Edit Subscription'}
          </Typography>
        </Box>
        <Typography variant="subtitle1" mt={1}>
          {subscriptionMode === SubscriptionMode.Create
            ? user?.name + ', here you can create your subscriptions'
            : user?.name + ', here you can edit your subscriptions'}
        </Typography>
        <br></br>

        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps: { completed?: boolean } = {};
            const labelProps: {
              optional?: React.ReactNode;
            } = {};
            if (isStepOptional(index)) {
              labelProps.optional = <Typography variant="caption">Optional</Typography>;
            }
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>

        {determineStep()}
      </Box>
    </Card>
  );
};

export default CreateSubscription;
