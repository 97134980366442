import { useState } from 'react';
import { useNavigate } from 'react-router';
import { useAuth } from '@core/utils/utils';
import HeaderSearch from './components/Search';
import HeaderUserbox from './components/Userbox';
import useResponsive from '@core/hooks/useResponsive';
import { useDispatch, useSelector } from 'react-redux';
import SettingsIcon from '@mui/icons-material/Settings';

import HeaderNotifications from './components/Notifications';
import { HeaderWrapper, MotionBox } from '@components/Styled';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import { RootState } from '@features/state/reducers/combinedReducers';
import { Box, Tooltip, IconButton, useTheme, useScrollTrigger, Slide, Drawer } from '@mui/material';
import { CLOSE_SIDEBAR, OPEN_SIDEBAR, toggleSidebar } from '@features/state/slices/local/layout.slice';
import Sidebar from '../Sidebar/index-v2';

function AppHeader() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const isDesktop = useResponsive('up', 'lg', 'sm');
  const layout = useSelector((state: RootState) => state.layout);

  const toggleSidebarStaus = () => {
    if (isDesktop) {
      if (layout.isOpen) {
        dispatch(toggleSidebar(CLOSE_SIDEBAR));
      } else {
        dispatch(toggleSidebar(OPEN_SIDEBAR));
      }
    } else {
      setOpen(true);
    }
  };
  const navigateToSettingScreen = () => {
    navigate('/dashboard/settings');
  };

  return (
    <HideOnScroll>
      <div>
        <HeaderWrapper initial={{ left: isDesktop ? layout.layoutValue : 0 }} animate={{ left: isDesktop ? layout.layoutValue : 0 }}>
          <Box ml={2} display="flex" alignItems="center">
            <Tooltip arrow title="Toggle Menu">
              <IconButton color="primary" onClick={toggleSidebarStaus}>
                <MenuTwoToneIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Box>
          <Box display="flex" alignItems="center">
            <HeaderSearch />
            <Box>
              <Box component="span">
                <HeaderNotifications />
              </Box>
              <Tooltip arrow title="Search">
                <IconButton color="primary" onClick={navigateToSettingScreen}>
                  <SettingsIcon />
                </IconButton>
              </Tooltip>
            </Box>
            <HeaderUserbox />
          </Box>
        </HeaderWrapper>

        <Drawer open={open} variant="temporary" onClose={(e) => setOpen(false)}>
          <MotionBox
            sx={{
              boxShadow: 3,
              bgcolor: 'white',
              width: OPEN_SIDEBAR.layoutValue,
            }}
            initial={{ width: OPEN_SIDEBAR.layoutValue }}
            animate={{ width: OPEN_SIDEBAR.layoutValue }}
          >
            <Sidebar layout={OPEN_SIDEBAR} onItemClick={() => setOpen(false)} />
          </MotionBox>
        </Drawer>
      </div>
    </HideOnScroll>
  );
}

interface Props {
  children: React.ReactElement;
}

function HideOnScroll(props: Props) {
  const { children } = props;

  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

export default AppHeader;
