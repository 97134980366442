import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type ForgotPassword = {
  email?: string | null;
  token?: string | null;
};

const forgotPasswordSlice = createSlice({
  name: 'forgotPassword',

  initialState: {
    email: null,
    token: null,
  },
  reducers: {
    setForgotPasswordEmail(state, action: PayloadAction<string | null>) {
      state.email = action.payload as any;
    },
    setForgotPasswordToken(state, action: PayloadAction<string | null>) {
      state.token = action.payload as any;
    },
  },
});
export const { setForgotPasswordEmail, setForgotPasswordToken } = forgotPasswordSlice.actions;
export default forgotPasswordSlice;
