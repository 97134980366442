import { useEffect, useState } from 'react';
import { Video, VideoPackage } from '@core/types';
import { useForm } from 'react-hook-form';
import { AppInput } from '@components/Styled';
import FileUpload from 'react-material-file-upload';
import FormControl from '@mui/material/FormControl';
import { yupResolver } from '@hookform/resolvers/yup';
import { determineBorderColor } from '@core/utils/utils';
import FormHelperText from '@mui/material/FormHelperText';

import {
  Box,
  Grid,
  Card,
  List,
  Button,
  Typography,
  InputLabel,
  ListItemText,
  ListItemButton,
  CircularProgress,
  IconButton,
  Tooltip,
  useTheme,
} from '@mui/material';
import { toast } from 'react-hot-toast';
import { AppRoutes } from '@core/constants/routes';
import { AnimatedList } from 'react-animated-list';
import LoadingButton from '@mui/lab/LoadingButton';
import { BackButton } from '@components/BackButton';
import { SearchField } from '@components/SearchBar';
import { Navigate, useNavigate, useParams } from 'react-router';
import KeywordSelector from '@components/KeywordSelector';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import { CreatePackageSchema, EditPackageSchema } from '@core/constants/schemas';
import TransitionWrapper from '@components/TransitionWrapper';
import { acceptedImageFormats } from '@core/constants/constants';
import { PackageTypeSelect } from './components/PackageTypeSelect';
import { PackageIntervalSelect } from './components/PackageIntervalSelect';
import { PackageCategorySelect } from './components/PackageCategorySelect';
import { useGetPackageByIdQuery, useEditPackageMutation, useGetPackageCategoriesQuery } from '@features/state/slices/api/package-slice';
import { VideoMode } from '@core/enums/enums';

type Props = {};

const EditPackage: React.FC<Props> = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const [price, setPrice] = useState({} as any);
  const [interval, setInterval] = useState('month');
  const [discount, setDiscount] = useState({} as any);
  const [category, setCategory] = useState({} as any);
  const [selectedIndex, setSelectedIndex] = useState(1);
  const [keywords, setKeywords] = useState<string[]>([]);
  const [preview, setVideoPreview] = useState<any>(null);
  const [description, setDescription] = useState({} as any);
  const [packageName, setPackageName] = useState({} as any);
  const [editPackageMutation, response] = useEditPackageMutation();
  const [selectedVideos, setSelectedVideos] = useState<Video[]>([]);
  const [packageIteration, setPackageIteration] = useState({} as any);
  const [videoPackage, setVideoPackage] = useState<VideoPackage>({} as any);

  const { data, error, isError, isFetching, isLoading, isSuccess, refetch } = useGetPackageByIdQuery(
    { id },
    { refetchOnMountOrArgChange: true },
  );

  const packageCategoriesResponse = useGetPackageCategoriesQuery({
    refetchOnMountOrArgChange: true,
  });
  const handleListItemClick = (event: any, index: any, video: Video) => {
    setVideoPreview(video.highResolutionVideoPreview);
    setSelectedIndex(index);
  };

  const {
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(EditPackageSchema),
  });

  useEffect(() => {
    if (!isFetching && !isError && isSuccess) {
      const keywords = data.data!.keywords.split(',');
      setVideoPackage(data.data!);

      setKeywords(keywords);
      setPrice(data.data?.price);
      setDiscount(data.data?.discount);
      setSelectedVideos(data.data?.videos!);
      setPackageName(data.data?.packageName);
      setDescription(data.data?.packageDescription);
      setPackageIteration(data.data?.packageIteration);

      setValue('keywords', keywords);
      setValue('price', data?.data?.price!);
      setValue('discount', data.data?.discount);
      setCategory(data?.data?.packageCategoryId);
      setValue('videoIds', data?.data?.videoIds!);
      setValue('packageName', data.data?.packageName);
      setValue('packageIteration', data?.data?.packageIteration!);
      setValue('packageCategoryId', data?.data?.packageCategoryId!);
      setValue('packageDescription', data.data?.packageDescription);
    }
  }, [isFetching, isSuccess, isError]);

  const editPackage = async (data: any) => {
    try {
      const { price, videoIds, keywords, packageName, packageIteration, packageCategoryId, packageDescription } = data;

      const formData = new FormData();

      keywords.push(packageName);

      formData.append('id', id!);
      formData.append('price', price);
      formData.append('videoIds', videoIds);
      formData.append('interval', interval);
      formData.append('packageName', packageName);
      formData.append('keywords', keywords.toString());
      formData.append('packageIteration', packageIteration);
      formData.append('packageCategoryId', packageCategoryId);
      formData.append('packageDescription', packageDescription);

      const { error, success } = await editPackageMutation(formData).unwrap();

      if (!success && error) {
        toast.error(error.message);
        return;
      }

      toast.success('Your package was sucessfully updated');
      //navigate(`/dashboard/packages`);
    } catch (e) {
      toast.error('An error occured');
    }
  };

  const removeVideo = (id: number) => {
    const filtered = selectedVideos.filter((video) => video.id !== id);
    setSelectedVideos(filtered);
    const videoIds = filtered.map((v) => v.id);

    if (videoIds.length == 0) {
      setValue('videoIds', null);
      return;
    }

    setValue('videoIds', videoIds);
  };

  return (
    <TransitionWrapper>
      <Card sx={{ p: 3 }}>
        <Box display="flex">
          <BackButton />
          <Typography ml={1} variant="h2">
            Edit Package
          </Typography>
        </Box>
        <Typography variant="subtitle1" mt={1}>
          Here you can bundles videos your videos and distribute them as packages to your customers
        </Typography>

        <Grid mt={3} container spacing={2}>
          <Grid item xs={6}>
            <FormControl variant="standard" style={{ width: '100%' }}>
              <InputLabel shrink htmlFor="bootstrap-input">
                Package Name
              </InputLabel>
              <AppInput
                fullWidth
                type="name"
                autoComplete="packageName"
                style={determineBorderColor(errors?.packageName?.message as any)}
                {...register('packageName', {
                  required: true,
                  maxLength: 80,
                })}
                //value={packageName}
                onChange={(event) => {
                  setPackageName(event.target.value);
                  setValue('packageName', event.target.value);
                }}
              />

              <FormHelperText style={{ color: 'red' }}>{errors?.packageName?.message as string}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl variant="standard" style={{ width: '100%' }}>
              <InputLabel shrink htmlFor="bootstrap-input">
                Package Iteration
              </InputLabel>
              <AppInput
                type="number"
                fullWidth
                style={determineBorderColor(errors?.packageIteration?.message as any)}
                {...register('packageIteration', {
                  required: true,
                  maxLength: 80,
                })}
                value={packageIteration}
                onChange={(event) => {
                  setPackageIteration(event.target.value);
                  setValue('packageIteration', event.target.value);
                }}
              />
              <FormHelperText style={{ color: 'red' }}>{errors?.packageIteration?.message as string}</FormHelperText>
            </FormControl>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl variant="standard" style={{ width: '100%' }}>
              <InputLabel shrink htmlFor="bootstrap-input">
                Price
              </InputLabel>

              <AppInput
                fullWidth
                type="number"
                style={determineBorderColor(errors?.price?.message as any)}
                {...register('price', {
                  required: true,
                  maxLength: 80,
                })}
                value={price}
                onChange={(event) => {
                  setPrice(event.target.value);
                  setValue('price', event.target.value);
                }}
              />
              <FormHelperText style={{ color: 'red' }}>{errors?.price?.message as string}</FormHelperText>
            </FormControl>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <InputLabel className="input-label">Subscription Interval</InputLabel>

            <PackageIntervalSelect
              name="interval"
              label="Interval"
              interval={interval}
              placeholder="Interval"
              helperText={errors.packageInterval?.message}
              error={errors.packageInterval?.message !== undefined}
              onChange={(value) => {
                setInterval(value);
                setValue('packageInterval', value, {
                  shouldValidate: true,
                });
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <InputLabel className="input-label">Package Category</InputLabel>

            <PackageCategorySelect
              name="category"
              label="Category"
              category={category}
              placeholder="Category"
              helperText={errors.packageCategoryId?.message}
              error={errors.packageCategoryId?.message !== undefined}
              onChange={(category) => {
                setCategory(category);
                setValue('packageCategoryId', category, {
                  shouldValidate: true,
                });
              }}
            />
          </Grid>
        </Grid>

        <Box mt={2}>
          <InputLabel className="input-label">Search for videos to add to your package</InputLabel>
          <SearchField
            multiple={true}
            searchValues={selectedVideos}
            onSelected={(videos: Video[]) => {
              setSelectedVideos(videos);
              const videoIds = videos.map((x) => x.id);

              if (videoIds.length == 0) {
                setValue('videoIds', null);
              }

              setValue('videoIds', videoIds);
            }}
          />

          <FormHelperText style={{ color: 'red' }}>{errors?.videoIds?.message as string}</FormHelperText>
        </Box>

        <Box mt={5} mb={4}>
          <Grid container spacing={3} ml={0.3}>
            <AnimatedList animation={'grow'}>
              {selectedVideos.map((video) => (
                <Grid sx={{ cursor: 'pointer' }} item xs={3} mr={2}>
                  <Box height={150} width={220}>
                    <img
                      crossOrigin="anonymous"
                      style={{ objectFit: 'cover', borderRadius: 10 }}
                      height={150}
                      width={220}
                      src={video.highResolutionThumbnail}
                    />
                    <Box mt={0.5} m={0.5} display="flex" justifyContent="space-between">
                      <Box width={100}>
                        <Typography
                          color="grey"
                          variant="subtitle1"
                          width={150}
                          sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: '-webkit-box',
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: 'vertical',
                          }}
                        >
                          {video.videoName}
                        </Typography>
                      </Box>
                      <Tooltip title="Delete" arrow>
                        <IconButton
                          sx={{
                            '&:hover': {
                              background: theme.colors.error.lighter,
                            },
                            color: theme.palette.error.main,
                          }}
                          color="inherit"
                          size="small"
                          onClick={() => removeVideo(video.id)}
                        >
                          <DeleteTwoToneIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Box>
                </Grid>
              ))}
            </AnimatedList>
          </Grid>
        </Box>

        <Box>
          <FormControl variant="standard" style={{ width: '100%' }}>
            <InputLabel shrink htmlFor="bootstrap-input">
              Package Description
            </InputLabel>

            <AppInput
              fullWidth
              type="textarea"
              style={determineBorderColor(errors?.packageDescription?.message as any)}
              {...register('packageDescription', {
                required: true,
                maxLength: 80,
              })}
              onChange={(event) => {
                setDescription(event.target.value);
                setValue('packageDescription', event.target.value);
              }}
            />
          </FormControl>
          <FormHelperText style={{ color: 'red' }}>{errors?.packageDescription?.message as string}</FormHelperText>
        </Box>
        <Box>
          <FormControl variant="standard" style={{ width: '100%', marginTop: 15 }}>
            <InputLabel shrink htmlFor="bootstrap-input">
              Keywords
            </InputLabel>
            <KeywordSelector
              keywords={keywords}
              videoMode={VideoMode.Edit}
              subscriptionMode={null}
              onSelected={(keywords) => {
                setKeywords(keywords);
                setValue('keywords', `${keywords}`);
              }}
            />
            <FormHelperText style={{ color: 'red' }}>{errors?.keywords?.message as string}</FormHelperText>
          </FormControl>
        </Box>
        <Box display="flex" justifyContent="end" mt={5}>
          <LoadingButton size="medium" type="submit" variant="contained" loading={response.isLoading} onClick={handleSubmit(editPackage)}>
            {response.isLoading ? <CircularProgress /> : 'Save Changes'}
          </LoadingButton>
        </Box>
      </Card>
    </TransitionWrapper>
  );
};

export default EditPackage;
