import { Box } from "@mui/material";

type Props = {
  children: any;
};

const Center: React.FC<Props> = ({ children }) => {
  return (
    <Box
      mt={2}
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      {children}
    </Box>
  );
};

export default Center;
