import {
  PackageType,
  ActionResult,
  VideoPackage,
  PackageCategory,
} from "@core/types";
import { baseQueryWithReauth } from "./base-slice";
import { ApiError, ServiceResult } from "@core/index";
import { PaginatedList } from "@core/constants/constants";
import { ApiMethods, BaseEndpoints } from "@core/enums/enums";
import { TagDescription, createApi } from "@reduxjs/toolkit/query/react";

const descriptionTags = ["Package"] as readonly TagDescription<"Package">[] &
  string[];

export const packageSlice = createApi({
  tagTypes: descriptionTags,
  baseQuery: baseQueryWithReauth,
  reducerPath: BaseEndpoints.VideoPackage,
  endpoints: (builder) => ({
    getPackageById: builder.query<ServiceResult<VideoPackage, ApiError>, any>({
      query: (args: any) => ({
        url: `/${BaseEndpoints.VideoPackage}/${args.id}`,
      }),
      providesTags: descriptionTags,
    }),
    getPackages: builder.query<
      ServiceResult<PaginatedList<VideoPackage[]>, ApiError>,
      any
    >({
      query: (args) => ({
        params: { ...args },
        url: `/${BaseEndpoints.VideoPackage}`,
      }),
      providesTags: descriptionTags,
    }),
    getPackageCategories: builder.query<
      ServiceResult<PackageCategory[], ApiError>,
      any
    >({
      query: (args) => ({
        url: `/${BaseEndpoints.VideoPackage}/categories`,
      }),
      providesTags: descriptionTags,
    }),
    getCompanyVideoPackages: builder.query<
      ServiceResult<PaginatedList<VideoPackage[]>, ApiError>,
      any
    >({
      query: (args: any) => ({
        url: `/${BaseEndpoints.VideoPackage}/company/${args.id}`,
        params: { ...args },
      }),
      providesTags: descriptionTags,
    }),
    getPackageTypes: builder.query<ServiceResult<PackageType[], ApiError>, any>(
      {
        query: (args) => ({
          url: `/${BaseEndpoints.VideoPackage}/types`,
          params: { ...args },
        }),
        providesTags: descriptionTags,
      }
    ),
    createPackage: builder.mutation({
      query: (payload) => ({
        body: payload,
        method: ApiMethods.POST,
        url: `/${BaseEndpoints.VideoPackage}`,
      }),
      invalidatesTags: descriptionTags,
    }),

    deletePackage: builder.mutation({
      query: (args) => ({
        method: ApiMethods.DELETE,
        url: `/${BaseEndpoints.VideoPackage}/${args.id}`,
      }),
      invalidatesTags: descriptionTags,
    }),
    editPackage: builder.mutation({
      query: (payload) => ({
        body: payload,
        method: ApiMethods.PUT,
        url: `/${BaseEndpoints.VideoPackage}"`,
      }),
      invalidatesTags: descriptionTags,
    }),
    getPackageCategoryById: builder.query<
      ServiceResult<PackageCategory, ApiError>,
      any
    >({
      query: (args: any) => ({
        url: `/${BaseEndpoints.VideoPackage}/${args.id}`,
      }),
      providesTags: descriptionTags,
    }),
    createPackageCategory: builder.mutation({
      query: (payload) => ({
        body: payload,
        method: ApiMethods.POST,
        url: `/${BaseEndpoints.VideoPackage}/categories`,
      }),
      invalidatesTags: descriptionTags,
    }),
    deletePackageCategory: builder.mutation({
      query: (args) => ({
        url: `/${BaseEndpoints.VideoPackage}/categories/${args.id}`,
        method: ApiMethods.DELETE,
      }),
      invalidatesTags: ["Package"],
    }),
    editPackageCategory: builder.mutation({
      query: (payload) => ({
        body: payload,
        method: ApiMethods.PUT,
        url: `/${BaseEndpoints.VideoPackage}/categories`,
      }),
      invalidatesTags: descriptionTags,
    }),
    assignPackageToCompany: builder.mutation<
      ServiceResult<ActionResult, ApiError>,
      any
    >({
      query: (payload) => ({
        body: payload,
        method: ApiMethods.POST,
        url: `/${BaseEndpoints.VideoPackage}/access/assign`,
      }),
      invalidatesTags: descriptionTags,
    }),

    revokePackageAccess: builder.mutation<
      ServiceResult<ActionResult, ApiError>,
      any
    >({
      query: (payload) => ({
        body: payload,
        method: ApiMethods.POST,
        url: `/${BaseEndpoints.VideoPackage}/access/revoke`,
      }),
      invalidatesTags: descriptionTags,
    }),
  }),
});
export const {
  useGetPackagesQuery,
  useEditPackageMutation,
  useGetPackageByIdQuery,
  useGetPackageTypesQuery,
  useCreatePackageMutation,
  useDeletePackageMutation,
  useGetPackageCategoriesQuery,
  useRevokePackageAccessMutation,
  useEditPackageCategoryMutation,
  useGetPackageCategoryByIdQuery,
  useGetCompanyVideoPackagesQuery,
  useCreatePackageCategoryMutation,
  useDeletePackageCategoryMutation,
  useAssignPackageToCompanyMutation,
} = packageSlice;
