import 'video-react/dist/video-react.css';
import { useEffect, useState } from 'react';
import { BigPlayButton, ControlBar, LoadingSpinner, Player, PlayToggle } from 'video-react';

type Props = {
  src: string;
  startTime?: any;
  onChange: (player: any) => void;
  onPlayerChange: (player: any) => void;
};

export function VideoPlayer({ src, onChange, onPlayerChange, startTime = undefined }: Props) {
  const [player, setPlayer] = useState<any>(undefined);
  const [playerState, setPlayerState] = useState<any>(undefined);

  useEffect(() => {
    if (playerState) {
      onChange(playerState);
    }
  }, [playerState]);

  useEffect(() => {
    onPlayerChange(player);

    if (player) {
      player.subscribeToStateChange(setPlayerState);
    }
  }, [player]);

  return (
    <Player
      fluid={false}
      height={600}
      width={window.innerWidth / 2}
      ref={(player: any) => {
        setPlayer(player);
      }}
      startTime={startTime}
    >
      <source style={{ borderRadius: 20 }} src={src} />
      <BigPlayButton position="center" />
      <LoadingSpinner />
      <ControlBar autoHide={false} disableDefaultControls={false}>
        <PlayToggle />
      </ControlBar>
    </Player>
  );
}
