import { baseQuery } from "./base-slice";
import { AppNotification } from "@core/types";
import { BaseEndpoints } from "@core/enums/enums";
import { ApiError, ServiceResult } from "@core/index";
import { PaginatedList } from "@core/constants/constants";
import { TagDescription, createApi } from "@reduxjs/toolkit/query/react";

const descriptionTags = [
  "Notifcation"
] as readonly TagDescription<"Notifcation">[] & string[];

export const notificationSlice = createApi({
  baseQuery: baseQuery,
  tagTypes: descriptionTags,
  reducerPath: BaseEndpoints.Notification,
  endpoints: (builder) => ({
    getNotifications: builder.query<
      ServiceResult<PaginatedList<AppNotification[]>, ApiError>,
      any
    >({
      query: (args) => ({
        url: `/${BaseEndpoints.Notification}/admin/${args.id}`,
      }),
    }),
  }),
});
export const { useGetNotificationsQuery } = notificationSlice;
