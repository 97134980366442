import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import LocationService from '@core/services/LocationService';
import { StyledButton } from '@components/CustomSelect/CustomSelect';
import RenderDelegate from '@components/RenderDelegate/RenderDelegate';
import { Select, MenuItem, FormHelperText, FormControl, SelectChangeEvent, CircularProgress } from '@mui/material';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useLazyGetCountriesQuery, useLazyGetProvincesByCountryQuery } from '@features/state/slices/api/service-slice';
import { Country } from '@core/types';

const selectUseStyles = makeStyles((theme: any) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  inputLabelRoot: {
    color: 'white',
  },
}));

type Props = {
  name: any;
  label: any;
  error?: any;
  style?: any;
  country: any;
  classes?: any;
  countryISO?: any;
  helperText?: any;
  placeholder?: any;
  onFocus?: (e: any) => void;
  onChange: (country: any, provinces: any) => void;
  setCurrentStates: (states: any) => void;
};

const CountrySelect: React.FC<Props> = ({
  name,
  error,
  label,
  onFocus,
  onChange,
  country,
  countryISO,
  helperText,
  placeholder,
  setCurrentStates,
}: Props) => {
  const selectClasses = selectUseStyles();

  const [isLoading, setLoading] = useState(false);

  const [countries, setCountries] = useState([] as any);
  const [getCountriesQuery] = useLazyGetCountriesQuery();
  const [getProvincesQuery] = useLazyGetProvincesByCountryQuery();

  useEffect(() => {
    getCountries();
  }, [country]);

  const getCountries = async () => {
    try {
      let countries: any = [];
      let provinces: any = [];

      const result = await getCountriesQuery({}).unwrap();

      if (!result.success) {
        return;
      }

      countries = result.data;

      const index = countries.findIndex((countryInstance: Country) => countryInstance.iso2 === country);

      const provinceResult = await getProvincesQuery({ iso: countries[index].iso2 }).unwrap();

      if (!provinceResult.success) {
        return;
      }

      provinces = provinceResult.data;

      setCountries(countries);
      setCurrentStates(provinces);
    } catch (err) {
      // console.log(err);
    }
  };

  const handleCountryChange = async (e: SelectChangeEvent<any>) => {
    const provinces = await LocationService.getProvinces(e.target.value);

    onChange(e.target.value, provinces.data);
  };

  return (
    <FormControl className={`${selectClasses.formControl} country-select__container`}>
      <RenderDelegate
        condition={countries?.length > 0}
        renderComponent={
          <Select error={error} value={country} className="country-select__base" onChange={handleCountryChange}>
            {countries?.map((c: any) => (
              <MenuItem key={c.iso2} value={c.iso2}>
                {c.name}
              </MenuItem>
            ))}
          </Select>
        }
        fallBackComponent={<StyledButton />}
      />
      <div className="login__password-icon">{isLoading ? <CircularProgress size={15} /> : null}</div>
      <FormHelperText sx={{ color: 'text.error' }}>{helperText}</FormHelperText>
    </FormControl>
  );
};

export default CountrySelect;
