import { Video } from '@core/types';
import { useEffect, useState } from 'react';
import 'video-player-for-react/dist/index.css';
import VideoPlayer from '@components/VideoPlayer';
import { Shimmer, Breathing } from 'react-shimmer';
import { useNavigate, useParams } from 'react-router-dom';
import Blinking from '../../../../../assets/images/eye.gif';
import { Card, Box, Typography, InputLabel, Button, Grid, Divider } from '@mui/material';
import { useGetVideoByIdQuery, useGetVideoRecommendationQuery } from '@features/state/slices/api/video-slice';
import moment from 'moment';
import AppChip from '@components/AppChip';
import { toCurrency } from '@core/utils/utils';
import { VideoItem } from '../VideoListing/VideoItem';
import { BackButton } from '@components/BackButton';
import ScoreIcon from '@mui/icons-material/Score';
import Carousel from 'react-material-ui-carousel';
import StatusComingSoon from '@components/ComingSoon';

type Props = {};

export const VideoDetails: React.FC<Props> = ({}) => {
  const { id } = useParams();
  const [videoRecommendations, setVideoRecommendations] = useState<Video[]>([]);
  const [isVideoReady,setIsVideoReady] = useState(false)

  const { data, error, isError, isFetching, isLoading, isSuccess, refetch } = useGetVideoByIdQuery(
    {
      id,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const videoRecommendation = useGetVideoRecommendationQuery(
    {
      id,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const [video, setVideo] = useState<Video>({} as any);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isFetching && !isError && isSuccess) {
      setVideo(data.data!);
    }
  }, [isFetching, isSuccess, isError]);

  useEffect(() => {
    if (!videoRecommendation.isFetching && !videoRecommendation.isError && videoRecommendation.isSuccess) {
      setVideoRecommendations(videoRecommendation.data.data!);
    }
  }, [videoRecommendation.isFetching, videoRecommendation.isSuccess, videoRecommendation.isError]);

  return (
    <>
      <div id="wrapper">
        <div id="content-wrapper">
          <div className="container-fluid pb-0">
            <div className="video-block section-padding">
              <div className="row">
                <div className="" style={{ paddingLeft: 0 }}>
                  <div className="single-video-left">
                    <Card sx={{ p: 2 }}>
                      <Box>
                        <Box display="flex" justifyContent="space-between">
                          <Box display="flex" justifyContent="center" justifyItems="center" alignItems="center">
                            <BackButton />
                            <Typography color={'#0066cc'} variant="h2">
                              {video.videoName}
                            </Typography>
                          </Box>
                          <Box p={2} display="flex">
                            <Button
                              onClick={() => {
                                navigate(`preview`);
                              }}
                              variant={'outlined'}
                              sx={{ marginRight: 2 }}
                            >
                              Preview
                            </Button>
                            <Button color="primary" type="button" variant="contained" startIcon={<ScoreIcon />}>
                              <strong> View Analytics </strong>
                            </Button>{' '}
                          </Box>
                        </Box>
                      </Box>

                      <div className="single-video">
                        <Box sx={{ width: '100%' }}>
                          {!isFetching && Object.keys(video).length > 0 && !isError ? (
                           <Box position={"relative"}>
                              <VideoPlayer
                                video={video}
                                videoType={"full"}
                                onReady={(e) =>{setIsVideoReady(true)}}
                              />
{/*                                
                              TODO Uncomment WatermarkCode 
                              {!isFetching && Object.keys(video ?? {}).length > 0 && !isError && isVideoReady &&
                              <Box sx={{
                                  position: 'absolute',
                                  top: 0,
                                  left: 0,
                                  width: '10%',
                                  height: '10%',
                                  backgroundColor: 'rgba(255, 0, 0, 0.5)', // Red box with some transparency
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  color: '#fff' // For any text inside the box
                                }}>
                                 
                              </Box>
                          }
                        */}
                        </Box>
                          ) : (
                            <Shimmer width={800} height={600} />
                          )}
                        </Box>
                      </div>
                      <br></br>
                      <Grid container flexDirection={'row'} justifyContent={'space-between'} width={'30%'}>
                        <b>
                          <p className="mb-0" style={{ display: 'inline' }}>
                            {video.watchCount} views
                          </p>
                          <p style={{ display: 'inline' }}> | </p>
                          <p style={{ display: 'inline' }}>Published on {moment(video.createdAt).format('LL')}</p>
                        </b>
                      </Grid>

                      <hr style={{ color: 'lightgray' }} />

                      <Box sx={{ mt: 2 }}>
                        <Grid container spacing={1}>
                          <Grid item>
                            <h6 style={{ color: '#0066cc' }}>Price:</h6>
                          </Grid>
                          <Grid item>
                            <Typography mb={2} color="text.disabled">
                              {video.price && toCurrency(video.price)}
                            </Typography>
                          </Grid>
                        </Grid>

                        {/* <Grid container spacing={1}>
                          <Grid item>
                            <h6 style={{ color: "#0066cc" }}>Discount:</h6>
                          </Grid>
                          <Grid item>
                            <Typography mb={2} color="text.disabled">
                              {video.discount}%
                            </Typography>
                          </Grid>
                        </Grid> */}

                        <Grid container spacing={1}>
                          <Grid item>
                            <h6 style={{ color: '#0066cc' }}>Description:</h6>
                          </Grid>
                          <Grid item>
                            <Typography mb={2} color="text.disabled">
                              {video.description}
                            </Typography>
                          </Grid>
                        </Grid>

                        <Grid container spacing={1}>
                          <Grid item>
                            <h6 style={{ color: '#0066cc' }}>Tags:</h6>
                          </Grid>
                          <Grid item>
                            <Box display={'flex'}>
                              {video?.keywords?.split(',').map((keyword) => (
                                <Box mr={1}>
                                  {' '}
                                  <AppChip>{keyword}</AppChip>
                                </Box>
                              ))}
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </Card>
                  </div>
                </div>
                <Typography mt={2} variant="h4">
                  Related Videos
                </Typography>
                <Grid mt={2}>
                  <StatusComingSoon />
                  {/* <Carousel >
                    {videoRecommendations.map((video, index) => (
                      <Card sx={{ mr: 2 }} key={index}>
                        <VideoItem
                          video={video}
                          index={index}
                          refetch={refetch}
                        />
                      </Card>
                    ))}
                  </Carousel> */}
                </Grid>
                {/* </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
