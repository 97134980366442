import * as yup from 'yup';

import { Navigator } from '../Navigator';
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { fadeInRight } from 'react-animations';
import { HelperText } from '@components/HelperText';
import FileUpload from 'react-material-file-upload';
import { yupResolver } from '@hookform/resolvers/yup';
import { HelperType, VideoMode } from '@core/enums/enums';
import TransitionWrapper from '@components/TransitionWrapper';
import VideoEditor from '@components/VideoEditor/VideoEditor';
import { Box, FormControlLabel, Switch, Typography } from '@mui/material';
import { CreateVideoDTO, acceptedVideoFormats } from '@core/constants/constants';
import { EditingMode } from '@core/types';

type Props = {
  steps: string[];
  activeStep: number;
  data: CreateVideoDTO;
  videoMode: VideoMode;
  handleBack: () => void;
  handleNext: () => void;
  handleSkip: () => void;
  onCapture: (data: any) => void;
};

const schema = yup.object().shape({
  isPreviewGenerated: yup.boolean().default(false),
  preview: yup
    .mixed()
    .test('preview', 'Preview video is required', (value) => {
      if (value === undefined) {
        return false;
      }
      if (value?.length === 0) return false;
      return true;
    })
    .test('preview', 'The file is too large', (value) => {
      return value && value.size <= 500_000_000;
    }),
});

export const UploadPreviewStep: React.FC<Props> = (props: Props) => {
  const { data, steps, activeStep, videoMode, onCapture, handleBack, handleNext, handleSkip } = props;

  const {
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [files, setFiles] = useState<File[]>([]);
  const [isGeneratingPreview, setIsGeneratingPreview] = useState(false);
  const isPreviewGenerated = watch<string>('isPreviewGenerated');

  useEffect(() => {
    let fileList: File[] = [];

    if (data?.preview) {
      fileList = [data?.preview];
    }
    setFiles(fileList);
    setValue<string>('preview', data?.preview);
    setIsGeneratingPreview(data.isPreviewGenerated ?? false);
    setValue<string>('isPreviewGenerated', data?.isPreviewGenerated);
  }, []);

  const captureDataThenNavigate = (data: any) => {
    onCapture(data);
    handleNext();
  };

  const captureDataThenSkip = (data: any) => {
    onCapture(data);
    handleSkip();
  };

  const captureData = (data: any) => {
    handleNext();
    onCapture(data);
  };

  const onChange = (files: File[]) => {
    setFiles(files);
    setValue<string>('preview', files[0]);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setIsGeneratingPreview(checked);
    setValue<string>('isPreviewGenerated', checked);
  };

  const onReset = () => {
    setValue<string>('preview', null);
  };

  return (
    <TransitionWrapper duration={0.2} animation={fadeInRight}>
      <Box>
        <HelperText title="Upload a video preview" subtitle="We recommend you upload .mp4 files. The maximum video size is 500mb" />

        <Box alignSelf={'flex-end'} justifyContent="flex-end" display="flex">
          <FormControlLabel
            control={<Switch sx={{ m: 1 }} onChange={handleChange} checked={isPreviewGenerated} />}
            label={<Typography variant="caption">Toggle Video Editor</Typography>}
          />
        </Box>

        <br></br>
        {!isGeneratingPreview && (
          <FileUpload
            value={files}
            onChange={onChange}
            accept={acceptedVideoFormats}
            sx={{ borderColor: errors?.preview?.message && 'red' }}
          />
        )}

        {isGeneratingPreview && (
          <VideoEditor
            onReset={onReset}
            video={data.video}
            config={{
              mode: EditingMode.preview,
            }}
            onEdited={(file) => {
              setValue<string>('preview', file);
            }}
          />
        )}

        <br></br>
        {errors?.preview?.message && (
          <HelperText title="Validation Error" type={HelperType.Error} subtitle={errors?.preview?.message as string} />
        )}

        <Navigator
          steps={steps}
          handleBack={handleBack}
          activeStep={activeStep}
          handleSkip={handleSkip}
          handleNext={handleSubmit(captureData)}
        />
      </Box>
    </TransitionWrapper>
  );
};
