import React from 'react';
import Iconify from '@components/iconify';
import { useNavigate } from 'react-router';
import Center from '@components/Center/Center';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import { Box, Button, Grid, Typography, useTheme } from '@mui/material';

type Props = {};

export const NoPackages: React.FC<Props> = () => {
  const navigate = useNavigate();

  const navigateToCreatePackage = () => {
    navigate('create');
  };

  const theme = useTheme();
  return (
    <Box mt={10}>
      <Grid container justifyContent="center">
        <Center>
          <Iconify
            icon="fluent:box-20-filled"
            sx={{
              width: 200,
              height: 200,
              color: theme.colors.primary.dark,
            }}
          />
          <Typography mt={1} sx={{ color: '#909090' }} textAlign="center" variant="subtitle2">
            No packages are currently on the system
          </Typography>

          <Box mt={2}>
            <Button variant="outlined" onClick={navigateToCreatePackage} startIcon={<AddTwoToneIcon fontSize="small" />}>
              Create package
            </Button>
          </Box>
        </Center>
      </Grid>
    </Box>
  );
};
