import React from 'react';
import { Video } from '@core/types';
import toast from 'react-hot-toast';
import { useSnackbar } from 'notistack';
import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAuth } from '@core/utils/utils';
import { useNavigate } from 'react-router';
import { VideoMode } from '@core/enums/enums';
import DoneIcon from '@mui/icons-material/Done';
import { BackButton } from '@components/BackButton';
import YouTubeIcon from '@mui/icons-material/YouTube';
import VideoService from '@core/services/VideoService';
import { CreateVideoDTO } from '@core/constants/constants';
import { VideoTitleStep } from './components/Steps/VideoTitleStep';
import { VideoPriceStep } from './components/Steps/VideoPriceStep';
import { CompletionStep } from './components/Steps/CompletionStep';
import { UploadVideoStep } from './components/Steps/UploadVideoStep';
import { UploadPreviewStep } from './components/Steps/UploadPreviewStep';
import { toggleLoading } from '@features/state/slices/local/loading-slice';
import { UploadThumbnailStep } from './components/Steps/UploadThumbailStep';
import { CategorizeVideoForm } from './components/Steps/CategorizeVideoStep';
import { useUploadVideoMutation } from '@features/state/slices/api/video-slice';
import { Box, Grid, Step, Button, Stepper, StepLabel, Typography, Card, CircularProgress } from '@mui/material';

const steps = [
  'Enter video name',
  'Enter video price',
  'Categorize video',
  'Upload video file',
  'Upload video thumbnail',
  'Upload video preview',
  'Finish',
];
type Props = {
  video: Video | null;
  videoMode: VideoMode;
};
export const UploadVideo: React.FC<Props> = ({ video, videoMode }) => {
  const { user } = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const optionalSteps: number[] = [];
  const [activeStep, setActiveStep] = React.useState(0);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [uploadVideoMutation, result] = useUploadVideoMutation();
  const [skipped, setSkipped] = React.useState(new Set<number>());
  const [data, setData] = useState<CreateVideoDTO>({} as CreateVideoDTO);

  const isStepOptional = (step: number) => {
    return optionalSteps.includes(step);
  };
  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      uploadVideo();
      return;
    }

    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const uploadVideo = async () => {
    dispatch(toggleLoading());

    let key;
    const formData = new FormData();

    const {
      name,
      type,
      video,
      price,
      preview,
      keywords,
      category,
      companyId,
      thumbnail,
      description,
      parentVideoId,
      packageCategoryId,
      parentVideoTimestamp,
      companyIds,
      filters,
    } = data;

    let keywordArr: any[] = [];
    try {
      if (keywords !== null && keywords !== undefined) {
        keywordArr = keywords;
      }

      keywordArr.push(name);

      formData.append('video', video);
      formData.append('price', price);
      formData.append('videoName', name);
      formData.append('preview', preview);
      formData.append('thumbnail', thumbnail);
      formData.append('videoLengthType', type);
      formData.append('videoCategory', category);
      formData.append('description', description);
      formData.append('adminId', String(user?.id!));
      formData.append('companyId', String(companyId));
      formData.append('selectedCompanies', JSON.stringify(companyIds));
      formData.append('keywords', keywordArr?.toString());
      formData.append('parentVideoId', String(parentVideoId));
      formData.append('packageCategoryId', String(packageCategoryId));
      formData.append('parentVideoTimestamp', String(parentVideoTimestamp));
      formData.append('tags', JSON.stringify(filters));
      console.log('A Video was uploaded', formData.get('video'));

      const { success, error } = await VideoService.processVideo(formData);
      // const { success, error } = { success: true, error: undefined };
      if (!success && error) {
        toast.error(error.message!);
        return;
      }
      console.log('company filters ', formData.get('tags'));
      toast.success('Your video was successfully uploaded. Search for it on the homescreen.');
      navigate('/dashboard/videos/standard');
    } catch (error) {
      toast.error(String(error));
    } finally {
      dispatch(toggleLoading());
    }
  };

  const determineStep = () => {
    switch (activeStep) {
      case 0:
        return (
          <VideoTitleStep
            data={data}
            steps={steps}
            handleBack={handleBack}
            handleNext={handleNext}
            activeStep={activeStep}
            handleSkip={handleSkip}
            videoMode={videoMode}
            onCapture={(c) =>
              setData((prevState) => {
                return { ...prevState, ...c };
              })
            }
          />
        );
      case 1:
        return (
          <VideoPriceStep
            data={data}
            steps={steps}
            activeStep={activeStep}
            handleNext={handleNext}
            handleBack={handleBack}
            handleSkip={handleSkip}
            videoMode={videoMode}
            onCapture={(c) =>
              setData((prevState) => {
                return { ...prevState, ...c };
              })
            }
          />
        );
      case 2:
        return (
          <CategorizeVideoForm
            data={data}
            steps={steps}
            activeStep={activeStep}
            handleNext={handleNext}
            handleBack={handleBack}
            handleSkip={handleSkip}
            videoMode={videoMode}
            onCapture={(c) =>
              setData((prevState) => {
                return { ...prevState, ...c };
              })
            }
          />
        );
      case 3:
        return (
          <UploadVideoStep
            data={data}
            steps={steps}
            activeStep={activeStep}
            handleNext={handleNext}
            handleBack={handleBack}
            handleSkip={handleSkip}
            videoMode={videoMode}
            onCapture={(c) =>
              setData((prevState) => {
                return { ...prevState, ...c };
              })
            }
          />
        );

      case 4:
        return (
          <UploadThumbnailStep
            data={data}
            steps={steps}
            activeStep={activeStep}
            handleNext={handleNext}
            handleBack={handleBack}
            handleSkip={handleSkip}
            videoMode={videoMode}
            onCapture={(c) =>
              setData((prevState) => {
                return { ...prevState, ...c };
              })
            }
          />
        );

      case 5:
        return (
          <UploadPreviewStep
            data={data}
            steps={steps}
            activeStep={activeStep}
            handleNext={handleNext}
            handleBack={handleBack}
            handleSkip={handleSkip}
            videoMode={videoMode}
            onCapture={(c) => {
              // console.log(c);
              setData((prevState) => {
                return { ...prevState, ...c };
              });
            }}
          />
        );

      case 6:
        return (
          <CompletionStep
            data={data}
            steps={steps}
            activeStep={activeStep}
            handleNext={handleNext}
            handleBack={handleBack}
            handleSkip={handleSkip}
            videoMode={videoMode}
            onCapture={(c) => {}}
          />
        );
      default:
        return;
    }
  };

  useMemo(() => {
    if (videoMode === VideoMode.Edit && video) {
      const data: CreateVideoDTO = {
        companyId: null,
        video: undefined,
        preview: undefined,
        thumbnail: undefined,
        name: video.videoName,
        price: video.price.toString(),
        description: video.description,
        parentVideoId: video.parentVideoId,
        keywords: video.keywords.split(','),
        discount: video.discount.toString(),
        type: video.videoLengthType.toString(),
        packageCategoryId: video.videoCategory,
        category: video.videoCategory.toString(),
        parentVideoTimestamp: video.parentVideoTimestamp,
        companyIds: [],
        filters: [],
      };
      setData(data);
    }
  }, [videoMode, video]);
  return (
    <Card sx={{ padding: 3 }}>
      <Box sx={{ width: '100%' }}>
        <Box display="flex">
          <BackButton />
          <Typography ml={1} variant="h2">
            {videoMode === VideoMode.Create ? 'Create Standard Video' : 'Edit Standard Video'}
          </Typography>
        </Box>
        <Typography variant="subtitle1" mt={1}>
          {videoMode === VideoMode.Create ? 'Here you can create  and upload videos' : 'Here you can edit and save your video'}
        </Typography>
        <br></br>

        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps: { completed?: boolean } = {};
            const labelProps: {
              optional?: React.ReactNode;
            } = {};
            if (isStepOptional(index)) {
              labelProps.optional = <Typography variant="caption">Optional</Typography>;
            }
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>

        {determineStep()}
      </Box>
    </Card>
  );
};
