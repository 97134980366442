import { SetStateAction, useEffect, useState } from "react";
import { Video, VideoPackage } from "@core/types";
import { useForm } from "react-hook-form";
import { AppInput } from "@components/Styled";
import FileUpload from "react-material-file-upload";
import FormControl from "@mui/material/FormControl";
import { yupResolver } from "@hookform/resolvers/yup";
import { determineBorderColor } from "@core/utils/utils";
import FormHelperText from "@mui/material/FormHelperText";

import {
  Box,
  Grid,
  Card,
  List,
  Button,
  Typography,
  InputLabel,
  ListItemText,
  ListItemButton,
  CircularProgress,
} from "@mui/material";
import { toast } from "react-hot-toast";
import { AppRoutes } from "@core/constants/routes";
import LoadingButton from "@mui/lab/LoadingButton";
import { BackButton } from "@components/BackButton";
import { SearchField } from "@components/SearchBar";
import { Navigate, useNavigate, useParams } from "react-router";
import KeywordSelector from "@components/KeywordSelector";
import { CreatePackageSchema, CreateVideoCategorySchema } from "@core/constants/schemas";
import TransitionWrapper from "@components/TransitionWrapper";
import { acceptedImageFormats, VideoCategory } from "@core/constants/constants";
import {
  useGetPackageByIdQuery,
  useEditPackageMutation,
  useGetPackageCategoriesQuery,
} from "@features/state/slices/api/package-slice";
import { VideoMode } from "@core/enums/enums";
import { useCreateVideoCategoryMutation } from "@features/state/slices/api/video-slice";

type Props = {};

const CreateVideoCategory: React.FC<Props> = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  
  
  const [createVideoCategoryMutation, response] = useCreateVideoCategoryMutation();
  
  const [videoCategory, setVideoCategory] = useState<VideoCategory>({} as any);

  

  const packageCategoriesResponse = useGetPackageCategoriesQuery({
    refetchOnMountOrArgChange: true,
  });
//   const handleListItemClick = (event: any, index: any, video: Video) => {
//     setVideoPreview(video.highResolutionVideoPreview);
//     setSelectedIndex(index);
//   };

  const {
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(CreateVideoCategorySchema),
  });

//   useEffect(() => {
//     if (!isFetching && !isError && isSuccess) {
//       setVideoCategory(data.data!);

//       setValue("categoryName", data.data?.name);
//       setValue("categoryDescription", data.data?.description);
//     }
//   }, [isFetching, isSuccess, isError]);

  const createVideoCategory = async (data: any) => {
    try {
      const {
        id,
        categoryName,
        categoryDescription
      } = data;

      const formData = new FormData();

      formData.append("id", id)
      formData.append("name", categoryName);
      formData.append("description", categoryDescription);
     

      const { error, success } = await createVideoCategoryMutation(formData).unwrap();

      if (!success && error) {
        toast.error(error.message);
        return;
      }

      toast.success("Your package was sucessfully updated");
      //navigate(`/dashboard/packages`);
    } catch (e) {
      toast.error("An error occured");
    }
  };

  return (
    <TransitionWrapper>
      <Card sx={{ p: 3 }}>
        <Box display="flex">
          <BackButton />
          <Typography ml={1} variant="h2">
            Create Video Category
          </Typography>
        </Box>
        <Typography variant="subtitle1" mt={1}>
          Here you can create your video categories.
        </Typography>

        <Grid mt={3} container spacing={2}>
          <Grid item xs={8}>
            <FormControl variant="standard" style={{ width: "100%" }}>
              <InputLabel shrink htmlFor="bootstrap-input">
                Video Category Name
              </InputLabel>
              <AppInput
                fullWidth
                type="text"
                autoComplete="videoCategory"
                style={determineBorderColor(
                  errors?.categoryName?.message as any
                )}
                {...register("categoryName", {
                  required: true,
                  maxLength: 80,
                })}
              />

              <FormHelperText style={{ color: "red" }}>
                {errors?.categoryName?.message as string}
              </FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl variant="standard" style={{ width: "100%" }}>
              <InputLabel shrink htmlFor="bootstrap-input">
                Video Category Description
              </InputLabel>

              <AppInput
                fullWidth
                type="textarea"
                style={determineBorderColor(
                  errors?.categoryDescription?.message as any
                )}
                {...register("categoryDescription", {
                  required: true,
                  maxLength: 80,
                })}
                
              />
            </FormControl>
            <FormHelperText style={{ color: "red" }}>
              {errors?.categoryDescription?.message as string}
            </FormHelperText>
          </Grid>
        </Grid>


        <Box display="flex" justifyContent="end" mt={5}>
          <LoadingButton
            size="medium"
            type="submit"
            variant="contained"
            loading={response.isLoading}
            onClick={handleSubmit(createVideoCategory)}
          >
            {response.isLoading ? <CircularProgress /> : "Create Video Category"}
          </LoadingButton>
        </Box>

      </Card>
    </TransitionWrapper>
  );
};

export default CreateVideoCategory;


