import { baseQueryWithReauth } from './base-slice';
import { ApiError, ServiceResult } from '@core/index';
import { ActionResult, CompanyBaseSubscription } from '@core/types';
import { ApiMethods, BaseEndpoints } from '@core/enums/enums';
import { TagDescription, createApi } from '@reduxjs/toolkit/query/react';
import { EditSubscriptionDTO, PaginatedList, Subscription } from '@core/constants/constants';

const descriptionTags = ['Subscription'] as readonly TagDescription<'Subscription'>[] & string[];

export const subscriptionSlice = createApi({
  tagTypes: descriptionTags,
  reducerPath: BaseEndpoints.Subscription,
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getSubscriptions: builder.query<ServiceResult<PaginatedList<Subscription[]>, ApiError>, any>({
      query: (args: any) => ({
        params: args,
        url: `/${BaseEndpoints.Subscription}/base`,
      }),
      providesTags: descriptionTags,
    }),
    editSubscriptions: builder.mutation<any, EditSubscriptionDTO>({
      query: (payload) => ({
        body: payload,
        method: 'PUT',
        url: `/${BaseEndpoints.Subscription}/base`,
      }),
    }),
    verifySubscriptionVerification: builder.query<ServiceResult<boolean, ApiError>, any>({
      query: () => ({
        url: `/${BaseEndpoints.Subscription}/base/verify`,
      }),
      providesTags: descriptionTags,
    }),
    getCompanyBaseSubscription: builder.query<ServiceResult<PaginatedList<CompanyBaseSubscription[]>, ApiError>, any>({
      query: (args: any) => ({
        url: `/${BaseEndpoints.Subscription}/company/base/${args.id}`,
      }),
      providesTags: descriptionTags,
    }),
    generateSubscriptions: builder.mutation({
      query: (payload) => ({
        body: payload,
        method: ApiMethods.POST,
        url: `/${BaseEndpoints.Subscription}/base/generate`,
      }),
      invalidatesTags: descriptionTags,
    }),
    createBaseSubscription: builder.mutation<ServiceResult<ActionResult, ApiError>, any>({
      query: (payload) => {
        console.log('Payload being sent Create Sub:', payload); // Log the payload
        return {
          body: payload,
          method: ApiMethods.POST,
          url: `/${BaseEndpoints.Subscription}/base`,
        };
      },
      invalidatesTags: descriptionTags,
    }),
    assignCompanyToBaseSubscription: builder.mutation({
      query: (payload) => ({
        body: payload,
        method: ApiMethods.POST,
        url: `/${BaseEndpoints.Subscription}/base/company`,
      }),
      invalidatesTags: descriptionTags,
    }),
    activateCompanyBaseSubscription: builder.mutation({
      query: (args) => ({
        method: ApiMethods.PUT,
        url: `/${BaseEndpoints.Subscription}/base/${args.id}/activate`,
      }),
      invalidatesTags: descriptionTags,
    }),
    cancelCompanyBaseSubscription: builder.mutation({
      query: (payload) => ({
        body: payload,
        method: ApiMethods.POST,
        url: `/${BaseEndpoints.Subscription}/base/company/cancel`,
      }),
      invalidatesTags: descriptionTags,
    }),
    deleteCompanyBaseSubscription: builder.mutation({
      query: (args) => ({
        method: ApiMethods.DELETE,
        url: `/${BaseEndpoints.Subscription}/base/company/${args.id}`,
      }),
      invalidatesTags: descriptionTags,
    }),
  }),
});

export const {
  useGetSubscriptionsQuery,
  useGenerateSubscriptionsMutation,
  useGetCompanyBaseSubscriptionQuery,
  useVerifySubscriptionVerificationQuery,
  useAssignCompanyToBaseSubscriptionMutation,
  useCancelCompanyBaseSubscriptionMutation,
  useDeleteCompanyBaseSubscriptionMutation,
  useActivateCompanyBaseSubscriptionMutation,
  useCreateBaseSubscriptionMutation,
  useEditSubscriptionsMutation,
} = subscriptionSlice;
