import { Box, Step, Card, Stepper, StepLabel, Typography, CircularProgress } from '@mui/material';
import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { useAuth } from '@core/utils/utils';
import { VideoMode } from '@core/enums/enums';
import DoneIcon from '@mui/icons-material/Done';
import { CustomVideo, Video } from '@core/types';
import { BackButton } from '@components/BackButton';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { useLocation, useNavigate } from 'react-router';
import { CompletionStep } from './components/Steps/CompletionStep';
import { Company, CreateVideoDTO } from '@core/constants/constants';
import { UploadVideoStep } from './components/Steps/UploadVideoStep';
import { UploadThumbnailStep } from './components/Steps/UploadThumbnailStep';
import { VideoLabellingStep } from './components/Steps/VideoLabellingStep';
import { CompanySelectionStep } from './components/Steps/CompanySelectionStep';
import { useProcessCustomVideoMutation } from '@features/state/slices/api/custom-video-slice';
import toast from 'react-hot-toast';
import { toggleLoading } from '@features/state/slices/local/loading-slice';
import { useDispatch } from 'react-redux';

type IncludeLogoType = 'true' | 'false';

type Props = {
  customVideo: CustomVideo | null;
  videoMode: VideoMode;
};
export const UploadCustomVideo: React.FC<Props> = ({ customVideo, videoMode }) => {
  const { user } = useAuth();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const optionalSteps: number[] = [];
  const query = new URLSearchParams(location.search);
  const [activeStep, setActiveStep] = React.useState(0);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [skipped, setSkipped] = React.useState(new Set<number>());
  const [data, setData] = useState<CreateVideoDTO>({} as CreateVideoDTO);
  const [selectedCompany, setSelectedCompany] = useState<Company | null>(null);
  const [processCustomVideoMutation, result] = useProcessCustomVideoMutation();

  const [steps, setSteps] = useState([
    {
      label: 'Label custom video',
      component: VideoLabellingStep,
    },
    {
      label: 'Select a company',
      component: CompanySelectionStep,
    },
    {
      label: 'Upload video file',
      component: UploadVideoStep,
    },
    {
      label: 'Upload video thumbnail',
      component: UploadThumbnailStep,
    },
    {
      label: 'Finish',
      component: CompletionStep,
    },
  ]);

  const isStepOptional = (step: number) => {
    return optionalSteps.includes(step);
  };
  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      uploadVideo();

      return;
    }

    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const navigateToReturnUrlOrVideos = () => {
    navigate('/dashboard/videos/custom');
  };

  const uploadVideo = async () => {
    dispatch(toggleLoading());
    try {
      const formData = new FormData();
      const { name, video, companyId, thumbnail, description, includeLogo } = data;

      formData.append('video', video);
      formData.append('videoName', name);
      formData.append('thumbnail', thumbnail);
      formData.append('description', description);
      formData.append('adminId', String(user?.id!));
      formData.append('companyId', String(companyId));
      formData.append('includeLogo', includeLogo ? 'true' : ('false' as IncludeLogoType));

      const { success, error } = await processCustomVideoMutation(formData).unwrap();

      if (!success && error) {
        toast.error(error.message!);
        return;
      }

      navigateToReturnUrlOrVideos();
    } catch (error) {
      toast.error(String(error));
    } finally {
      dispatch(toggleLoading());
    }
  };

  const onCapture = (capturedState: any) => {
    setData((prevState: any) => {
      return { ...prevState, ...capturedState };
    });
  };

  const currentStep = steps[activeStep];

  const StepComponent = currentStep.component;

  return (
    <Card sx={{ padding: 3 }}>
      <Box sx={{ width: '100%' }}>
        <Box display="flex">
          <BackButton />
          <Typography ml={1} variant="h2">
            {videoMode === VideoMode.Create ? 'Create Custom Video' : 'Edit Custom Video'}
          </Typography>
        </Box>
        <Typography variant="subtitle1" mt={1}>
          {videoMode === VideoMode.Create ? 'Here you can create  and upload videos' : 'Here you can edit and save your video'}
        </Typography>
        <br></br>

        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps: { completed?: boolean } = {};
            const labelProps: {
              optional?: React.ReactNode;
            } = {};
            if (isStepOptional(index)) {
              labelProps.optional = <Typography variant="caption">Optional</Typography>;
            }
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step key={label.label} {...stepProps}>
                <StepLabel {...labelProps}>{label.label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>

        <StepComponent
          data={data}
          steps={steps}
          videoMode={videoMode}
          onCapture={onCapture}
          handleBack={handleBack}
          handleNext={handleNext}
          activeStep={activeStep}
          handleSkip={handleSkip}
        />
      </Box>
    </Card>
  );
};
