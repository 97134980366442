import * as yup from 'yup';
import { Navigator } from '../Navigator';
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { AppInput } from '@components/Styled';
import { fadeInRight } from 'react-animations';
import { yupResolver } from '@hookform/resolvers/yup';
import { determineBorderColor } from '@core/utils/utils';
import TransitionWrapper from '@components/TransitionWrapper';
import { Box, InputLabel, Typography, FormControl, FormHelperText } from '@mui/material';

type Props = {
  data: any;
  steps: any[];
  activeStep: number;
  handleBack: () => void;
  handleNext: () => void;
  handleSkip: () => void;
  onCapture: (data: any) => void;
};

const schema = yup.object().shape({
  title: yup.string().required('Title is required'),
  description: yup.string().required('Description is required'),
});

export const EnterDetailsStep: React.FC<Props> = (props: Props) => {
  const { data, steps, activeStep, onCapture, handleBack, handleNext, handleSkip } = props;

  useEffect(() => {
    setValue('title', data.title);
    setValue('description', data.description);
  }, [data]);

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const captureData = (data: any) => {
    handleNext();
    onCapture(data);
  };

  return (
    <TransitionWrapper duration={0.2} animation={fadeInRight}>
      <Box mt={2} mb={5}>
        <Typography variant="subtitle1">Title your video so users can find it easier</Typography>
        <FormControl variant="standard" style={{ width: '100%', marginTop: 15 }}>
          <InputLabel shrink htmlFor="bootstrap-input">
            Title
          </InputLabel>
          <AppInput
            required
            fullWidth
            {...register('title', {
              required: true,
              maxLength: 80,
            })}
            style={determineBorderColor(errors?.title?.message as any)}
          />
          <FormHelperText style={{ color: 'red' }}>{errors?.title?.message as string}</FormHelperText>
        </FormControl>

        <FormControl variant="standard" style={{ width: '100%', marginTop: 15 }}>
          <InputLabel shrink htmlFor="bootstrap-input">
            Description
          </InputLabel>
          <AppInput
            required
            fullWidth
            id="description"
            {...register('description', {
              required: true,
              maxLength: 80,
            })}
            multiline
            rows={4}
            maxRows={3}
            style={determineBorderColor(errors?.description?.message as any)}
          />
          <FormHelperText style={{ color: 'red' }}>{errors?.description?.message as string}</FormHelperText>
        </FormControl>

        <Navigator
          steps={steps}
          handleSkip={handleSkip}
          handleBack={handleBack}
          activeStep={activeStep}
          handleNext={handleSubmit(captureData)}
        />
      </Box>
    </TransitionWrapper>
  );
};
