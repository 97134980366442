import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router';
import { useAuth } from '@core/utils/utils';
import { Query } from '@core/constants/constants';
import AppPageHeader from '@components/PageHeader';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import TransitionWrapper from '@components/TransitionWrapper';
import { CategoriesTable } from './components/CategoriesTable';
import { useGetVideoCategoriesQuery } from '@features/state/slices/api/video-slice';

function VideoCategories() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [query, setQuery] = useState<Query>({
    page: 1,
    limit: 10,
    searchTerm: '',
  });

  const { data, error, isLoading, refetch } = useGetVideoCategoriesQuery(
    {
      limit: query.limit,
      page: query.page,
      searchTerm: query.searchTerm,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  return (
    <TransitionWrapper>
      <Helmet>
        <title>Video Categories</title>
      </Helmet>

      <AppPageHeader
        title="Video Categories"
        subtitle={`${user?.name}, these are your recent video categories`}
        trailing={
          <Button
            variant="contained"
            sx={{ mt: { xs: 2, md: 0 } }}
            onClick={() => {
              navigate(`create`);
            }}
          >
            Create Video Category
          </Button>
        }
      />

      <CategoriesTable data={data} error={error} refetch={refetch} loading={isLoading} onChange={setQuery} />
    </TransitionWrapper>
  );
}

export default VideoCategories;
