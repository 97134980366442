import * as yup from 'yup';

export const AuthenticationSchema = yup.object().shape({
  email: yup.string().email('Email address is invalid').max(255).required('Email is required'),
  password: yup.string().required('Password is required').min(8, 'Must be at least 8 characters'),
});
const requiredText = 'This is a required field';
export const PaymentMethodSchema = yup.object().shape({
  number: yup.string().required(requiredText).matches(/[0-9]/, 'Card number must only contain numeric digits'),

  expMonth: yup
    .string()
    .required(requiredText)
    .matches(/[0-9]/, 'Expiration month must only contain numeric digits')
    .length(2, 'Expiration month must be 2 digits'),

  expYear: yup
    .string()
    .required(requiredText)
    .matches(/[0-9]/, 'Expiration year must only contain numeric digits')
    .length(4, 'Expiration year must be 4 digits'),

  cvc: yup.string().required(requiredText).matches(/[0-9]/, 'CVC must only contain numeric digits').length(3, 'CVC must be 3 digits'),

  name: yup.string().required(requiredText),

  city: yup.string().required(requiredText),

  state: yup.string().required(requiredText),

  country: yup.string().required(requiredText),

  line1: yup.string().required(requiredText),

  line2: yup.string(),

  postalCode: yup.string().required(requiredText),

  phoneNumber: yup.string(),

  email: yup.string().email('Email address is invalid').max(255).required(requiredText),
});

const getCharacterValidationError = (characterType: any) => `Password must include at least one ${characterType} character`;

export const SetPasswordSchema = yup.object().shape({
  oldPassword: yup.string().max(255).required('Current password is required'),
  newPassword: yup
    .string()
    .required('New password is required')
    .min(8, 'Password must have at least 8 characters')
    .matches(/[0-9]/, getCharacterValidationError('digit'))
    .matches(/[a-z]/, getCharacterValidationError('lowercase'))
    .matches(/[A-Z]/, getCharacterValidationError('uppercase'))
    .matches(/[!@#$%^&*(),.?":{}|<>]/, getCharacterValidationError('symbol')),
  confirmPassword: yup
    .string()
    .required('Confim Password is required')
    .oneOf([yup.ref('newPassword'), null], 'Passwords must match'),
});

export const ResetPasswordSchema = yup.object().shape({
  newPassword: yup
    .string()
    .required('New password is required')
    .min(8, 'Password must have at least 8 characters')
    .matches(/[0-9]/, getCharacterValidationError('digit'))
    .matches(/[a-z]/, getCharacterValidationError('lowercase'))
    .matches(/[A-Z]/, getCharacterValidationError('uppercase'))
    .matches(/[!@#$%^&*(),.?":{}|<>]/, getCharacterValidationError('symbol')),

  confirmPassword: yup
    .string()
    .required('Confim Password is required')
    .oneOf([yup.ref('newPassword'), null], 'Passwords must match'),
});

export const ForgotPasswordSchema = yup.object().shape({
  email: yup.string().email('Email address is invalid').max(255).required('Email is required'),
});

export const RegisterSchema = yup.object().shape({
  email: yup.string().email('Email address is invalid').max(255).required('Email is required'),
  companyName: yup.string().max(255).required('Company Name is required'),
  password: yup.string().required('Password is required').min(8, 'Must be at least 8 characters'),

  country: yup.string().required('Country is required'),
  province: yup.string().required('Province is required'),
});

export const VideoSchema = yup.object().shape({
  price: yup.string().required('Price is required'),
  name: yup.string().required('Video Name is required').max(50, '50 characters maximum'),
  /*file: yup.mixed().test("value", "Video is required", (value) => {
    if (value.length === 0) return false;
    return true;
  }),*/
});
export const CreateCompanySchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  email: yup.string().email('Email address is invalid').max(255).required('Email is required'),

  phoneNumber: yup.string().required('Phone number is required'),
  country: yup.string().required('Country is required'),
  state: yup.string().required('State is required'),
  addressLine1: yup.string().required('Address Line 1 is required'),
  addressLine2: yup.string(),
  postalCode: yup.string().required('Postal Code is required'),
  city: yup.string().required('City is required'),
});
export const CreateAdminSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  email: yup.string().email('Email address is invalid').max(255).required('Email is required'),

  phoneNumber: yup.string().required('Phone number is required'),
  role: yup.string().required('Role is required').default('admin'),
  // password: yup
  //   .string()
  //   .required("Password is required")
  //   .min(8, "Must be at least 8 characters"),
});

export const CreateVideoCategorySchema = yup.object().shape({
  categoryName: yup.string().required('Category name is required.'),
  categoryDescription: yup.string().required('Category despcription is required'),
});

export const CreatePackageCategorySchema = yup.object().shape({
  categoryName: yup.string().required('Category name is required.'),
  categoryDescription: yup.string().required('Category despcription is required'),
});

export const CreatePackageSchema = yup.object().shape({
  packageName: yup.string().required('Package Name is required'),
  packageIteration: yup.number().required('Package Iteration is required').typeError('Package Iteration is required'),
  keywords: yup.mixed().test('keywords', 'Keywords are required', (value) => {
    if (value === undefined) {
      return false;
    }
    if (value?.length === 0) return false;
    return true;
  }),
  price: yup.number().required('Price is required').typeError('Price is required'),
  // discount: yup
  //   .number()
  //   .required("Discount is required")
  //   .typeError("Discount is required"),
  purchasableWithCredits: yup.boolean(),
  packageCategoryId: yup.number().required('Package Category is required'),
  videoIds: yup.mixed().test('videoIds', 'At least one video must be added to the package', (value) => {
    if (value === undefined) {
      return false;
    }
    if (value?.length === 0) return false;
    return true;
  }),

  packageDescription: yup.string().required('Package Description is required'),
});

export const EditPackageSchema = yup.object().shape({
  packageName: yup.string().required('Package Name is required'),
  packageIteration: yup.number().required('Package Iteration is required').typeError('Package Iteration is required'),
  videoIds: yup.mixed().test('videoIds', 'At least one video must be added to the package', (value) => {
    if (value === undefined) {
      return false;
    }
    if (value?.length === 0) return false;
    return true;
  }),
  price: yup.number().required('Price is required').typeError('Price is required'),

  purchasableWithCredits: yup.boolean(),
  packageCategoryId: yup.number().required('Package Category is required'),

  packageDescription: yup.string().required('Package Description is required'),
});

export const AdminDetailsEditSchema = yup.object().shape({
  name: yup.string().required('Display Name is required'),
  email: yup.string().email('Email address is invalid').max(255).required('Email is required'),
  phoneNumber: yup.string().required('Phone number is required'),
});

export const UpdateCustomVideoPromotion = yup.object().shape({
  title: yup.string().required('Title is required'),
  description: yup.string().required('Description is required'),
  thumbnailFile: yup.mixed(),
  // .test('thumbnailFile', 'Thumbnail  is required', (value) => {
  //   if (value === undefined) {
  //     return false;
  //   }
  //   if (value?.length === 0) return false;
  //   return true;
  // })
  // .test('thumbnailFile', 'The file is too large', (value) => {
  //   return value && value.size <= 500_000_000;
  // })
  videoFile: yup.mixed(),
  // .test('videoFile', 'Video is required', (value) => {
  //   if (value === undefined) {
  //     return false;
  //   }
  //   if (value?.length === 0) return false;
  //   return true;
  // })
  // .test('videoFile', 'The file is too large', (value) => {
  //   return value && value.size <= 500_000_000;
  // }),
});
