import { AxiosError } from 'axios';
import { BaseService } from './BaseService';
import { getDomain } from '@core/utils/utils';
import { Endpoints } from '@core/constants/endpoint';
import { ApiError, AuthenticationSuccess, ServiceResult } from '..';

const API_URL = getDomain();

class AppService extends BaseService {
  constructor() {
    super({
      baseURL: `${API_URL}`,
    });
  }
  async generateThumbnail(formData: FormData): Promise<ServiceResult<any, ApiError>> {
    let result: ServiceResult<any, ApiError> = {
      success: true,
    };

    try {
      const response = await this.api.post<any>(`service/transcoder/generate-thumbnail`, formData, {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      result.data = response.data;

      return result;
    } catch (error) {
      const { response } = error as AxiosError<any>;

      result.success = false;
      result.error = response?.data?.error ?? 'An error occured';

      return result;
    }
  }

  async trimVideo(formData: FormData): Promise<ServiceResult<any, ApiError>> {
    let result: ServiceResult<any, ApiError> = {
      success: true,
    };

    try {
      const response = await this.api.post<any>(`service/transcoder/trim-video`, formData, {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      result.data = response.data;

      return result;
    } catch (error) {
      const { response } = error as AxiosError<any>;

      result.success = false;
      result.error = response?.data?.error ?? 'An error occured';

      return result;
    }
  }
}

const service = new AppService();

export { service as default, service as AppService };
