import { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useAuth } from '@core/utils/utils';
import { useNavigate } from 'react-router';
import AppPageHeader from '@components/PageHeader';
import { SubscriptionMode } from '@core/enums/enums';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import PageTitleWrapper from '@components/PageTitleWrapper';
import TransitionWrapper from '@components/TransitionWrapper';
import { SubscriptionTable } from './components/SubscriptionTable';
import { useGetSubscriptionsQuery } from '@features/state/slices/api/subscription-slice';
import { SubscriptionDisplay } from './components/SubscriptionDisplay';
import { Subscription } from '@core/types';
import AppTablePagination from '@components/AppTablePagination';

function ManageSubscription({ handleSetSubscription, handleSetSubscriptionMode }: any) {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [query, setQuery] = useState({
    page: 1,
    limit: 10,
  });

  const { data, error, isError, isFetching, isSuccess, refetch } = useGetSubscriptionsQuery(
    {
      page: query.page,
      limit: query.limit,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);

  const navigateToCreateSubscription = () => {
    handleSetSubscription(null);
    handleSetSubscriptionMode(SubscriptionMode.Create);
    navigate('/dashboard/subscriptions/create');
  };

  useEffect(() => {
    if (!isFetching && !isError) {
      setSubscriptions(data?.data?.items ?? []);
    }
  }, [isFetching, isError, data]);

  const handlePaginationChanged = (limit: number, page: number) => {
    setQuery({ limit, page });
  };
  const contentAvailable = isSuccess && data?.data?.items !== null && data!.data!.items!.length > 0 && !isFetching;

  const onEdit = (subscription: Subscription) => {
    handleSetSubscription(subscription);
    handleSetSubscriptionMode(SubscriptionMode.Edit);
    navigate(`edit/${subscription.id}`);
  };
  return (
    <TransitionWrapper>
      <Helmet>
        <title>Manage Subscriptions</title>
      </Helmet>

      <AppPageHeader
        title="Subscriptions"
        subtitle={`${user?.name}, these are your recent subscriptions`}
        trailing={
          <Button
            sx={{ mt: { xs: 2, md: 0 } }}
            variant="contained"
            onClick={navigateToCreateSubscription}
            startIcon={<AddTwoToneIcon fontSize="small" />}
          >
            Create Subscription
          </Button>
        }
      />

      <SubscriptionDisplay
        selectedSubscription={null}
        subscriptions={subscriptions}
        onSelect={(s, p) => {}}
        isAdminView={true}
        onEdit={onEdit}
      />
      <AppTablePagination
        onChange={handlePaginationChanged}
        totalPages={data?.data?.meta.totalPages ?? 1}
        contentAvailable={contentAvailable}
      />
    </TransitionWrapper>
  );
}

export default ManageSubscription;
