import { AxiosError } from 'axios';
import { BaseService } from './BaseService';
import { getDomain } from '@core/utils/utils';
import { ApiError, ServiceResult } from '..';
import { Endpoints } from '@core/constants/endpoint';

const API_URL = getDomain();

class VideoService extends BaseService {
  constructor() {
    super({
      baseURL: `${API_URL}`,
    });
  }
  async getVideos(id: string): Promise<ServiceResult<any, ApiError>> {
    let result: ServiceResult<any, ApiError> = {
      success: false,
    };

    try {
      const response = await this.api.get<any>(`${Endpoints.GetCompanyVideos}/${id}`);

      result.success = true;
      result.data = response.data;

      return result;
    } catch (error) {
      const { response } = error as AxiosError<ApiError>;
      result.error = response?.data ?? {
        //error: "An error occured",
        message: 'An error occured',
      };

      return result;
    }
  }

  async processVideo(data: FormData): Promise<ServiceResult<any, ApiError>> {
    let result: ServiceResult<any, ApiError> = {
      success: false,
    };

    try {
      const response = await this.api.post<any>(
        `${Endpoints.ProcessVideo}`,

        data,

        { headers: { 'Content-Type': 'multipart/form-data' } },
      );

      result.success = true;
      result.data = response.data;

      return result;
    } catch (error) {
      // console.log(error);

      const { response } = error as AxiosError<ApiError>;
      result.error = response?.data ?? {
        //  error: "An error occured",
        message: 'An error occured',
      };

      return result;
    }
  }
}

const service = new VideoService();

export { service as default, service as VideoService };
