import * as yup from 'yup';
import { Navigator } from '../Navigator';
import { useForm } from 'react-hook-form';
import { useEffect, useRef, useState } from 'react';
import { fadeInRight } from 'react-animations';
import { TimeRangeInput } from '../TimeRangeInput';
import { HelperText } from '@components/HelperText';
import { SearchField } from '@components/SearchBar';
import { yupResolver } from '@hookform/resolvers/yup';
import { determineBorderColor } from '@core/utils/utils';
import { VideoMode, VideoTypes } from '@core/enums/enums';
import TransitionWrapper from '@components/TransitionWrapper';
import { Company, CreateVideoDTO, Query, VideoCategory, VideoType } from '@core/constants/constants';
import { Box, Select, MenuItem, InputLabel, FormControl, FormHelperText, Autocomplete, TextField } from '@mui/material';
import { useGetVideoTypesQuery, useGetVideoCategoriesQuery } from '@features/state/slices/api/video-slice';
import { PackageCategorySelect } from '@features/pages/VideoPackageManagement/Package/components/PackageCategorySelect';
import { useGetCompaniesQuery } from '@features/state/slices/api/company-slice';
//NOTE ComapnyID passed to the process video dto is undefined
type Props = {
  data: CreateVideoDTO;
  steps: any;
  activeStep: number;
  handleBack: () => void;
  handleNext: () => void;
  handleSkip: () => void;
  onCapture: (data: any) => void;
  videoMode: VideoMode;
};

const schema = yup.object().shape({
  type: yup.number().required('Type is required'),
  category: yup.number().required('Category is required'),
});

export const CategorizeVideoForm: React.FC<Props> = (props: Props) => {
  const { data, steps, activeStep, videoMode, onCapture, handleBack, handleNext, handleSkip } = props;

  const [type, setType] = useState(1);
  const [category, setCategory] = useState('');
  const [types, setVideoTypes] = useState<VideoType[]>([]);
  const [searchedVideo, setSearchedVideo] = useState<any>(null);
  const [selectedCompany, setSelectedCompany] = useState<Company[]>([]);
  const [parentVideoTimestamp, setTimestamp] = useState<any>(null);
  const [packageCategory, setPackageCategory] = useState<number | null>(null);
  const [categories, setVideoCategories] = useState<VideoCategory[]>([]);
  const [companies, setCompanies] = useState<Company[]>([]);
  const { data: videoTypes, error: typesError, isLoading: typesLoading } = useGetVideoTypesQuery({});

  const { data: videoCategories, error: categoriesError, isLoading: categoriesLoading } = useGetVideoCategoriesQuery({});

  const [query, setQuery] = useState<Query>({
    page: 1,
    limit: 10,
    searchTerm: '',
  });

  const {
    data: comapniesFetched,
    error,
    isLoading,
    refetch,
  } = useGetCompaniesQuery(
    {
      limit: query.limit,
      page: query.page,
      searchTerm: query.searchTerm,
      dump: true,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const {
    watch,
    register,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const watchedType = watch<string>('type');
  const watchedCategory = watch<string>('category');

  useEffect(() => {
    setType(watchedType);
  }, [watchedType]);

  useEffect(() => {
    setCategory(watchedCategory);
  }, [watchedCategory]);

  useEffect(() => {
    if (videoTypes && !typesError && !typesLoading) {
      setVideoTypes(videoTypes.data!);
    }
  }, [typesError, typesLoading]);

  useEffect(() => {
    if (videoCategories && !categoriesError && !categoriesLoading) {
      setVideoCategories(videoCategories.data!);
    }
  }, [categoriesError, categoriesLoading]);

  useEffect(() => {
    if (comapniesFetched && !error && !isLoading) {
      setCompanies(comapniesFetched.data!.items);
    }
  }, [error, isLoading]);

  useEffect(() => {
    setValue<string>('type', data?.type);
    setValue<string>('category', data?.category);

    setType(parseInt(data?.type));
    setCategory(data?.category);
  }, []);

  const captureData = (data: any) => {
    onCapture({
      ...data,
      ...{
        parentVideoTimestamp,
        companyIds: selectedCompany.map((e) => e.id),
        packageCategoryId: packageCategory,
        parentVideoId: searchedVideo?.id ?? null,
      },
    });
    handleNext();
  };

  // useEffect(() => {
  //   console.log('Comapnies Selected', selectedCompany);
  // }, [selectedCompany]);
  return (
    <TransitionWrapper duration={0.2} animation={fadeInRight}>
      <Box mt={2} mb={5}>
        <Box ml={2}>
          <HelperText title="Categorize your video" subtitle="This will help to determine where your video is dispalyed in the app" />
          <br></br>
          <InputLabel className="input-label">Type</InputLabel>
          <FormControl fullWidth variant="outlined">
            <Select
              label="Type"
              value={type}
              className="country-select__base"
              style={determineBorderColor(errors?.type?.message as any)}
              {...register<string>('type', {
                required: true,
                maxLength: 80,
              })}
            >
              {types.map((x) => (
                <MenuItem key={x.id} value={x.id}>
                  {x.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormHelperText style={{ color: 'red' }}>{errors?.type?.message as string}</FormHelperText>
        </Box>

        <Box ml={2} mt={3}>
          <InputLabel className="input-label">Category</InputLabel>
          <FormControl fullWidth variant="outlined">
            <Select
              value={category}
              label="Category"
              className="country-select__base"
              style={determineBorderColor(errors?.category?.message as any)}
              {...register('category', {
                required: true,
                maxLength: 80,
              })}
            >
              {categories.map((x) => (
                <MenuItem key={x.id} value={x.id}>
                  {x.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormHelperText style={{ color: 'red' }}>{errors?.category?.message as string}</FormHelperText>
        </Box>

        <Box ml={2} mt={3}>
          <InputLabel className="input-label">Package Category</InputLabel>

          <PackageCategorySelect
            label="Category"
            name="category"
            placeholder="Category"
            category={packageCategory}
            helperText={errors.packageTypeId?.message}
            error={errors.packageTypeId?.message !== undefined}
            onChange={(category) => {
              setPackageCategory(category);
              setValue('categoryTypeId', category?.id, {
                shouldValidate: true,
              });
            }}
          />
        </Box>
        {type === VideoTypes.CLIP ? (
          <Box ml={2} mt={3}>
            <InputLabel className="input-label" sx={{ marginBottom: 2 }}>
              Assign Video to Company
            </InputLabel>

            <Autocomplete
              multiple
              limitTags={2}
              id="multiple-limit-tags"
              options={companies}
              getOptionLabel={(option) => option.name!}
              onChange={(event, newCompany) => {
                setSelectedCompany(newCompany);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={selectedCompany.length == 0 ? 'All Companies' : 'Companies Assigned'}
                  placeholder="Select a Company"
                />
              )}
            />
          </Box>
        ) : (
          <div></div>
        )}
      </Box>

      {type === VideoTypes.CLIP ? (
        <Box ml={2} mt={4} display="flex" width={500}>
          <Box>
            <InputLabel className="input-label">
              You have selected clip as your video type, you can search for the parent video in the field below
            </InputLabel>
            <SearchField onSelected={setSearchedVideo} width={500} />
          </Box>

          {searchedVideo && <TimeRangeInput onCompleted={setTimestamp} />}
        </Box>
      ) : (
        <div />
      )}

      <Navigator
        steps={steps}
        activeStep={activeStep}
        handleBack={handleBack}
        handleSkip={handleSkip}
        handleNext={handleSubmit(captureData)}
      />
    </TransitionWrapper>
  );
};
