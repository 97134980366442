import { useState, ChangeEvent } from 'react';
import { Helmet } from 'react-helmet-async';
import PageHeader from './components/PageHeader';

import { Container, Tabs, Tab, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

import ActivityTab from './components/Tabs/ActivityTab';
import EditProfileTab from './components/Tabs/EditProfileTab';
import NotificationsTab from './components/NotificationsTab';
import SecurityTab from './components/SecurityTab';
import PageTitleWrapper from '@components/PageTitleWrapper';
import Footer from '@components/Footer';
import TransitionWrapper from '@components/TransitionWrapper';
import { fadeInRight } from 'react-animations';
import AppPageHeader from '@components/PageHeader';
import { useAuth } from '@core/utils/utils';
import ThemeTab from './components/Tabs/ThemeTab';

const TabsWrapper = styled(Tabs)(
  () => `
    .MuiTabs-scrollableX {
      overflow-x: auto !important;
    }
`,
);

function ManageAccount() {
  const { user } = useAuth();
  const [currentTab, setCurrentTab] = useState<string>('edit_profile');

  const tabs = [
    { value: 'edit_profile', label: 'Edit Profile' },
    { value: 'security', label: 'Passwords/Security' },
    //{ value: 'theme', label: 'Theme' },
  ];

  const handleTabsChange = (event: ChangeEvent<{}>, value: string): void => {
    setCurrentTab(value);
  };

  return (
    <TransitionWrapper duration={0.3} animation={fadeInRight}>
      <Helmet>
        <title>Sales Assist - Settings</title>
      </Helmet>

      <AppPageHeader title="User Settings" subtitle={`${user?.name}, these are your recent company accounts`} />

      <Grid container direction="row" justifyContent="center" alignItems="stretch" spacing={3}>
        <Grid item xs={12}>
          <TabsWrapper
            onChange={handleTabsChange}
            value={currentTab}
            variant="scrollable"
            scrollButtons="auto"
            textColor="primary"
            indicatorColor="primary"
          >
            {tabs.map((tab) => (
              <Tab key={tab.value} label={tab.label} value={tab.value} />
            ))}
          </TabsWrapper>
        </Grid>
        <Grid item xs={12}>
          {currentTab === 'edit_profile' && <EditProfileTab />}
          {currentTab === 'security' && <SecurityTab />}
          {currentTab === 'theme' && <ThemeTab />}
        </Grid>
      </Grid>
    </TransitionWrapper>
  );
}

export default ManageAccount;
