import React, { useState } from "react";
import { Box, InputLabel } from "@mui/material";
import { AppInput } from "@components/Styled";
//import TimeRangeSlider from "react-time-range-slider";

type Props = {
  onCompleted: (value: string) => void;
};

export const TimeRangeInput: React.FC<Props> = (props: Props) => {
  const { onCompleted } = props;

  const [value, setValue] = useState({
    start: "00:00",
    end: "20:00",
  });
  const [timestamp, setTimestamp] = useState(value.start);

  const changeStartHandler = (time: typeof value) => {};

  const timeChangeHandler = (time: typeof value) => {
    setValue(time);
    setTimestamp(time.start);
  };

  const changeCompleteHandler = (time: typeof value) => {
    onCompleted(time.start);
  };

  return (
    <Box ml={8} mt={0}>
      <Box>
        <InputLabel className="input-label">
          Enter the timestamp for the parent video
        </InputLabel>

        <AppInput
          value={timestamp}
          style={{ marginTop: 10 }}
          onChange={(e) => setTimestamp(e.target.value)}
        />
      </Box>
      <Box mt={1}>
        {/* <TimeRangeSlider
          step={15}
          format={24}
          value={value}
          disabled={false}
          maxValue={"23:59"}
          minValue={"00:00"}
          name={"time_range"}
          onChange={timeChangeHandler}
          onChangeStart={changeStartHandler}
          onChangeComplete={changeCompleteHandler}
        /> */}
      </Box>
    </Box>
  );
};
