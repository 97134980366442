import { baseQueryWithReauth } from './base-slice';
import { ApiError, ServiceResult } from '@core/index';
import { ApiMethods, BaseEndpoints } from '@core/enums/enums';
import { ActionResult, UpdatePhotoResult } from '@core/types';
import { Admin, PaginatedList } from '@core/constants/constants';
import { TagDescription, createApi } from '@reduxjs/toolkit/query/react';

const descriptionTags = ['Admin'] as readonly TagDescription<'Admin'>[] & string[];

export const adminSlice = createApi({
  tagTypes: descriptionTags,
  baseQuery: baseQueryWithReauth,
  reducerPath: BaseEndpoints.Admin,
  endpoints: (builder) => ({
    getAdmins: builder.query<ServiceResult<PaginatedList<Admin[]>, ApiError>, any>({
      query: (args) => ({
        params: { ...args },
        url: `/${BaseEndpoints.Admin}`,
      }),
      providesTags: descriptionTags,
    }),
    createAdmin: builder.mutation({
      query: (payload) => ({
        body: payload,
        method: ApiMethods.POST,
        url: `/${BaseEndpoints.Admin}`,
      }),
      invalidatesTags: descriptionTags,
    }),
    updateProfilePhoto: builder.mutation<ServiceResult<UpdatePhotoResult, ApiError>, any>({
      query: (payload) => ({
        body: payload,
        method: ApiMethods.POST,
        url: `/${BaseEndpoints.Admin}/photo`,
      }),
      invalidatesTags: descriptionTags,
    }),
    deleteAdmin: builder.mutation<ServiceResult<ActionResult, ApiError>, any>({
      query: (args) => ({
        method: ApiMethods.DELETE,
        url: `/${BaseEndpoints.Admin}/${args.id}`,
      }),
      invalidatesTags: descriptionTags,
    }),
    editAdmin: builder.mutation<ServiceResult<ActionResult, ApiError>, any>({
      query: (payload) => ({
        body: payload,
        method: ApiMethods.PUT,
        url: `/${BaseEndpoints.Admin}`,
      }),
      invalidatesTags: descriptionTags,
    }),
    editAdminUser: builder.mutation<ServiceResult<ActionResult, ApiError>, any>({
      query: (payload) => ({
        body: payload,
        method: ApiMethods.PUT,
        url: `/${BaseEndpoints.Admin}/account`,
      }),
      invalidatesTags: descriptionTags,
    }),
    getAdminById: builder.query<ServiceResult<Admin, ApiError>, any>({
      query: (args) => ({
        url: `/${BaseEndpoints.Admin}/${args.id}`,
      }),
    }),
    resendCompanyInvite: builder.mutation<ServiceResult<ActionResult, ApiError>, any>({
      query: (body) => ({
        body,
        method: ApiMethods.POST,
        url: `/${BaseEndpoints.Admin}/resend-company-invite`,
      }),
    }),
    recoverPassword: builder.mutation<ServiceResult<ActionResult, ApiError>, any>({
      query: (body) => ({
        body,
        method: ApiMethods.POST,
        url: `/${BaseEndpoints.Admin}/forgot-password`,
      }),
    }),
    verifyCode: builder.mutation<ServiceResult<{ token: string }, ApiError>, any>({
      query: (body) => ({
        body,
        method: ApiMethods.POST,
        url: `/${BaseEndpoints.Admin}/forgot-password/verify-code`,
      }),
    }),
    resetPassword: builder.mutation<ServiceResult<ActionResult, ApiError>, any>({
      query: (body) => ({
        body,
        method: ApiMethods.POST,
        url: `/${BaseEndpoints.Admin}/forgot-password/reset`,
      }),
    }),
   
  }),
});
export const {
  useGetAdminsQuery,
  useGetAdminByIdQuery,
  useEditAdminMutation,
  useVerifyCodeMutation,
  useCreateAdminMutation,
  useDeleteAdminMutation,
  useEditAdminUserMutation,
  useResetPasswordMutation,
  useRecoverPasswordMutation,
  useUpdateProfilePhotoMutation,
  useResendCompanyInviteMutation,
} = adminSlice;
