import { Box } from '@mui/material';
import { Doughnut } from 'react-chartjs-2';
import ChartistTooltip from 'chartist-plugin-tooltips-updated';

export const BarChart = (props: any) => {
  const { labels = [], series = [], chartClassName = 'ct-golden-section', data } = props;

  const options = {};

  const plugins = [ChartistTooltip()];

  return (
    <Box height={30} width={30}>
      <Doughnut
        height={30}
        width={30}
        data={data}
        options={{
          maintainAspectRatio: true,
          plugins: {
            legend: {
              display: false,
            },
          },
        }}
      />
    </Box>
  );
};

export const CircleChart = (props: any) => {
  const data = {
    labels: ['', '', '', '', '', ''],
    datasets: [
      {
        label: '',
        data: props.data.map((p: any) => p.value),
        backgroundColor: props.data.map((p: any) => p.color),
      },
    ],
  };

  return (
    <Box height={120} width={120}>
      <Doughnut
        width={120}
        data={data}
        height={120}
        options={{
          maintainAspectRatio: true,
          plugins: {
            legend: {
              display: false,
            },
          },
        }}
      />
    </Box>
  );
};
