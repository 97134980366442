import { useNavigate, useParams } from 'react-router';

import {
  Card,
  useTheme,
  Typography,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Slide,
  FormControlLabel,
  Switch,
  Divider,
} from '@mui/material';
import TransitionWrapper from '@components/TransitionWrapper';
import { PackageSearchField } from '@components/PackageSearchBar';

import { useAuth } from '@core/utils/utils';
import { Box, Button, List, ListItem, Radio, RadioGroup } from '@mui/material';
import { useGetPaymentMethodsQuery } from '@features/state/slices/api/payment-methods.slice';

import { MDBRow, MDBCol, MDBBtn, MDBCard, MDBIcon, MDBInput, MDBCardBody, MDBContainer } from 'mdb-react-ui-kit';
import { Shimmer } from 'react-shimmer';
import AddIcon from '@mui/icons-material/Add';
import VisaCard from '@assets/images/visa-card.jpg';
import MasterCard from '@assets/images/master-card.jpg';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import {
  useActivatePackageSubscriptionMutation,
  useCreatePackageSubscriptionAccessMutation,
  useCreatePackageSubscriptionMutation,
} from '@features/state/slices/api/package-subscriptions-slice';

import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { VideoPackage } from '@core/types';
import Center from '@components/Center/Center';
import { BackButton } from '@components/BackButton';
import { useStripe } from '@stripe/react-stripe-js';
import { toggleLoading } from '@features/state/slices/local/loading-slice';
import { useGetCompanyByIdQuery } from '@features/state/slices/api/company-slice';
import { useAssignPackageToCompanyMutation } from '@features/state/slices/api/package-slice';
import MessageDisplay, { IMessageDisplayRef } from '@components/MessageDIsplay/MessageDisplay';
import PaymentMethodsForm from '../ManageCompanySubscriptionCard/ManageCompanySubscriptions/components/Steps/PaymentMethodForm';

type Props = {};

const Transition = React.forwardRef(function Transition(props, ref) {
  const SlideComponent = Slide as any;
  return <SlideComponent direction="up" ref={ref} {...props} />;
});

const CreateCompanyPackageSubscription: React.FC<Props> = () => {
  const theme = useTheme();
  const { id } = useParams();
  const { user } = useAuth();
  const stripe = useStripe();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [width, setWidth] = useState(900);
  const messageRef = useRef<IMessageDisplayRef>();
  const [company, setCompany] = useState<any>(null);
  const [paymentDialog, setPaymentDialog] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState<any>(null);
  const [assignPackageMutation] = useAssignPackageToCompanyMutation();
  const [createSubscription] = useCreatePackageSubscriptionMutation();
  const [activateSubscription] = useActivatePackageSubscriptionMutation();
  const [subscriptionActivated, setSubscriptionActivated] = useState(false);
  const [createPackageAccess] = useCreatePackageSubscriptionAccessMutation();
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState({} as any);

  const { data, isError, isFetching, isSuccess } = useGetCompanyByIdQuery({
    id,
  });

  const { data: paymentMethods, isFetching: paymentMethodsLoading } = useGetPaymentMethodsQuery(id, {
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (!isError && !isFetching && isSuccess) {
      setCompany(data.data);
    }
  }, [isError, isFetching, isSuccess]);

  const toggleDialog = () => {
    setPaymentDialog(!paymentDialog);
  };

  const node = useCallback((node: any) => {
    if (node !== null) {
      setWidth(node.getBoundingClientRect().width);
    }
  }, []);

  const assignPackage = async () => {
    dispatch(toggleLoading());

    try {
      const result = await assignPackageMutation({
        companyId: id,
        packageId: selectedPackage.id,
      }).unwrap();

      if (!result.success || result?.error) {
        messageRef.current?.showError('An error occured');
        return;
      }

      messageRef.current?.showSuccess('Changes saved');
      navigate(-1);
    } catch (err: any) {
      messageRef.current?.showError(err.toString());
    } finally {
      dispatch(toggleLoading());
    }
  };

  const subscribeToPackage = async () => {
    if (Object.keys(selectedPaymentMethod).length == 0) {
      toast.error('A payment method is required to subscribe to this package');
      return;
    }
    dispatch(toggleLoading());

    try {
      const result = await createSubscription({
        companyId: id,
        packageId: selectedPackage.id,
        stripeCustomerId: company?.stripeCustomerId,
        paymentMethodId: selectedPaymentMethod?.id,
      }).unwrap();

      if (result.success && !result?.data?.actionRequired) {
        toast.success('Your subscription is active');
        return;
      }

      if (result?.data?.actionRequired && result?.data?.action == 'REQUIRES_3DS') {
        handle3DSSubscription(result?.data);
      }
    } catch (e: any) {
      toast.error('An error occured subscribing to this package');
    } finally {
      dispatch(toggleLoading());
    }
  };

  const handle3DSSubscription = async (data: any) => {
    try {
      const response = await stripe?.confirmCardPayment(data.data.clientSecret!);

      if (response?.error) {
        throw new Error();
      }

      const activationResult = await activateSubscription({
        id: data.data.subscriptionId,
      }).unwrap();

      if (!activationResult.success) {
        throw new Error();
      }

      const result = await createPackageAccess({
        id: data.data.subscriptionId,
      }).unwrap();

      if (!result.success) {
        toast.error('An error occured while activating subscription.');
        return;
      }

      toast.success('Your subscription is active');
      navigate(-1);
    } catch (e: any) {
      toast.error('An error occured while activating subscription.');
    }
  };

  return (
    <TransitionWrapper>
      <Card sx={{ p: 3, mt: 3 }}>
        <br></br>
        <Box display="flex" justifyContent="space-between" alignItems="start">
          <Box>
            <Box display="flex">
              <BackButton />
              <Typography ml={1} variant="h2">
                Add Package
              </Typography>
            </Box>

            <Typography variant="subtitle1" mt={1}>
              Subscribe a company to one of your packages
            </Typography>
          </Box>
        </Box>
        <PackageSearchField
          multiple={false}
          onSelected={(videoPackage: VideoPackage) => {
            setSelectedPackage(videoPackage);
          }}
        />
        <Box>
          {selectedPackage && (
            <MDBContainer fluid className="py-5" ref={node}>
              <MDBRow className="d-flex justify-content-center">
                <MDBCol md="12" lg="12" xl="12">
                  <MDBCard style={{ backgroundColor: '#F9F9F9' }}>
                    <MDBCardBody className="p-md-5">
                      <div>
                        <h4>Subscribe to this package</h4>
                        <p className="text-muted pb-2">Please make the payment to start utlizing this package as soon as possible</p>
                      </div>
                      <div className="px-3 py-4 border border-primary border-2 rounded mt-4 d-flex justify-content-between">
                        <div className="d-flex flex-row align-items-center">
                          <img crossOrigin="anonymous" src="https://i.imgur.com/S17BrTx.webp" className="rounded" width="60" />
                          <div className="d-flex flex-column ms-4">
                            <span className="h5 mb-1">{selectedPackage?.packageName}</span>
                            <span className="small text-muted">{selectedPackage?.packageDescription}</span>
                          </div>
                        </div>
                        <div className="d-flex flex-row align-items-center">
                          <sup className="dollar font-weight-bold text-muted">$</sup>
                          <span className="h2 mx-1 mb-0">{selectedPackage?.price}</span>
                          <span className="text-muted font-weight-bold mt-2">/ {selectedPackage?.interval ?? 'month'}</span>
                        </div>
                      </div>
                      <h4 className="mt-5">Payment details</h4>

                      <RadioGroup aria-label="Your plan" name="people" defaultValue="Individual">
                        {paymentMethodsLoading ? (
                          <Box mb={5}>
                            {' '}
                            <Shimmer width={width} height={50} />
                          </Box>
                        ) : (
                          <Box mt={2}>
                            {paymentMethods?.data?.map((item: any, index: number) => (
                              <Box display="flex">
                                <Radio
                                  value={item}
                                  checked={selectedPaymentMethod?.id === item.id}
                                  onClick={() => setSelectedPaymentMethod(item)}
                                  sx={{}}
                                />

                                <div className="">
                                  <div className="d-flex flex-row align-items-center">
                                    <img
                                      crossOrigin="anonymous"
                                      width="70"
                                      className="rounded"
                                      src={item?.card?.brand === 'visa' ? VisaCard : MasterCard}
                                    />
                                    <div className="d-flex flex-column ms-3">
                                      <span className="h5 mb-1">{item?.card?.brand.toUpperCase()}</span>
                                      <span className="small text-muted">ending in **** {item?.card?.last4}</span>
                                    </div>
                                  </div>
                                </div>
                              </Box>
                            ))}
                          </Box>
                        )}

                        {paymentMethods?.data?.length == 0 && (
                          <Center>
                            <br></br>
                            <Center>
                              <Typography variant="subtitle1" mt={1} mb={1}>
                                This company has no available payment methods
                              </Typography>
                              <Button onClick={toggleDialog} variant="contained" startIcon={<AddIcon fontSize="small" />}>
                                Add Card
                              </Button>
                            </Center>
                          </Center>
                        )}
                      </RadioGroup>
                      <Box mt={3}></Box>
                      <Box style={{ float: 'right' }}>
                        <Button variant="outlined" className="mt-3" onClick={subscribeToPackage}>
                          Subscribe
                          <MDBIcon fas icon="long-arrow-alt-right" />
                        </Button>
                      </Box>
                    </MDBCardBody>
                  </MDBCard>
                </MDBCol>
              </MDBRow>
              <Center>
                <Divider textAlign="center">
                  <Typography variant="button" align="center" style={{ fontSize: 11 }} gutterBottom>
                    OR
                  </Typography>
                </Divider>
                <br></br>
                <Typography fontSize={12} variant="subtitle1" mt={2}>
                  Assign this package to a user without a subscription
                </Typography>
                <br></br>
                <Button variant="contained" onClick={assignPackage}>
                  Assign Package
                </Button>
              </Center>
            </MDBContainer>
          )}
        </Box>
        <MessageDisplay ref={messageRef as any} />
      </Card>

      <Dialog
        open={paymentDialog}
        onClose={toggleDialog}
        maxWidth="xl"
        TransitionComponent={Transition as any}
        PaperProps={{
          sx: {
            width: '30%',
            height: 800,
            maxWidth: window.innerWidth,
          },
        }}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{''}</DialogTitle>
        <DialogContent>
          <PaymentMethodsForm close={toggleDialog} />
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </TransitionWrapper>
  );
};

export default CreateCompanyPackageSubscription;
