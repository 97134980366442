import { Box, Card, Step, Stepper, StepLabel, Typography } from '@mui/material';
import React from 'react';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { useAuth } from '@core/utils/utils';
import { BackButton } from '@components/BackButton';
import { useStripe } from '@stripe/react-stripe-js';
import { useNavigate, useParams } from 'react-router';
import TransitionWrapper from '@components/TransitionWrapper';
import { CreateSubscriptionDTO } from '@core/constants/constants';
import { CompletionStep } from './components/Steps/CompletionStep';
import { toggleLoading } from '@features/state/slices/local/loading-slice';
import { PaymentInformationForm } from './components/Steps/PaymentInformationForm';
import { SubscriptionSelectionStep } from './components/Steps/SubscriptionSelectionStep';
import {
  useAssignCompanyToBaseSubscriptionMutation,
  useActivateCompanyBaseSubscriptionMutation,
} from '@features/state/slices/api/subscription-slice';
import { CreateCustomSubscription } from './components/Steps/CreateCustomSubscription';
import { SubscriptionData } from '@core/types';

type Props = {};

const CUSTOM_STEP = {
  label: 'Create Custom Subscription',
  component: CreateCustomSubscription,
};

const ManageCompanySubscription: React.FC<Props> = () => {
  const { user } = useAuth();
  const stripe = useStripe();
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const optionalSteps: number[] = [];
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set<number>());
  const [createCompanyBaseSubscription] = useAssignCompanyToBaseSubscriptionMutation();
  const [data, setData] = useState<SubscriptionData>({} as any);
  const [activateCompanyBaseSubscription] = useActivateCompanyBaseSubscriptionMutation();

  const [steps, setSteps] = useState([
    {
      label: 'Select subscription',
      component: SubscriptionSelectionStep,
    },
    {
      label: 'Enter payment information',
      component: PaymentInformationForm,
    },
    {
      label: 'Start subscription',
      component: CompletionStep,
    },
  ]);

  const isStepOptional = (step: number) => {
    return optionalSteps.includes(step);
  };
  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      createSubscription();
      return;
    }
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const createSubscription = async () => {
    dispatch(toggleLoading());

    const request = {
      customSubscription: data.customSubscription,
      subscriptionMetadata: data.subscriptionMetadata,
    };

    try {
      const result = await createCompanyBaseSubscription(request).unwrap();

      if (result?.success && !result?.data?.actionRequired) {
        toast.success('Subscription successfully created');
        navigate(`/dashboard/company-accounts/update/${id}`);
        return;
      }

      const apiResult = result?.data as any;

      if (apiResult?.actionRequired && apiResult?.action == 'REQUIRES_3DS') {
        let response;

        if(apiResult?.offSession) { // if it won't charge right now but later
          response = await stripe?.confirmCardSetup(
            apiResult.data.clientSecret!
          );
        } else {
          response = await stripe?.confirmCardPayment(
            apiResult.data.clientSecret!
          );
        }
        
        if (!response?.error) {
          const activationResult = await activateCompanyBaseSubscription({
            id: apiResult?.data?.subscriptionId,
          }).unwrap();

          if (activationResult?.success) {
            toast.success('Your subscription is active');
            navigate(`/dashboard/company-accounts/update/${id}`);
            return;
          }
        }
      }
    } catch (e: any) {
      toast.error('An error occurred.');
    } finally {
      dispatch(toggleLoading());
    }
  };

  const onCapture = (capturedState: any) => {
    const existingIndex = steps.findIndex((obj) => obj.label === CUSTOM_STEP.label);

    const shouldIncludeCustomStep = capturedState?.subscriptionMetadata?.isCustom && existingIndex === -1;

    let shouldRemoveCustomStep = !data?.subscriptionMetadata?.isCustom && existingIndex === 1;

    const newArray = shouldIncludeCustomStep
      ? [...steps.slice(0, 1), CUSTOM_STEP, ...steps.slice(1)]
      : shouldRemoveCustomStep
      ? [...steps.slice(0, existingIndex), ...steps.slice(existingIndex + 1)]
      : steps;

    setSteps(newArray);

    setData((prevState: any) => {
      return { ...prevState, ...capturedState };
    });
  };

  const currentStep = steps[activeStep];

  const StepComponent = currentStep.component;
  return (
    <TransitionWrapper>
      <Card sx={{ padding: 3 }}>
        <Box sx={{ width: '100% ' }}>
          <Box display={'flex'}>
            <BackButton />
            <Typography ml={1} variant="h2">
              Create Company Subscription
            </Typography>
          </Box>
          <Typography variant="subtitle1" mt={1}>
            {'Here you can create a subscription for ' + user?.name}
          </Typography>
          <br></br>

          <Stepper activeStep={activeStep}>
            {steps.map((step, index) => {
              const stepProps: { completed?: boolean } = {};
              const labelProps: {
                optional?: React.ReactNode;
              } = {};
              if (isStepOptional(index)) {
                labelProps.optional = <Typography variant="caption">Optional</Typography>;
              }
              if (isStepSkipped(index)) {
                stepProps.completed = false;
              }
              return (
                <Step key={step.label} {...stepProps}>
                  <StepLabel {...labelProps}>{step.label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>

          <StepComponent
            data={data}
            steps={steps}
            onCapture={onCapture}
            handleBack={handleBack}
            handleNext={handleNext}
            activeStep={activeStep}
            handleSkip={handleSkip}
          />
        </Box>
      </Card>
    </TransitionWrapper>
  );
};

export default ManageCompanySubscription;
