import React from "react";
import { Box, Button } from "@mui/material";

type Props = {
  steps: number;
  activeStep: number;
  handleBack: () => void;
  handleNext: () => void;
  handleSkip: () => void;
};

export const Navigator: React.FC<Props> = (props: Props) => {
  const { steps, activeStep, handleBack, handleNext, handleSkip } = props;

  const optionalSteps: number[] = [];

  const isStepOptional = (step: number) => {
    return optionalSteps.includes(step);
  };

  return (
    <React.Fragment>
      <Box className="navigator__base">
        <Button
          color="inherit"
          onClick={handleBack}
          disabled={activeStep === 0}
          className="navigator__button"
        >
          Back
        </Button>
        {isStepOptional(activeStep) && (
          <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
            Skip
          </Button>
        )}
        <Button onClick={handleNext}>
          {activeStep === steps - 1 ? "Finish" : "Next"}
        </Button>
      </Box>
    </React.Fragment>
  );
};
