import { Company, CreateVideoDTO, VideoFilter } from '@core/constants/constants';
import { VideoMode, VideoProcessingStatus } from '@core/enums/enums';
import { SnackbarKey } from 'notistack';

export interface AuthenticateCompantDTO {
  email: string;
  password: string;
}
export interface ActionResult {
  action: string;
  message: string;
  actionRequired: boolean;
}
export interface VideoCredit {
  id: number;
  userId: number;
  createdAt?: string;
  updatedAt?: string;
  creditAmount: number;
}
export interface NavItemType {
  title: string;
  path: string;
  info: string;
  icon: JSX.Element;
}
export interface UpdatePhotoResult {
  url: string;
}
export interface AppNotification {
  id: number;
  userId: number;
  message: string;
  createdAt: string;
}
export interface Industry {
  id: number;
  name: string;
  description: string;
}
export interface PackageType {
  id: number;
  name: string;
  description: string;
}

export interface PackageCategory {
  id: number;
  name: string;
  description: string;
}

export interface VideoPackage {
  id: number;
  tag: string;
  price: number;
  discount: number;
  industry: Industry;
  description: string;
  keywords: string;
  packageName: string;
  packageType: any;
  interval: any;
  packageCategory: any;
  packageCategoryId: number;
  videoIds: string[];
  videos: Video[];
  packageLength: number;
  packageThumbail: string;
  packageDescription: string;
  packageIteration: number;
  createdAt?: string;
  updatedAt?: string;
}

export interface SearchResponse {
  items?: Video[] | VideoPackage[];
}

export interface BaseSubscription {
  id: number;

  name: string;

  upFrontBillingCost: string;

  billingMonthlyThreshold: string;

  sixMonthContractCost: string;

  sixMonthContractPriceId: string;

  monthlyContractCost: string;

  monthlyContractPriceId: string;

  pricePlan: string;

  twoYearContractCost: string;

  twoYearContractPriceId: string;

  oneYearContractCost: number;

  oneYearContractPriceId: string;

  pricingType: number;

  usersCovered: number;

  additionalUserCost: string;

  setupCost: string;

  videoPackageDiscount: number;

  subscriptionItemsIncluded: string[];

  productId: string;

  credits: number;

  createdAt: string;

  updatedAt: string;
}

export interface CustomVideo {
  id: number;

  videoJobId?: string;

  videoRetryJson?: string;
  //NOTE videoId Not be used
  videoId: string;

  adminId: number;

  downloadableUrl?: string;

  company?: Company;

  susbcription?: CompanyBaseSubscription;

  companyId: number;

  watchCount: number;

  videoName: string;

  videoUrl?: string;

  videoLength?: number;

  canUserAccess?: boolean;

  videoThumbnail?: string;

  description: string;

  transcodingErrorMessage?: string;

  ultraLowResolutionVideo?: string;

  lowResolutionVideo?: string;

  lowResolutionThumbnail?: string;

  highResolutionThumbnail?: string;

  highResolutionVideo?: string;

  mediumResolutionVideo?: string;

  status: VideoProcessingStatus;

  createdAt?: string;

  updatedAt?: string;
}

//BUG Duplicated for some reason with core/constants/constants Subscription
export interface Subscription {
  id: number;

  name: string;

  pricePlan: string;

  upFrontBillingCost: string;

  billingMonthlyThreshold: string;

  sixMonthContractCost: string;

  monthlyContractCost: string;
  credits?: number;

  twoYearContractCost?: string;

  oneYearContractCost: string;

  pricingType: string;

  usersCovered: number;

  additionalUserCost: string;

  setupCost: string;

  videoPackageDiscount?: number;

  subscriptionItemsIncluded: string[];

  createdAt: string;

  updatedAt: string;

  hasTrialPeriod?: boolean;
  numberOfDaysInTrialPeriod?: number;
  public: boolean;
  accessCodes?: string[];
  companies: Company[];
}

export interface SubscriptionStatus {
  id: number;
  name: string;
  description?: string | null;
  createdAt?: string;
  updatedAt?: string;
}
export interface CompanyBaseSubscription {
  id: number;

  isActive: boolean;

  stripeSubscriptionId?: string;

  subscriptionAmount: number;

  subscriptionName: string;

  trialPeriod?: number;

  isTrial?: boolean;

  baseSubscription: BaseSubscription;

  baseSubscriptionId: number;

  companyId: number;

  status: number;

  subscriptionStatus: SubscriptionStatus | any;

  period?: string;

  paymentMethod?: string;

  nextBillDate: string;

  billingCount: number;
  createdAt?: string;
  updatedAt?: string;
}

export interface Video {
  id: number;
  tag: string;
  price: number;
  discount: number;
  videoId: number;
  adminId: number;
  watchCount: number;
  keywords: string;
  transcodingErrorMessage?: string;
  parentVideoId?: number;
  packageCategoryId: number;
  parentVideoTimestamp?: number;
  videoName: string;
  videoUrl: string;
  videoLength: number;
  videoPreview: string;
  description: string;
  lowResolutionVideoPreview?: string;
  mediumResolutionVideoPreview?: string;
  highResolutionVideoPreview?: string;
  videoPromoUrl?: string;
  videoThumbnail?: string;
  videoCategory: number;
  videoLengthType: number;
  videoMaskedUrl?: string;
  lowResolutionVideoUrl?: string;
  lowResolutionThumbnail?: string;
  highResolutionThumbnail?: string;
  mediumResolutionThumbnail?: string;
  highResolutionVideoUrl?: string;
  mediumResolutionVideoUrl?: string;
  status: string;
  createdAt?: string;
  updatedAt?: string;
  tags: VideoFilter[];
  selectedCompanies: Company[];
}

export type SnackBarButtonType = {
  snackbarKey: SnackbarKey;
};

export interface FilterType {
  title: string;
  filters: string[];
}

export interface StepProps {
  data: SubscriptionData;
  steps: any;
  activeStep: number;
  handleBack: () => void;
  handleNext: () => void;
  handleSkip: () => void;
  onCapture: (caturedState: any) => void;
}

export interface CustomVideoStepProps {
  steps: any;
  activeStep: number;
  videoMode: VideoMode;
  data: CreateVideoDTO;
  handleBack: () => void;
  handleNext: () => void;
  handleSkip: () => void;
  onCapture: (caturedState: any) => void;
}

export interface SubscriptionData {
  selectedSubscription: Subscription;
  subscriptionMetadata: SubscriptionMetadata;
  customSubscription: Subscription & { isTrial: boolean; hasUpFrontBillingCost: boolean; intervalPrice: string | number };
}

export interface SubscriptionMetadata {
  isCustom: boolean;
  companyId: string;
  priceId: string;
  subscriptionId: number;
  paymentMethodId: string;
}

export enum EditingMode {
  preview = 1,
  thumbnail = 2,
}

export interface VideoEditorConfiguration {
  mode: EditingMode;
}

export interface Country {
  id: number;
  name: string;
  iso2: string;
}

export interface Province {
  id: number;
  name: string;
  countryId: number;
  countryCode: string;
  iso2: string;
  type: string | null;
  latitude: string;
  longitude: string;
}

export interface AccessLog {
  id: number;

  device?: string;

  ipAddress?: string;

  adminId?: string;

  location?: string;

  createdAt?: string;

  updatedAt?: string;
}

export interface CustomVideoBanner {
  id: number;

  title: string;

  description: string;

  videoUrl: string;

  thumbnail: string;

  isVisible: boolean;

  createdAt?: string;

  updatedAt?: string;
}
