import moment from 'moment';
import toast from 'react-hot-toast';
import { LoadingButton } from '@mui/lab';
import { CustomVideo } from '@core/types';
import { useDispatch } from 'react-redux';
import AppChip from '@components/AppChip';
import AddIcon from '@mui/icons-material/Add';
import Center from '@components/Center/Center';
import { useNavigate, useParams } from 'react-router';
import { createRef, useEffect, useState } from 'react';
import { RefreshButton } from '@components/RefreshButton';
import { AppDialog, IDialogRef } from '@components/AppDialog';
import { toggleLoading } from '@features/state/slices/local/loading-slice';
import { Box, Card, Button, useTheme, Typography, CircularProgress, InputAdornment, Tooltip } from '@mui/material';
import MessageDisplay, { IMessageDisplayRef } from '@components/MessageDIsplay/MessageDisplay';
import { useLazyGetCompanyCustomVideosQuery, useRevokeCustomVideoAccessMutation } from '@features/state/slices/api/custom-video-slice';
import { PaginatedList } from '@core/constants/constants';
import { StyledSearch } from '@components/Styled';
import Iconify from '@components/iconify/Iconify';
import Filter from '@components/Filter';
import AppTablePagination from '@components/AppTablePagination';

type Props = {};

const ManageCompanyCustomVideoCard: React.FC<Props> = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id: companyId } = useParams();
  const dialogRef = createRef<IDialogRef>();
  const messageRef = createRef<IMessageDisplayRef>();
  const [revokeDialog, setRevokeDialog] = useState(false);
  const [revokeAccess] = useRevokeCustomVideoAccessMutation();
  const [customVideo, setCustomVideo] = useState<PaginatedList<CustomVideo[]> | null>(null);
  const [getCompanyCustomVideos, response] = useLazyGetCompanyCustomVideosQuery();
  const [videoToRevoke, setVideoToRevoke] = useState<CustomVideo | null>();
  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);
  const [openFilter, setOpenFilter] = useState(false);
  const [limit, setLimit] = useState<number>(10);
  const [query, setQuery] = useState({
    page: 1,
    limit: limit,
    searchTerm: '',
  });
  const [contentAvailable, setContentAvailable] = useState<boolean>(false);

  useEffect(() => {
    getCompanyCustomVideo();
  }, [query]);

  const navigateToCustomVideo = () => {
    navigate(`/dashboard/videos/custom/upload?selectedCompany=${companyId}&returnUrl=/dashboard/company-accounts/update/${companyId}`);
  };

  const getCompanyCustomVideo = async () => {
    const response = await getCompanyCustomVideos({ id: companyId, query: query }).unwrap();
    setCustomVideo(response.success && !response.error ? response.data! : null);
    setContentAvailable(response.success && !response.error && response.data != undefined);
  };

  const refetch = async () => {
    await getCompanyCustomVideo();
  };

  const revokeCustomVideoAccess = async () => {
    dispatch(toggleLoading());
    if (videoToRevoke) {
      const customVideo = videoToRevoke!;
      try {
        const result = await revokeAccess({
          customVideoId: customVideo!.id,
          companyId: parseInt(companyId!),
        }).unwrap();

        if (!result.success || result?.error) {
          messageRef.current?.showError('An error occured');
          return;
        }

        messageRef.current?.showSuccess('Changes saved');

        setTimeout(async () => {
          await getCompanyCustomVideo();
        }, 1000);
      } catch (err: any) {
        messageRef.current?.showError(err.toString());
      } finally {
        dispatch(toggleLoading());
      }
    }
  };

  const toggleRevokeDialog = (v: CustomVideo) => {
    setVideoToRevoke(v);
    dialogRef.current?.openDialog();
  };

  const handleSearch = (e: any) => {
    setTimeout(() => {
      setQuery({
        page: query.page,
        limit: query.limit,
        searchTerm: e.target.value,
      });
    }, 1000);
  };

  const handlePaginationChanged = (limit: number, page: number) => {
    setQuery({
      page,
      limit,
      searchTerm: query.searchTerm,
    });
  };

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  return (
    <Card sx={{ p: 3, mt: 3 }}>
      <Box display="flex" justifyContent="space-between" alignItems="start">
        <Box>
          <Typography variant="h2">Manage Custom Videos</Typography>
          <Typography variant="subtitle1" mt={1}>
            Add, update or remove custom videos for a company
          </Typography>
        </Box>

        <Box display={'flex'}>
          <RefreshButton onRefresh={getCompanyCustomVideo} color={'text.primary'} />
        </Box>
      </Box>

      <Box>
        {response.isLoading ? (
          <CircularProgress></CircularProgress>
        ) : (
          <Box>
            {customVideo == null ? (
              <Center>
                <Center>
                  <Typography variant="subtitle1" mt={1} mb={1}>
                    This company has no custom videos
                  </Typography>
                  <Button variant="contained" onClick={navigateToCustomVideo} startIcon={<AddIcon fontSize="small" />}>
                    Add Custom Video
                  </Button>
                </Center>
              </Center>
            ) : (
              <div>
                <Box display="flex" alignItems="center" justifyContent="space-between" mb={3}>
                  <Box mt={'10px'}>
                    <StyledSearch
                      fullWidth
                      sx={{ height: 45, borderRadius: 0.8 }}
                      onChange={handleSearch}
                      placeholder="Search Custom Videos"
                      startAdornment={
                        <InputAdornment position="start">
                          <Iconify
                            icon="eva:search-fill"
                            sx={{
                              color: 'text.disabled',
                              width: 20,
                              height: 20,
                            }}
                          />
                        </InputAdornment>
                      }
                    />
                    {selectedFilters.map((filter) => (
                      <Tooltip title={filter} placement="top">
                        <AppChip sx={{ ml: 1 }}>{filter}</AppChip>
                      </Tooltip>
                    ))}
                  </Box>

                  <Box display="flex" alignItems="center" justifyContent="center">
                    <Filter
                      openFilter={openFilter}
                      onOpenFilter={handleOpenFilter}
                      selectedFilters={selectedFilters}
                      onCloseFilter={handleCloseFilter}
                      onFilterChange={setSelectedFilters}
                      filters={[
                        {
                          title: 'Status',
                          filters: ['Processed', 'Completed', 'Failed'],
                        },
                      ]}
                    />
                  </Box>
                </Box>
                {customVideo.items.length != 0 ? (
                  customVideo!.items.map((v: CustomVideo) => {
                    return (
                      <Box
                        sx={{
                          backgroundColor: theme.palette.grey[50],
                          '&:hover': {
                            backgroundColor: theme.palette.grey[100],
                            cursor: 'pointer',
                            '& .addIcon': {
                              color: 'purple',
                            },
                          },
                        }}
                      >
                        <div className="px-3 py-4 rounded mt-4 d-flex justify-content-between">
                          <div className="d-flex flex-row align-items-center">
                            <img
                              crossOrigin="anonymous"
                              width="130"
                              height="100"
                              className="rounded"
                              style={{ objectFit: 'cover' }}
                              src={v.videoThumbnail}
                            />
                            <div className="d-flex flex-column ms-4">
                              <span className="h5 mb-1">{v.videoName}</span>
                              <span className="small text-muted">Description: {v.description}</span>
                              <Box width={50} mt={1}>
                                <AppChip>{moment.utc(v.videoLength).format('mm:ss')}</AppChip>
                              </Box>
                            </div>
                          </div>
                          {/* FIXME DEPRECATED REVOKE ACCESS
                          <div className="d-flex flex-row align-items-center">
                            <span className="h2 mx-1 mb-0"></span>
                            <Box mt={1} mr={2}>
                              <LoadingButton
                                size="medium"
                                type="submit"
                                color="error"
                                sx={{ ml: 1 }}
                                variant="outlined"
                                onClick={() => toggleRevokeDialog(v)}
                              >
                                Revoke Access
                              </LoadingButton>
                            </Box>
                          </div> */}
                        </div>
                      </Box>
                    );
                  })
                ) : (
                  <Center>
                    <Typography variant="subtitle1" mt={1} mb={1}>
                      {query.searchTerm === ''
                        ? 'This company has no custom videos available'
                        : `This company has no custom videos matching the search term provided`}
                    </Typography>
                  </Center>
                )}
                <AppTablePagination
                  onChange={handlePaginationChanged}
                  totalPages={customVideo.meta.totalPages ?? 1}
                  contentAvailable={contentAvailable}
                />
              </div>
            )}
          </Box>
        )}
      </Box>

      <AppDialog
        ref={dialogRef}
        title="Revoke Access"
        onConfirm={revokeCustomVideoAccess}
        subtitle="Are you sure you want to revoke this company's access to this custom video?"
      />

      <MessageDisplay ref={messageRef} />
    </Card>
  );
};

export default ManageCompanyCustomVideoCard;
