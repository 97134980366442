import React, { useRef, useEffect, useState } from 'react';

import { chartColors } from './ChartJsConfig';
import { Chart, LineController, LineElement, Filler, PointElement, LinearScale, TimeScale, Tooltip } from 'chart.js';
import 'chartjs-adapter-moment';

Chart.register(LineController, LineElement, Filler, PointElement, LinearScale, TimeScale, Tooltip);

function LineChart08({ data, width, height }: any) {
  const [chart, setChart] = useState(null);

  const darkMode = false;
  const canvas = useRef<any>(null);
  const { gridColor, tooltipBodyColor, tooltipBgColor, tooltipBorderColor } = chartColors;

  useEffect(() => {
    const ctx = canvas.current;

    const newChart = new Chart(ctx, {
      type: 'line',
      data: data,
      options: {
        layout: {
          // padding: {
          //   top: 16,
          //   bottom: 16,
          //   left: 20,
          //   right: 20,
          // },
        },
        scales: {
          y: {
            beginAtZero: true,

            grid: {
              drawTicks: false,
              color: darkMode ? gridColor.dark : gridColor.light,
            },
            ticks: {
              maxTicksLimit: 2,
              display: false,
            },
          },
          x: {
            type: 'time',
            time: {
              parser: 'MM-DD-YYYY',
              unit: 'month',
            },
            display: false,
          },
        },
        plugins: {
          tooltip: {
            bodyColor: darkMode ? tooltipBodyColor.dark : tooltipBodyColor.light,
            backgroundColor: darkMode ? tooltipBgColor.dark : tooltipBgColor.light,
            borderColor: 'rgba(0, 0, 0, 0)',
          },
          legend: {
            display: false,
          },
        },
        interaction: {
          intersect: false,
          mode: 'nearest',
        },
        maintainAspectRatio: false,
        borderColor: 'rgba(0, 0, 0, 0)',
      },
    });
    setChart(newChart as any);
    return () => newChart.destroy();
  }, []);

  return <canvas ref={canvas} width={width} height={height}></canvas>;
}

export default LineChart08;
