import * as yup from 'yup';
import { Navigator } from '../Navigator';
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { fadeInRight } from 'react-animations';
import FileUpload from 'react-material-file-upload';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, FormHelperText, InputLabel } from '@mui/material';
import { CreateVideoDTO, acceptedImageFormats, MAX_IMAGE_FILE_SIZE } from '@core/constants/constants';
import TransitionWrapper from '@components/TransitionWrapper';
import { determineBorderColor, toMegabytes } from '@core/utils/utils';
import { VideoMode } from '@core/enums/enums';

type Props = {
  data: any;
  steps: any[];
  activeStep: number;
  handleBack: () => void;
  handleNext: () => void;
  handleSkip: () => void;
  onCapture: (data: any) => void;
};

const schema = yup.object().shape({
  thumbnail: yup
    .mixed()

    .test('thumbnailFile', 'Thumbnail image is too large. Max size is 5MB', (value: File) => {
      if (value && value.size > MAX_IMAGE_FILE_SIZE) {
        return false;
      }
      return true;
    }),
});

export const UploadThumbnailStep: React.FC<Props> = (props: Props) => {
  const { data, steps, activeStep, onCapture, handleBack, handleNext, handleSkip } = props;

  const [files, setFiles] = useState<File[]>([]);

  useEffect(() => {
    let fileList: File[] = [];

    if (data?.thumbnail) {
      fileList = [data?.thumbnail];
    }
    setFiles(fileList);
    setValue<string>('thumbnailFile', data?.thumbnail);
  }, []);

  const {
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const captureDataThenNavigate = (data: any) => {
    onCapture(data);
    handleNext();
  };

  const captureData = (data: any) => {
    handleNext();
    onCapture(data);
  };

  const captureDataThenSkip = (data: any) => {
    onCapture(data);
    handleSkip();
  };

  const onChange = (files: File[]) => {
    setFiles(files);
    setValue<string>('thumbnailFile', files[0]);
  };

  return (
    <TransitionWrapper duration={0.2} animation={fadeInRight}>
      <Box>
        <InputLabel>Upload a thumbnail for your video</InputLabel>
        <br></br>
        <FileUpload
          value={files}
          onChange={onChange}
          accept={acceptedImageFormats}
          sx={{ borderColor: errors?.thumbnail?.message && 'red' }}
        />
        <Navigator
          steps={steps}
          handleBack={handleBack}
          activeStep={activeStep}
          handleSkip={handleSkip}
          handleNext={handleSubmit(captureData)}
        />

        <FormHelperText style={{ color: 'red' }}>{errors?.thumbnail?.message as string}</FormHelperText>
      </Box>
    </TransitionWrapper>
  );
};
