import { Video } from '@core/types';
import { Grid } from '@mui/material';
import { NoVideos } from './NoVideos';
import { VideoItem } from './VideoItem';
import { Retry } from '@components/Retry';
import { AnimatedList } from 'react-animated-list';

type Props = {
  refetch: any;
  videos: Video[];
  loading: boolean;
};

export const VideoLisitng: React.FC<Props> = ({ videos, refetch, loading }) => {
  return (
    <div>
      {!loading && videos.length == 0 ? (
        <NoVideos />
      ) : (
        <Grid container spacing={3} ml={0.3}>
          <AnimatedList animation={'grow'}>
            {videos.map((video, index) => (
              <Grid item xs={3} mr={2}>
                <VideoItem video={video} index={index} refetch={refetch} />
              </Grid>
            ))}
          </AnimatedList>
        </Grid>
      )}
    </div>
  );
};
