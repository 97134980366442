import { baseQueryWithReauth } from './base-slice';
import { ApiError, ServiceResult } from '@core/index';
import { ActionResult, CustomVideo, CustomVideoBanner } from '@core/types';
import { PaginatedList } from '@core/constants/constants';
import { ApiMethods, BaseEndpoints } from '@core/enums/enums';
import { TagDescription, createApi } from '@reduxjs/toolkit/query/react';

const descriptionTags = ['CustomVideo'] as readonly TagDescription<'CustomVideo'>[] & string[];

export const customVideoSlice = createApi({
  tagTypes: descriptionTags,
  reducerPath: BaseEndpoints.CustomVideo,
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    processCustomVideo: builder.mutation<ServiceResult<ActionResult, ApiError>, any>({
      query: (payload) => ({
        body: payload,
        method: ApiMethods.POST,
        url: `/${BaseEndpoints.CustomVideo}/process`,
      }),
      invalidatesTags: descriptionTags,
    }),
    getCustomVideos: builder.query<ServiceResult<PaginatedList<CustomVideo[]>, ApiError>, any>({
      query: (args) => ({
        params: { ...args },
        url: `/${BaseEndpoints.CustomVideo}`,
      }),
      providesTags: descriptionTags,
    }),
    getCompanyCustomVideos: builder.query<ServiceResult<PaginatedList<CustomVideo[]>, ApiError>, any>({
      query: (args) => ({
        params: { ...args.query },
        url: `/${BaseEndpoints.CustomVideo}/v2/${args.id}`,
      }),
      providesTags: descriptionTags,
    }),
    getCompanyCustomVideoById: builder.query<ServiceResult<CustomVideo, ApiError>, any>({
      query: (args) => ({
        url: `/${BaseEndpoints.CustomVideo}/${args.id}?videoId=${args.videoId}`,
      }),
      providesTags: descriptionTags,
    }),
    deleteCustomVideo: builder.mutation({
      query: (args) => ({
        method: ApiMethods.DELETE,
        url: `/${BaseEndpoints.CustomVideo}/${args.id}`,
      }),
      invalidatesTags: descriptionTags,
    }),
    revokeCustomVideoAccess: builder.mutation<ServiceResult<ActionResult, ApiError>, any>({
      query: (payload) => ({
        body: payload,
        method: ApiMethods.POST,
        url: `/${BaseEndpoints.CustomVideo}/access/revoke`,
      }),
      invalidatesTags: descriptionTags,
    }),
    assignCustomVideoAccess: builder.mutation<ServiceResult<ActionResult, ApiError>, any>({
      query: (payload) => ({
        body: payload,
        method: ApiMethods.DELETE,
        url: `/${BaseEndpoints.CustomVideo}/access/assign`,
      }),
      invalidatesTags: descriptionTags,
    }),
    getCustomVideoBanner: builder.query<ServiceResult<CustomVideoBanner, ApiError>, any>({
      query: (args) => ({
        url: `/${BaseEndpoints.Promotions}/custom-video`,
      }),
      providesTags: descriptionTags,
    }),
  }),
});

export const {
  useGetCustomVideosQuery,
  useGetCustomVideoBannerQuery,
  useDeleteCustomVideoMutation,
  useProcessCustomVideoMutation,
  useGetCompanyCustomVideosQuery,
  useGetCompanyCustomVideoByIdQuery,
  useLazyGetCompanyCustomVideosQuery,
  useRevokeCustomVideoAccessMutation,
  useAssignCustomVideoAccessMutation,
} = customVideoSlice;
