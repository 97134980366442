import { makeStyles } from "@mui/styles";
import { PackageCategory } from "@core/types";
import React, { useEffect, useState } from "react";
import { StyledButton } from "@components/CustomSelect/CustomSelect";
import RenderDelegate from "@components/RenderDelegate/RenderDelegate";
import { useGetPackageCategoriesQuery } from "@features/state/slices/api/package-slice";
import {
  Select,
  MenuItem,
  FormControl,
  FormHelperText,
  CircularProgress,
  SelectChangeEvent,
} from "@mui/material";

type Props = {
  name: any;
  label: any;
  error?: any;
  style?: any;
  classes?: any;
  countryISO?: any;
  helperText?: any;
  placeholder?: any;
  category: any;
  onFocus?: (e: any) => void;
  onChange: (category: any) => void;
};

const selectUseStyles = makeStyles((theme: any) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  inputLabelRoot: {
    color: "white",
  },
}));

export const PackageCategorySelect: React.FC<Props> = ({
  error,
  onChange,
  category,
  helperText,
}) => {
  const selectClasses = selectUseStyles();
  const [categories, setCategories] = useState<PackageCategory[]>([]);

  const { data, isError, isLoading, isFetching } = useGetPackageCategoriesQuery(
    {
      refetchOnMountOrArgChange: true,
    }
  );

  useEffect(() => {

    if (!isError && data && !isFetching && !isLoading) {
      setCategories(data.data ?? []);

    }
  }, [isFetching]);

  const handleCategoryChange = async (e: SelectChangeEvent<any>) => {
    onChange(e.target.value);
  };

  return (
    <FormControl
      className={`${selectClasses.formControl} country-select__container`}
    >
      <RenderDelegate
        condition={!isFetching}
        renderComponent={
          <Select
            error={error}
            value={category}
            className="country-select__base"
            onChange={handleCategoryChange}
          >
            {categories.map((c: any) => (
              <MenuItem key={c.name} value={c.id}>
                {c.name}
              </MenuItem>
            ))}
          </Select>
        }
        fallBackComponent={<StyledButton className="country-select__base" />}
      />
      <div className="login__password-icon">
        {isLoading ? <CircularProgress size={15} /> : null}
      </div>
      <FormHelperText sx={{ color: "red" }}>{helperText}</FormHelperText>
    </FormControl>
  );
};
