import { useState } from 'react';
import { Button } from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Helmet } from 'react-helmet-async';
import { Query } from '@core/constants/constants';
import AppPageHeader from '@components/PageHeader';
import { AdminTable } from './components/AdminTable';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import TransitionWrapper from '@components/TransitionWrapper';
import { RootState } from '@features/state/reducers/combinedReducers';
import { useGetAdminsQuery } from '@features/state/slices/api/admin-slice';

function ManageAdmins() {
  const navigate = useNavigate();
  const user = useSelector<RootState, any>((state) => state.auth!.auth!.user);

  const [query, setQuery] = useState<Query>({
    page: 1,
    limit: 10,
    searchTerm: '',
  });

  const { data, error, isLoading, refetch } = useGetAdminsQuery(
    {
      limit: query.limit,
      page: query.page,
      searchTerm: query.searchTerm,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const navigateToCreateAdmin = () => {
    navigate('create');
  };

  return (
    <TransitionWrapper>
      <Helmet>
        <title>Manage Admin</title>
      </Helmet>

      <AppPageHeader
        title="Admins"
        subtitle={`${user?.name}, these are your recent admin accounts`}
        trailing={
          <Button
            variant="contained"
            sx={{ mt: { xs: 2, md: 0 } }}
            onClick={navigateToCreateAdmin}
            startIcon={<AddTwoToneIcon fontSize="small" />}
          >
            Create Admin
          </Button>
        }
      />

      <AdminTable data={data} error={error} refetch={refetch} loading={isLoading} onChange={setQuery} />
    </TransitionWrapper>
  );
}

export default ManageAdmins;
