import { Transition } from 'react-transition-group';
import { Alert, AlertColor, Box } from '@mui/material';
import { forwardRef, useImperativeHandle, useState, useEffect, useCallback, useRef } from 'react';

const duration = 300;

interface IMessageDisplayProps {
  className?: string;
}

export interface IMessageDisplayRef {
  showError: (message: string) => void;
  showSuccess: (message: string) => void;
  showWarning: (message: string) => void;
}

const MessageDisplay = forwardRef<IMessageDisplayRef, IMessageDisplayProps>((props, ref: any) => {
  const elementRef = useRef<any>(null);
  const [visible, setVisible] = useState(false);
  const [alert, setAlert] = useState<{ message: string | null; type: AlertColor }>({ message: null, type: 'error' });

  useEffect(() => {
    if (alert.message) {
      setVisible(true);
      setTimeout(() => {
        setVisible(false);
      }, 10000);
    }
  }, [alert]);

  useImperativeHandle(
    ref,
    () => ({
      showError: (message: string) => {
        setAlert({ message, type: 'error' });
      },
      showSuccess: (message: string) => {
        setAlert({ message, type: 'success' });
      },
      showWarning: (message: string) => {
        setAlert({ message, type: 'warning' });
      },
    }),
    [],
  );

  const handleClose = useCallback(() => {
    setVisible(false);
  }, []);

  return (
    <Box ref={elementRef}>
      <Transition in={visible} timeout={duration}>
        {(state: any) => (
          <>
            {visible && (
              <Box mt={2} mb={2}>
                <Alert severity={alert.type} onClose={handleClose}>
                  {alert.message}
                </Alert>
              </Box>
            )}
          </>
        )}
      </Transition>
    </Box>
  );
});

export default MessageDisplay;
