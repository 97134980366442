import React from "react";
import { Stack, Typography, Button } from "@mui/material";
import ReplayIcon from "@mui/icons-material/Replay";

type Props = {
  refetch: any;
};
export const Retry: React.FC<Props> = ({ refetch }) => {
  return (
    <Stack className="retry__container" spacing={2} alignItems="center">
      <ReplayIcon fontSize="large" />
      <Typography variant="subtitle2" gutterBottom>
        An error occured fetching data. Click the button below to retry
      </Typography>
      <Button variant="outlined" size="small" onClick={refetch}>
        Retry
      </Button>
    </Stack>
  );
};
