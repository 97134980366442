import { Doughnut } from 'react-chartjs-2';
import { Box, Typography } from '@mui/material';
import { CircleChart } from '@components/Charts';
import { Col, Row, Card } from '@themesberg/react-bootstrap';

export const DashboardTrafficChart = (props: any) => {
  const { title, data = [] } = props;
  const series = data.map((d: any) => d.value);

  return (
    <div className="flex flex-col col-span-full sm:col-span-6 xl:col-span-3 bg-white dark:bg-slate-800 shadow-sm rounded-md border border-slate-200 dark:border-slate-700">
      <div className="px-5 pt-3">
        <Row className="d-block d-xl-flex align-items-center mb-12">
          <Col xs={12} xl={5} className="text-xl-center d-flex align-items-center justify-content-xl-center mb-3 mb-xl-0">
            <CircleChart data={data} />
          </Col>
          <Col xs={12} xl={7} className="px-xl-0">
            <h5 className="mb-3">{title}</h5>

            {data.map((d: any) => {
              const Icon = d.icon;
              return (
                <Box display="flex" alignItems="center" mt={1.2}>
                  <Icon style={{ color: d.color, height: 20, width: 20 }} />
                  <Typography ml={1} variant="subtitle1" fontSize={13} key={`circle-element-${d.id}`} className="fw-normal text-gray">
                    {` ${d.label} `}
                    {`${d.value}%`}
                  </Typography>{' '}
                </Box>
              );
            })}
          </Col>
        </Row>
      </div>
    </div>
  );
};
