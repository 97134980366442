import { Subscription, subscriptionTabs, SIX_MONTH_CONTRACT_KEY } from '@core/constants/constants';
import { Box, styled, Tab, Tabs } from '@mui/material';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { SubscriptionDisplayItem } from './SubscriptionDisplay/SubscriptionItem';
import { BaseSubscription } from '@core/types';

type Props = {
  selectedTab?: string | null;
  subscriptions: Subscription[];
  selectedSubscription: Subscription | null;
  onSelect: (subscription: Subscription | null, pricingPlan: string) => void;
  onEdit?: (subscription: Subscription) => void;
  isAdminView?: boolean;
};

const TabsWrapper = styled(Tabs)(
  () => `
    .MuiTabs-scrollableX {
      overflow-x: auto !important;
    }
`,
);
export const SubscriptionDisplay: React.FC<Props> = ({
  onSelect,
  selectedTab,
  subscriptions,
  selectedSubscription,
  isAdminView = false,
  onEdit,
}) => {
  useEffect(() => {
    if (selectedTab) {
      setCurrentTab(selectedTab);
    }
  }, [selectedTab]);
  const [currentTab, setCurrentTab] = useState<string>(SIX_MONTH_CONTRACT_KEY);

  const handleTabsChange = (event: ChangeEvent<{}>, value: string): void => {
    setCurrentTab(value);
    onSelect(selectedSubscription, value);
  };

  return (
    <React.Fragment>
      <Box mt={2} mb={2} display="flex" textAlign="center" alignSelf="center" alignItems="center">
        <TabsWrapper
          onChange={handleTabsChange}
          value={currentTab}
          variant="scrollable"
          scrollButtons="auto"
          textColor="primary"
          indicatorColor="primary"
        >
          {subscriptionTabs.map((tab) => (
            <Tab key={tab.value} label={tab.label} value={tab.value} />
          ))}
        </TabsWrapper>
      </Box>
      <section className="pricing py-5" style={{ borderRadius: 15 }}>
        <div className="container">
          <div className="row">
            {subscriptions.map((subscription) => (
              <SubscriptionDisplayItem
                onEdit={onEdit}
                isAdminView={isAdminView}
                onSelect={onSelect}
                pricePlan={currentTab}
                subscription={subscription}
                config={{
                  displayTrialPeriod: subscription.pricePlan == SIX_MONTH_CONTRACT_KEY,
                  displayUpFrontBilling: subscription.pricePlan == SIX_MONTH_CONTRACT_KEY,
                }}
                isSelected={subscription.id === selectedSubscription?.id}
              />
            ))}
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};
