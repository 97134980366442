import { ReactNode } from 'react';

interface SidebarNavItem {
  children?: SidebarBaseItem[];
}

interface SidebarBaseItem {
  path: string;
  title: string;
  getIcon: (pathname: string) => ReactNode | JSX.Element;
}

type SidebarNavItemType = SidebarNavItem & SidebarBaseItem;

export const topNavConfig: SidebarNavItemType[] = [
  {
    title: 'Dashboard',
    path: 'dashboard/home', // Routes.App.Dashboard,
    getIcon: (pathname) => (
      <svg className="shrink-0 h-6 w-6" viewBox="0 0 24 24">
        <path
          className={`fill-current ${pathname.includes('dashboard/home') ? 'text-blue-300' : 'text-slate-400'}`}
          d="M13 15l11-7L11.504.136a1 1 0 00-1.019.007L0 7l13 8z"
        />
        <path
          className={`fill-current ${pathname.includes('dashboard/home') ? 'text-blue-600' : 'text-slate-700'}`}
          d="M13 15L0 7v9c0 .355.189.685.496.864L13 24v-9z"
        />
        <path
          className={`fill-current ${pathname.includes('dashboard/home') ? 'text-blue-500' : 'text-slate-600'}`}
          d="M13 15.047V24l10.573-7.181A.999.999 0 0024 16V8l-11 7.047z"
        />
      </svg>
    ),
  },
  {
    title: 'Admins',
    path: 'dashboard/admins',
    getIcon: (pathname) => (
      <svg className="shrink-0 h-6 w-6" enable-background="new 0 0 48 48" viewBox="0 0 48 48">
        <path
          className={`fill-current ${pathname.includes('dashboard/admin') ? 'text-blue-600' : 'text-slate-700'}`}
          d="M43.281,27.487V8.115C33.105,7.944,24.292,0.008,24.292,0.008S15.479,7.944,5.303,8.115H4.719v18.897  c0,0.803,0.186,2.161,0.187,2.165c0.604,3.032,3.658,11.327,19.091,18.794c0.001,0.007,0.003,0.014,0.004,0.021  c0.006-0.004,0.012-0.006,0.019-0.009c0.006,0.003,0.012,0.005,0.018,0.009c0.002-0.007,0.003-0.014,0.004-0.021  c15.244-7.375,18.408-15.556,19.065-18.678C43.223,28.742,43.281,27.487,43.281,27.487z"
        />
      </svg>
    ),
  },

  {
    title: 'Company Accounts',
    path: 'dashboard/company-accounts',
    getIcon: (pathname) => (
      <svg className="shrink-0 h-6 w-6" viewBox="0 0 24 24">
        <path
          className={`fill-current ${pathname.includes('dashboard/company-accounts') ? 'text-blue-500' : 'text-slate-600'}`}
          d="M18.974 8H22a2 2 0 012 2v6h-2v5a1 1 0 01-1 1h-2a1 1 0 01-1-1v-5h-2v-6a2 2 0 012-2h.974zM20 7a2 2 0 11-.001-3.999A2 2 0 0120 7zM2.974 8H6a2 2 0 012 2v6H6v5a1 1 0 01-1 1H3a1 1 0 01-1-1v-5H0v-6a2 2 0 012-2h.974zM4 7a2 2 0 11-.001-3.999A2 2 0 014 7z"
        />
        <path
          className={`fill-current ${pathname.includes('dashboard/company-accounts') ? 'text-blue-300' : 'text-slate-400'}`}
          d="M12 6a3 3 0 110-6 3 3 0 010 6zm2 18h-4a1 1 0 01-1-1v-6H6v-6a3 3 0 013-3h6a3 3 0 013 3v6h-3v6a1 1 0 01-1 1z"
        />
      </svg>
    ),
  },
  {
    title: 'Videos',
    path: 'dashboard/videos',
    getIcon: (pathname) => (
      <svg className="shrink-0 h-7 w-7" viewBox="0 0 24 24">
        <path
          className={`fill-current ${pathname.includes('dashboard/videos') ? 'text-blue-600' : 'text-slate-700'}`}
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M15.3276 7.54199H8.67239C5.29758 7.54199 3.61017 7.54199 2.66232 8.52882C1.71447 9.51565 1.93748 11.0403 2.38351 14.0895L2.80648 16.9811C3.15626 19.3723 3.33115 20.5679 4.22834 21.2839C5.12553 21.9999 6.4488 21.9999 9.09534 21.9999H14.9046C17.5512 21.9999 18.8745 21.9999 19.7717 21.2839C20.6689 20.5679 20.8437 19.3723 21.1935 16.9811L21.6165 14.0895C22.0625 11.0403 22.2855 9.51564 21.3377 8.52882C20.3898 7.54199 18.7024 7.54199 15.3276 7.54199ZM14.5812 15.7942C15.1396 15.448 15.1396 14.5519 14.5812 14.2057L11.2096 12.1156C10.6669 11.7792 10 12.2171 10 12.9098V17.0901C10 17.7828 10.6669 18.2207 11.2096 17.8843L14.5812 15.7942Z"
          fill="#1C274C"
        />
        <path
          className={`fill-current ${pathname.includes('dashboard/videos') ? 'text-blue-500' : 'text-slate-600'}`}
          opacity="0.4"
          d="M8.50956 2.00001H15.4897C15.7221 1.99995 15.9004 1.99991 16.0562 2.01515C17.164 2.12352 18.0708 2.78958 18.4553 3.68678H5.54395C5.92846 2.78958 6.83521 2.12352 7.94303 2.01515C8.09884 1.99991 8.27708 1.99995 8.50956 2.00001Z"
          fill="#1C274C"
        />
        <path
          className={`fill-current ${pathname.includes('dashboard/videos') ? 'text-blue-400' : 'text-slate-400'}`}
          opacity="0.7"
          d="M6.3102 4.72266C4.91958 4.72266 3.77931 5.56241 3.39878 6.67645C3.39085 6.69967 3.38325 6.72302 3.37598 6.74647C3.77413 6.6259 4.18849 6.54713 4.60796 6.49336C5.68833 6.35485 7.05367 6.35492 8.6397 6.35501H15.5318C17.1178 6.35492 18.4832 6.35485 19.5635 6.49336C19.983 6.54713 20.3974 6.6259 20.7955 6.74647C20.7883 6.72302 20.7806 6.69967 20.7727 6.67645C20.3922 5.56241 19.2519 4.72266 17.8613 4.72266H6.3102Z"
          fill="#1C274C"
        />
      </svg>
    ),
    children: [
      {
        title: 'Standard',
        path: 'dashboard/videos/standard',
        getIcon: (pathname) => (
          <svg className="shrink-0 h-6 w-6" viewBox="0 0 512 512" enable-background="new 0 0 512 512">
            <path
              className={`fill-current ${pathname.includes('dashboard/videos/standard') ? 'text-blue-500' : 'text-slate-500'}`}
              d="M396.67 229.909c18.32 11.71 18.32 38.47 0 50.179l-261.58 167.17c-14.49 9.26-33.5-1.148-33.5-18.35V81.088c0-17.2 19.01-27.61 33.5-18.35l261.58 167.171z"
              fill="#000000"
            ></path>
          </svg>
        ),
      },
      {
        title: 'Custom',
        path: 'dashboard/videos/custom',
        getIcon: (pathname) => (
          <svg className="shrink-0 h-6 w-6" enable-background="new 0 0 24 24" viewBox="0 0 24 24">
            <g>
              <path
                className={`fill-current ${pathname.includes('dashboard/videos/custom') ? 'text-blue-500' : 'text-slate-500'}`}
                d="M23.3,7.3c0-0.2-0.3-1.8-1-2.5c-0.9-1-1.9-1.1-2.4-1.1l-0.1,0c-3.1-0.2-7.7-0.2-7.8-0.2c0,0-4.7,0-7.8,0.2l-0.1,0   c-0.5,0-1.5,0.1-2.4,1.1c-0.7,0.8-1,2.4-1,2.6c0,0.1-0.2,1.9-0.2,3.8v1.7c0,1.9,0.2,3.7,0.2,3.8c0,0.2,0.3,1.8,1,2.5   c0.8,0.9,1.8,1,2.4,1.1c0.1,0,0.2,0,0.3,0c1.8,0.2,7.3,0.2,7.5,0.2c0,0,0,0,0,0c0,0,4.7,0,7.8-0.2l0.1,0c0.5-0.1,1.5-0.2,2.4-1.1   c0.7-0.8,1-2.4,1-2.6c0-0.1,0.2-1.9,0.2-3.8v-1.7C23.5,9.3,23.3,7.4,23.3,7.3z M15.9,12.2l-6,3.2c-0.1,0-0.1,0.1-0.2,0.1   c-0.1,0-0.2,0-0.2-0.1c-0.1-0.1-0.2-0.2-0.2-0.4l0-6.5c0-0.2,0.1-0.3,0.2-0.4S9.8,8,10,8.1l6,3.2c0.2,0.1,0.3,0.2,0.3,0.4   S16.1,12.1,15.9,12.2z"
              />
            </g>
          </svg>
        ),
      },
    ],
  },
  //DEPRECATED Packages removed
  // {
  //   title: 'Packages',
  //   path: 'dashboard/packages',
  //   getIcon: (pathname) => (
  //     <svg className="shrink-0 h-6 w-6" viewBox="0 0 12 12">
  //       <g>
  //         <g>
  //           <polygon
  //             className={`fill-current ${pathname.includes('dashboard/packages') ? 'text-blue-500' : 'text-slate-500'}`}
  //             points="2.75,3.815918 8.190918,1.095459 6,0 0.559082,2.720459   "
  //           />
  //         </g>
  //         <g>
  //           <polygon
  //             className={`fill-current ${pathname.includes('dashboard/packages') ? 'text-blue-500' : 'text-slate-500'}`}
  //             points="9.309082,1.654541 3.8681641,4.375 6,5.440918 11.440918,2.720459   "
  //           />
  //         </g>
  //         <g>
  //           <polygon
  //             className={`fill-current ${pathname.includes('dashboard/packages') ? 'text-blue-500' : 'text-slate-600'}`}
  //             points="5.5,6.309082 0,3.559082 0,9.25 5.5,12   "
  //           />
  //         </g>
  //         <g>
  //           <polygon
  //             className={`fill-current ${pathname.includes('dashboard/packages') ? 'text-blue-500' : 'text-slate-700'}`}
  //             points="6.5,6.309082 6.5,12 12,9.25 12,3.559082   "
  //           />
  //         </g>
  //       </g>
  //     </svg>
  //   ),
  // },
  {
    title: 'Video Requests',
    path: 'dashboard/request',
    getIcon: (pathname) => (
      <svg className="shrink-0 h-6 w-6" viewBox="0 0 24 24">
        <path
          className={`fill-current ${pathname.includes('dashboard/request') ? 'text-blue-500' : 'text-slate-600'}`}
          d="M20 7a.75.75 0 01-.75-.75 1.5 1.5 0 00-1.5-1.5.75.75 0 110-1.5 1.5 1.5 0 001.5-1.5.75.75 0 111.5 0 1.5 1.5 0 001.5 1.5.75.75 0 110 1.5 1.5 1.5 0 00-1.5 1.5A.75.75 0 0120 7zM4 23a.75.75 0 01-.75-.75 1.5 1.5 0 00-1.5-1.5.75.75 0 110-1.5 1.5 1.5 0 001.5-1.5.75.75 0 111.5 0 1.5 1.5 0 001.5 1.5.75.75 0 110 1.5 1.5 1.5 0 00-1.5 1.5A.75.75 0 014 23z"
        />
        <path
          className={`fill-current ${pathname.includes('dashboard/request') ? 'text-blue-300' : 'text-slate-400'}`}
          d="M17 23a1 1 0 01-1-1 4 4 0 00-4-4 1 1 0 010-2 4 4 0 004-4 1 1 0 012 0 4 4 0 004 4 1 1 0 010 2 4 4 0 00-4 4 1 1 0 01-1 1zM7 13a1 1 0 01-1-1 4 4 0 00-4-4 1 1 0 110-2 4 4 0 004-4 1 1 0 112 0 4 4 0 004 4 1 1 0 010 2 4 4 0 00-4 4 1 1 0 01-1 1z"
        />
      </svg>
    ),
  },
  {
    title: 'Subscriptions',
    path: 'dashboard/subscriptions',
    getIcon: (pathname) => (
      <svg className="shrink-0 h-6 w-6" viewBox="0 0 24 24">
        <circle
          className={`fill-current ${pathname.includes('dashboard/subscriptions') ? 'text-blue-500' : 'text-slate-600'}`}
          cx="16"
          cy="8"
          r="8"
        />
        <circle
          className={`fill-current ${pathname.includes('dashboard/subscriptions') ? 'text-blue-300' : 'text-slate-400'}`}
          cx="8"
          cy="16"
          r="8"
        />
      </svg>
    ),
  },
  {
    title: 'Promotions',
    path: 'dashboard/promotions',
    getIcon: (pathname) => (
      <svg className="shrink-0 h-6 w-6" viewBox="0 0 24 24">
        <circle
          className={`fill-current ${pathname.includes('dashboard/promotions') ? 'text-blue-300' : 'text-slate-400'}`}
          cx="18.5"
          cy="5.5"
          r="4.5"
        />
        <circle
          className={`fill-current ${pathname.includes('dashboard/promotions') ? 'text-blue-500' : 'text-slate-600'}`}
          cx="5.5"
          cy="5.5"
          r="4.5"
        />
        <circle
          className={`fill-current ${pathname.includes('dashboard/promotions') ? 'text-blue-500' : 'text-slate-600'}`}
          cx="18.5"
          cy="18.5"
          r="4.5"
        />
        <circle
          className={`fill-current ${pathname.includes('dashboard/promotions') ? 'text-blue-300' : 'text-slate-400'}`}
          cx="5.5"
          cy="18.5"
          r="4.5"
        />
      </svg>
    ),
  },
];
