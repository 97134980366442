import * as yup from 'yup';
import { Navigator } from '../Navigator';
import { useForm } from 'react-hook-form';
import { AppInput } from '@components/Styled';
import { yupResolver } from '@hookform/resolvers/yup';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { determineBorderColor } from '@core/utils/utils';
import { Box, InputLabel, Typography, FormControl, FormHelperText } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { fadeInRight } from 'react-animations';
import TransitionWrapper from '@components/TransitionWrapper';
import { CreateSubscriptionDTO } from '@core/constants/constants';
import { SubscriptionMode } from '@core/enums/enums';

type Props = {
  data: CreateSubscriptionDTO;
  steps: any;
  activeStep: number;
  subscriptionMode: SubscriptionMode;
  handleBack: () => void;
  handleNext: () => void;
  handleSkip: () => void;
  onCapture: (data: any) => void;
};

const schema = yup.object().shape({
  monthlyContractCost: yup.number().required('Monthly Contract Cost is required'),
  sixMonthContractCost: yup.number().required('Six-Month Contract Cost is required'),
  oneYearContractCost: yup.number().required('One-Year Contract Cost is required'),
  twoYearContractCost: yup.number().default(0),
  setupCost: yup.number().required('Setup Cost is required'),
});

export const ContractCostsForm: React.FC<Props> = (props: Props) => {
  const { data, steps, activeStep, subscriptionMode, onCapture, handleBack, handleNext, handleSkip } = props;

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useMemo(() => {
    if (subscriptionMode === SubscriptionMode.Edit) {
      setValue('monthlyContractCost', data?.monthlyContractCost);
      setValue('sixMonthContractCost', data?.sixMonthContractCost);
      setValue('oneYearContractCost', data?.oneYearContractCost);
      setValue('twoYearContractCost', data?.twoYearContractCost);
      setValue('setupCost', data?.setupCost);
    }
  }, [subscriptionMode]);

  useEffect(() => {
    console.log('errors', errors);
  }, [errors]);

  const captureData = (data: any) => {
    handleNext();
    onCapture(data);
  };
  return (
    <TransitionWrapper duration={0.2} animation={fadeInRight}>
      <Box mt={2} mb={5}>
        {subscriptionMode == SubscriptionMode.Edit ? (
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: 'red' }}>
            When Editing You currently cannot modify the costs as persons may already be subscribed
          </Typography>
        ) : (
          <Typography variant="subtitle1">Give your subscription different contract costs</Typography>
        )}

        <FormControl variant="standard" style={{ width: '100%', marginTop: 15 }}>
          <InputLabel shrink htmlFor="bootstrap=input">
            Monthly Contract Cost
          </InputLabel>
          <AppInput
            required
            fullWidth
            id="monthlyContractCost"
            type="number"
            {...register('monthlyContractCost', {
              required: true,
              maxLength: 80,
            })}
            style={determineBorderColor(errors?.monthlyContractCost?.message as any)}
            disabled={subscriptionMode === SubscriptionMode.Edit}
          />

          <div className="input-icon">
            <AttachMoneyIcon style={{ width: 20, color: 'grey' }} />
          </div>
        </FormControl>
        <FormHelperText style={{ color: 'red' }}>{errors?.monthlyContractCost?.message as string}</FormHelperText>

        <FormControl variant="standard" style={{ width: '100%', marginTop: 15 }}>
          <InputLabel shrink htmlFor="bootstrap=input">
            Six-Months Contract Cost
          </InputLabel>
          <AppInput
            required
            fullWidth
            id="sixMonthContractCost"
            type="number"
            {...register('sixMonthContractCost', {
              required: true,
              maxLength: 80,
            })}
            style={determineBorderColor(errors?.sixMonthContractCost?.message as any)}
            disabled={subscriptionMode === SubscriptionMode.Edit}
          />

          <div className="input-icon">
            <AttachMoneyIcon style={{ width: 20, color: 'grey' }} />
          </div>
        </FormControl>
        <FormHelperText style={{ color: 'red' }}>{errors?.sixMonthContractCost?.message as string}</FormHelperText>

        <FormControl variant="standard" style={{ width: '100%', marginTop: 15 }}>
          <InputLabel shrink htmlFor="bootstrap=input">
            One-Year Contract Cost
          </InputLabel>
          <AppInput
            required
            fullWidth
            id="oneYearContractCost"
            type="number"
            {...register('oneYearContractCost', {
              required: true,
              maxLength: 80,
            })}
            style={determineBorderColor(errors?.oneYearContractCost?.message as any)}
            disabled={subscriptionMode === SubscriptionMode.Edit}
          />

          <div className="input-icon">
            <AttachMoneyIcon style={{ width: 20, color: 'grey' }} />
          </div>
        </FormControl>
        <FormHelperText style={{ color: 'red' }}>{errors?.oneYearContractCost?.message as string}</FormHelperText>

        {/* DEPRECATED Two year Contract Cost
        <FormControl variant="standard" style={{ width: '100%', marginTop: 15 }}>
          <InputLabel shrink htmlFor="bootstrap=input">
            Two-Year Contract Cost
          </InputLabel>
          <AppInput
            required
            fullWidth
            id="twoYearContractCost"
            type="number"
            {...register('twoYearContractCost', {
              required: true,
              maxLength: 80,
            })}
            style={determineBorderColor(errors?.twoYearContractCost?.message as any)}
          />

          <div className="input-icon">
            <AttachMoneyIcon style={{ width: 20, color: 'grey' }} />
          </div>
        </FormControl> */}
        {/* <FormHelperText style={{ color: 'red' }}>{errors?.twoYearContractCost?.message as string}</FormHelperText> */}

        <FormControl variant="standard" style={{ width: '100%', marginTop: 15 }}>
          <InputLabel shrink htmlFor="bootstrap=input">
            Setup Cost
          </InputLabel>
          <AppInput
            required
            fullWidth
            id="setupCost"
            type="number"
            {...register('setupCost', {
              required: true,
              maxLength: 80,
            })}
            style={determineBorderColor(errors?.setupCost?.message as any)}
            disabled={subscriptionMode === SubscriptionMode.Edit}
          />

          <div className="input-icon">
            <AttachMoneyIcon style={{ width: 20, color: 'grey' }} />
          </div>
        </FormControl>
        <FormHelperText style={{ color: 'red' }}>{errors?.setupCost?.message as string}</FormHelperText>

        <Navigator
          steps={steps}
          handleSkip={handleSkip}
          handleBack={handleBack}
          activeStep={activeStep}
          handleNext={handleSubmit(captureData)}
        />
      </Box>
    </TransitionWrapper>
  );
};
