import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import { motion } from 'framer-motion';
import Paper from '@mui/material/Paper';
import { useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { browserName } from '@core/utils/utils';
import Logo from '@assets/images/small-logo.png';
import Typography from '@mui/material/Typography';
import RoofingVideo from '@assets/videos/login.mp4';
import CssBaseline from '@mui/material/CssBaseline';
import VerifyEmailCode from './components/VerifyEmailCode';
import { verticalAnimation } from '@core/constants/constants';
import SendRecoveryEmail from './components/SendRecoveryEmail';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import ResetPassword from './components/ResetPassword';

export default function ForgetPassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { animate, exit } = verticalAnimation;
  const [activeStep, setActiveStep] = useState(0);
  const [email, setEmail] = useState<string | null>(null);
  const [token, setVerficationToken] = useState<string | null>(null);
  const [experienceDialog, setExperienceDialog] = React.useState(false);

  const [steps, setSteps] = useState([
    {
      component: SendRecoveryEmail,
    },
    {
      component: VerifyEmailCode,
    },
    {
      component: ResetPassword,
    },
  ]);

  useEffect(() => {
    setTimeout(() => {
      setExperienceDialog(browserName !== 'Chrome');
    }, 1000);
  }, []);

  const onNext = () => {
    if (activeStep == steps.length - 1) {
      return;
    }
    setActiveStep((prevState) => prevState + 1);
  };

  const toggleExperienceDialog = () => {
    setExperienceDialog(!experienceDialog);
  };

  const currentStep = steps[activeStep];

  const StepComponent = currentStep.component as any;

  return (
    <>
      <Grid container component="main" className="login__container-height">
        <CssBaseline />
        <Grid item xs={false} sm={4} md={8} borderRadius={0}>
          <video className="login__video-grid" autoPlay muted loop>
            <source src={RoofingVideo} type="video/mp4" />
          </video>
        </Grid>
        <Grid item xs={12} sm={8} md={4} component={Paper} elevation={6} square>
          <motion.div initial={{ y: 500 }} animate={animate} exit={exit}>
            <Box className="login__form-container">
              <img crossOrigin="anonymous" src={Logo} height={70} width={70} style={{ position: 'absolute', top: 5, right: 15 }} />
              <br></br>
              <br></br>
              <StepComponent onNext={onNext} />
            </Box>
          </motion.div>
        </Grid>
      </Grid>
      <Dialog open={experienceDialog} onClose={toggleExperienceDialog} aria-describedby="alert-dialog-slide-description">
        <DialogTitle>{'Please Note!'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {browserName !== 'other' && `We noticed you are using ${browserName}`}{' '}
            {browserName === 'other' && "We noticed you aren't using Chrome"} as your browser. For the best experience using this website,
            we recommend using{' '}
            <a
              target={'_blank'}
              href="https://www.google.com/chrome/?brand=YTUH&gclid=CjwKCAiA_6yfBhBNEiwAkmXy5x_jEwOha1Qkz-DtgdyXKlZgde4fWQtAt5D1jJVdakrT1U1bskFcrxoCoYgQAvD_BwE&gclsrc=aw.ds"
            >
              Chrome
            </a>{' '}
            as your browser.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleExperienceDialog}>OK</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
