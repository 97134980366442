import toast from 'react-hot-toast';
import { useForm } from 'react-hook-form';
import { useState, useEffect, Fragment, useRef } from 'react';
import { AppInput, ProfileBox } from '@components/Styled';
import FormControl from '@mui/material/FormControl';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { determineBorderColor } from '@core/utils/utils';
import FormHelperText from '@mui/material/FormHelperText';
import { CreateAdminSchema } from '@core/constants/schemas';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { RootState } from '@features/state/reducers/combinedReducers';
import { Box, Grid, Select, Button, MenuItem, InputLabel, Typography, CircularProgress, Card, Avatar } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useNavigate, useParams } from 'react-router';
import PhoneInput from 'react-phone-input-2';
import { CloudUploadFill } from '@styled-icons/bootstrap/CloudUploadFill';
import TransitionWrapper from '@components/TransitionWrapper';
import { PermissionPicker } from './components/PermissionPicker';
import { toggleLoading } from '@features/state/slices/local/loading-slice';
import { useCreateAdminMutation, useEditAdminMutation, useGetAdminByIdQuery } from '@features/state/slices/api/admin-slice';
import { BackButton } from '@components/BackButton';

export function EditAdmin() {
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(CreateAdminSchema),
  });
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [role, setRole] = useState('');
  const uploadInputRef = useRef<any>(null as any);
  const [displayUrl, setDisplayUrl] = useState('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [permissions, setPermissions] = useState<string[]>([]);
  const [editAdminMutation, response] = useEditAdminMutation();
  const [displayPhoto, setDisplayPhoto] = useState<File | Blob | null>(null);
  const { data, isError, isFetching, isSuccess } = useGetAdminByIdQuery({
    id,
  });

  useEffect(() => {
    if (!isError && !isFetching && isSuccess) {
      setRole(data.data?.role!);
      setValue('role', data.data?.role);
      setValue('name', data.data?.name);
      setValue('email', data.data?.email);
      setDisplayUrl(data.data?.profilePic!);
      setPhoneNumber(data.data?.phoneNumber!);
      setValue('phoneNumber', data.data?.phoneNumber);
    }
  }, [isError, isFetching, isSuccess]);

  const editAdmin = async (form: any) => {
    try {
      const formData = new FormData();

      formData.append('id', String(id));
      formData.append('role', form.role);
      formData.append('name', form.name);
      formData.append('email', form.email);

      if (displayPhoto) {
        formData.append('displayPhoto', displayPhoto!);
      }

      formData.append('phoneNumber', form.phoneNumber);

      dispatch(toggleLoading());
      const { data, error, success } = await editAdminMutation(formData).unwrap();

      if (!success && error) {
        toast.error(error.message!);
        dispatch(toggleLoading());
        return;
      }

      toast.success('Admin account successfully edited');
    } catch (e) {}

    dispatch(toggleLoading());
  };

  const handleReferenceClick = async () => uploadInputRef.current && uploadInputRef.current.click();

  const handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e?.target?.files![0];
    const objectURL = URL.createObjectURL(file);

    setDisplayPhoto(file);
    setDisplayUrl(objectURL);
  };

  return (
    <TransitionWrapper>
      <Card sx={{ p: 3 }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
          }}
        >
          <Box display="flex" justifyContent="space-between">
            <Box>
              <Box display="flex">
                <BackButton />
                <Typography ml={1} variant="h2">
                  Edit Admin
                </Typography>
              </Box>

              <Typography variant="subtitle1" mt={1}>
                Update this admin's account information
              </Typography>
            </Box>
            <ProfileBox sx={{ mb: 20, height: 200 }}>
              <Avatar alt="photoURL" src={displayUrl} sx={{ height: 180, width: 180 }} />
              <div className="d-flex p-3">
                <Fragment>
                  <input type="file" accept="image/*" ref={uploadInputRef} style={{ display: 'none' }} onChange={(e) => handleUpload(e)} />
                  <Button onClick={() => handleReferenceClick()} variant="contained">
                    Upload &nbsp;
                    <CloudUploadFill size="20" />
                  </Button>
                </Fragment>

                <Box ml={2} className="d-md-block text-start">
                  <div className="fw-normal">Choose Image</div>
                  <div style={{ marginBottom: 5 }} className="text-gray small mb-10">
                    JPG, GIF or PNG. Max size of 800K
                  </div>
                </Box>
              </div>
            </ProfileBox>
          </Box>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl variant="standard" style={{ width: '100%' }}>
                <InputLabel shrink htmlFor="bootstrap-input">
                  Name
                </InputLabel>
                <AppInput
                  fullWidth
                  type="name"
                  autoComplete="name"
                  style={determineBorderColor(errors?.email?.message as any)}
                  {...register('name', {
                    required: true,
                    maxLength: 80,
                  })}
                />

                <FormHelperText style={{ color: 'red' }}>{errors?.name?.message as string}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl variant="standard" style={{ width: '100%' }}>
                <InputLabel shrink htmlFor="bootstrap-input">
                  Email
                </InputLabel>
                <AppInput
                  fullWidth
                  type="text"
                  autoComplete="email"
                  style={determineBorderColor(errors?.email?.message as any)}
                  {...register('email', {
                    required: true,
                    maxLength: 80,
                  })}
                />
                <FormHelperText style={{ color: 'red' }}>{errors?.email?.message as string}</FormHelperText>
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={6} mt={0.7}>
              <InputLabel shrink htmlFor="bootstrap-input">
                Phone Number
              </InputLabel>
              <FormControl variant="standard" style={{ width: '100%' }}>
                <PhoneInput
                  country={'us'}
                  value={phoneNumber}
                  containerStyle={{
                    width: '100%',
                    height: 45,
                  }}
                  inputStyle={{ width: '100%', height: 45 }}
                  onChange={(phone) => {
                    setValue('phoneNumber', phone);
                    setPhoneNumber(phone);
                  }}
                />

                <FormHelperText style={{ color: 'red' }}>{errors?.phoneNumber?.message as string}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth style={{ marginTop: 17 }}>
                <InputLabel shrink htmlFor="bootstrap-input">
                  Role
                </InputLabel>
                <Select
                  label="Role"
                  value={role}
                  {...register('role')}
                  className="country-select__base"
                  onChange={(e) => {
                    setRole(e.target.value);
                    setValue('role', e.target.value);
                  }}
                >
                  <MenuItem value={'admin'}>Admin</MenuItem>
                  <MenuItem value={'super_admin'}>Super Admin</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          {/* <PermissionPicker
            permissions={permissions}
            onChange={(p) => setPermissions(p)}
          /> */}
        </Box>
        <Box mt={3} sx={{ float: 'right' }}>
          <LoadingButton size="medium" type="submit" variant="contained" loading={response.isLoading} onClick={handleSubmit(editAdmin)}>
            {response.isLoading ? <CircularProgress /> : 'Save Changes'}
          </LoadingButton>
        </Box>
        <br></br>
      </Card>
    </TransitionWrapper>
  );
}
