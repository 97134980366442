import { useState } from 'react';
import ProfilePhoto from '../ProfilePhoto';
import { useForm } from 'react-hook-form';
import { AppInput } from '@components/Styled';
import Center from '@components/Center/Center';
import { yupResolver } from '@hookform/resolvers/yup';
import { AdminDetailsEditSchema } from '@core/constants/schemas';
import { determineBorderColor, useAuth } from '@core/utils/utils';
import { Grid, Box, Card, Button, Typography, InputLabel, FormControl, FormHelperText } from '@mui/material';
import { useEditAdminUserMutation } from '@features/state/slices/api/admin-slice';
import { toast } from 'react-hot-toast';
import { updateUser } from '@features/state/slices/local/auth.slice';
import { useDispatch } from 'react-redux';
import { toggleLoading } from '@features/state/slices/local/loading-slice';

function EditProfileTab() {
  const { user } = useAuth();
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(AdminDetailsEditSchema),
    defaultValues: {
      name: user?.name,
      email: user?.email,
      phoneNumber: user?.phoneNumber,
    },
  });

  const [editAdminUser] = useEditAdminUserMutation();

  const handleDetailsSubmit = async (data: any) => {
    dispatch(toggleLoading());
    try {
      const { error, success } = await editAdminUser({ ...data, ...{ id: user?.id } }).unwrap();

      if (!success && error) {
        toast.error(error.message!);
        return;
      }

      dispatch(updateUser(data));

      toast.success('Account updated');
    } catch (e) {
      toast.error(String(e));
    } finally {
      dispatch(toggleLoading());
    }
  };

  return (
    <>
      <Center>
        <ProfilePhoto />
      </Center>
      <Card sx={{ p: 3 }}>
        <Box>
          <Typography variant="h4" gutterBottom>
            Personal Details
          </Typography>
          <Typography variant="subtitle2">Manage informations related to your personal details</Typography>
        </Box>
        <Grid container spacing={2} mt={2}>
          <Grid item xs={6}>
            <FormControl variant="standard" style={{ width: '100%' }}>
              <InputLabel shrink htmlFor="bootstrap-input">
                Name
              </InputLabel>
              <AppInput
                fullWidth
                type="name"
                autoComplete="name"
                style={determineBorderColor(errors?.name?.message as any)}
                {...register('name', {
                  required: true,
                  maxLength: 80,
                })}
              />

              <FormHelperText style={{ color: 'red' }}>{errors?.name?.message as string}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl variant="standard" style={{ width: '100%' }}>
              <InputLabel shrink htmlFor="bootstrap-input">
                Email
              </InputLabel>
              <AppInput
                fullWidth
                type="text"
                autoComplete="email"
                style={determineBorderColor(errors?.email?.message as any)}
                {...register('email', {
                  required: true,
                  maxLength: 80,
                })}
              />
              <FormHelperText style={{ color: 'red' }}>{errors?.email?.message as string}</FormHelperText>
            </FormControl>
          </Grid>
        </Grid>

        <Grid container spacing={2} mt={2}>
          <Grid item xs={6}>
            <FormControl variant="standard" style={{ width: '100%' }}>
              <InputLabel shrink htmlFor="bootstrap-input">
                Phone Number
              </InputLabel>
              <AppInput
                fullWidth
                type="phoneNumber"
                autoComplete="phoneNumber"
                style={determineBorderColor(errors?.phoneNumber?.message as any)}
                {...register('phoneNumber', {
                  required: true,
                  maxLength: 80,
                })}
              />

              <FormHelperText style={{ color: 'red' }}>{errors?.phoneNumber?.message as string}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={6}></Grid>
        </Grid>

        <Box display="flex" alignItems="flex-end" justifyContent="flex-end" mt={4}>
          <Button variant="contained" onClick={handleSubmit(handleDetailsSubmit)}>
            Save Changes
          </Button>
        </Box>
      </Card>
    </>
  );
}

export default EditProfileTab;
