import { alpha, Badge, Box, CircularProgress, Divider, IconButton, List, ListItem, Popover, Tooltip, Typography } from '@mui/material';
import moment from 'moment';
import { useRef, useState } from 'react';
import NotificationsActiveTwoToneIcon from '@mui/icons-material/NotificationsActiveTwoTone';
import { styled } from '@mui/material/styles';

import { formatDistance, subDays } from 'date-fns';
import { useGetNotificationsQuery } from '@features/state/slices/api/notification-slice';
import { useAuth } from '@core/utils/utils';
import { NotificationsBadge } from '@components/Styled';

function HeaderNotifications() {
  const { user } = useAuth();
  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  const { data, error, isError, isFetching } = useGetNotificationsQuery({
    id: user?.id,
  });

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip arrow title="Notifications">
        <IconButton color="primary" ref={ref} onClick={handleOpen}>
          <NotificationsBadge
            badgeContent={0}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <NotificationsActiveTwoToneIcon />
          </NotificationsBadge>
        </IconButton>
      </Tooltip>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box sx={{ p: 2 }} display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h5">Notifications</Typography>
        </Box>
        <Divider />
        {isFetching ? (
          <CircularProgress />
        ) : (
          <List sx={{ p: 0 }}>
            {data?.data?.items.map((notification) => (
              <ListItem
                sx={{
                  p: 2,
                  minWidth: 350,
                  display: { xs: 'block', sm: 'flex' },
                }}
              >
                <Box flex="1">
                  <Box display="flex" justifyContent="space-between">
                    {/* <Typography sx={{ fontWeight: "bold" }}>
                    Messaging Platform
                  </Typography> */}
                    <Typography variant="caption" sx={{ textTransform: 'none' }}>
                      {moment(notification.createdAt).fromNow(true)}
                    </Typography>
                  </Box>
                  <Typography component="span" variant="body2" color="text.secondary">
                    {notification.message}
                  </Typography>
                </Box>
              </ListItem>
            ))}
            {data?.data?.items.length == 0 && (
              <Box mt={4} width={350} height={70} display="flex" justifyContent="center">
                <Typography variant="subtitle1" sx={{ fontSize: 12 }}>
                  You have no notifications
                </Typography>
              </Box>
            )}
          </List>
        )}
      </Popover>
    </>
  );
}

export default HeaderNotifications;
