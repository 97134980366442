import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import {
  Select,
  MenuItem,
  FormControl,
  FormHelperText,

  SelectChangeEvent,
} from "@mui/material";

type Props = {
  name: any;
  label: any;
  error?: any;
  style?: any;
  classes?: any;
  countryISO?: any;
  helperText?: any;
  placeholder?: any;
  interval: string;
  onFocus?: (e: any) => void;
  onChange: (value: string) => void;
};

const selectUseStyles = makeStyles((theme: any) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  inputLabelRoot: {
    color: "white",
  },
}));

const intervals = [
  {
    title: "Daily",
    value: "day",
  },
  {
    title: "Monthly",
    value: "month",
  },
  {
    title: "Weekly",
    value: "week",
  },
  {
    title: "Yearly",
    value: "year",
  },
  
];

export const PackageIntervalSelect: React.FC<Props> = ({
  error,
  onChange,
  interval,
  helperText,
}) => {
  const selectClasses = selectUseStyles();

  const handleIntervalChange = async (e: SelectChangeEvent<any>) => {
    onChange(e.target.value);
  };

  return (
    <FormControl
      className={`${selectClasses.formControl} country-select__container`}
    >
      <Select
        error={error}
        value={interval}
        className="country-select__base"
        onChange={handleIntervalChange}
      >
        {intervals.map((i) => (
          <MenuItem key={i.value} value={i.value}>
            {i.title}
          </MenuItem>
        ))}
      </Select>

      <FormHelperText sx={{ color: "red" }}>{helperText}</FormHelperText>
    </FormControl>
  );
};
