import React from 'react';
import Center from '@components/Center/Center';
import { Box, Grid, Typography } from '@mui/material';

type Props = {
  subtitle: string;
  icon: JSX.Element;
  trailing?: JSX.Element;
};

export const EmptyTable: React.FC<Props> = ({ subtitle, icon, trailing }) => {
  return (
    <Box mt={10}>
      <Grid container justifyContent="center">
        <Center>
          {icon && icon}
          <Typography sx={{ color: '#909090' }} mt={1} textAlign="center" variant="subtitle2">
            {subtitle}
          </Typography>

          <Box mt={2}>{trailing && trailing}</Box>
        </Center>
      </Grid>
    </Box>
  );
};
