import { Navigator } from '../Navigator';
import { Box, Typography } from '@mui/material';
import { fadeInRight } from 'react-animations';
import TransitionWrapper from '@components/TransitionWrapper';
import Completion from '../../../../../../assets/images/video-complete.webp';
import { SubscriptionMode, VideoMode } from '@core/enums/enums';
import { useAuth } from '@core/utils/utils';

type Props = {
  data: any;
  steps: any;
  activeStep: number;
  subscriptionMode: SubscriptionMode;
  handleBack: () => void;
  handleNext: () => void;
  handleSkip: () => void;
  onCapture: (data: any) => void;
};

export const CompletionStep: React.FC<Props> = (props: Props) => {
  const { user } = useAuth();
  const { steps, activeStep, subscriptionMode, handleBack, handleNext, handleSkip } = props;

  return (
    <TransitionWrapper duration={0.2} animation={fadeInRight}>
      <Box display="flex" justifyContent="center" alignItems="center">
        <img crossOrigin="anonymous" height={400} width={650} src={Completion} />
        <Typography>
          {subscriptionMode === SubscriptionMode.Create
            ? `${user?.name}, you're all set now, click finish to create your subscription`
            : `${user?.name}, you're all set now, click finish to save your changes`}
        </Typography>
        <br></br>
      </Box>{' '}
      <Navigator steps={steps} handleSkip={handleSkip} handleBack={handleBack} activeStep={activeStep} handleNext={handleNext} />
    </TransitionWrapper>
  );
};
