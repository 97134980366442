import React, { useRef } from 'react';
import { useSnackbar } from 'notistack';
import { Toaster } from 'react-hot-toast';
import { CssBaseline } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import { getStripe } from '@core/utils/utils';
import { loadStripe } from '@stripe/stripe-js';

import { SnackBarButtonType } from '@core/types';
import { Router } from '@features/router/router';
import { Button, Typography } from '@mui/material';
import { Elements } from '@stripe/react-stripe-js';
import ThemeProvider from '../assets/theme/ThemeProvider';
import LoadingProvider from '@components/Loading/Loading';
import LoadingBar, { LoadingBarRef } from 'react-top-loading-bar';
import {
  Chart as ChartJS,
  Title,
  Legend,
  Tooltip,
  ArcElement,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  CategoryScale,
} from 'chart.js';
import '@assets/css/styles.css';
import '@assets/css/styles.scss';
import 'react-phone-input-2/lib/style.css';
import 'react-phone-number-input/style.css';
import { toastOptions } from '@core/constants/constants';

type Props = {};

ChartJS.register(ArcElement, LineElement, PointElement, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const stripePromise = loadStripe(getStripe()!);

const App: React.FunctionComponent<Props> = (props: Props) => {
  const ref = useRef<LoadingBarRef>(null);

  return (
    <Elements stripe={stripePromise}>
      <SnackbarProvider>
        <ThemeProvider>
          <Router />
          <CssBaseline />
          <LoadingProvider />
          <LoadingBar ref={ref} />
          <Toaster toastOptions={toastOptions} />
        </ThemeProvider>
      </SnackbarProvider>
    </Elements>
  );
};

export default App;
