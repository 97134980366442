import jwt_decode from 'jwt-decode';
import toast from 'react-hot-toast';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { Helmet } from 'react-helmet-async';
import { AppInput } from '@components/Styled';
import Logo from '@assets/images/small-logo.png';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState, MouseEvent, ChangeEvent, useEffect } from 'react';
import { SetPasswordSchema } from '@core/constants/schemas';
import TransitionWrapper from '@components/TransitionWrapper';
import PasswordStrengthBar from 'react-password-strength-bar';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { setAuth } from '@features/state/slices/local/auth.slice';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { determineBorderColor, useAuth } from '@core/utils/utils';
import { toggleLoading } from '@features/state/slices/local/loading-slice';
import { useGetAccessLogsQuery, useSetAdminPasswordMutation } from '@features/state/slices/api/auth-slice';
import {
  Box,
  Card,
  Grid,
  List,
  Table,
  Button,
  Divider,
  ListItem,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  CardHeader,
  InputLabel,
  Typography,
  FormControl,
  ListItemText,
  FormHelperText,
  TableContainer,
  TablePagination,
  useTheme,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import AppTablePagination from '@components/AppTablePagination';
import { RefreshButton } from '@components/RefreshButton';
import { AccessLog } from '@core/types';
import DatePicker from '@components/DatePicker';
import moment from 'moment';

function SecurityTab() {
  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(SetPasswordSchema),
  });

  const auth = useAuth();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [accessLogs, setAccessLogs] = useState<AccessLog[]>([]);

  const [query, setQuery] = useState({
    page: 1,
    limit: 10,
    startDate: null,
    endDate: null,
  });

  const [showNewPassword, setShowNewPassword] = useState(false);
  const [setAdminPasswordMutation] = useSetAdminPasswordMutation();
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const toggleNewPassword = () => setShowNewPassword(!showNewPassword);
  const toggleCurrentPassword = () => setShowCurrentPassword(!showCurrentPassword);
  const toggleConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);

  const { isFetching, isSuccess, isError, refetch, data } = useGetAccessLogsQuery(
    {
      params: query,
      adminId: auth?.user?.id,
    },
    { refetchOnFocus: true },
  );

  useEffect(() => {
    if (!isFetching && !isError && isSuccess) {
      setAccessLogs(data.data?.items ?? []);
    }
  }, [isFetching, isSuccess, isError]);

  const setAdminPassword = async (data: any) => {
    dispatch(toggleLoading());
    try {
      const result = await setAdminPasswordMutation({
        ...data,
        ...{ id: auth!.user!.id },
      }).unwrap();

      if (!result.success && result.error) {
        const message = result.error?.message as string;

        toast.error(message);

        dispatch(toggleLoading());
        return;
      }

      const token = result.data!;

      const { accessToken } = token;

      const user = jwt_decode<any>(accessToken);

      const authDto = {
        token,
        user: user.user,
        role: user.role,
      };

      reset();

      dispatch(setAuth(authDto));
    } catch (err: any) {
      toast.error(err.toString());
    }
    dispatch(toggleLoading());
  };

  const handlePaginationChanged = (pageLimit: number, pageNumber: number) => {
    setQuery((prev: any) => ({
      ...prev,
      page: pageNumber,
      limit: pageLimit,
    }));
  };

  const handleDateChange = (dates: string[]) => {
    if (dates.length > 1) {
      const startDate = dates[0];
      const endDate = dates[1];
      if (startDate == endDate) {
        setQuery((prev: any) => ({
          ...prev,
          startDate: startDate,
        }));
      } else {
        setQuery((prev: any) => ({
          ...prev,
          startDate,
          endDate,
        }));
      }
    }
  };

  return (
    <Grid sx={{ p: 2 }} container spacing={3}>
      <Grid item xs={12}>
        <Box pb={2}>
          <Typography variant="h3">Security</Typography>
          <Typography variant="subtitle2">Change your security preferences below</Typography>
        </Box>
        <Card>
          <List>
            <ListItem sx={{ p: 2 }}>
              <ListItemText
                sx={{ ml: 0 }}
                primaryTypographyProps={{ variant: 'h5', gutterBottom: true }}
                secondaryTypographyProps={{
                  variant: 'subtitle2',
                  lineHeight: 1,
                }}
                primary="Change Password"
                secondary="Admin, you can change your account password here. This essential feature allows you to update your current password for improved
                security."
              />
            </ListItem>
          </List>

          <Box pl={3} pr={3}>
            <div className="flex justify-between space-x-5">
              <Box mt={1} width="100%">
                <FormControl variant="standard" style={{ width: '100%' }}>
                  <InputLabel shrink htmlFor="bootstrap-input">
                    Current Password
                  </InputLabel>
                  <AppInput
                    required
                    id="currentPassword"
                    fullWidth={true}
                    placeholder="Enter your current password"
                    style={determineBorderColor(errors?.oldPassword?.message as any)}
                    {...register('oldPassword', {
                      required: true,
                      maxLength: 80,
                    })}
                    type={showCurrentPassword ? 'text' : 'password'}
                  />

                  <div onClick={toggleCurrentPassword} className="login__password-icon">
                    {showCurrentPassword ? (
                      <RemoveRedEyeIcon className="pointer" fontSize="small" />
                    ) : (
                      <VisibilityOffIcon className="pointer" fontSize="small" />
                    )}
                  </div>

                  <FormHelperText style={{ color: 'red' }}>{errors?.oldPassword?.message as string}</FormHelperText>
                </FormControl>
              </Box>
              <Box mt={1} width="100%">
                <FormControl variant="standard" style={{ width: '100%' }}>
                  <InputLabel shrink htmlFor="bootstrap-input">
                    New Password
                  </InputLabel>
                  <AppInput
                    required
                    id="newPassword"
                    fullWidth={true}
                    placeholder="Enter your new password"
                    style={determineBorderColor(errors?.newPassword?.message as any)}
                    {...register('newPassword', {
                      required: true,
                      maxLength: 80,
                    })}
                    type={showNewPassword ? 'text' : 'password'}
                  />

                  <div onClick={toggleNewPassword} className="login__password-icon">
                    {showNewPassword ? (
                      <RemoveRedEyeIcon className="pointer" fontSize="small" />
                    ) : (
                      <VisibilityOffIcon className="pointer" fontSize="small" />
                    )}
                  </div>

                  {/* <PasswordStrengthBar password={watch<string>('newPassword')} /> */}

                  <FormHelperText style={{ color: 'red' }}>{errors?.newPassword?.message as string}</FormHelperText>
                </FormControl>
              </Box>
              <Box mt={1} width="100%">
                <FormControl variant="standard" style={{ width: '100%' }}>
                  <InputLabel shrink htmlFor="bootstrap-input">
                    Confirm New Password
                  </InputLabel>

                  <AppInput
                    required
                    id="password"
                    fullWidth={true}
                    placeholder="Confirm your new password"
                    style={determineBorderColor(errors?.confirmPassword?.message as any)}
                    {...register('confirmPassword', {
                      required: true,
                      maxLength: 80,
                    })}
                    type={showConfirmPassword ? 'text' : 'password'}
                  />

                  <div onClick={toggleConfirmPassword} className="login__password-icon">
                    {showConfirmPassword ? (
                      <RemoveRedEyeIcon className="pointer" fontSize="small" />
                    ) : (
                      <VisibilityOffIcon className="pointer" fontSize="small" />
                    )}
                  </div>
                  {/* <PasswordStrengthBar password={watch<string>('confirmPassword')} /> */}
                  <FormHelperText style={{ color: 'red' }}>{errors?.confirmPassword?.message as string}</FormHelperText>
                </FormControl>
              </Box>
            </div>

            <Box mt={1}>
              <Button
                type="submit"
                variant="contained"
                className="login__button"
                style={{ float: 'right' }}
                onClick={handleSubmit(setAdminPassword)}
              >
                Change Password
              </Button>
            </Box>
          </Box>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <div className="flex justify-between mb-3 mt-3">
          <div />
          <div className="flex justify-end space-x-3 items-center">
            <DatePicker sx={{ mr: 1 }} onDateChange={handleDateChange} />
            <RefreshButton onRefresh={refetch} />
          </div>
        </div>

        <Card>
          <CardHeader
            subheaderTypographyProps={{}}
            titleTypographyProps={{}}
            title="Access Logs"
            subheader="Recent sign in activity logs"
          />
          <Divider />
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Browser</TableCell>
                  <TableCell>IP Address</TableCell>
                  <TableCell>Location</TableCell>
                  <TableCell>Date/Time</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {accessLogs.map((log) => (
                  <TableRow key={log.id} hover>
                    <TableCell>{log.device}</TableCell>
                    <TableCell>{log.ipAddress}</TableCell>
                    <TableCell>{log.location}</TableCell>
                    <TableCell>{moment(log.createdAt).format('LL')}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
        <AppTablePagination totalPages={data?.data?.meta?.totalPages ?? 0} onChange={handlePaginationChanged} />
      </Grid>
    </Grid>
  );
}

export default SecurityTab;
