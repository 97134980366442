import { Button } from '@mui/material';
import { toast } from 'react-hot-toast';
import Center from '@components/Center/Center';

import { EditingMode, VideoEditorConfiguration } from '@core/types';
import { secondsToTimeString, sliderValueToVideoTime, useAuth } from '@core/utils/utils';
import AppService from '@core/services/AppService';

type Props = {
  videoFile: any;
  sliderValues: any;
  videoPlayerState: any;
  config: VideoEditorConfiguration;
  onLoading: (loading: boolean) => void;
  onConversionEnd: (file: File) => void;
  onConversionStart: (hasStarted: boolean) => void;
};

function VideoConversionButton({
  config,
  videoFile,
  sliderValues,
  onConversionEnd,
  videoPlayerState,
  onConversionStart,
  onLoading,
}: Props) {
  const trimVideo = async () => {
    // starting the conversion process
    onLoading(true);
    onConversionStart(true);

    const formData = new FormData();

    const [min, max] = sliderValues;
    const minTime = secondsToTimeString(sliderValueToVideoTime(videoPlayerState.duration, min)).trim().toString();
    const duration = secondsToTimeString(sliderValueToVideoTime(videoPlayerState.duration, max - min))
      .trim()
      .toString();

    formData.append('video', videoFile);
    formData.append('startTime', minTime);
    formData.append('endTime', duration);

    const result = await AppService.trimVideo(formData);

    if (!result.success) {
      toast.error('An error has occured');
      return;
    }

    const file = new File([result.data], 'video.mp4', { type: 'video/mp4' });

    onLoading(false);
    onConversionEnd(file);
  };

  const generateThumbnail = async () => {
    onLoading(true);
    onConversionStart(true);

    const formData = new FormData();

    const [min, max] = sliderValues;
    const minTime = secondsToTimeString(sliderValueToVideoTime(videoPlayerState.duration, min)).trim().toString();
    const duration = secondsToTimeString(sliderValueToVideoTime(videoPlayerState.duration, max - min))
      .trim()
      .toString();

    formData.append('video', videoFile);
    formData.append('timestamp', minTime);

    const result = await AppService.generateThumbnail(formData);

    if (!result.success) {
      toast.error('An error has occured');
      return;
    }

    const file = new File([result.data], 'thumbnail.png', { type: 'image/png' });

    onLoading(false);
    onConversionEnd(file);
  };

  const handleOperation = async () => {
    if (config.mode == EditingMode.preview) {
      await trimVideo();
    } else {
      await generateThumbnail();
    }
  };

  return (
    <Center>
      <br></br>
      <Button variant="outlined" onClick={handleOperation}>
        {config.mode == EditingMode.preview ? 'Trim Video' : 'Create Thumbnail'}
      </Button>
    </Center>
  );
}

export default VideoConversionButton;
