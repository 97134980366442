import { useState } from 'react';
import jwt_decode from 'jwt-decode';
import toast from 'react-hot-toast';
import { Roles } from '@core/enums/enums';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { Helmet } from 'react-helmet-async';
import { AppInput } from '@components/Styled';
import Logo from '@assets/images/small-logo.png';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { determineBorderColor } from '@core/utils/utils';
import PasswordStrengthBar from 'react-password-strength-bar';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { RootState } from '@features/state/reducers/combinedReducers';
import { toggleLoading } from '@features/state/slices/local/loading-slice';
import { ResetPasswordSchema, SetPasswordSchema } from '@core/constants/schemas';
import { useResetPasswordMutation } from '@features/state/slices/api/admin-slice';
import { Box, Card, Grid, Button, InputLabel, Typography, FormControl, FormHelperText } from '@mui/material';
import { ForgotPassword, setForgotPasswordEmail, setForgotPasswordToken } from '@features/state/slices/local/forgot.password.slice';

type Props = {
  onNext: () => void;
};
const ResetPassword: React.FunctionComponent<Props> = ({}) => {
  const {
    watch,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(ResetPasswordSchema),
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [resetPasswordMutation] = useResetPasswordMutation();
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const toggleNewPassword = () => setShowNewPassword(!showNewPassword);
  const toggleConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);

  const { email, token } = useSelector<RootState>((state) => state.forgotPassword) as ForgotPassword;

  const resetPassword = async (data: any) => {
    dispatch(toggleLoading());
    try {
      const { error, success } = await resetPasswordMutation({
        email,
        token,
        userRole: Roles.Admin,
        newPassword: data.newPassword,
        confirmPassword: data.confirmPassword,
      }).unwrap();

      if (error && !success) {
        toast.error(error.message!);
        return;
      }

      dispatch(setForgotPasswordEmail(null));
      dispatch(setForgotPasswordToken(null));

      navigate('/auth/login');

      toast.success('Password reset!');
    } catch (err: any) {
      toast.error(String(err));
    } finally {
      dispatch(toggleLoading());
    }
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box mb={5}>
        <Typography variant="h2">Change your Password</Typography>
        <Typography variant="subtitle1" mt={1}>
          Choose a strong password
        </Typography>
      </Box>
      <Box mt={1}>
        <FormControl variant="standard" style={{ width: '100%' }}>
          <InputLabel shrink htmlFor="bootstrap-input">
            New Password
          </InputLabel>
          <AppInput
            required
            id="newPassword"
            fullWidth={true}
            placeholder="Enter your new password"
            style={determineBorderColor(errors?.newPassword?.message as any)}
            {...register('newPassword', {
              required: true,
              maxLength: 80,
            })}
            type={showNewPassword ? 'text' : 'password'}
          />

          <div onClick={toggleNewPassword} className="login__password-icon">
            {showNewPassword ? (
              <RemoveRedEyeIcon className="pointer" fontSize="small" />
            ) : (
              <VisibilityOffIcon className="pointer" fontSize="small" />
            )}
          </div>

          <PasswordStrengthBar password={watch<string>('newPassword')} />

          <FormHelperText style={{ color: 'red' }}>{errors?.newPassword?.message as string}</FormHelperText>
        </FormControl>
      </Box>

      <Box mt={1}>
        <FormControl variant="standard" style={{ width: '100%' }}>
          <InputLabel shrink htmlFor="bootstrap-input">
            Confirm New Password
          </InputLabel>

          <AppInput
            required
            id="password"
            fullWidth={true}
            placeholder="Confirm your new password"
            style={determineBorderColor(errors?.confirmPassword?.message as any)}
            {...register('confirmPassword', {
              required: true,
              maxLength: 80,
            })}
            type={showConfirmPassword ? 'text' : 'password'}
          />

          <div onClick={toggleConfirmPassword} className="login__password-icon">
            {showConfirmPassword ? (
              <RemoveRedEyeIcon className="pointer" fontSize="small" />
            ) : (
              <VisibilityOffIcon className="pointer" fontSize="small" />
            )}
          </div>
          <PasswordStrengthBar password={watch<string>('confirmPassword')} />
          <FormHelperText style={{ color: 'red' }}>{errors?.confirmPassword?.message as string}</FormHelperText>
        </FormControl>
      </Box>
      <Box mt={1}>
        <Button
          type="submit"
          variant="contained"
          className="login__button"
          style={{ float: 'right' }}
          onClick={handleSubmit(resetPassword)}
        >
          Change Password
        </Button>
      </Box>
    </Box>
  );
};
export default ResetPassword;
