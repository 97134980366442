import { useState, useEffect } from 'react';

type Props = {
  reset: number;
  initialTime: number;
};

const CountdownTimer = (props: Props) => {
  const { reset, initialTime } = props;
  const [time, setTime] = useState(initialTime);

  useEffect(() => {
    if (time <= 0) {
      // Time has reached 0, stop the timer
      return;
    }

    // Function to update the time every second
    const timer = setInterval(() => {
      setTime((prevTime) => {
        if (prevTime <= 0) {
          clearInterval(timer);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    // Clean up the interval when the component is unmounted
    return () => {
      clearInterval(timer);
    };
  }, [time]);

  useEffect(() => {
    if (reset) {
      // Reset the time when the "reset" prop changes
      setTime(initialTime);
    }
  }, [reset, initialTime]);

  // Calculate minutes and seconds from the remaining time
  const minutes = Math.floor(time / 60);
  const seconds = time % 60;

  // Format the time with leading zeros if needed
  const formattedMinutes = String(minutes).padStart(2, '0');
  const formattedSeconds = String(seconds).padStart(2, '0');

  return (
    <div>
      {formattedMinutes}:{formattedSeconds}
    </div>
  );
};

export default CountdownTimer;
