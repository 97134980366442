import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Pagination, Typography } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

const FilterBox = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  marginTop: '0.5rem',
  marginBottom: '1rem',
  justifyContent: 'space-between',
}));

interface FilterProps {
  totalPages: number;
  onChange: (limit: number, page: number) => void;
  contentAvailable?: boolean;
}

const AppTablePagination = ({ totalPages, onChange, contentAvailable = true }: FilterProps) => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState('10');

  const [entriesToShow] = useState(['5', '10', '20', '30']);

  const onPaginationChange = (event: React.ChangeEvent<unknown>, currentPage: number) => {
    setPage(currentPage);
    const numLimit = parseInt(limit);
    onChange(numLimit, currentPage);
  };

  const onLimitChange = (event: SelectChangeEvent) => {
    const limit = parseInt(event.target.value);

    setLimit(`${limit}`);
    setPage(1);
    onChange(limit, 1);
  };

  if (!contentAvailable) {
    return <></>;
  }

  return (
    <FilterBox>
      <Box
        sx={{
          width: 155,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="subtitle1">Show</Typography>
        <FormControl size="small">
          <Select
            sx={{
              height: 35,
              borderRadius: 0.5,
              backgroundColor: '#fcfcfb',
              //  background: "#FFFFFF 0% 0% no-repeat padding-box"
            }}
            value={limit}
            onChange={onLimitChange}
          >
            {entriesToShow.map((item) => (
              <MenuItem value={item}>{item}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <Typography variant="subtitle1">entries</Typography>
      </Box>
      <Pagination
        color="primary"
        shape="rounded"
        variant="outlined"
        page={page}
        count={totalPages ?? 1}
        style={{ marginTop: 20 }}
        onChange={onPaginationChange}
      />
    </FilterBox>
  );
};

export default AppTablePagination;
