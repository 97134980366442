import { useSelector } from 'react-redux';
import CustomSelect, { StyledButton, StyledOption } from '@components/CustomSelect/CustomSelect';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';

import { Select, FormControl, InputLabel, FormHelperText, MenuItem, alpha, SelectChangeEvent } from '@mui/material';
import RenderDelegate from '@components/RenderDelegate/RenderDelegate';

const selectUseStyles = makeStyles((theme: any) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  inputLabelRoot: {
    color: 'white',
  },
}));

type Props = {
  name: any;
  label: any;
  error?: any;
  style?: any;
  provinces: any;
  helperText?: any;
  placeholder?: any;
  selectedProvince: any;
  onFocus?: (e: any) => void;
  onChange: (province: any) => void;
};

const ProvinceSelect: React.FC<Props> = ({
  name,
  error,
  label,
  onFocus,
  onChange,
  provinces,
  helperText,
  placeholder,
  selectedProvince,
}: Props) => {
  const selectClasses = selectUseStyles();

  // const index = provinces?.findIndex(
  //   (provinceInstance: any) => provinceInstance?.iso2 === selectedProvince?.iso2
  // );

  useEffect(() => {
    if (selectedProvince?.iso2 !== null && selectedProvince?.iso2 !== '0000' && provinces?.length > 0) {
      const index = provinces?.findIndex((provinceInstance: any) => {
        // console.log(provinceInstance)

        try {
          if (provinceInstance?.iso2 !== undefined) {
            return provinceInstance?.iso2 === selectedProvince?.iso2;
          }
          return false;
        } catch (err) {
          // console.log(err);
          return false;
        }
      });

      onChange(provinces[index]);
    }
  }, [provinces, selectedProvince]);

  const handleStateChange = async (e: SelectChangeEvent<any>) => {
    onChange(e.target.value);
  };

  return (
    <FormControl className={`${selectClasses.formControl} country-select__container`}>
      <RenderDelegate
        condition={provinces?.length > 0}
        renderComponent={
          <Select name={name} error={error} onChange={handleStateChange} value={selectedProvince} className="country-select__base">
            {Array.isArray(provinces)
              ? provinces?.map((province: any, indx: any) => {
                  return (
                    <MenuItem key={indx} value={province?.iso2}>
                      {province?.name}
                    </MenuItem>
                  );
                })
              : null}
          </Select>
        }
        fallBackComponent={<StyledButton />}
      />
      <FormHelperText className="error-text">{helperText}</FormHelperText>
    </FormControl>
  );
};

export default ProvinceSelect;
