import React, { FC, ChangeEvent, useState, useEffect } from 'react';

import {
  Tooltip,
  Divider,
  Box,
  Grid,
  Stack,
  FormControl,
  InputLabel,
  Card,
  Checkbox,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Select,
  MenuItem,
  Typography,
  useTheme,
  CardHeader,
  Button,
  TextField,
  LinearProgress,
  InputAdornment,
  Pagination,
  Link,
  Avatar,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { ServiceResult } from '@core/index';
import { formatPhoneNumber, roleMapper } from '@core/utils/utils';
import BulkActions from '@components/BulkActions/BulkActions';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import { Admin, PaginatedList, Query } from '@core/constants/constants';
import { Retry } from '@components/Retry';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { AppInput, StyledSearch } from '@components/Styled';
import SearchIcon from '@mui/icons-material/Search';
import { useDeleteAdminMutation } from '@features/state/slices/api/admin-slice';
import { toggleLoading } from '@features/state/slices/local/loading-slice';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { Navigate, useNavigate } from 'react-router';
import Filter from '@components/Filter';
import Iconify from '@components/iconify';
import { RefreshButton } from '@components/RefreshButton';
import { Transition } from 'framer-motion';
import moment from 'moment';
import AppFilter from '@components/Filter';
import AppChip from '@components/AppChip';
import AppTablePagination from '@components/AppTablePagination';
import { EmptyTable } from '@components/EmptyTable';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import GroupsIcon from '@mui/icons-material/Groups';

type Props = {
  loading: boolean;
  error: any | undefined;
  refetch: any;
  onChange: (query: Query) => void;
  data: ServiceResult<PaginatedList<Admin[]>, any> | undefined;
};

const applyFilters = (admins: Admin[], filters: any): any[] => {
  return admins.filter((admin) => {
    let matches = true;

    if (filters.role && admin.role !== admin.role) {
      matches = false;
    }

    return matches;
  });
};

export function AdminTable({ data, loading, error, refetch, onChange }: Props) {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(10);
  const [admins, setAdmins] = useState<Admin[]>([]);
  const [adminId, setAdminId] = useState<number>(0);
  const [openFilter, setOpenFilter] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [selectedAdmins, setSelectedAdmins] = useState<number[]>([]);
  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);

  const [filters, setFilters] = useState<any>({
    status: null,
  });

  const [deleteAdminMutation, result] = useDeleteAdminMutation();

  const selectedBulkActions = selectedAdmins.length > 0;

  const open = Boolean(anchorEl);

  const handleMenuOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const toggleDeleteDialog = () => {
    handleMenuClose();
    setDeleteDialog(!deleteDialog);
  };

  useEffect(() => {
    if (!loading && !error) {
      setAdmins(data?.data?.items ?? []);
    }
  }, [loading, error, data]);

  const statusOptions = [
    {
      id: 'super_admin',
      name: 'Super Admin',
    },
    {
      id: 'admin',
      name: 'Admin',
    },
  ];

  const handleStatusChange = (e: any): void => {
    let value: any = null;

    if (e.target.value !== 'all') {
      value = e.target.value;
    }

    setFilters((prevFilters: any) => ({
      ...prevFilters,
      status: value,
    }));
  };

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  const handleSelectAllCryptoOrders = (event: ChangeEvent<HTMLInputElement>): void => {
    setSelectedAdmins(event.target.checked ? admins.map((admin) => admin.id) : []);
  };

  const handleSelectOneAdmin = (event: ChangeEvent<HTMLInputElement>, adminId: number): void => {
    if (!selectedAdmins.includes(adminId)) {
      setSelectedAdmins((prevSelected) => [...prevSelected, adminId]);
    } else {
      setSelectedAdmins((prevSelected) => prevSelected.filter((id) => id !== adminId));
    }
  };

  const handlePaginationChanged = (limit: number, page: number) => {
    setPage(page);
    setLimit(limit);

    onChange({
      page,
      limit,
      searchTerm,
    });
    refetch();
  };

  const handleSearch = (event: any) => {
    setSearchTerm(event.target.value);
    setTimeout(
      () =>
        onChange({
          page,
          limit,
          searchTerm: event.target.value,
        }),
      1000,
    );
  };

  const handleAdminEdit = (id: number) => {
    navigate(`edit/${id}`);
  };

  const handleAdminDelete = async (id: number) => {
    try {
      dispatch(toggleLoading());
      const { data, error, success } = await deleteAdminMutation({
        id,
      }).unwrap();

      if (!success && error) {
        toast.error(error.message!);
        dispatch(toggleLoading());
        return;
      }

      toast.success('Admin successfully deleted');

      refetch();
    } catch (e) {}
    toggleDeleteDialog();
    dispatch(toggleLoading());
  };

  const selectedSomeAdmins = admins.length > 0 && admins.length < admins.length;
  const selectedAllAdmins = selectedAdmins.length === admins.length;

  return (
    <>
      {!error ? (
        <React.Fragment>
          <Card>
            {selectedBulkActions && (
              <Box flex={1} p={2}>
                <BulkActions />
              </Box>
            )}
            {!selectedBulkActions && (
              <CardHeader
                style={{ fontWeight: 'light' }}
                action={
                  <Box
                    sx={{
                      float: 'right',
                      display: 'flex',
                      justifyContent: 'end',
                      alignItems: 'center',
                    }}
                  >
                    <Box>
                      {selectedFilters.map((filter) => (
                        <Tooltip title={filter} placement="top">
                          <AppChip sx={{ ml: 1 }}>{filter}</AppChip>
                        </Tooltip>
                      ))}
                    </Box>
                    <Box display="flex" alignItems="center">
                      <AppFilter
                        openFilter={openFilter}
                        onOpenFilter={handleOpenFilter}
                        selectedFilters={selectedFilters}
                        onCloseFilter={handleCloseFilter}
                        onFilterChange={setSelectedFilters}
                        filters={[
                          {
                            title: 'Status',
                            filters: ['Admin', 'Super Admin'],
                          },
                        ]}
                      />
                    </Box>
                    <Box>
                      <RefreshButton onRefresh={refetch} />
                    </Box>
                  </Box>
                }
                title={
                  <StyledSearch
                    fullWidth
                    onChange={handleSearch}
                    placeholder="Search Admins"
                    startAdornment={
                      <InputAdornment position="start">
                        <Iconify
                          icon="eva:search-fill"
                          sx={{
                            color: 'text.disabled',
                            width: 20,
                            height: 20,
                          }}
                        />
                      </InputAdornment>
                    }
                    endAdornment={<InputAdornment position="start">{loading && <CircularProgress size={20} />}</InputAdornment>}
                  />
                }
              />
            )}
            <Divider />
            <TableContainer>
              <Table>
                {loading && <LinearProgress />}
                {!loading && (
                  <>
                    <TableHead>
                      <TableRow>
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={selectedAllAdmins}
                            indeterminate={selectedSomeAdmins}
                            onChange={handleSelectAllCryptoOrders}
                          />
                        </TableCell>
                        <TableCell>ID</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Phone Number</TableCell>
                        <TableCell>Role</TableCell>
                        <TableCell>Last Login</TableCell>
                        <TableCell>Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {admins.map((admin: Admin) => {
                        const isAdminSelected = selectedAdmins.includes(admin.id);
                        return (
                          <TableRow
                            hover
                            key={admin.id}
                            // sx={{ cursor: "pointer" }}
                            selected={isAdminSelected}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                color="primary"
                                checked={isAdminSelected}
                                onChange={(event: ChangeEvent<HTMLInputElement>) => handleSelectOneAdmin(event, admin.id)}
                                value={isAdminSelected}
                              />
                            </TableCell>
                            <TableCell>
                              {/* <Tooltip title={admin.id} arrow> */}
                              <Typography variant="body1" fontWeight="bold" color="text.primary" gutterBottom noWrap>
                                {admin.id}
                              </Typography>
                              {/* </Tooltip> */}
                            </TableCell>
                            <TableCell
                              onClick={(event: any) => {
                                handleAdminEdit(admin.id);
                              }}
                            >
                              <Tooltip title={admin.name} arrow>
                                <Box display="flex" alignItems="center" sx={{ cursor: 'pointer' }}>
                                  <Avatar alt={admin.name} src={admin?.profilePic} />
                                  <Typography ml={1} noWrap variant="body1" color="text.secondary">
                                    {admin.name}
                                  </Typography>
                                </Box>
                              </Tooltip>
                            </TableCell>
                            <TableCell>
                              {/* <Tooltip title={admin.email} arrow> */}
                              <Typography variant="body2" color="text.secondary" gutterBottom noWrap>
                                {admin.email}
                              </Typography>
                              {/* </Tooltip> */}
                            </TableCell>
                            <TableCell>
                              {/* <Tooltip
                                title={formatPhoneNumber(admin.phoneNumber)}
                                arrow
                              > */}
                              <Typography variant="body2" color="text.secondary" gutterBottom noWrap>
                                {formatPhoneNumber(admin.phoneNumber) ?? 'N/A'}
                              </Typography>
                              {/* </Tooltip> */}
                            </TableCell>
                            <TableCell>
                              {/* <Tooltip title={roleMapper(admin.role)} arrow> */}
                              <Typography variant="body2" color="text.secondary" noWrap>
                                {roleMapper(admin.role)}
                              </Typography>
                              {/* </Tooltip> */}
                            </TableCell>
                            <TableCell>
                              {' '}
                              {/* <Tooltip title={admin.lastLogin} arrow> */}
                              <Typography variant="body2" color="text.secondary" noWrap>
                                {admin.lastLogin !== null ? moment(admin.lastLogin).format('L') : 'N/A'}
                              </Typography>
                              {/* </Tooltip> */}
                            </TableCell>
                            <TableCell>
                              <Tooltip title="Edit" arrow>
                                <IconButton
                                  size="small"
                                  color="inherit"
                                  onClick={() => handleAdminEdit(admin.id)}
                                  sx={{
                                    '&:hover': {
                                      background: theme.colors.primary.lighter,
                                    },
                                    color: theme.palette.primary.main,
                                  }}
                                >
                                  <EditTwoToneIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Delete" arrow>
                                <IconButton
                                  size="small"
                                  color="inherit"
                                  onClick={() => {
                                    toggleDeleteDialog();
                                    setAdminId(admin.id);
                                  }}
                                  sx={{
                                    '&:hover': {
                                      background: theme.colors.error.lighter,
                                    },
                                    color: theme.palette.error.main,
                                  }}
                                >
                                  <DeleteTwoToneIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                    <Dialog open={deleteDialog} onClose={toggleDeleteDialog} aria-describedby="alert-dialog-slide-description">
                      <DialogTitle>{'Delete Admin'}</DialogTitle>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                          Are you sure you want to delete this admin?
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={toggleDeleteDialog}>No</Button>
                        <Button onClick={() => handleAdminDelete(adminId)}>Yes, Delete Admin</Button>
                      </DialogActions>
                    </Dialog>
                  </>
                )}
              </Table>
            </TableContainer>
          </Card>
          {data?.data?.items?.length != null && data?.data?.items?.length == 0 && (
            <EmptyTable
              subtitle="There are currently no admins accounts on the system"
              icon={<GroupsIcon sx={{ color: theme.colors.primary.dark, fontSize: 130 }} />}
            />
          )}
          {!loading && data?.data?.items?.length != null && data?.data?.items?.length > 0 && (
            <AppTablePagination onChange={handlePaginationChanged} totalPages={data?.data?.meta.totalPages ?? 1} />
          )}
        </React.Fragment>
      ) : (
        <Retry refetch={refetch} />
      )}
    </>
  );
}
