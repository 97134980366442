import * as yup from "yup";
import { Navigator } from "../Navigator";
import { useForm } from "react-hook-form";
import { AppInput } from "@components/Styled";
import { yupResolver } from "@hookform/resolvers/yup";
import { determineBorderColor } from "@core/utils/utils";
import {
  Box,
  InputLabel,
  Typography,
  FormControl,
  FormHelperText,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { fadeInRight } from "react-animations";
import TransitionWrapper from "@components/TransitionWrapper";
import KeywordSelector from "@components/KeywordSelector";
import { HelperType, VideoMode } from "@core/enums/enums";
import { CreateVideoDTO } from "@core/constants/constants";
import { HelperText } from "@components/HelperText";

type Props = {
  data: CreateVideoDTO;
  steps: any;
  activeStep: number;
  videoMode: VideoMode;
  handleBack: () => void;
  handleNext: () => void;
  handleSkip: () => void;
  onCapture: (data: any) => void;
};

const schema = yup.object().shape({
  name: yup.string().required("Video name is required"),
  description: yup.string().required("Description is required"),
});

export const VideoLabellingStep: React.FC<Props> = (props: Props) => {
  const {
    data,
    steps,
    activeStep,
    videoMode,
    onCapture,
    handleBack,
    handleNext,
    handleSkip,
  } = props;
  const [name, setName] = useState(data.name);
  const [description, setDescription] = useState(data.description);
  const [keywords, setKeywords] = useState<string[]>([]);

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useMemo(() => {
    if (videoMode === VideoMode.Edit) {
      setName(data?.name);
      setDescription(data?.description);
      setKeywords(data?.keywords);
      setValue("keywords", data?.keywords);
    }
  }, [videoMode]);

  const captureData = (data: any) => {
    handleNext();
    onCapture(data);
  };

  return (
    <TransitionWrapper duration={0.2} animation={fadeInRight}>
      <Box mt={2} mb={5}>
        {/* <HelperText message="Title your video so users can find it easier"/> */}

        <HelperText
          title="Title your video"
          subtitle="This will help your customers find your videos with ease"
        />
        <FormControl
          variant="standard"
          style={{ width: "100%", marginTop: 15 }}
        >
          <InputLabel shrink htmlFor="bootstrap-input">
            Title
          </InputLabel>
          <AppInput
            required
            fullWidth
            id="name"
            value={name}
            {...register("name", {
              required: true,
              maxLength: 80,
            })}
            style={determineBorderColor(errors?.name?.message as any)}
            onChange={(event) => {
              setName(event.target.value);
              setValue("name", event.target.value);
            }}
          />
          <FormHelperText style={{ color: "red" }}>
            {errors?.name?.message as string}
          </FormHelperText>
        </FormControl>

        <FormControl
          variant="standard"
          style={{ width: "100%", marginTop: 15 }}
        >
          <InputLabel shrink htmlFor="bootstrap-input">
            Description
          </InputLabel>
          <AppInput
            required
            fullWidth
            id="description"
            {...register("description", {
              required: true,
              maxLength: 80,
            })}
            value={description}
            multiline
            rows={4}
            maxRows={3}
            style={determineBorderColor(errors?.description?.message as any)}
            onChange={(event) => {
              setDescription(event.target.value);
              setValue("description", event.target.value);
            }}
          />
          <FormHelperText style={{ color: "red" }}>
            {errors?.description?.message as string}
          </FormHelperText>
        </FormControl>

        <Navigator
          steps={steps}
          handleSkip={handleSkip}
          handleBack={handleBack}
          activeStep={activeStep}
          handleNext={handleSubmit(captureData)}
        />
      </Box>
    </TransitionWrapper>
  );
};
