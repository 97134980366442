import React from 'react';
import { Grid, Box } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useTheme } from '@mui/material/styles';
import DashboardNewUsers from './components/DashboardActiveUsers';
import { DashboardTrafficChart } from './components/DashboardTrafficChart';
import DashboardTotalVideoViews from './components/DashboardTotalVideoViews';
import DashboardPerformanceChart from './components/DashboardPerformanceChart';
import DashboardSubscriptionRevenue from './components/DashboardSubscriptionRevenue';
import DashboardPlatformSubjectChart from './components/DashboardPlatformSubjectChart';
import { trafficeShareChartData, appPerformanceChartData, platformSubjectChartData } from './components/chart-data';

export default function DashboardAppPage() {
  const theme = useTheme();

  return (
    <React.Fragment>
      <Helmet>
        <title> Dashboard </title>
      </Helmet>
      <Box>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <DashboardNewUsers />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <DashboardTotalVideoViews />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <DashboardSubscriptionRevenue />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <DashboardTrafficChart title="Traffic Share" data={trafficeShareChartData} />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <DashboardPerformanceChart
              title="Perfomance"
              subheader="(+43%) than last year"
              chartData={appPerformanceChartData.metrics}
              chartLabels={appPerformanceChartData.labels}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <DashboardPlatformSubjectChart
              title="Platform Subject"
              chartData={platformSubjectChartData.metrics}
              chartLabels={platformSubjectChartData.labels}
              chartColors={[...Array(6)].map(() => theme.palette.text.secondary)}
            />
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
}
