import { useAddPaymentMethodMutation } from "@features/state/slices/api/payment-methods.slice";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { PaymentMethodSchema } from "@core/constants/schemas";
import { useDispatch } from "react-redux";
import { toggleLoading } from "@features/state/slices/local/loading-slice";
import { ApiError } from "@core/index";
import toast from "react-hot-toast";
import { useEffect, useState } from "react";
import { determineBorderColor, useAuth } from "@core/utils/utils";
import { useParams } from "react-router";
import Card from "./Card";
import { AppInput } from "@components/Styled";
import ProvinceSelect from "@components/ProvinceSelect/ProvinceSelect";
import CountrySelect from "@components/CountrySelect/CountrySelect";
import { LoadingButton } from "@mui/lab";

const PaymentMethodsForm = ({ close }: { close: () => void }) => {
  const {
    watch,
    reset,
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(PaymentMethodSchema),
  });

  const { id } = useParams();
  const { user } = useAuth();
  const dispatch = useDispatch();
  const [state, setState] = useState("AR");
  const [country, setCountry] = useState("US");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [currentStates, setCurrentStates] = useState([]);
  const [addPaymentMethod, response] = useAddPaymentMethodMutation();

  const submitPaymentMethod = async (data: any) => {
    const newPaymentMethod = formatData(data);
    try {
      const apiResponse = await addPaymentMethod(newPaymentMethod).unwrap();

      if (apiResponse.success) {
        toast.success("Payment method successfully added");
        reset();
        close();
      } else {
        toast.error(apiResponse?.error?.message ?? "An errorr occured");
      }
    } catch (err: any) {
      toast.error(err.toString());
    }
  };

  const formatData = (data: any) => {
    return {
      cvc: data.cvc,
      number: data.number,
      expMonth: data.expMonth,
      expYear: data.expYear,
      companyId: id,
      address: {
        city: data.city,
        country: data.country,
        line1: data.line1,
        line2: data.line2,
        postalCode: data.postalCode,
        state: data.state,
      },
      details: {
        name: data.name,
        email: data.email,
        phone: data.phoneNumber,
      },
    };
  };

  return (
    <Box>
      <Typography variant="h4" mb={4}>
        Add Payment Information
      </Typography>

      <Card
        number={watch("number") ?? ""}
        name={watch("name") ?? ""}
        expiry={`${watch("expMonth") ?? ""}/${watch("expYear") ?? ""}`}
        cvc={watch("cvc") ?? ""}
        focused={true}
      />

      <Divider
        light
        variant="fullWidth"
        sx={{
          mt: 3,
          ml: 1,
          width: "100%",
        }}
      />
      <Box mt={2}>
        <FormControl variant="standard" style={{ width: "100%" }}>
          <InputLabel shrink htmlFor="bootstrap-input">
            Card number
          </InputLabel>
          <AppInput
            {...register("number", {
              required: true,
            })}
            style={determineBorderColor(errors?.number?.message as any)}
            name={"number"}
            placeholder={"Card number"}
          />
        </FormControl>

        <FormHelperText style={{ color: "red" }}>
          {errors?.number?.message as string}
        </FormHelperText>
      </Box>

      <Box mt={2}>
        <FormControl variant="standard" style={{ width: "100%" }}>
          <InputLabel shrink htmlFor="bootstrap-input">
            Name on card
          </InputLabel>
          <AppInput
            {...register("name", {
              required: true,
              maxLength: 80,
            })}
            style={determineBorderColor(errors?.name?.message as any)}
            name={"name"}
            placeholder={"Name on card"}
          />
        </FormControl>

        <FormHelperText style={{ color: "red" }}>
          {errors?.name?.message as string}
        </FormHelperText>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Box mt={2}>
            <FormControl variant="standard" style={{ width: "100%" }}>
              <InputLabel shrink htmlFor="bootstrap-input">
                Expiration Month
              </InputLabel>
              <AppInput
                {...register("expMonth", {
                  required: true,
                  maxLength: 80,
                })}
                style={determineBorderColor(errors?.expMonth?.message as any)}
                name={"expMonth"}
                placeholder={"Expiration Month"}
              />
            </FormControl>

            <FormHelperText style={{ color: "red" }}>
              {errors?.expMonth?.message as string}
            </FormHelperText>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box mt={2}>
            <FormControl variant="standard" style={{ width: "100%" }}>
              <InputLabel shrink htmlFor="bootstrap-input">
                Expiration Year
              </InputLabel>
              <AppInput
                {...register("expYear", {
                  required: true,
                  maxLength: 80,
                })}
                style={determineBorderColor(errors?.expYear?.message as any)}
                name={"expYear"}
                placeholder={"Expiration Year"}
              />
            </FormControl>

            <FormHelperText style={{ color: "red" }}>
              {errors?.expYear?.message as string}
            </FormHelperText>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box mt={2}>
            <FormControl variant="standard" style={{ width: "100%" }}>
              <InputLabel shrink htmlFor="bootstrap-input">
                CVC
              </InputLabel>
              <AppInput
                {...register("cvc", {
                  required: true,
                  maxLength: 80,
                })}
                style={determineBorderColor(errors?.cvc?.message as any)}
                name={"cvc"}
                placeholder={"CVC"}
              />
            </FormControl>

            <FormHelperText style={{ color: "red" }}>
              {errors?.cvc?.message as string}
            </FormHelperText>
          </Box>
        </Grid>
      </Grid>

      <Box mt={2}>
        <FormControl variant="standard" style={{ width: "100%" }}>
          <InputLabel shrink htmlFor="bootstrap-input">
            Address Line 1
          </InputLabel>
          <AppInput
            {...register("line1", {
              required: true,
              maxLength: 80,
            })}
            style={determineBorderColor(errors?.line1?.message as any)}
            name={"line1"}
            placeholder={"Address Line 1"}
          />
        </FormControl>

        <FormHelperText style={{ color: "red" }}>
          {errors?.line1?.message as string}
        </FormHelperText>
      </Box>

      <Box mt={2}>
        <FormControl variant="standard" style={{ width: "100%" }}>
          <InputLabel shrink htmlFor="bootstrap-input">
            Address Line 2
          </InputLabel>
          <AppInput
            {...register("line2", {
              required: true,
              maxLength: 80,
            })}
            style={determineBorderColor(errors?.line2?.message as any)}
            name={"line2"}
            placeholder={"Address Line 2"}
          />
        </FormControl>

        <FormHelperText style={{ color: "red" }}>
          {errors?.line2?.message as string}
        </FormHelperText>
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormControl variant="standard" style={{ width: "100%" }}>
            <InputLabel shrink htmlFor="bootstrap-input">
              Country
            </InputLabel>
            <br></br>
            <CountrySelect
              name="country"
              label="Country"
              country={country}
              //  countryISO={user?.countryISO}
              helperText={errors.country?.message}
              error={errors.country?.message !== undefined}
              setCurrentStates={setCurrentStates}
              onChange={(country, provinces) => {
                setCountry(country);
                setCurrentStates(provinces);
                setValue("country", country, {
                  shouldValidate: true,
                });
              }}
            />
            <FormHelperText style={{ color: "red" }}>
              {errors?.country?.message as string}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl variant="standard" style={{ width: "100%" }}>
            <InputLabel shrink htmlFor="bootstrap-input">
              State
            </InputLabel>
            <br></br>
            <ProvinceSelect
              name="state"
              label="State"
              selectedProvince={state}
              provinces={currentStates}
              error={errors.state?.message !== undefined}
              helperText={errors.state?.message}
              onChange={(state) => {
                if (!state) {
                  return;
                }
                setState(state);
                setValue("state", state, {
                  shouldValidate: true,
                });
              }}
            />
            <FormHelperText style={{ color: "red" }}>
              {errors?.state?.message as string}
            </FormHelperText>
          </FormControl>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Box mt={2}>
            <FormControl variant="standard" style={{ width: "100%" }}>
              <InputLabel shrink htmlFor="bootstrap-input">
                City
              </InputLabel>
              <AppInput
                {...register("city", {
                  required: true,
                  maxLength: 80,
                })}
                style={determineBorderColor(errors?.city?.message as any)}
                name={"city"}
                placeholder={"City"}
              />
            </FormControl>

            <FormHelperText style={{ color: "red" }}>
              {errors?.city?.message as string}
            </FormHelperText>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box mt={2}>
            <FormControl variant="standard" style={{ width: "100%" }}>
              <InputLabel shrink htmlFor="bootstrap-input">
                Postal Code
              </InputLabel>
              <AppInput
                {...register("postalCode", {
                  required: true,
                  maxLength: 80,
                })}
                style={determineBorderColor(errors?.postalCode?.message as any)}
                name={"postalCode"}
                placeholder={"Postal Code"}
              />
            </FormControl>

            <FormHelperText style={{ color: "red" }}>
              {errors?.postalCode?.message as string}
            </FormHelperText>
          </Box>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Box mt={2}>
            <FormControl variant="standard" style={{ width: "100%" }}>
              <InputLabel shrink htmlFor="bootstrap-input">
                Email
              </InputLabel>
              <AppInput
                {...register("email", {
                  required: true,
                  maxLength: 80,
                })}
                style={determineBorderColor(errors?.email?.message as any)}
                name={"email"}
                placeholder={"Email"}
              />
            </FormControl>

            <FormHelperText style={{ color: "red" }}>
              {errors?.email?.message as string}
            </FormHelperText>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box mt={2}>
            <FormControl variant="standard" style={{ width: "100%" }}>
              <InputLabel shrink htmlFor="bootstrap-input">
                Phone Number
              </InputLabel>
              <AppInput
                {...register("phoneNumber", {
                  required: true,
                  maxLength: 80,
                })}
                style={determineBorderColor(
                  errors?.phoneNumber?.message as any
                )}
                name={"phoneNumber"}
                placeholder={"Phone Number"}
              />
            </FormControl>

            <FormHelperText style={{ color: "red" }}>
              {errors?.phoneNumber?.message as string}
            </FormHelperText>
          </Box>
        </Grid>
      </Grid>

      <Box sx={{ float: "right", display: "flex" }} mt={2}>
        <Button sx={{ mr: 3 }} onClick={close}>
          Close
        </Button>
        <LoadingButton
          fullWidth
          variant="contained"
          loading={response.isLoading}
          disabled={response.isLoading}
          onClick={handleSubmit(submitPaymentMethod)}
        >
          Save
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default PaymentMethodsForm;
