export class AppRoutes {
  static readonly AuthBase = 'auth';
  static readonly AdminBase = 'admins';
  static readonly VideosBase = 'videos';
  static readonly RouteBase = 'dashboard';
  static readonly CompanyBase = 'company-accounts';
  static readonly RequestBase = 'request';
  static readonly PackageBase = 'packages';
  static readonly SettingBase = 'settings';
  static readonly FeedbackBase = 'feedback';
  static readonly SubscriptionBase = 'subscriptions';
  static readonly ConfigBase = 'utilities';
  static readonly PromotionBase = 'promotions';
  static readonly CustomVideoBase = 'custom-videos';

  static readonly Dashboard = {
    App: `dashboard`,
  };

  static readonly Auth = {
    Login: `login`,
    SetPassword: `set-password`,
    ForgetPassword: `forgot-password`,
  };

  static readonly Promotions = {};

  static readonly Company = {
    Create: `create`,
    Update: `update/:id`,
    AddPackage: `add-package`,
    ManageAnalytics: `:id/analytics`,
    ManageSubscription: `manage-subscription`,
  };

  static readonly Admin = {
    Create: `create`,
    Edit: `edit/:id`,
  };

  static readonly Configure = {
    Videos: `video-categories`,
    Packages: `package-categories`,
  };

  static readonly VideoCategory = {
    Edit: `edit/:id`,
    Create: `create`,
  };

  static readonly PackageCategory = {
    Edit: `edit/:id`,
    Create: `create`,
  };

  static readonly Promos = {
    CustomVideos: `custom-videos`,
  };

  static readonly Videos = {
    Standard: `standard`,
    Custom: `custom`,
  };

  static readonly CustomVideos = {
    Upload: `upload`,
    Stream: `stream/:id`,
    Preview: `preview`,
    Edit: `edit/:id`,
  };

  static readonly StandardVideos = {
    Upload: `upload`,
    Stream: `stream/:id`,
    Preview: `preview`,
    Edit: `edit/:id`,
  };

  static readonly BannerAds = {
    VideoAds: `video-ads`,
  };

  static readonly BannerVideos = {
    Upload: `create`,
    Edit: `edit/:id`,
    Stream: `stream/:id`,
  };

  static readonly Packages = {
    Create: `create`,
    Edit: `edit/:id`,
  };

  static readonly Feedback = {};
  static readonly Settings = {
    ResetPassword: `reset-password`,
  };
  static readonly Subscription = {
    Create: `create`,
    /// `edit/:id`
    Edit: `edit/:id`,
  };
}
