import { useState } from 'react';
import { Button } from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Helmet } from 'react-helmet-async';
import { Query } from '@core/constants/constants';
import AppPageHeader from '@components/PageHeader';
import { CompanyTable } from './components/CompanyTable';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import TransitionWrapper from '@components/TransitionWrapper';
import { RootState } from '@features/state/reducers/combinedReducers';
import { useGetCompaniesQuery } from '@features/state/slices/api/company-slice';

function ManageCompany() {
  const navigate = useNavigate();

  const user = useSelector<RootState, any>((state) => state.auth!.auth!.user);

  const [query, setQuery] = useState<Query>({
    page: 1,
    limit: 10,
    searchTerm: '',
  });

  const { data, error, isLoading, refetch } = useGetCompaniesQuery(
    {
      limit: query.limit,
      page: query.page,
      searchTerm: query.searchTerm,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const navigateToCreateCompany = () => {
    navigate('create');
  };

  return (
    <TransitionWrapper>
      <Helmet>
        <title>Manage Company Accounts</title>
      </Helmet>

      <AppPageHeader
        title="Company Accounts"
        subtitle={`${user?.name}, these are your recent company accounts`}
        trailing={
          <Button variant="contained" sx={{ mt: { xs: 2, md: 0 } }} onClick={navigateToCreateCompany}>
            Create Company
          </Button>
        }
      />

      <CompanyTable data={data} error={error} refetch={refetch} loading={isLoading} onChange={setQuery} />
    </TransitionWrapper>
  );
}

export default ManageCompany;
