import * as yup from "yup";
import { Navigator } from "../Navigator";
import { useForm } from "react-hook-form";
import { AppInput } from "@components/Styled";
import { yupResolver } from "@hookform/resolvers/yup";
import PercentIcon from "@mui/icons-material/Percent";
import { determineBorderColor } from "@core/utils/utils";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import {
  Box,
  InputLabel,
  Typography,
  FormControl,
  FormHelperText,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import TransitionWrapper from "@components/TransitionWrapper";
import { fadeInRight } from "react-animations";
import { VideoMode } from "@core/enums/enums";
import { CreateVideoDTO } from "@core/constants/constants";
import { HelperText } from "@components/HelperText";

type Props = {
  data: CreateVideoDTO;
  steps: any;
  activeStep: number;
  videoMode: VideoMode;
  handleBack: () => void;
  handleNext: () => void;
  handleSkip: () => void;
  onCapture: (data: any) => void;
};

const schema = yup.object().shape({
  price: yup
    .number()
    .required("Price is required")
    .min(1, "Price must be greater than 1$")
    .positive(),
});

export const VideoPriceStep: React.FC<Props> = (props: Props) => {
  const {
    data,
    steps,
    activeStep,
    onCapture,
    handleBack,
    handleNext,
    handleSkip,
    videoMode,
  } = props;

  const [price, setPrice] = useState(data.discount);


  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useMemo(() => {
    if (videoMode === VideoMode.Edit) {
      setPrice(data?.price);

    }
  }, [videoMode]);

  const captureData = (data: any) => {
    handleNext();
    onCapture(data);
  };

  return (
    <TransitionWrapper duration={0.2} animation={fadeInRight}>
      <Box mt={2} mb={5}>
        <HelperText
          title="Price your video"
          subtitle="Add a quote for customers to purchase your video"
        />

        <FormControl
          variant="standard"
          style={{ width: "100%", marginTop: 15 }}
        >
          <InputLabel shrink htmlFor="bootstrap-input">
            Price
          </InputLabel>
          <AppInput
            required
            fullWidth
            id="price"
            type="number"
            value={price}
            {...register("price", {
              required: true,
              maxLength: 80,
            })}
            style={determineBorderColor(errors?.price?.message as any)}
            onChange={(event) => {
              setPrice(event.target.value);
              setValue("price", event.target.value);
            }}
          />
          <div className="input-icon">
            <AttachMoneyIcon style={{ width: 20, color: "grey" }} />
          </div>
        </FormControl>
        <FormHelperText style={{ color: "red" }}>
          {errors?.price?.message as string}
        </FormHelperText>


        <Navigator
          steps={steps}
          handleSkip={handleSkip}
          handleBack={handleBack}
          activeStep={activeStep}
          handleNext={handleSubmit(captureData)}
        />
      </Box>
    </TransitionWrapper>
  );
};
