import * as React from "react";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import { CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@features/state/reducers/combinedReducers";

const style = {
  p: 4,
  top: "50%",
  left: "50%",
  width: 400,
  color: "white",
  position: "absolute" as any,
  transform: "translate(-50%, -50%)",
  "&:focus": {
    border: "none",
    outline: "none",
    opacity: [0.9, 0.8, 0.7],
  },
};
type Props = {};

const LoadingProvider: React.FC<Props> = (props: Props) => {
  const loading = useSelector((state: RootState) => state?.loading.isLoading);

  return (
    <div>
      <Modal
        BackdropProps={{
          timeout: 500,
        }}
        closeAfterTransition
        className="modal-zindex"
        open={loading ?? false}
        BackdropComponent={Backdrop}
      >
        <Fade in={loading ?? false}>
          <Box sx={style}>
            <CircularProgress color="inherit" />
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default LoadingProvider;
