import { LoadingButton } from '@mui/lab';
import { useEffect, useRef, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import Center from '@components/Center/Center';
import { useNavigate, useParams } from 'react-router';
import { CompanyBaseSubscription } from '@core/types';
import {
  useGetCompanyBaseSubscriptionQuery,
  useCancelCompanyBaseSubscriptionMutation,
  useDeleteCompanyBaseSubscriptionMutation,
} from '@features/state/slices/api/subscription-slice';
import {
  Box,
  Card,
  Button,
  Slide,
  Dialog,
  useTheme,
  Typography,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  DialogContentText,
  IconButton,
} from '@mui/material';
import React from 'react';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import AppChip from '@components/AppChip';
import { SubscriptionStatus } from '@core/enums/enums';
import { RefreshButton } from '@components/RefreshButton';
import { toggleLoading } from '@features/state/slices/local/loading-slice';
import { API_FETCH_ARGS, PaginatedList } from '@core/constants/constants';
import MessageDisplay, { IMessageDisplayRef } from '@components/MessageDIsplay/MessageDisplay';
import { BootstrapDialogTitle } from '@components/BootstrapDialog';
import { AppInput, BootstrapDialog } from '@components/Styled';
import { AppDialog, IDialogRef } from '@components/AppDialog';

const Transition = React.forwardRef(function Transition(props, ref) {
  const SlideComponent = Slide as any;
  return <SlideComponent direction="up" ref={ref} {...props} />;
});

type Props = {};

const ManageCompanySubscriptionCard: React.FC<Props> = () => {
  const theme = useTheme();

  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const dialogRef = React.createRef<IDialogRef>();
  const messageRef = React.createRef<IMessageDisplayRef>();
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [cancelDialog, setCancelDialog] = useState(false);
  const [selectedSusbcription, setSelectedSubscription] = useState<CompanyBaseSubscription | null>(null);
  const [subscription, setSubscription] = useState<PaginatedList<CompanyBaseSubscription[]> | null>(null);
  const { error, isLoading, isError, isSuccess, data, refetch } = useGetCompanyBaseSubscriptionQuery(
    {
      id,
    },
    API_FETCH_ARGS,
  );

  const [cancelSusbcriptionMutation, response] = useCancelCompanyBaseSubscriptionMutation();

  const [deleteSusbcriptionMutation] = useDeleteCompanyBaseSubscriptionMutation();

  useEffect(() => {
    if (isSuccess && !isError && !isLoading) {
      console.log(`SUB DATA ${JSON.stringify(data.data!.items)}`);
      setSubscription(data.data!);
    }
  }, [isLoading, isError, isSuccess]);

  const toggleCancelDialog = () => {
    setCancelDialog(!cancelDialog);
  };

  const toggleDeleteDialog = () => {
    setDeleteDialog(!deleteDialog);
  };

  const navigateToManageSubscriptions = () => {
    navigate(`manage-subscription`);
  };

  const determineStatusColor = (index: number) => {
    if (subscription?.items[index].status == SubscriptionStatus.ACTIVE) {
      return 'success';
    } else if (subscription?.items[index].status == SubscriptionStatus.EXPIRED) {
      return 'error';
    } else if (subscription?.items[index].status == SubscriptionStatus.INCOMPLETE) {
      return 'default';
    } else if (subscription?.items[index].status == SubscriptionStatus.FAILED) {
      return 'error';
    }
  };

  const onRefresh = async () => {
    const result = await refetch().unwrap();

    if (!result.success) {
      toast.error(result.error?.message!);
    }

    setSubscription(result.data!);
  };

  const cancelSubscription = async () => {
    toggleCancelDialog();

    try {
      const result = await cancelSusbcriptionMutation({
        subscriptionId: selectedSusbcription?.id,
        stripeSubscriptionId: selectedSusbcription?.stripeSubscriptionId,
      }).unwrap();

      if (result.success) {
        messageRef.current?.showSuccess('Subscription successfully cancelled');

        setTimeout(async () => {
          await onRefresh();
        }, 1000);
      } else {
        messageRef.current?.showError(result.error?.message!);
      }
    } catch (err) {
      messageRef.current?.showError('An error occured');
    }
  };

  const deleteSubscription = async () => {
    toggleDeleteDialog();

    try {
      const result = await deleteSusbcriptionMutation({
        id: selectedSusbcription?.stripeSubscriptionId,
      }).unwrap();

      if (result.success) {
        messageRef.current?.showSuccess('Subscription successfully deleted');
        onRefresh();
      } else {
        messageRef.current?.showError(result.error?.message!);
      }
    } catch (err) {
      messageRef.current?.showError('An error occured');
    }
  };

  const isEmpty = subscription?.items.length != null && subscription?.items.length == 0;

  return (
    <Card sx={{ p: 3, mt: 3 }}>
      <Box display="flex" justifyContent="space-between" alignItems="start">
        <Box>
          <Typography variant="h2">Manage Subscriptions</Typography>
          <Typography variant="subtitle1" mt={1}>
            Add, update or remove subscriptions for a company
          </Typography>
        </Box>

        <Box>
          <IconButton
            sx={{
              '&:hover': {
                background: theme.colors.primary.lighter,
              },
              color: theme.palette.primary.main,
            }}
            color="inherit"
            size="small"
            onClick={navigateToManageSubscriptions}
          >
            <AddIcon fontSize="small" />
          </IconButton>
          <RefreshButton onRefresh={onRefresh} />
        </Box>
      </Box>

      <Box>
        {isLoading && <CircularProgress />}

        {isEmpty && (
          <Center>
            <Box p={2}>
              {' '}
              <Center>
                <Typography variant="subtitle1" mt={1} mb={1}>
                  This company has no active subscriptions
                </Typography>
                <Button variant="contained" onClick={navigateToManageSubscriptions} startIcon={<AddIcon fontSize="small" />}>
                  Add Subscription
                </Button>{' '}
              </Center>
            </Box>
          </Center>
        )}

        {subscription?.items != null && (
          <Box>
            {subscription?.items?.map((subscription, index) => {
              return (
                <Box
                  mt={1}
                  sx={{
                    backgroundColor: theme.palette.grey[50],
                    '&:hover': {
                      backgroundColor: theme.palette.grey[100],
                      cursor: 'pointer',
                      '& .addIcon': {
                        color: 'purple',
                      },
                    },
                  }}
                >
                  <div className="px-3 py-4 border  rounded mt-4 d-flex justify-content-between">
                    <div className="d-flex flex-row align-items-center">
                      <img crossOrigin="anonymous" src="https://i.imgur.com/S17BrTx.webp" className="rounded" width="60" />
                      <div className="d-flex flex-column ms-4">
                        <span className="h5 mb-1">{subscription?.subscriptionName}</span>

                        <Box display="flex" alignItems="center">
                          <span>
                            <div style={{ fontSize: 17 }} className="text-muted font-weight-bold">
                              ${subscription?.subscriptionAmount} / month
                            </div>
                          </span>
                          <Box ml={1}>
                            <AppChip color={determineStatusColor(index)}>{subscription?.subscriptionStatus?.name}</AppChip>
                          </Box>
                        </Box>
                        <span className="small text-muted mt-1">Next Bill Bate: {subscription.nextBillDate}</span>
                      </div>
                    </div>
                    <div className="d-flex flex-row align-items-center">
                      <span className="h2 mx-1 mb-0"></span>

                      {/* FIXME DEPRECATED Cancel Button should be re-installed in weekend */}
                      {subscription.status == SubscriptionStatus.ACTIVE && (
                        <Box mt={1}>
                          <LoadingButton
                            size="medium"
                            type="submit"
                            color="error"
                            sx={{ ml: 1 }}
                            variant="outlined"
                            loading={response.isLoading}
                            onClick={() => {
                              setSelectedSubscription(subscription);
                              dialogRef.current?.openDialog();
                            }}
                          >
                            Cancel
                          </LoadingButton>
                        </Box>
                      )}
                    </div>
                  </div>
                </Box>
              );
            })}
          </Box>
        )}
      </Box>

      <AppDialog
        ref={dialogRef}
        title="Cancel Subscription"
        onConfirm={cancelSubscription}
        subtitle="Are you sure you want to cancel this company's subscription?. This will subsequently revoke their access to any custom video that was assigned to them?"
      />

      <MessageDisplay ref={messageRef} />
    </Card>
  );
};

export default ManageCompanySubscriptionCard;
