import toast from 'react-hot-toast';
import { motion } from 'framer-motion';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import PhoneInput from 'react-phone-input-2';
import CheckIcon from '@mui/icons-material/Check';
import { BackButton } from '@components/BackButton';
import FormControl from '@mui/material/FormControl';
import { useNavigate, useParams } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';
import { determineBorderColor } from '@core/utils/utils';
import FormHelperText from '@mui/material/FormHelperText';
import { AppInput, ProfileBox } from '@components/Styled';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import { CreateCompanySchema } from '@core/constants/schemas';
import TransitionWrapper from '@components/TransitionWrapper';
import { useState, useEffect, Fragment, useRef } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CountrySelect from '@components/CountrySelect/CountrySelect';
import AddCompanyCredits from './components/AddCompanyCredits';
import ProvinceSelect from '@components/ProvinceSelect/ProvinceSelect';
import { CloudUploadFill } from '@styled-icons/bootstrap/CloudUploadFill';
import { toggleLoading } from '@features/state/slices/local/loading-slice';
import { useResendCompanyInviteMutation } from '@features/state/slices/api/admin-slice';
import { Box, Grid, Button, InputLabel, Typography, Card, Avatar, Switch, Tooltip } from '@mui/material';
import MessageDisplay, { IMessageDisplayRef } from '@components/MessageDIsplay/MessageDisplay';
import ManageCompanyPackagesCard from './components/ManageCompanyPackagesCard/ManageCompanyPackages';
import { useEditCompanyMutation, useGetCompanyByIdQuery } from '@features/state/slices/api/company-slice';
import ManageCompanySubscriptions from './components/ManageCompanySubscriptionCard/ManageCompanySubscriptionCard';
import ManageCompanyCustomVideoCard from './components/ManageCompanyCustomVideoCard/ManageCompanyCustomVideoCard';

type Props = {
  show: boolean;
  onCreate: () => void;
  handleClose: () => void;
};

let easing = [0.6, -0.05, 0.01, 0.99];

const animate = {
  opacity: 1,
  y: 0,
  transition: {
    duration: 0.6,
    ease: easing,
    delay: 0.16,
  },
};

export function ManageCompanySubscriptionEditCompany() {
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(CreateCompanySchema),
  });

  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [state, setState] = useState('AR');
  const [country, setCountry] = useState('US');
  const messageRef = useRef<IMessageDisplayRef>();
  const uploadInputRef = useRef<any>(null as any);
  const [displayUrl, setDisplayUrl] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [currentStates, setCurrentStates] = useState([]);
  const [editCompanyMutation, response] = useEditCompanyMutation();
  const [resendCompanyInviteMutation] = useResendCompanyInviteMutation();
  const [displayPhoto, setDisplayPhoto] = useState<File | Blob | null>(null);
  const { data, isError, isFetching, isSuccess } = useGetCompanyByIdQuery({
    id,
  });
  const [isGranted, setIsGranted] = useState(true);

  //TODO Add company watermark toggle here
  useEffect(() => {
    setState(data?.data?.state!);
    setCountry(data?.data?.country!);
    setValue('city', data?.data?.city);
    setValue('name', data?.data?.name);
    setValue('email', data?.data?.email);
    setValue('state', data?.data?.state);
    setDisplayUrl(data?.data?.profilePic!);
    setValue('country', data?.data?.country);
    setPhoneNumber(data?.data?.phoneNumber!);

    setValue('postalCode', data?.data?.postalCode);
    setValue('phoneNumber', data?.data?.phoneNumber);
    setValue('addressLine1', data?.data?.addressLine1);
    setValue('addressLine2', data?.data?.addressLine2);
    setIsGranted(data?.data?.canAccessWatermark ?? false);
  }, [isError, isFetching, isSuccess]);

  //TODO Add Watermark Access Toggle here as well
  const editCompany = async (form: any) => {
    try {
      const formData = new FormData();

      formData.append('id', String(id));
      formData.append('city', form.city);
      formData.append('name', form.name);
      formData.append('email', form.email);
      formData.append('state', form.state);
      formData.append('country', form.country);

      if (displayPhoto) {
        formData.append('displayPhoto', displayPhoto!);
      }

      formData.append('postalCode', form.postalCode);
      formData.append('phoneNumber', form.phoneNumber);
      formData.append('addressLine1', form.addressLine1);
      formData.append('addressLine2', form.addressLine2);
      formData.append('canAccessWatermark', isGranted ? 'true' : 'false');
      console.log('FORMDATA', Object.fromEntries(formData.entries()));
      dispatch(toggleLoading());
      const { data, error, success } = await editCompanyMutation(formData).unwrap();

      if (!success && error) {
        messageRef!.current!.showError(error.message!);
        dispatch(toggleLoading());
        return;
      }

      messageRef!.current!.showSuccess('Your changes have been saved.');

      navigate(`/dashboard/company-accounts/update/${id}`);
    } catch (e: any) {
      messageRef!.current!.showError(e.toString());
    }

    dispatch(toggleLoading());
  };

  const handleReferenceClick = async () => uploadInputRef.current && uploadInputRef.current.click();

  const handleUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    let file = e?.target?.files![0];

    if (file) {
      const extension = file.name.split('.').pop();
      if (extension !== 'png') {
        toast.error('Image must be a png file');
        return;
      }
    }

    const objectURL = URL.createObjectURL(file);

    setDisplayPhoto(file);
    setDisplayUrl(objectURL);
  };

  const resendCompanyInvite = async () => {
    dispatch(toggleLoading());
    try {
      const { success, error } = await resendCompanyInviteMutation({
        email: data?.data?.email,
      }).unwrap();

      if (!success || error) {
        toast.error(error?.message!);
        return;
      }

      toast.success('Company invite successfully resent');
    } catch (err: any) {
      toast.error(err.toString());
    } finally {
      dispatch(toggleLoading());
    }
  };

  const navigateToAnalytics = () => {
    navigate(`/dashboard/company-accounts/${id}/analytics`);
  };

  const handleToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsGranted(event.target.checked);
  };
  // console.log(`FROM Company ${JSON.stringify(data?.data)}`);
  return (
    <TransitionWrapper>
      <Card sx={{ p: 3 }}>
        <Box sx={{ gap: 3, display: 'flex', flexDirection: 'column' }}>
          <Box justifyContent="space-between">
            <Box>
              <Box display="flex">
                <BackButton />
                <Typography ml={1} variant="h2">
                  Edit {data?.data?.name}
                </Typography>
              </Box>

              <Typography variant="subtitle1" mt={1} mb={3}>
                Update this company's account information
              </Typography>
            </Box>

            <Box display="flex" justifyContent="space-between">
              <ProfileBox sx={{ p: 1, height: 200 }}>
                <Avatar alt="photoURL" src={displayUrl} sx={{ height: 180, width: 180 }} />

                <div className="d-flex p-3">
                  <Fragment>
                    <input
                      type="file"
                      accept="image/*"
                      ref={uploadInputRef}
                      style={{ display: 'none' }}
                      onChange={(e) => handleUpload(e)}
                    />
                    <Button onClick={() => handleReferenceClick()} variant="contained">
                      Upload &nbsp;
                      <CloudUploadFill size="20" />
                    </Button>
                  </Fragment>

                  <Box ml={2} className="d-md-block text-start">
                    <Typography variant="subtitle1">Choose Image</Typography>
                    <Typography style={{ marginBottom: 5, fontSize: 12 }} variant="subtitle1" className="text-gray small mb-10">
                      Must be a PNG. Square logos must have equal dimensions eg. 500 x 500
                    </Typography>
                  </Box>
                </div>
              </ProfileBox>

              <Box mt={20} alignSelf="flex-end">
                <Button startIcon={<EqualizerIcon />} sx={{ mb: 2 }} onClick={navigateToAnalytics} variant="contained">
                  View Analytics
                </Button>
                {!data?.data?.hasInitialPassword ? (
                  <Box>
                    <Button onClick={resendCompanyInvite} variant="contained">
                      Resend Invitation
                    </Button>
                    <Box display="flex" mt={1}>
                      <InfoOutlinedIcon sx={{ color: 'grey' }} />
                      <Typography ml={0.5} variant="subtitle1">
                        You are able to resend this company's invite
                      </Typography>
                    </Box>
                  </Box>
                ) : (
                  <Box>
                    <Button variant="contained" disabled>
                      Company Onboarded
                    </Button>
                    <Box display="flex">
                      <CheckIcon sx={{ color: 'green' }} />
                      <Typography ml={1} variant="subtitle1">
                        {data?.data?.name} has been successfully onboarded.
                      </Typography>
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
          <Box
            mt={7}
            animate={{
              transition: {
                staggerChildren: 0.55,
              },
            }}
            component={motion.div}
          >
            <Box
              animate={animate}
              component={motion.div}
              initial={{ opacity: 0, y: 20 }}
              sx={{ gap: 3, display: 'flex', flexDirection: 'column' }}
            >
              <Typography variant="subtitle1" mt={1} sx={{ fontSize: 12 }}>
                Personal Information
              </Typography>

              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <FormControl variant="standard" style={{ width: '100%' }}>
                    <InputLabel shrink htmlFor="bootstrap-input">
                      Name
                    </InputLabel>
                    <AppInput
                      fullWidth
                      type="name"
                      autoComplete="name"
                      style={determineBorderColor(errors?.email?.message as any)}
                      {...register('name', {
                        required: true,
                        maxLength: 80,
                      })}
                    />

                    <FormHelperText style={{ color: 'red' }}>{errors?.name?.message as string}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl variant="standard" style={{ width: '100%' }}>
                    <InputLabel shrink htmlFor="bootstrap-input">
                      Email
                    </InputLabel>
                    <AppInput
                      fullWidth
                      type="text"
                      autoComplete="email"
                      style={determineBorderColor(errors?.email?.message as any)}
                      {...register('email', {
                        required: true,
                        maxLength: 80,
                      })}
                    />
                    <FormHelperText style={{ color: 'red' }}>{errors?.email?.message as string}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <InputLabel shrink htmlFor="bootstrap-input">
                    Phone Number
                  </InputLabel>
                  <FormControl variant="standard" style={{ width: '100%' }}>
                    <PhoneInput
                      country={'us'}
                      value={phoneNumber}
                      containerStyle={{
                        width: '100%',
                        height: 45,
                        marginTop: 7,
                      }}
                      inputStyle={{ width: '100%', height: 45 }}
                      onChange={(phone) => {
                        setValue('phoneNumber', phone);
                        setPhoneNumber(phone);
                      }}
                    />

                    <FormHelperText style={{ color: 'red' }}>{errors?.phoneNumber?.message as string}</FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>

              <Typography variant="subtitle1" mt={1} sx={{ fontSize: 12 }}>
                Address
              </Typography>
            </Box>
            <br></br>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControl variant="standard" style={{ width: '100%' }}>
                  <InputLabel shrink htmlFor="bootstrap-input">
                    Address Line 1
                  </InputLabel>

                  <AppInput
                    fullWidth
                    type="addressLine1"
                    autoComplete="addressLine1"
                    style={determineBorderColor(errors?.addressLine1?.message as any)}
                    {...register('addressLine1', {
                      required: true,
                      maxLength: 80,
                    })}
                  />
                  <FormHelperText style={{ color: 'red' }}>{errors?.addressLine1?.message as string}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl variant="standard" style={{ width: '100%' }}>
                  <InputLabel shrink htmlFor="bootstrap-input">
                    Address Line 2
                  </InputLabel>

                  <AppInput
                    fullWidth
                    type="addressLine2"
                    autoComplete="addressLine2"
                    style={determineBorderColor(errors?.addressLine2?.message as any)}
                    {...register('addressLine2', {
                      required: true,
                      maxLength: 80,
                    })}
                  />
                  <FormHelperText style={{ color: 'red' }}>{errors?.addressLine2?.message as string}</FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
            <br></br>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControl variant="standard" style={{ width: '100%' }}>
                  <InputLabel shrink htmlFor="bootstrap-input">
                    Country
                  </InputLabel>
                  <br></br>
                  <CountrySelect
                    name="country"
                    label="Country"
                    country={country}
                    //  countryISO={user?.countryISO}
                    helperText={errors.country?.message}
                    error={errors.country?.message !== undefined}
                    setCurrentStates={setCurrentStates}
                    onChange={(country, provinces) => {
                      setCountry(country);
                      setCurrentStates(provinces);
                      setValue('country', country, {
                        shouldValidate: true,
                      });
                    }}
                  />
                  <FormHelperText style={{ color: 'red' }}>{errors?.country?.message as string}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl variant="standard" style={{ width: '100%' }}>
                  <InputLabel shrink htmlFor="bootstrap-input">
                    City
                  </InputLabel>

                  <AppInput
                    fullWidth
                    type="city"
                    autoComplete="city"
                    style={determineBorderColor(errors?.city?.message as any)}
                    {...register('city', {
                      required: true,
                      maxLength: 80,
                    })}
                  />
                  <FormHelperText style={{ color: 'red' }}>{errors?.city?.message as string}</FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
            <br></br>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControl variant="standard" style={{ width: '100%' }}>
                  <InputLabel shrink htmlFor="bootstrap-input">
                    State
                  </InputLabel>
                  <br></br>
                  <ProvinceSelect
                    name="state"
                    label="State"
                    selectedProvince={state}
                    provinces={currentStates}
                    error={errors.state?.message !== undefined}
                    helperText={errors.state?.message}
                    onChange={(state) => {
                      if (!state) {
                        return;
                      }
                      setState(state);
                      setValue('state', state, {
                        shouldValidate: true,
                      });
                    }}
                  />
                  <FormHelperText style={{ color: 'red' }}>{errors?.state?.message as string}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl variant="standard" style={{ width: '100%' }}>
                  <InputLabel shrink htmlFor="bootstrap-input">
                    Zip Code
                  </InputLabel>

                  <AppInput
                    fullWidth
                    type="postalCode"
                    autoComplete="postalCode"
                    style={determineBorderColor(errors?.postalCode?.message as any)}
                    {...register('postalCode', {
                      required: true,
                      maxLength: 80,
                    })}
                  />
                  <FormHelperText style={{ color: 'red' }}>{errors?.postalCode?.message as string}</FormHelperText>
                </FormControl>
              </Grid>
            </Grid>

            <br></br>

            <Box display={'flex'} flexDirection={'column'} alignItems="flex-start">
              <Tooltip arrow placement="top" title="This ovverides a Company's ability to use a watermark in their custom videos">
                <InputLabel shrink htmlFor="watermark-toggle">
                  Watermark Access
                </InputLabel>
              </Tooltip>

              <Box display="flex" alignItems="center" mt={2}>
                {/* Toggle Switch */}
                <Switch checked={isGranted} onChange={handleToggle} inputProps={{ 'aria-label': 'Watermark Access Toggle' }} />
                {/* Conditional Container */}
                <Box ml={2} p={1} borderRadius={1} bgcolor={isGranted ? 'lightgreen' : 'lightcoral'} paddingX={'30px'}>
                  <Typography variant="body2" fontWeight="bold" color={'white'}>
                    {isGranted ? 'Granted' : 'Blocked'}
                  </Typography>
                </Box>
              </Box>
            </Box>

            <Box component={motion.div} initial={{ opacity: 0, y: 20 }} animate={animate}></Box>
          </Box>
          <Box display="flex" alignSelf="end" mt={3}>
            <LoadingButton size="medium" type="submit" variant="contained" loading={response.isLoading} onClick={handleSubmit(editCompany)}>
              Save
            </LoadingButton>
          </Box>
        </Box>
        <MessageDisplay ref={messageRef as any} />
      </Card>{' '}
      {/* <br></br>
      DEPRECATED Credit System 
      <AddCompanyCredits /> */}
      <br></br>
      <ManageCompanySubscriptions />
      <br></br>
      <ManageCompanyCustomVideoCard />
      <br></br>
      {/* DEPRECATED Packages Uncomment and fix package bug defined in notes */}
      {/* <ManageCompanyPackagesCard /> */}
    </TransitionWrapper>
  );
}
