import { baseQuery } from "./base-slice";
import { ApiError, ServiceResult } from "@core/index";
import { PaginatedList } from "@core/constants/constants";
import { ApiMethods, BaseEndpoints } from "@core/enums/enums";
import { TagDescription, createApi } from "@reduxjs/toolkit/query/react";
import {
  Subscription,
  ActionResult,
  SubscriptionStatus,
  CompanyBaseSubscription,
} from "@core/types";

const descriptionTags = [
  "PackageSubscriptions",
] as readonly TagDescription<"PackageSubscriptions">[] & string[];

export const packageSubscriptionSlice = createApi({
  reducerPath: "package-subscriptions",
  baseQuery: baseQuery,
  tagTypes: descriptionTags,
  endpoints: (builder) => ({
    getSubscriptionStatuses: builder.query<
      ServiceResult<SubscriptionStatus[], ApiError>,
      any
    >({
      query: () => `${BaseEndpoints.Subscription}/status`,
      providesTags: descriptionTags,
    }),
    getCompanySubscriptions: builder.query<
      ServiceResult<PaginatedList<Subscription[]>, ApiError>,
      any
    >({
      query: (params) => ({
        params: { ...params },
        url: `${BaseEndpoints.Subscription}/company/${params.companyId}`,
      }),
      providesTags: descriptionTags,
    }),
    getSubcriptionUsers: builder.query({
      query: ({ subscriptionId }) => ({
        url: `${BaseEndpoints.Subscription}/${subscriptionId}/users`,
      }),
      providesTags: descriptionTags,
    }),
    getCompanyBaseSubcriptions: builder.query<
      ServiceResult<PaginatedList<CompanyBaseSubscription[]>, ApiError>,
      any
    >({
      query: (params) => ({
        params: { ...params },
        url: `/${BaseEndpoints.Subscription}/company/base/${params.companyId}`,
      }),
      providesTags: descriptionTags,
    }),
    createPackageSubscription: builder.mutation<
      ServiceResult<ActionResult, ApiError>,
      any
    >({
      query: (payload) => ({
        body: payload,
        method: ApiMethods.POST,
        url: `${BaseEndpoints.Subscription}/package`,
      }),
      invalidatesTags: descriptionTags,
    }),
    createPackageSubscriptionAccess: builder.mutation<
      ServiceResult<ActionResult, ApiError>,
      any
    >({
      query: (payload) => ({
        body: payload,
        method: ApiMethods.POST,
        url: `${BaseEndpoints.Subscription}/package/access`,
      }),
      invalidatesTags: descriptionTags,
    }),
    activatePackageSubscription: builder.mutation<
      ServiceResult<ActionResult, ApiError>,
      any
    >({
      query: (payload) => ({
        method: ApiMethods.PUT,
        url: `${BaseEndpoints.Subscription}/${payload.id}/activate`,
      }),
      invalidatesTags: descriptionTags,
    }),
  }),
});

export const {
  useGetSubcriptionUsersQuery,
  useGetSubscriptionStatusesQuery,
  useGetCompanySubscriptionsQuery,
  useCreatePackageSubscriptionAccessMutation,
  useGetCompanyBaseSubcriptionsQuery,
  useCreatePackageSubscriptionMutation,
  useActivatePackageSubscriptionMutation,
} = packageSubscriptionSlice;
