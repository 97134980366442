import Swal from 'sweetalert2';
import { getDomain } from '@core/utils/utils';

import { BaseQueryFn, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { RootState } from '@features/state/reducers/combinedReducers';

const fileEndpint = ['uploadVideo'];

export const baseQuery = fetchBaseQuery({
  validateStatus(response, body) {
    return [200, 201, 400, 409, 404].includes(response.status);
  },
  baseUrl: getDomain(),
  prepareHeaders: (headers, { getState, endpoint }) => {
    // headers.set("Content-Type", "multipart/form-data");
    const token = (getState() as RootState)?.auth?.auth?.token;
    if (token) {
      headers.set('Authorization', `Bearer ${token.accessToken}`);
    }

    return headers;
  },
});

export const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (args, api, extraOptions) => {
  // console.log(args)
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && (result.error.status === 401 || result.error.status === 403)) {
    localStorage.clear();
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'Your session has expired. We are redirecting you to the login screen',
    }).then((result) => {
      // console.log('AUTH STATE', (api.getState() as RootState)?.auth);
      window.location.replace('/auth/login');
    });
  }

  return result;
};
