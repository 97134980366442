import { Card, Box, Typography, InputLabel, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "video-player-for-react/dist/index.css";
import { Shimmer, Breathing } from "react-shimmer";
import { ReactVideoPlayer } from "video-player-for-react";
import { useGetVideoByIdQuery } from "@features/state/slices/api/video-slice";
import VideoPlayer from "@components/VideoPlayer";
import { Video } from "@core/types";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import VisibilityIcon from "@mui/icons-material/Visibility";
import moment from "moment";
import { BackButton } from "@components/BackButton";

type Props = {};

export const VideoPreview: React.FC<Props> = ({}) => {
  const { id } = useParams();

  const { data, error, isError, isFetching, isLoading, isSuccess, refetch } =
    useGetVideoByIdQuery(
      {
        id,
      },
      {
        refetchOnMountOrArgChange: true,
      }
    );

  const [video, setVideo] = useState<Video>({} as any);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isFetching && !isError && isSuccess) {
      setVideo(data.data!);
    }
  }, [isFetching, isSuccess, isError]);

  return (
    <Card sx={{ padding: 3 }}>
      <Box mb={1}>
        <BackButton />
      </Box>
      <Box>
        <Box sx={{ width: "100%" }}>
          {!isFetching && Object.keys(video).length > 0 && !isError ? (
            <VideoPlayer
              video={video}
              videoType={"clip"}
              onReady={(e) => null}
            />
          ) : (
            <Shimmer width={800} height={600} />
          )}
        </Box>
      </Box>
      <Typography align="left" variant="h6" mt={1}>
        {video.videoName + " Preview"}
      </Typography>
    </Card>
  );
};
