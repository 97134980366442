import toast from 'react-hot-toast';
import Modal from '@mui/material/Modal';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { AppInput } from '@components/Styled';
import videojs, { VideoJsPlayer } from 'video.js';
import AppPageHeader from '@components/PageHeader';
import React, { useEffect, useState } from 'react';
import FileUpload from 'react-material-file-upload';
import { yupResolver } from '@hookform/resolvers/yup';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import TransitionWrapper from '@components/TransitionWrapper';
import ProgressiveImage from 'react-progressive-graceful-image';
import { determineBorderColor, useAuth } from '@core/utils/utils';
import { PromotionService } from '@core/services/PromotionService';
import { UpdateCustomVideoPromotion } from '@core/constants/schemas';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { toggleLoading } from '@features/state/slices/local/loading-slice';
import { acceptedImageFormats, acceptedVideoFormats } from '@core/constants/constants';
import { useGetCustomVideoBannerQuery } from '@features/state/slices/api/custom-video-slice';
import { Box, Button, CircularProgress, FormControl, FormHelperText, InputLabel, Skeleton, Typography } from '@mui/material';
import { useNavigate } from 'react-router';

const style = {
  p: 4,
  top: '50%',
  left: '50%',
  width: 700,
  boxShadow: 0,
  borderRadius: 1,
  border: '0px solid #000',
  bgcolor: 'background.paper',
  transform: 'translate(-50%, -50%)',
  position: 'absolute' as 'absolute',
};

let options: videojs.PlayerOptions = {
  fluid: true,
  autoplay: false,
  controls: true,
  responsive: true,
  plugins: {
    httpSourceSelector: {
      default: 'auto',
    },
  },
  sources: [],
};

type Props = {};

const ManagePromotions = (props: Props) => {
  const { user } = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [videoFiles, setVideoFiles] = useState<File[]>([]);
  const playerRef = React.useRef<VideoJsPlayer | null>(null);
  const videoRef = React.useRef<any | undefined>(null as any);
  const [thumbnailFiles, setThumbnailFiles] = useState<File[]>([]);

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(UpdateCustomVideoPromotion),
  });

  const { data, isError, isFetching, refetch, isSuccess } = useGetCustomVideoBannerQuery(
    {},
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  const onThumbnailChange = (files: File[]) => {
    setThumbnailFiles(files);
    setValue<string>('thumbnailFile', files[0]);
  };

  const onVideoChange = (files: File[]) => {
    setVideoFiles(files);
    setValue<string>('videoFile', files[0]);
  };

  useEffect(() => {
    if (isSuccess && !isError && !isFetching) {
      setValue('title', data.data?.title);
      setValue('description', data.data?.description);
      setValue('thumbnail', [data.data?.thumbnail]);
      setValue('videoUrl', [data.data?.videoUrl]);
    }
  }, [isSuccess, isError, isFetching]);

  useEffect(() => {
    // Make sure Video.js player is only initialized once
    if (!playerRef.current && videoRef?.current != null) {
      // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode.
      const videoElement = document.createElement('video-js');

      videoElement.classList.add('vjs-big-play-centered');
      videoRef?.current?.appendChild(videoElement);

      const player = (playerRef!.current = videojs(videoElement, options, () => {
        //onReady && onReady(player);
      }));
      window.videojs = player as any;
    } else {
      const player = playerRef.current;

      if (player != null) {
        player.autoplay(options.autoplay!);
        options.sources?.push({
          src: data?.data?.videoUrl ?? '',
          type: 'video/mp4',
        });

        player.src(options.sources!);
      }
    }
  }, [options, videoRef, isFetching, data, isError, isSuccess]);

  useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player && !player.isDisposed()) {
        const dispose = null as any;
        player.dispose();
        playerRef.current! = dispose;
      }
    };
  }, [playerRef]);

  const navigateToUpdateBanner = () => {
    navigate('upload');
  };

  return (
    <TransitionWrapper>
      <div>{isFetching && <CircularProgress />}</div>
      <div>
        <div>
          <AppPageHeader
            title="Promotions"
            subtitle={`${user?.name}, these are your recent promotional items`}
            trailing={
              <Button
                sx={{ mt: { xs: 2, md: 0 } }}
                variant="contained"
                onClick={navigateToUpdateBanner}
                startIcon={<AddTwoToneIcon fontSize="small" />}
              >
                Update Banner
              </Button>
            }
          />

          <div className="col-span-full bg-white dark:bg-slate-800 shadow-sm rounded-md border border-slate-200 dark:border-slate-700">
            <div className="flex space-x-5">
              <ProgressiveImage src={data?.data?.thumbnail!} placeholder={data?.data?.thumbnail}>
                {(src, loading) =>
                  loading ? (
                    <Skeleton
                      width={900}
                      height={900}
                      animation="wave"
                      variant="rectangular"
                      className="cursor-pointer rounded-bl-lg rounded-tl-lg"
                      sx={{ borderBottomLeftRadius: 10, borderBottomRightRadius: 10 }}
                    />
                  ) : (
                    <div className="relative inline-block cursor-pointer">
                      <img
                        src={src}
                        width={900}
                        height={900}
                        crossOrigin="anonymous"
                        className="cursor-pointer rounded-bl-lg rounded-tl-lg"
                      />
                      <div className="absolute inset-0 bg-slate-900 bg-opacity-30 hover:bg-slate-700 rounded-bl-lg rounded-tl-lg"></div>
                      <div className="absolute inset-0 flex items-center justify-center">
                        <PlayCircleOutlineIcon className="text-white h-25 w-25" />
                      </div>
                    </div>
                  )
                }
              </ProgressiveImage>
              <Box p={4}>
                <Typography variant="h1">{data?.data?.title}</Typography>
                <Box>
                  <Typography sx={{ color: 'grey' }} mt={2}>
                    {data?.data?.description}
                  </Typography>
                </Box>
              </Box>
            </div>
          </div>
        </div>
      </div>
    </TransitionWrapper>
  );
};

export default ManagePromotions;
